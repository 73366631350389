import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const order = (state: RootState) => state.order
export const selectListedItem = createSelector(
  order,
  (state) => state.listedItem
)
export const selectOrderHeader = createSelector(
  order,
  (state) => state.orderHeader
)
export const selectOrderLoadingPage = createSelector(
  order,
  (state) => state.loadingPage
)
export const selectLoadingError = createSelector(
  order,
  (state) => state.loadingError
)
export const selectCreateShippingLabelError = createSelector(
  order,
  (state) => state.createShippingLabelError
)
