export type MsalEmptyDataAuthPayload = {
  readonly accessMsalToken?: string
}

export type RegisterPayload = {
  readonly name: string
  readonly email: string
  readonly password: string
}

export type RegisterModalVisibleStateType = {
  isSubmitting: boolean
  isOpen: boolean
}

export type LoginPayload = {
  readonly email: string
  readonly password: string
}

export type ForgotPasswordPayload = {
  readonly email: string
}

export type UserUpdatePayload = {
  readonly name: string
}

export type ActivatePayload = {
  readonly token: string
}

export type ResetPasswordPayload = {
  readonly token: string | undefined
  readonly password: string
}

export type ResetPasswordVisibleStateType = {
  isSubmitting: boolean
  isOpen: boolean
  error?: null | string
}

export type ChangeEmailPayload = {
  readonly email: string
}

export type ChangePasswordPayload = {
  readonly passwordOld: string
  readonly passwordNew: string
}

export enum EmailTypeToResend {
  Welcome = 1,
}
