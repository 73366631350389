import React, {useEffect, useRef} from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { SliderProps } from './slider-component.types'
import { useSliderComponentStyles } from './slider-component.styles'
import { axiosInstance } from '../../../../api'
import {useDispatch} from 'react-redux'
import {trackException} from '../../../../store/app-insights/reducer'

/*Create Img Url*/
const buildBigImageUrl = (imageId: number) => `listed-items/images/${imageId}/large.jpg`
const buildSmallImageUrl = (imageId: number) => `listed-items/images/${imageId}/small.jpg`

export const SliderComponent: React.FC<SliderProps> = (props: SliderProps) => {
  const { images } = props
  const classes = useSliderComponentStyles()

  const _images = images.map((item) => {
    return {
      original: `${buildBigImageUrl(item)}`,
      thumbnail: `${buildSmallImageUrl(item)}`,
    }
  })

  return (
    <>
      <div className={classes.slider}>
        <ImageGallery
          items={_images}
          renderThumbInner={(props: ThumbType)=> <Thumb {...props}/>}
          showFullscreenButton={false}
          lazyLoad
          renderItem={(props: ItemType)=> <Item {...props}/>}
          showPlayButton={images.length > 1}
        />
      </div>
    </>
  )
}

type ItemType = {
  original: string
}

type ThumbType = {
  thumbnail: string
}

const Thumb: React.FC<ThumbType> = (props) => {
  const {thumbnail} = props
  const classes = useSliderComponentStyles()
  const ref = useRef(null)
  const dispatch = useDispatch()

  useEffect(()=> {
    axiosInstance.get(thumbnail, { responseType: 'blob' }).then((response) => response.data)
    .then((blob: Blob) => {
      const url = URL.createObjectURL(blob);
      (ref.current! as HTMLDivElement).style.backgroundImage = `url(${url})`
    })
    .catch(e => dispatch(trackException({e, source: 'SliderGetThumb'})))
  }, [])

  return(
    <div
      className={classes.imageThumb}
      ref={ref}
    ></div>
  )
}

const Item: React.FC<ItemType>= (props)=> {
  const { original } = props
  const classes = useSliderComponentStyles()
  const ref = useRef(null)

  useEffect(()=> {
    axiosInstance.get(original, { responseType: 'blob' })
    .then(({ data }) => data)
    .then((blob: Blob) => {
      const url = URL.createObjectURL(blob);
      (ref.current! as HTMLDivElement).style.backgroundImage = `url(${url})`
    })
  }, [])

  return(
    <div className={classes.imageArea}>
      <div className={classes.imgBgBlock}>
        <div
          ref={ref}
          className={classes.imageHolder}
        />
      </div>
    </div>
  )
}

