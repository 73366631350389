import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { useExpandableTextStyles } from './expandable-text.styles'
import { useInfoComponentStyles } from '../info-component.styles'
import { Typography } from '@material-ui/core'
import { transformToMultiLine } from '../../../../../utils/strings'
import { useTranslation } from 'react-i18next'

type ExpandableTextType = {
  description: string | undefined
  children?: React.ReactNode
}

export const ExpandableText: React.FC<ExpandableTextType> = ({
  description = '',
}) => {
  const { descriptionText } = useInfoComponentStyles()
  const classes = useExpandableTextStyles()
  const [isExpand, setIsExpand] = useState(false)
  const { t } = useTranslation()

  return (
    <div
      className={
        isExpand ? classes.expandHolderOpen : classes.expandHolderClose
      }
    >
      <div>
        <div
          className={classes.expandBtnBlock}
          onClick={() => setIsExpand(!isExpand)}
        >
          {!isExpand ? (
            <>
              <ExpandMoreIcon /> {t('itemDetails.showMore')}
            </>
          ) : (
            <>
              <ExpandLessIcon /> {t('itemDetails.hide')}
            </>
          )}
        </div>
      </div>
      <Typography className={descriptionText} variant="body1" component="p">
        {transformToMultiLine(description)}
      </Typography>
    </div>
  )
}
