import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useShopStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 30,
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    mainText: {
      maxWidth: '40%',
      textAlign: 'left',
      marginBottom: 30,
    },
    paper: {
      padding: theme.spacing(2),
    },
    buttonsContainer: {
      justifyContent: 'space-between',
      display: 'flex',
      width: '100%',
    },
    mainButton: {
      marginBottom: 10,
    },
  })
)
