declare global {
  interface Window{
    globalConfig: {
      REACT_APP_MAIN_DOMAIN: string,
      REACT_APP_DEFAULT_URL_API: string,

      REACT_APP_INSTRUMENTATION_KEY: string,
      REACT_APP_STRIPE_PUBLIC_KEY: string,
      REACT_APP_HELP_SITE_BASE: string,
      REACT_APP_NAMES_SIGN_UP_SIGN_IN_AUTHORITY: string,
      REACT_APP_NAMES_AUTHORITY_DOMAIN: string,

      REACT_APP_NAMES_CLIENT_URL: string,
      REACT_APP_SCOPE_URL: string
      REACT_APP_NON_PROFIT_REGISTER: string
      REACT_APP_TERMS: string
    }
  }
}
import { LogLevel } from '@azure/msal-browser'

const factoryProd = (): any => {
    const b2cPolicies = {
      authorities: {
        signUpSignIn: {
          authority: window.globalConfig.REACT_APP_NAMES_SIGN_UP_SIGN_IN_AUTHORITY!,
        },
      },
      authorityDomain: window.globalConfig.REACT_APP_NAMES_AUTHORITY_DOMAIN!,
    }

    const msalConfig = {
      auth: {
        clientId: window.globalConfig.REACT_APP_NAMES_CLIENT_URL!,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (level: any, message: any, containsPii: any) => {
            if (containsPii) {
              return
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message)
                return
              case LogLevel.Info:
                console.info(message)
                return
              case LogLevel.Verbose:
                console.debug(message)
                return
              case LogLevel.Warning:
                console.warn(message)
                return
              default:
                return
            }
          },
        },
      },
    }

    const msalRequest = {
      scopes: [window.globalConfig.REACT_APP_SCOPE_URL!],
    }

    const stripePublicKey = window.globalConfig.REACT_APP_STRIPE_PUBLIC_KEY
    const instrumentationKey = window.globalConfig.REACT_APP_INSTRUMENTATION_KEY
    const configUrl = window.globalConfig.REACT_APP_DEFAULT_URL_API
    const nonProfitRegister = window.globalConfig.REACT_APP_NON_PROFIT_REGISTER
    const terms = window.globalConfig.REACT_APP_TERMS

    return {
      msalRequest,
      msalConfig,
      stripePublicKey,
      instrumentationKey,
      configUrl,
      nonProfitRegister,
      terms
    }
}

const factoryDev = ()=> {
  const b2cPolicies = {
    authorities: {
      signUpSignIn: {
        authority: process.env.REACT_APP_NAMES_SIGN_UP_SIGN_IN_AUTHORITY!,
      },
    },
    authorityDomain: process.env.REACT_APP_NAMES_AUTHORITY_DOMAIN!,
  }

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_NAMES_CLIENT_URL!,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level: any, message: any, containsPii: any) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message)
              return
            case LogLevel.Info:
              console.info(message)
              return
            case LogLevel.Verbose:
              console.debug(message)
              return
            case LogLevel.Warning:
              console.warn(message)
              return
            default:
              return
          }
        },
      },
    },
  }

  const msalRequest = {
    scopes: [process.env.REACT_APP_SCOPE_URL!],
  }

  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY!
  const instrumentationKey = process.env.REACT_APP_INSTRUMENTATION_KEY
  const configUrl = process.env.REACT_APP_DEFAULT_URL_API
  const configDomain = process.env.REACT_APP_MAIN_DOMAIN
  const nonProfitRegister = window.globalConfig.REACT_APP_NON_PROFIT_REGISTER
  const terms = window.globalConfig.REACT_APP_TERMS

  return {
    msalRequest,
    msalConfig,
    stripePublicKey,
    instrumentationKey,
    configUrl,
    configDomain,
    nonProfitRegister,
    terms
  }
}

const {
  msalRequest,
  msalConfig,
  stripePublicKey,
  instrumentationKey,
  configUrl,
  configDomain,
  nonProfitRegister,
  terms
} = (process.env && process.env.NODE_ENV !== 'development') ? factoryProd() : factoryDev()

export {
  msalRequest,
  msalConfig,
  stripePublicKey,
  instrumentationKey,
  configDomain,
  configUrl,
  nonProfitRegister,
  terms
}
