import {
  Form,
  FormInitialValues,
  ValidateFn,
  ValidationErrors,
  ValidationSchema,
} from './use-form.types'
import { curry } from '../../utils'

const FORM_DEFAULT_VALUES = {
  errors: {},
  focused: false,
  dirty: false,
  touched: false,
  reduxErrors: [],
}

export const createForm = (schema: FormInitialValues): Form => {
  return Object.fromEntries(
    Object.entries(schema).map(([key, value]) => [
      key,
      { value, ...FORM_DEFAULT_VALUES },
    ])
  )
}

const curryGetErrors = (
  form: Form,
  fieldName: string,
  validators: readonly ValidateFn[]
): ValidationErrors => {
  return (
    validators
      .map((validator) => validator(form, fieldName))
      .filter((error) => !!error) as readonly ValidationErrors[]
  ).reduce(
    (prev: ValidationErrors, curr: ValidationErrors) => ({ ...prev, ...curr }),
    {} as ValidationErrors
  )
}
export const getErrors = curry(curryGetErrors)

const curryGetErrorsFromSchema = (
  validationSchema: ValidationSchema,
  form: Form
) => {
  return Object.fromEntries(
    Object.entries(validationSchema).map(([fieldName, value]) => [
      fieldName,
      getErrors(form, fieldName, value)[fieldName],
    ])
  )
}
export const getErrorsFromSchema = curry(curryGetErrorsFromSchema)

export const hasErrors = (errors: ValidationErrors): boolean =>
  !!Object.entries(errors).filter(([, value]) => !!value).length
