import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import { PublicClientApplication, EventType } from '@azure/msal-browser'
import { msalConfig, configDomain, configUrl } from './env-config/'

const domain = configDomain || ''
const url =  configUrl || ''

/* Base Api Url */
export const baseURL = `${domain}${url}`

/* Base Domain */
export const baseDomain = domain

const msalInstance = new PublicClientApplication(msalConfig)
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    //@ts-ignore
    event.payload.account
  ) {
    //@ts-ignore
    msalInstance.setActiveAccount(event.payload.account)
  }
})

//===================================================//
class AxiosInterceptorManager{
  constructor() {
    // eslint-disable-next-line functional/no-this-expression
    this.axiosInstance = axios.create({
      baseURL,
      withCredentials: true
    })
  }

  private axiosInstance: AxiosInstance
  private token: string | null  = null
  getAxiosInstance(){
    return this.axiosInstance
  }

  setToken(token: string | null){
    this.token = token

    this.axiosInstance.interceptors.request.use((config)=>{
      config.headers = {
        ...config.headers
      }

      config.headers['Authorization'] = 'Bearer ' + this.token
      return config
    })
  }

  /** Post **/
  post<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.axiosInstance.post(url, data, config)
  }

  /** Get **/
  get<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.axiosInstance.get(url, config)
  }

  /** Delete **/
  delete<T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.axiosInstance.delete(url, config)
  }

  /** Put **/
  put<T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: AxiosRequestConfig<D>): Promise<R>{
    return this.axiosInstance.put(url, data, config)
  }
}

const axiosManager = new AxiosInterceptorManager()
const axiosInstance = axiosManager.getAxiosInstance()

export { msalInstance, axiosManager, axiosInstance }
