import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import {
  ItemState,
  Nonprofit,
  NonprofitCategory,
  ItemDetails,
} from '../../models/item-details'
import { listedItemsApi } from '../../services/api/listed-items'
import { SimpleFunc } from '../../models/profile'
import { ApiError } from '../../services/api/api-error-data'
import { useTranslation } from 'react-i18next'
import { selectDeliveryType } from '../../store/checkout-page/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { setDeliveryType } from '../../store/checkout-page/reducer'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { AppDialogVariants } from '../../store/app-dialog/types'
import {
  DeliveryType,
  DraftDataType,
  KindImageEnum,
} from './draft-editor/draft-editor.constants'
import { SelectNonprofitProps } from '../donate-page/donate.models'
import { nonprofitsApi } from '../../services/api/nonprofits'
import { ImageObject } from '../donate-page/item-images/item-images.hook'
import { selectUser } from '../../store/auth/selectors'
import { Form } from '../../hooks/use-form'
import useValidateParcel from '../../hooks/use-validate-parcel/use-validate-parcel.hooks'
import { useOverlayPreloader } from '../../hooks/use-overlay-preloader'
import {trackException} from '../../store/app-insights/reducer'

type UseOwnItemDetailsPage = {
  detailProps?: ItemDetails & { nonprofitName: string }
  loading: boolean
  getOwnItemDetails: SimpleFunc
  deliveryType: string
  setInStoreDeliveryType: (value: string) => void
  draftData: DraftDataType
  setImages: (value: ImageObject[]) => void
  initialImages: string[]
  isDirtyDraft: boolean
  loadSelectCategory: (nonprofitCategoryId: number) => void
  submitItem: (value: any) => void
  setIsDirtyDraft: (value: boolean) => void
  ownItemState?: ItemState
  updateState: (state: ItemState) => void
} & SelectNonprofitProps

const initial = {
  name: '',
  description: '',
  width: 0,
  height: 0,
  length: 0,
  weight: 0,
  condition: '',
  price: 0,
  delivery: DeliveryType.Shipping,
  images: [],
}

const ItemConditionList = {
  0: 'new',
  1: 'used',
}

export const useOwnItemDetailsPage = (): UseOwnItemDetailsPage => {
  const navigate = useNavigate()
  const { itemId } = useParams()
  const dispatch = useDispatch()
  const [detailProps, setDetailProps] =
    useState<ItemDetails & { nonprofitName: string }>()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { setAppDialog } = useAppDialog()
  const deliveryType = useSelector(selectDeliveryType)
  const [draftData, setDraftData] = useState<DraftDataType>({ ...initial })
  const [initialImages, setInitialImages] = useState<string[]>([])
  const [ownItemState, setOwnItemState] =
    useState<ItemState | undefined>(undefined)
  const shippingAddressExists = useSelector(selectUser).shippingAddressExists
  const { setIsShowOverlayPreloader } = useOverlayPreloader()

  /**Select**/
  const [categories, setCategories] = useState<NonprofitCategory[]>([])
  const [selectedCategory, _setSelectedCategory] = useState<NonprofitCategory>()
  const [nonprofits, setNonprofits] = useState<Nonprofit[]>([])
  const [selectedNonprofit, _setSelectedNonprofit] =
    useState<Nonprofit | null>()
  const [isDirtyDraft, setIsDirtyDraft] = useState(false)

  /**Price**/
  const setSelectedCategory = (selectedCategory: NonprofitCategory) => {
    _setSelectedCategory(selectedCategory)
    setSelectedNonprofit(null)
    setNonprofits([])
  }

  /** Parcel */
  const { validateParcel } = useValidateParcel(
    t('editItemDetailsPage.draftError.title')
  )

  const setSelectedNonprofit = (selectedNonprofit: Nonprofit | null) => {
    _setSelectedNonprofit(selectedNonprofit)
    setIsDirtyDraft(true)
  }

  /**Get Categories*/
  const loadSelectCategory = async (
    initialNonprofitCategoryId: number | string
  ) => {
    await nonprofitsApi.categories().then((data) => {
      setCategories(data)
      loadSelectNonprofit(initialNonprofitCategoryId)
      const initialCategory = data.filter(
        (item: NonprofitCategory) =>
          item.nonprofitCategoryId === initialNonprofitCategoryId
      )[0]
      _setSelectedCategory(initialCategory)
    }).catch(e => dispatch(trackException({e, source: 'loadSelectCategory'})))
  }

  /**Get nonprofits*/
  const loadSelectNonprofit = async (nonprofitCategoryId: number | string) => {
    await nonprofitsApi
      .lookupData({
        pageSize: '',
        pageIndex: '',
        categoryId: nonprofitCategoryId.toString(),
        searchName: '',
      })
      .then((data) => {
        setNonprofits(data)
        setIsDirtyDraft(false)
      })
      .catch(e => dispatch(trackException({e, source: 'loadSelectNonprofit'})))
  }

  /*Get Listed Item Details*/
  const getOwnItemDetails = async () => {
    await listedItemsApi
      .getOwnItemById(itemId!)
      .then((data) => {
        setDetailProps(data)
        setOwnItemState(data.state)
        const {
          name,
          description = '',
          length,
          height,
          weight,
          width,
          itemCondition,
          images,
          nonprofit,
          currentPrice,
        } = data
        const condition =
          ItemConditionList[itemCondition as keyof typeof ItemConditionList] ||
          ''

        const initialImages = images.map((item: number) => {
          return {
            kind: KindImageEnum.existing,
            existingImageId: item,
          }
        })

        setDraftData({
          ...draftData,
          name,
          description,
          width,
          length,
          height,
          weight,
          condition,
          images: initialImages,
          price: currentPrice,
        })
        //@ts-ignore
        setInitialImages(images)
        setSelectedNonprofit(nonprofit)
        return data.nonprofit.nonprofitCategoryId
      })
      .then((nonprofitCategoryId) => {
        loadSelectCategory(nonprofitCategoryId ? nonprofitCategoryId : 0)
      })
      .catch((reason) => {
        setAppDialog({
          isShow: true,
          title: 'Error',
          message: (reason as ApiError).getErrorMessage(t),
          variant: AppDialogVariants.error,
          actions: null,
          closeHandler: () => navigate('/'),
        })
        dispatch(trackException({e: reason, source: 'getOwnItemDetails'}))
      })
      .finally(() => {
        setIsShowOverlayPreloader(false)
        setLoading(false)
      })
  }

  const submitItem = async (form: Form, isPublish?: boolean) => {
    const { height, length, weight, width } = form

    if (isPublish) {
      setIsShowOverlayPreloader(true)
      setLoading(true)
      validateParcel({
        height: height.value as number,
        length: length.value as number,
        weight: weight.value as number,
        width: width.value as number,
      })
        .then((result) => {
          if (
            result &&
            Array.isArray(result.rates) &&
            result.rates.length > 0
          ) {
            handleItemSubmit(form, isPublish)
          } else {
            setAppDialog({
              isShow: true,
              title: t('editItemDetailsPage.draftError.title'),
              variant: AppDialogVariants.error,
              message: result?.messages.reduce(
                (acc, curr) => acc + ' ' + curr.text,
                ''
              ),
            })
          }
        })
        .catch(e => dispatch(trackException({e, source: 'submitItem'})))
        .finally(() => {
          setIsShowOverlayPreloader(false)
          setLoading(false)
        })
    } else {
      handleItemSubmit(form, isPublish)
    }
  }

  const handleItemSubmit = async (form: Form, isPublish?: boolean) => {
    setIsShowOverlayPreloader(true)
    setLoading(true)
    const {
      price,
      description,
      height,
      length,
      weight,
      width,
      condition,
      name,
    } = form

    const draftImages = draftData.images.map(
      ({ existingImageId, newImageGuid }) => {
        return existingImageId
          ? {
              existingImageId,
              kind: KindImageEnum.existing,
            }
          : {
              newImageGuid,
              kind: KindImageEnum.new,
            }
      }
    )

    /** Save Draft **/
    await listedItemsApi
      .update(itemId!, {
        name: name.value as string,
        price: price.value as number,
        description: description.value as string,
        height: height.value as number,
        length: length.value as number,
        weight: weight.value as number,
        width: width.value as number,
        itemCondition: condition.value as string,
        isDraft: true,
        nonprofitId: selectedNonprofit?.nonprofitId || null,
        //@ts-ignore
        images: draftImages?.length ? [...draftImages] : [],
      })
      .then(() => {
        setIsDirtyDraft(false)
        !isPublish &&
          setAppDialog({
            isShow: true,
            title: `${t('editItemDetailsPage.draftSuccess.title')}`,
            message: `${t('editItemDetailsPage.draftSuccess.saveDraft')} ${
              ownItemState === ItemState.Active
                ? t('editItemDetailsPage.movedToDraft')
                : ''
            }`,
            variant: AppDialogVariants.success,
          })
        !isPublish && getOwnItemDetails()
        isPublish && publishItem()
      })
      .catch((e) => {
        const error = new ApiError(e).getErrorMessage(t)
        setAppDialog({
          isShow: true,
          title: t('editItemDetailsPage.draftError.title'),
          message: error ?? t('editItemDetailsPage.draftError.saveDraft'),
          variant: AppDialogVariants.error,
          actions: null,
        })
        dispatch(trackException({e, source: 'listedItemsApiUpdate'}))
      })
      .finally(() => {
        setIsShowOverlayPreloader(false)
        setLoading(false)
      })
  }

  /** Publish Item **/
  const publishItem = async () => {
    setIsShowOverlayPreloader(true)
    setLoading(true)

    /**To publish**/
    await listedItemsApi
      .updateState(itemId!, { state: ItemState.Active })
      .then(() => {
        getOwnItemDetails()
        setAppDialog({
          isShow: true,
          title: t('editItemDetailsPage.publishSuccess.title'),
          message: t('editItemDetailsPage.publishSuccess.publish'),
          variant: AppDialogVariants.success,
          actions: null,
        })
      })
      .catch((e) => {
        const error = new ApiError(e).getErrorMessage(t)
        setAppDialog({
          isShow: true,
          title: t('editItemDetailsPage.draftError.title'),
          message: error ?? t('editItemDetailsPage.draftError.publishError'),
          variant: AppDialogVariants.error,
          actions: null,
        })
        dispatch(trackException({e, source: 'listedItemsApiPublish'}))
      })
      .finally(() => {
        setIsShowOverlayPreloader(false)
        setLoading(false)
      })
  }

  /** Change Item State **/
  const updateState = async (state: ItemState) => {
    setLoading(true)
    setIsShowOverlayPreloader(true)
    await listedItemsApi
      .updateState(itemId!, { state })
      .then(() => {
        setOwnItemState(state)
        getOwnItemDetails()
      })
      .catch((e) => {
        setIsShowOverlayPreloader(false)
        setLoading(false)
        setAppDialog({
          isShow: true,
          title: t('editItemDetailsPage.updateState.title'),
          message: t('editItemDetailsPage.updateState.updateStateMessage'),
          variant: AppDialogVariants.error,
          actions: null,
        })
        dispatch(trackException({e, source: 'listedItemsApiUpdateState'}))
      })
  }

  /** Set Images**/
  const setImages = (images: ImageObject[]) => {
    const draftImages = images.map((item) => {
      const { isTemp, id } = item
      return isTemp
        ? {
            kind: KindImageEnum.new,
            newImageGuid: id,
          }
        : {
            kind: KindImageEnum.new,
            existingImageId: id,
          }
    })

    setIsDirtyDraft(true)
    setDraftData({
      ...draftData,
      images: [...draftImages],
    })
  }

  const setInStoreDeliveryType = (value: string) => {
    dispatch(setDeliveryType(value))
  }

  const getCategoryId = () => {
    return selectedCategory?.nonprofitCategoryId?.toString() || ''
  }

  return {
    detailProps,
    loading,
    getOwnItemDetails,
    deliveryType,
    setInStoreDeliveryType,
    draftData,
    isDirtyDraft,
    ownItemState,

    //Images
    setImages,
    initialImages,
    updateState,

    //Select Data
    loadSelectCategory,
    submitItem,
    categories,
    shippingAddressExists,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    nonprofits,
    setNonprofits,
    selectedNonprofit,
    setSelectedNonprofit,
    categoryRequest: nonprofitsApi.categories,
    setIsDirtyDraft,
    nonprofitRequest: () =>
      nonprofitsApi.lookupData({
        pageSize: '',
        pageIndex: '',
        categoryId: getCategoryId(),
        searchName: '',
      }),
  }
}
