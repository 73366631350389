import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAppHeaderLoadingIndicatorStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes imageLoadingAnimation': {
      '0%': {
        backgroundPosition: '-2000px 0',
      },
      '100%': {
        backgroundPosition: '2000px 0',
      },
    },

    loadingStatus: {
      left: 0,
      top: 0,
      position: 'absolute',
      background:
        'linear-gradient(to right, #ffffff00 10%, #ffffff21 40%, #ffffff00 70%)',
      animationDuration: '5s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$imageLoadingAnimation',
      animationTimingFunction: 'linear',
      width: '100%',
      height: theme.spacing(8),
      display: 'inline-box',

      [theme.breakpoints.down('xs')]: {
        animationDuration: '25s',
        height: theme.spacing(7),
      },
    },
  })
)
