import React, { useState } from 'react'
import { useItemImagesStyles } from '../item-images.styles'
import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

interface IProps {
  onFilesSelectedHandler: (files: File[]) => void
  uploadingProgress?: number
}

export const UploadArea: React.FC<IProps> = ({
  onFilesSelectedHandler,
  uploadingProgress,
}): React.ReactElement => {
  const classes = useItemImagesStyles()
  const { t } = useTranslation()
  const [dragEffect, setDragEffect] = useState(false)

  const onFileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) return
    onFilesSelectedHandler(Array.from(event.target.files))
  }

  const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()

    setDragEffect(false)
    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0)
      return
    onFilesSelectedHandler(Array.from(event.dataTransfer.files))
  }
  const onDragOverHandler = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()

    event.dataTransfer.dropEffect = 'copy'
    setDragEffect(true)
  }
  const onDragExitHandler = () => {
    setDragEffect(false)
  }

  return (
    <div className={classes.uploadArea}>
      <div
        className={`${classes.dragArea} ${dragEffect ? 'highlight' : ''}`}
        onDrop={onDropHandler}
        onDragOver={onDragOverHandler}
        onDragLeave={onDragExitHandler}
      >
        <img src="/images/upload-icon.png" alt="upload" height={86} />
        <div className={classes.dragTitle}>{t('donation.dragToUpload')}</div>
      </div>
      <Button
        variant="contained"
        component="label"
        className={classes.uploadButton}
      >
        {t('donation.browseImageButton')}
        <input
          type="file"
          onChange={onFileChangeHandler}
          hidden
          accept=".jpg, .png"
        />
      </Button>
      {uploadingProgress !== undefined && uploadingProgress >= 0 && (
        <div className={classes.progressOverlay}>
          {uploadingProgress < 100 && (
            <span>
              {t('donation.progressTitle').replace(
                '{0}',
                uploadingProgress.toString()
              )}
            </span>
          )}
          {uploadingProgress >= 100 && (
            <span>{t('donation.progressFinishingTitle')}</span>
          )}
        </div>
      )}
    </div>
  )
}

export default UploadArea
