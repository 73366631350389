import React, { useState, useEffect } from 'react'
import { useShippingPage } from './shipping-page.hook'
import { validationRules } from './shipping-page.constants'
import { PageWrapper } from '../page-wrapper'
import { hasErrors, UseForm } from '../../../hooks/use-form'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Slide,
  Snackbar,
} from '@material-ui/core'
import { ShippingFormTextField } from './shipping-form-text-field'
import { useTranslation } from 'react-i18next'
import { useAppDialog } from '../../../hooks/use-app-dialog'
import { ShippingFormAutocomplete } from './shipping-form-autocomplete'
import { Alert } from '@material-ui/lab'
import { AppDialogVariants } from '../../../store/app-dialog/types'

export const ShippingPage: React.FC = () => {
  const {
    dataLoadedStatus,
    address,
    regions,
    showSnackbar,
    dirtyForm,
    isValid,
    isAddressChanged,

    saveChangesAsync,
    setShowSnackbar,
    setDirtyForm,
    setIsAddressChanged,
  } = useShippingPage()
  const { t } = useTranslation()
  const { setAppDialog, setIsShow } = useAppDialog()
  const isAddressEntered =
    address.name &&
    address.addressLine1 &&
    address.city &&
    address.region &&
    address.postalCode
  const savedAddress = `
  ${address.addressLine1 && address.addressLine1 + ','} 
  ${address.addressLine2 && address.addressLine2 + ','}
  ${address.city && address.city + ','}
  ${address.region && address.region + ','}
  ${address.postalCode && address.postalCode}
  `

  // If not original address entered by user// Shippo found out valid address
  useEffect(() => {
    isAddressChanged &&
      isValid &&
      setAppDialog({
        isShow: true,
        variant: AppDialogVariants.success,
        message: (
          <span>
            {`${t('accountShipping.addressValidSaved')} `}{' '}
            <div>
              {' '}
              {t('accountShipping.address')}: <b>{savedAddress}</b>
            </div>
          </span>
        ),
        title: t('accountShipping.addressSaved'),
        closeHandler: () => setIsAddressChanged(false),
        actions: (
          <Button
            onClick={() => {
              setIsAddressChanged(false)
              setIsShow(false)
            }}
          >
            Ok
          </Button>
        ),
      })
  }, [isAddressChanged])

  if (!dataLoadedStatus) {
    return <CircularProgress />
  }

  return (
    <PageWrapper>
      {showSnackbar && (
        <CustomSnackbar
          mode={isValid ? SnackbarMode.SUCCESS : SnackbarMode.WARNING}
          onExited={() => setShowSnackbar(false)}
        />
      )}

      <UseForm
        initialValues={address}
        onSubmit={saveChangesAsync}
        validate={validationRules}
      >
        {(formProps) => (
          <form
            noValidate
            onSubmit={formProps.handleSubmit}
            onChange={() => {
              setDirtyForm(true)
            }}
          >
            <Box justifyContent="center" display="flex">
              <Grid
                container
                item
                spacing={2}
                xs={12}
                md={8}
                lg={6}
                justifyContent="center"
              >
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  {!dirtyForm && isAddressEntered && (
                    <Alert severity={isValid ? 'success' : 'warning'}>
                      {isValid
                        ? t('accountShipping.addressValid')
                        : t('accountShipping.addressInvalid')}
                    </Alert>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ShippingFormTextField
                    required
                    formProps={formProps}
                    name="name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ShippingFormTextField
                    required
                    formProps={formProps}
                    name="addressLine1"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ShippingFormTextField
                    formProps={formProps}
                    name="addressLine2"
                  />
                </Grid>
                <Grid item xs={12}>
                  <ShippingFormTextField
                    formProps={formProps}
                    required
                    name="city"
                  />
                </Grid>

                <Grid
                  item
                  container
                  spacing={2}
                  xs={12}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  <Grid item xs={12} md={5}>
                    <ShippingFormAutocomplete
                      required
                      formProps={formProps}
                      name="region"
                      values={regions}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ShippingFormTextField
                      required
                      formProps={formProps}
                      name="postalCode"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ShippingFormTextField
                      required
                      formProps={formProps}
                      name="countryCode"
                      disabled
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={hasErrors(formProps.errors)}
                  >
                    {t('accountShipping.submitButton')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </UseForm>
    </PageWrapper>
  )
}

enum SnackbarMode {
  SUCCESS = 'success',
  WARNING = 'warning',
}

type SuccessSnackbarProps = { onExited: () => void; mode?: SnackbarMode }
const CustomSnackbar: React.FC<SuccessSnackbarProps> = (
  props
): React.ReactElement => {
  const { onExited, mode } = props
  const [show, setShow] = useState(true)
  const { t } = useTranslation()

  const translateList = {
    success: t('accountShipping.saveSuccessMessage'),
    warning: t('accountShipping.saveInvalidAddressMessage'),
  }

  return mode && mode === SnackbarMode.SUCCESS ? (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={show}
      onClose={() => setShow(false)}
      TransitionProps={{ onExited: onExited }}
      autoHideDuration={2000}
      TransitionComponent={Slide}
    >
      <Alert severity={mode || SnackbarMode.SUCCESS} variant="filled">
        {translateList[mode ? mode : SnackbarMode.SUCCESS]}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  )
}
