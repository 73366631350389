import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAppHeaderRightMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      lineHeight: 0,
      marginLeft: 'auto',
      display: 'flex',
      flexWrap: 'nowrap',
    },

    link: {
      color: 'white',
      textDecoration: 'none',
      margin: '0 7px',
      display: 'inline-flex',
      alignItems: 'center',
      fontSize: 16,
      transition: 'text-shadow 300ms',
      cursor: 'pointer',

      '&:hover': {
        textShadow: '0 0 20px rgb(0 0 0 / 90%)',
      },

      '& > svg': {
        marginRight: theme.spacing(0.75),
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 16,
        margin: '0 6px',

        '& > svg': {
          transform: 'scale(.9)',
          marginRight: theme.spacing(0.5),
        },
      },
    },

    hideForLaptop: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    showForLaptop: {
      display: 'none',

      [theme.breakpoints.down('md')]: {
        display: 'inline',
      },
    },

    hideForTablet: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    showForTablet: {
      display: 'none',

      [theme.breakpoints.down('sm')]: {
        display: 'inline',
      },
    },

    hideForMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },

    showForMobile: {
      display: 'none',

      [theme.breakpoints.down('xs')]: {
        display: 'inline',
      },
    },

    accountName: {
      maxWidth: 200,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      lineHeight: '1em',

      padding: 20,
      margin: -20,
    },
  })
)
