/** Item State For item */
export enum ItemState {
  Draft = 0,
  Active = 1,
  SellPending = 2,
  Sold = 3,
  Unlisted = 4,
}

/** Item Condition */
export enum ItemCondition {
  New = 0,
  Used = 1,
}

/** NonprofitCategory Type**/
export type NonprofitCategory = Readonly<{
  nonprofitCategoryId?: number
  name?: string
  selected?: boolean
}>

/** Nonprofit */
export type Nonprofit = Readonly<{
  nonprofitId: number
  name?: string
  nonprofitCategoryId?: NonprofitCategory['nonprofitCategoryId']
  selected?: boolean
}>

export type ItemDetails = {
  listedItemId: number
  isCurrentUserOwner: boolean
  name: string
  description?: string
  itemCondition: ItemCondition
  currentPrice: number
  state: ItemState
  listedUtc?: Date
  soldUtc?: Date
  createdUtc?: Date
  defaultImageId?: number
  donorName: string
  nonprofit: Nonprofit
  images: number[]
  version: string
  city: string
  region: string
  isLocalPickupAvailable: boolean
  isShippingAvailable: boolean
  width: number
  height: number
  length: number
  weight: number
}
