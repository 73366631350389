import React from 'react'
import {
  selectAccountForm,
  selectIsAuthenticated,
  selectUser,
} from '../../../store/auth/selectors'
import {
  setNonUpdatesStatus,
  updateUserInfo,
} from '../../../store/auth/reducer'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileComponent } from './profile-component'
import { UpdateUserInfo } from './profile-component/profile-component.models'
import { PageWrapper } from '../page-wrapper'

export const ProfilePage: React.FC = () => {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuthenticated)
  const userInfo = useSelector(selectUser)
  const updateUserInfoAction = (data: UpdateUserInfo) => {
    dispatch(updateUserInfo(data))
  }
  const accountFormState = useSelector(selectAccountForm)
  const setNonUpdates = () => {
    dispatch(setNonUpdatesStatus())
  }

  return (
    <PageWrapper>
      {isAuth && (
        <ProfileComponent
          updateUserInfoAction={updateUserInfoAction}
          initialState={{
            name: userInfo.name,
          }}
          accountFormState={accountFormState}
          setNonUpdated={setNonUpdates}
        />
      )}
    </PageWrapper>
  )
}
