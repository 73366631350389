import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useOwnItemDetailsComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      textAlign: 'left',
      overflow: 'hidden',
      position: 'relative',
    },
    sliderBlock: {},

    sliderSection: {
      minHeight: 50,
      background: 'red',
    },
    sliderSectionBig: {},
    sliderSectionSmall: {
      '& .carousel-item': {},
      '& .carousel-item.active .carousel-row': {
        background: theme.palette.primary.main,
      },
    },
    sliderHolderBig: {
      position: 'relative',
    },
    sliderHolderSmall: {
      position: 'relative',
    },
    sliderArrow: {
      position: 'absolute',
      top: '50%',
      marginTop: '-20px',
      zIndex: 1,
      cursor: 'pointer',
    },
    sliderArrowLeft: {
      left: '5px',
    },
    sliderArrowRight: {
      right: '5px',
    },
    slideSmall: {
      padding: '5px 5px 0 5px',
    },
    slideSmallInner: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      height: 80,
    },
    imgSkeleton: {
      paddingBottom: '75%',
      transform: 'scale(1)',
    },
    title: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 23,
      marginBottom: 10,
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      letterSpacing: .75,
    },
    back: {
      padding: 0
    },
  })
)
