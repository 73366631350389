import React, { Fragment } from 'react'
import { Box, Button, Container, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ArrowForward, LoyaltyRounded } from '@material-ui/icons'
import { useSessionStorage } from 'react-use-storage'
import { useWelcomeMessageStyles } from './welcome-message.styles'

export const WelcomeMessage = (): React.ReactElement => {
  const { t } = useTranslation()
  const [displayState, setDisplayState] = useSessionStorage(
    'userPropsWelcomeMessageDisplay',
    'show'
  )
  const classes = useWelcomeMessageStyles()
  const linkToHelp =
    process.env.REACT_APP_HELP_SITE_BASE + 'link-to-help-hardcoded'

  const onHideButtonClickHandler = () => {
    setDisplayState('hide')
  }

  return (
    (displayState !== 'hide' && (
      <Container>
        <Box marginTop={2}>
          <Paper className={`${classes.paper} ${classes.welcomeSection}`}>
            <div>
              <LoyaltyRounded className={classes.welcomeIcon} />
            </div>
            <div className={classes.welcomeText}>{t('home.welcomeText')}</div>
            <div className={classes.welcomeButtons}>
              <Button
                href={linkToHelp}
                variant="outlined"
                endIcon={<ArrowForward />}
              >
                {t('home.readMoreButton')}
              </Button>
              <span
                onClick={onHideButtonClickHandler}
                className={classes.welcomeHideButton}
              >
                {t('home.hideWelcomeButton')}
              </span>
            </div>
          </Paper>
        </Box>
      </Container>
    )) || <Fragment />
  )
}
