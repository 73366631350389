import { createSlice } from '@reduxjs/toolkit'
import {
  SetQueryParams,
  SetCategories,
  FetchNonprofits,
  FetchNonprofitsSuccess,
  ChangeVisibleHandlerType,
  ErrorActionType,
  GetDonorItems,
} from './contracts'
import { ListedItemProps } from '../../components/listed-item'
import { Nonprofit, NonprofitCategory } from '../../models/item-details'

type InitialStateType = {
  itemsList: ListedItemProps[]
  categories: NonprofitCategory[]
  nonprofits: Nonprofit[]
  loading: boolean
  totalPages: number
  needApply: boolean
  firstRender: boolean
  isFilterCollapsed: boolean
  query: Partial<{
    pageSize: number
    nonprofitId: number | null
    nonprofitCategoryId: number | null
    searchText: string
    sortByDate: number
    pageIndex: number
    listedItemState: null | number
  }>
  error: any
}

const initialQuery = {
  pageSize: 12,
  nonprofitId: null,
  nonprofitCategoryId: null,
  searchText: '',
  sortByDate: 1,
  pageIndex: 0,
  listedItemState: null,
}

const InitialState: InitialStateType = {
  itemsList: [],
  categories: [],
  nonprofits: [],
  loading: false,
  totalPages: 0,
  needApply: true,
  firstRender: true,
  isFilterCollapsed: true,
  query: {
    ...initialQuery,
  },
  error: null,
}

const initialState: InitialStateType = {
  ...InitialState,
  query: {
    ...initialQuery,
  },
}

const myListedItemsSlice = createSlice({
  name: 'myListedItems',
  initialState: { ...initialState },
  reducers: {
    setShowItems(state, action) {},
    fetchCategories(state) {},
    resetRequestParams(state) {
      state.needApply = true
      state.categories = []
      state.nonprofits = []
    },
    setSortingInitial(state) {
      state.query = { ...initialQuery }
    },
    fetchCategoriesSuccess(state, action: SetCategories) {
      state.categories = action.payload
    },
    fetchNonprofits(state, action: FetchNonprofits) {},
    fetchNonprofitsSuccess(state, action: FetchNonprofitsSuccess) {
      state.nonprofits = action.payload
    },
    fetchNonprofitsFailed(state) {},

    //Get Donor Items
    getDonorItems(state, action: GetDonorItems) {
      state.loading = true
    },
    getDonorItemsSuccess(state, action) {
      state.itemsList = [...(action.payload ? action.payload.values : [])]
      state.loading = false
      state.totalPages = action.payload.totalPages
      state.firstRender = false
      state.needApply = false
    },
    getDonorItemsFailed(state) {
      state.loading = false
    },
    setQueryParams(state, action: SetQueryParams) {
      const {
        pageIndex,
        pageSize,
        isApply,
        nonprofitCategoryId,
        nonprofitId,
        isStartPagingInBegin,
        searchText,
        listedItemState,
        sortByDate,
      } = action.payload

      if (pageIndex || pageIndex === 0) {
        state.query.pageIndex = pageIndex
      }

      /*Set Category*/
      if (nonprofitCategoryId) {
        state.query.nonprofitCategoryId = nonprofitCategoryId
        state.query.nonprofitId = null
      } else if (nonprofitCategoryId === null) {
        state.query.nonprofitCategoryId = null
        state.query.nonprofitId = null
        state.nonprofits = []
      }
      if (nonprofitId) {
        state.query.nonprofitId = nonprofitId
      } else if (nonprofitId === null) {
        state.query.nonprofitId = null
      }

      //Page Size
      if (pageSize) {
        state.query.pageSize = pageSize
      }

      if (searchText) {
        state.query.searchText = searchText
      } else if (searchText === null || searchText === '') {
        state.query.searchText = ''
      }

      if (
        listedItemState ||
        listedItemState === 0 ||
        listedItemState === null
      ) {
        state.query.listedItemState = listedItemState
      }

      if (sortByDate || sortByDate === 0) {
        state.query.sortByDate = sortByDate
      }

      if (isStartPagingInBegin) state.query.pageIndex = 0

      if (isApply) state.needApply = true
    },
    setError(state, action: ErrorActionType) {
      state.error = action.payload
    },
    changeFilterCollapsedAction(state, action: ChangeVisibleHandlerType) {
      state.isFilterCollapsed = action.payload
    },
  },
})

export const myListedItemsReducer = myListedItemsSlice.reducer
export const {
  setSortingInitial,
  fetchCategories,
  setQueryParams,
  fetchCategoriesSuccess,
  fetchNonprofits,
  fetchNonprofitsSuccess,
  changeFilterCollapsedAction,
  setError,
  getDonorItemsSuccess,
  getDonorItems,
  resetRequestParams,
} = myListedItemsSlice.actions
