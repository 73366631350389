import { useEffect, useState } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAddressFormDirty,
  selectAddress,
  selectRegions,
  selectAddressLoading,
} from '../../../store/checkout-page/selectors'
import {
  setAddress,
  loadAddressData,
} from '../../../store/checkout-page/reducer'
import { initialAddressDataState } from './address-block.constants'

export const useAddressForm = () => {
  /* Redux Props */
  const dispatch = useDispatch()
  const addressFormDirty = useSelector(selectAddressFormDirty)
  const address = useSelector(selectAddress)
  const regions = useSelector(selectRegions)
  const addressLoading = useSelector(selectAddressLoading)

  useEffect(() => {
    setTempValues(address)
  }, [address])

  /* Store */
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [tempValues, setTempValues] = useState<AddressDataType>(
    initialAddressDataState
  )

  /* Form Value Change Handler */
  const formValueChangesHandler = (values: any) => {
    setTempValues({
      ...values.formValue,
      region: tempValues.region,
    })
  }

  /* Form Value Save Handler */
  const formValueSaveHandler = () => {
    setIsOpen(false)
    dispatch(setAddress({ ...tempValues }))
  }

  /* Change Region */
  const setRegion = (value: string) => {
    setTempValues({ ...tempValues, region: value })
  }

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  //Set Initial Address Data
  const loadInitialAddressData = () => {
    dispatch(loadAddressData('us')) //Hardcode now
  }

  return {
    //props
    address,
    regions,
    fullScreen,
    tempValues,
    isOpen,
    addressFormDirty,
    addressLoading,

    //methods
    setIsOpen,
    formValueChangesHandler,
    formValueSaveHandler,
    setRegion,
    setTempValues,
    loadInitialAddressData,
  }
}

//Address Data Type
export type AddressDataType = {
  addressLine1: string
  addressLine2: string
  city: string
  countryCode: string
  name: string
  postalCode: string
  region: string
  isValidated?: boolean
}
