import React from 'react'
import { HomeComponentProps } from './home.constants'
import { useHomeStyles } from './home.styles'
import {
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core'
import { Filter } from '../../../components/filter'
import { ListedItem, ListedItemProps } from '../../../components/listed-item'
import { Pagination } from '@material-ui/lab'
import {
  ApplyType,
  FilterParamsType,
} from '../../../components/filter/filter.constants'
import { EmptySearchResultComponent } from './empty-search-result-component'
import { useScreenSize } from '../../../hooks/use-screen-size'
import { itemsCounter } from '../../../settings/home-page-settings'
import { useTranslation } from 'react-i18next'
import { PlaceholderArrayComponent } from './placeholder-array-component'

export const Home: React.FC<HomeComponentProps> = (
  props: HomeComponentProps
) => {
  const {
    itemsList,
    query,
    changeQueryParamsAction,
    totalPages,
    loading,
    initialMaxPrice,
    initialMinPrice,
    firstRender,
    cancelHandler,
    categories,
    nonprofits,
    isFilterCollapsed,
    changeFilterCollapsedHandler,
  } = props

  const classes = useHomeStyles()

  const { t } = useTranslation()

  /*Device Type*/
  const screenSize = useScreenSize()

  /*Filter Change Handler*/
  const onFilterChanged = (
    data: FilterParamsType,
    apply?: ApplyType,
    isStartPagingInBegin?: boolean
  ) => {
    changeQueryParamsAction(data, apply, isStartPagingInBegin)
  }

  /*Pagination Change Handler*/
  const onPaginationHandler = (pageIndex: number) => {
    changeQueryParamsAction(
      {
        pageIndex,
      },
      true
    )
  }

  /*Show Page Size Handler*/
  const onPageSizeHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    changeQueryParamsAction(
      {
        pageSize: event.target.value as number,
      },
      true,
      true
    )
  }

  return (
    <div className={classes.homeContainer}>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={6} lg={3} md={4}>
            <div
              className={`${classes.filterContainer} ${classes.noTopOffset}`}
            >
              <Filter
                searchText={query.searchText}
                categories={categories}
                nonprofitCategoryId={query.nonprofitCategoryId}
                nonprofits={nonprofits}
                nonprofitId={query.nonprofitId}
                priceValue={[...query.priceValue]}
                initialMinPrice={initialMinPrice}
                initialMaxPrice={initialMaxPrice}
                onFilterChanged={onFilterChanged}
                condition={query.condition}
                loading={loading}
                cancelHandler={cancelHandler}
                isFilterCollapsed={isFilterCollapsed}
                setFilterCollapsed={changeFilterCollapsedHandler}
                screenSize={screenSize}
              />
            </div>
          </Grid>
          {screenSize === 1 && (
            <Grid item xs={6} className={classes.showItemsWrapper}>
              <div className={classes.selectHolder}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="select-show-items-label" disabled={loading}>
                    {t('home.showItems')}
                  </InputLabel>
                  <Select
                    disabled={loading}
                    labelId="select-show-items-label"
                    id="select-show-items"
                    variant="outlined"
                    value={query.pageSize}
                    label={t('home.showItems')}
                    onChange={onPageSizeHandler}
                  >
                    {itemsCounter.map((item, key) => (
                      <MenuItem value={item} key={key}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
          )}
          <Grid item xs={12} lg={9} md={8}>
            <Grid container spacing={2}>
              {screenSize === 3 && (
                <Grid item xs={12} className={classes.itemsCounter}>
                  <div className={classes.selectHolder}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        id="select-show-items-label"
                        disabled={loading}
                      >
                        {t('home.showItems')}
                      </InputLabel>
                      <Select
                        disabled={loading}
                        labelId="select-show-items-label"
                        id="select-show-items"
                        variant="outlined"
                        value={query.pageSize}
                        label={t('home.showItems')}
                        onChange={onPageSizeHandler}
                      >
                        {itemsCounter.map((item, key) => (
                          <MenuItem value={item} key={key}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </Grid>
              )}
              {firstRender && <PlaceholderArrayComponent />}
              {!itemsList.length && !firstRender && (
                <Grid xs={12} item>
                  <EmptySearchResultComponent />
                </Grid>
              )}
              {itemsList.map((item: ListedItemProps, key: number) => (
                <Grid key={key} item xs={12} lg={3} md={4} sm={6}>
                  <ListedItem {...item} />
                </Grid>
              ))}
              {!firstRender && totalPages > 1 && (
                <Grid item xs={12}>
                  <div className={classes.paginationHolder}>
                    <Pagination
                      count={totalPages}
                      disabled={loading}
                      shape="rounded"
                      color="primary"
                      page={query.pageIndex}
                      onChange={(e, page) => onPaginationHandler(page)}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
