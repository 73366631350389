import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useCookieBannerStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'sticky',
      bottom: '50px',
      zIndex: 999,
    },
    paper: {
      padding: theme.spacing(1),
    },
    cookieBannerSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    cookieBannerIcon: {
      margin: '10px 30px 5px',
      filter:
        'invert(26%) sepia(87%) saturate(819%) hue-rotate(204deg) brightness(100%) contrast(96%)',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    cookieBannerText: {
      flexGrow: 1,
      textAlign: 'left',
      fontSize: '16px',
      padding: '10px 30px 10px 25px',

      [theme.breakpoints.down('sm')]: {
        padding: '0',
        textAlign: 'center',
      },
    },
    cookieBannerButton: {
      whiteSpace: 'nowrap',
      padding: '5px 40px',
      margin: '0 40px',
    },
    hidden: {
      display: 'none',
    },
  })
)
