import React, { useState } from 'react'
import { useAccountSideMenu } from './account-side-menu.hook'
import { NavLink, useNavigate } from 'react-router-dom'
import { FormControl, NativeSelect } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { AccountSideMenuItem } from './account-side-menu.types'

export const menuArray: AccountSideMenuItem[] = [
  { to: '/account', title: 'accountSettings.menu.profile', exact: true },
  {
    to: '/account/shipping',
    title: 'accountSettings.menu.shipping',
    exact: true,
  },
  {
    to: '/account/security',
    title: 'accountSettings.menu.security',
    exact: true,
  },
]

export const AccountSideMenu: React.FC = (): React.ReactElement => {
  const { classes } = useAccountSideMenu()
  const [page, setPage] = useState('')
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const to = `${event.target.value}`
    setPage(to)
    navigate(to)
  }

  return (
    <>
      <div className={classes.sideMenu}>
        <h2 className="sideMenuTitle">{t('accountSettings.pageTitle')}</h2>
        <ul className={classes.sideMenuHolder}>
          {menuArray.map(({ to, title }, key) => (
            <li key={key} className={classes.sideMenuItem}>
              <NavLink
                className={({ isActive }) => (isActive ? classes.active : '')}
                to={to}
                end
              >
                {t(title)}
              </NavLink>
            </li>
          ))}
        </ul>
        <>
          <FormControl className={classes.selectHolder}>
            <NativeSelect
              id="mobile-account-menu"
              onChange={handleChange}
              value={page}
              className={classes.select}
            >
              {menuArray.map(({ to, title }, key) => (
                <option key={key} value={to}>
                  {t(title)}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </>
      </div>
    </>
  )
}
