import React, { useEffect, useRef, useState } from 'react'
import { useImageCardStyles } from './image-card.styles'
import { axiosInstance } from '../../api'
import { Loader } from '../loader'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { useDispatch } from 'react-redux'
import { trackException } from '../../store/app-insights/reducer'

interface IImageCardProps {
  imageId: number
}

export const ImageCard: React.FC<IImageCardProps> = (
  props
): React.ReactElement => {
  const classes = useImageCardStyles()
  const { imageId } = props
  const dispatch = useDispatch()
  const ref = useRef(null)
  const src = `/listed-items/images/${imageId}/medium.jpg`
  const [loading, setLoading] = useState<boolean>(true)
  const [imgError, setImgError] = useState<boolean>(false)

  useEffect(()=> {
    setLoading(true)
    setImgError(false)
    // @ts-ignore
    ref!.current!.style.backgroundImage = 'none'
    axiosInstance.get(src, {responseType: 'blob'}).then((response) => response.data)
    .then(blob => {
      const url = URL.createObjectURL(blob)
      // @ts-ignore
      ref!.current!.style.backgroundImage = `url(${url})`
    })
    .catch((e)=> {
      setImgError(true)
      dispatch(trackException({e, source: 'getBlodDataForImage'}))
    })
    .finally(()=> {
      setLoading(false)
    })

  }, [imageId])

  return (
    <div className={classes.imageWrapper}>
      <div className={classes.imageArea}>
        <div
          ref={ref}
          className={classes.imageWrapper}
        >
          <div className={classes.loaderHolder}>

            {/* Loader */}
            <div className={classes.loader}>
              {loading && <Loader/>}
            </div>

            {/* If 404 of image */}
            <div className={classes.imgError}>
              {imgError && <ErrorOutlineIcon fontSize={'inherit'}/>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
