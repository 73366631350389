import { TFunction } from 'i18next'
import { ItemState } from '../models'
import { transformDate } from './dates'

export type ItemDates = {
  soldUtc?: Date
  createdUtc?: Date
  listedUtc?: Date
}

export const getDateTitle = (t: TFunction, state: ItemState): string => {
  const TITLE_LIST = {
    [ItemState.Draft]: t('itemDetails.createdDate'),
    [ItemState.Active]: t('itemDetails.listedDate'),
    [ItemState.Sold]: t('itemDetails.soldDate'),
  }

  return (
    TITLE_LIST[state as keyof typeof TITLE_LIST] ?? t('itemDetails.createdDate')
  )
}

export const getDateByState = (
  { soldUtc, createdUtc, listedUtc }: ItemDates,
  state: ItemState
): string => {
  const DATE_LIST = {
    [ItemState.Sold]: transformDate(soldUtc ? new Date(soldUtc) : soldUtc),
    [ItemState.Draft]: transformDate(
      createdUtc ? new Date(createdUtc) : createdUtc
    ),
    [ItemState.Active]: transformDate(
      listedUtc ? new Date(listedUtc) : listedUtc
    ),
  }

  return (
    DATE_LIST[state as keyof typeof DATE_LIST] ||
    transformDate(createdUtc ? new Date(createdUtc) : createdUtc)
  )
}
