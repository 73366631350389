import React from 'react'
import { useDragAndDropperStyles } from './drag-and-dropper.styles'

type DropperType = {
  draggable?: boolean //ability to drag and drop files.
  draggingStyleClass?: string //style class that is assigned when a drag occurs over an element.
  onDragStart?: (e: React.DragEvent<HTMLElement>) => void
  onDragLeave?: (e: React.DragEvent<HTMLElement>) => void
  onDragEnd?: (e: React.DragEvent<HTMLElement>) => void
  onDragOver?: (e: React.DragEvent<HTMLElement>) => void
  onDrop?: (e: React.DragEvent<HTMLElement>) => void
  onDropEnter?: (e: React.DragEvent<HTMLElement>) => void
  children?: React.ReactNode
}

export const DragAndDropper: React.FC<DropperType> = (props) => {
  const classes = useDragAndDropperStyles()

  const {
    //params
    children,
    draggable = true,
    draggingStyleClass = '',

    //methods
    onDragStart,
    onDragLeave,
    onDragEnd,
    onDragOver,
    onDrop,
    onDropEnter,
  } = props

  const onDragStartHandler = (e: React.DragEvent<HTMLElement>) => {
    onDragStart && onDragStart(e)
  }

  const onDragLeaveHandler = (e: React.DragEvent<HTMLElement>) => {
    onDragLeave && onDragLeave(e)
  }

  const onDragEndHandler = (e: React.DragEvent<HTMLElement>) => {
    onDragEnd && onDragEnd(e)
  }

  const onDropHandler = (e: React.DragEvent<HTMLElement>) => {
    onDrop && onDrop(e)
  }

  const onDropEnterHandler = (e: React.DragEvent<HTMLElement>) => {
    onDropEnter && onDropEnter(e)
  }

  const onDragOverHandler = (e: React.DragEvent<HTMLElement>) => {
    onDragOver && onDragOver(e)
  }

  return (
    <div
      className={[draggingStyleClass, classes.dragAndDroper].join(' ')}
      draggable={draggable}
      onDragStart={onDragStartHandler}
      onDragLeave={onDragLeaveHandler}
      onDragEnd={onDragEndHandler}
      onDrop={onDropHandler}
      onTouchStart={(e) => {
        console.log(e)
      }}
      onDragEnter={onDropEnterHandler}
      onDragOver={onDragOverHandler}
    >
      {children}
    </div>
  )
}
