import React from 'react'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { useMenuForNotAuthenticated } from './menu-for-not-authenticated.hook'
import { useTranslation } from 'react-i18next'
import {msalRequest} from '../../../../env-config'
import {useOverlayPreloader} from '../../../../hooks/use-overlay-preloader'
import {useMsal} from '@azure/msal-react'

type MenuForNotAuthenticatedProps = {
  closeDrawer: () => void
}

export const MenuForNotAuthenticated: React.FC<MenuForNotAuthenticatedProps> = (
  props
): React.ReactElement => {
  const { closeDrawer } = props

  const {
    onDonateClickHandler,
  } = useMenuForNotAuthenticated(closeDrawer)

  const { t } = useTranslation()
  const { setIsShowOverlayPreloader } = useOverlayPreloader()
  const { instance } = useMsal()
  /* Login Handler */
  const handleLoginRedirect = () => {
    setIsShowOverlayPreloader(true)
    closeDrawer()
    instance.loginRedirect(msalRequest).catch((error) => console.log(error))
  }
  const menuButton = (
    label: string,
    onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  ) => (
    <ListItem button onClick={onClick}>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  )

  return (
    <>
      <List>
        {menuButton(t('header.menu.donate'), onDonateClickHandler)}
        <Divider />
        {menuButton(t('header.menu.login'), handleLoginRedirect)}
      </List>
    </>
  )
}
