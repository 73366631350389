import {axiosInstance} from '../../../api'
import {ListedItem} from '../../../store/nonprofits/types'
import {ApiError} from '../api-error-data'
import {ItemDetails} from '../../../models'
import {DonorItemsRequestProps, DonorItemsResponseProps,} from '../../../models'
import {ItemDataRequest, UpdateStateRequest} from './types'
import {MsalEmptyDataAuthPayload} from '../auth/models'

export const listedItemsApi = {
  create(listedItemProps: ListedItem & MsalEmptyDataAuthPayload): Promise<any> {
    return axiosInstance
      .post('listed-items', { ...listedItemProps })
      .then((response) => response.data)
      .catch((error) => {
        throw new ApiError(error)
      })
  },

  uploadImages(
    images: File[],
    onUploadProgress?: (event: ProgressEvent) => void
  ): Promise<string[]> {
    const formData = new FormData()
    images.forEach((image) => formData.append('files', image))

    return axiosInstance
      .post('listed-items/images/temp', formData, {
        onUploadProgress,
      })
      .then((response) => {
        return response.data as string[]
      })
      .catch((error) => {
        throw new ApiError(error)
      })
  },

  readDetails(listedItemId: string): Promise<ItemDetails> {
    return axiosInstance
      .get(`listed-items/${listedItemId}`)
      .then((response) => {
        const { listedUtc, soldUtc } = response.data
        const data: ItemDetails = {
          ...response.data,
          listedUtc: listedUtc ? new Date(listedUtc) : listedUtc,
          soldUtc: soldUtc ? new Date(soldUtc) : soldUtc,
        }
        return data
      })
      .catch((error) => {
        throw new ApiError(error)
      })
  },

  getDonorItems(
    query: DonorItemsRequestProps
  ): Promise<DonorItemsResponseProps> {
    return axiosInstance
      .get<DonorItemsResponseProps>('listed-items/donor', {
        params: { ...query }
      })
      .then((response) => {
        return response.data
      })
  },

  getOwnItemById(id: string): Promise<ItemDetails & { nonprofitName: string }> {
    return axiosInstance
      .get<ItemDetails & { nonprofitName: string }>(`/listed-items/donor/${id}` )
      .then((response) => {
        const { listedUtc, soldUtc } = response.data
        return {
          ...response.data,
          listedUtc: listedUtc ? new Date(listedUtc) : listedUtc,
          soldUtc: soldUtc ? new Date(soldUtc) : soldUtc,
        }
      })
  },

  update(id: string, value: ItemDataRequest): Promise<any> {
    return axiosInstance.put(`/listed-items/${id}`, { ...value })
  },

  updateState(id: string, value: UpdateStateRequest): Promise<any> {
    return axiosInstance.put(`/listed-items/${id}/state`, { ...value })
  },
}
