import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const Account2Svg = (props: SvgIconProps) => (
  <SvgIcon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11 1.83331C10.0935 1.83331 9.20736 2.10212 8.45364 2.60574C7.69991 3.10937 7.11245 3.82519 6.76555 4.66268C6.41865 5.50017 6.32789 6.42173 6.50473 7.31081C6.68158 8.19989 7.1181 9.01656 7.75909 9.65755C8.40008 10.2985 9.21676 10.7351 10.1058 10.9119C10.9949 11.0888 11.9165 10.998 12.754 10.6511C13.5915 10.3042 14.3073 9.71673 14.8109 8.96301C15.3145 8.20928 15.5833 7.32314 15.5833 6.41665C15.5833 5.20107 15.1004 4.03528 14.2409 3.17574C13.3814 2.3162 12.2156 1.83331 11 1.83331V1.83331ZM11 9.16665C10.4561 9.16665 9.92442 9.00536 9.47218 8.70319C9.01995 8.40101 8.66747 7.97152 8.45933 7.46903C8.25119 6.96653 8.19673 6.4136 8.30284 5.88015C8.40895 5.3467 8.67086 4.8567 9.05546 4.4721C9.44005 4.08751 9.93005 3.8256 10.4635 3.71949C10.9969 3.61338 11.5499 3.66784 12.0524 3.87598C12.5549 4.08412 12.9844 4.43659 13.2865 4.88883C13.5887 5.34106 13.75 5.87275 13.75 6.41665C13.75 7.14599 13.4603 7.84547 12.9445 8.36119C12.4288 8.87692 11.7293 9.16665 11 9.16665V9.16665ZM19.25 19.25V18.3333C19.25 16.6315 18.574 14.9994 17.3706 13.796C16.1672 12.5927 14.5351 11.9166 12.8333 11.9166H9.16667C7.46486 11.9166 5.83276 12.5927 4.6294 13.796C3.42604 14.9994 2.75 16.6315 2.75 18.3333V19.25H4.58333V18.3333C4.58333 17.1177 5.06622 15.9519 5.92576 15.0924C6.7853 14.2329 7.95109 13.75 9.16667 13.75H12.8333C14.0489 13.75 15.2147 14.2329 16.0742 15.0924C16.9338 15.9519 17.4167 17.1177 17.4167 18.3333V19.25H19.25Z"
      fill="white"
    />
  </SvgIcon>
)
