import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useCheckoutSummaryStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& > .MuiBox-root': {
        height: '100%',
      },
    },

    totalPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2),
    },

    totalPriceLabel: {
      fontWeight: 'bold',
    },

    totalPriceAmount: {
      fontSize: '1.2rem',
      color: theme.palette.primary.main,
    },

    summaryItem: {
      marginBottom: theme.spacing(1),
    },

    summaryItemValue: {
      fontWeight: 'bold',
      marginLeft: 4,
    },
  })
)
