import React from 'react'
import { useOrderPageStyles } from '../order-page.styles'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { OrderItemInfoType } from './order-item-info.constant'
import { baseURL } from '../../../api'
import { useTranslation } from 'react-i18next'

const DeliveryTypeMap = new Map([
  //[0, 'Local Pickup'],
  [1, 'Shipping'],
  //[2, 'Local Pickup, Shipping']
])

export const OrderItemInfo: React.FC<OrderItemInfoType> = (props) => {
  //Translation
  const { t } = useTranslation()

  /*Props*/
  const { orderHeader, listedItem } = props

  /*Styles*/
  const classes = useOrderPageStyles()
  const getImgSrc = (imageId: number) => {
    return `${baseURL}listed-items/images/${imageId}/medium.jpg`
  }

  //Transform Date
  const transformDate = (date: string) => {
    const dateObject = new Date(date)
    return dateObject.toLocaleDateString('en-US', {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
    })
  }

  //Full Delivery Address
  const getFullAddress = () => {
    const {
      shipToAddressLine1,
      shipToAddressLine2,
      shipToCity,
      shipToCountryCode,
      shipToPostalCode,
      shipToRegion,
    } = orderHeader
    return `${shipToAddressLine1}${
      shipToAddressLine2 ? ', ' + shipToAddressLine2 : ''
    }, ${shipToCity}, ${shipToCountryCode}, ${shipToPostalCode}, ${shipToRegion}`
  }

  return (
    <div>
      {/*Title Holder*/}
      <div className={classes.orderItemInfoTitleHolder}>
        {/*Image Holder*/}
        <div className={classes.orderImgHolder}>
          <div
            className={classes.orderBgHolder}
            style={{
              backgroundImage: `url(${getImgSrc(listedItem.defaultImageId)})`,
            }}
          >
            {' '}
          </div>
        </div>

        {/*Typography Holder*/}
        <div className={classes.orderTypographyHolder}>
          <Typography variant="h6" className={classes.orderItemInfoTitle}>
            {listedItem.name}
          </Typography>

          {/*Item Prop*/}
          <div className={classes.orderItemPropItem}>
            <span className={classes.orderItemPropLabel}>
              {t('orderPage.created')}:
            </span>
            <span className={classes.orderItemPropDescription}>
              {listedItem.listedUtc && transformDate(listedItem.listedUtc)}
            </span>
          </div>

          {/*Item Prop*/}
          <div className={classes.orderItemPropItem}>
            <span className={classes.orderItemPropLabel}>
              {t('nonprofitName')}:
            </span>
            <span className={classes.orderItemPropDescription}>
              {listedItem.nonprofitName}
            </span>
          </div>

          <div className={classes.orderDescription}>
            <Typography variant="h6" className={classes.orderDescriptionTitle}>
              {t('orderPage.description')}
            </Typography>
            <p className={classes.orderDescriptionText}>
              {listedItem.description}
            </p>
          </div>
        </div>
      </div>

      {/*Info Item */}
      <div className={classes.orderItemInfoBlockWrapper}>
        <Typography variant="h6" className={classes.orderItemInfoBlockTitle}>
          {t('orderPage.order-details')}
        </Typography>
        <div className={classes.orderItemInfoBlock}>
          {/*Order Detail Item*/}
          <div className={classes.orderItemInfoBlockItem}>
            <div className={classes.orderItemInfoBlockItemLabel}>
              {t('orderPage.created')}:
            </div>
            <div className={classes.orderItemInfoBlockItemValue}>
              {orderHeader.orderCreatedUtc &&
                transformDate(orderHeader.orderCreatedUtc)}
            </div>
          </div>

          {/*Divider*/}
          <Divider />

          {/*Order Detail Item*/}
          <div className={classes.orderItemInfoBlockItem}>
            <div className={classes.orderItemInfoBlockItemLabel}>
              {t('orderPage.orderNumber')}:
            </div>
            <div className={classes.orderItemInfoBlockItemValue}>
              {orderHeader.orderNumber}
            </div>
          </div>

          {/*Divider*/}
          <Divider />

          {/*Order Detail Item*/}
          <div className={classes.orderItemInfoBlockItem}>
            <div className={classes.orderItemInfoBlockItemLabel}>
              {t('orderPage.deliveryType')}:
            </div>
            <div className={classes.orderItemInfoBlockItemValue}>
              {DeliveryTypeMap.get(orderHeader.shippingProvider)}
            </div>
          </div>

          {/*Divider*/}
          <Divider />

          {/*Order Detail Item*/}
          {orderHeader.shippingProvider > 0 && (
            <div className={classes.orderItemInfoBlockItem}>
              <div className={classes.orderItemInfoBlockItemLabel}>
                {t('orderPage.provider')}:
              </div>
              <div className={classes.orderItemInfoBlockItemValue}>
                {orderHeader.shippingRateProviderData.provider}
              </div>
            </div>
          )}

          {/*Divider*/}
          {orderHeader.shippingRateProviderData.servicelevel.name && (
            <Divider />
          )}

          {/*Order Detail Item*/}
          {orderHeader.shippingRateProviderData.servicelevel.name && (
            <div className={classes.orderItemInfoBlockItem}>
              <div className={classes.orderItemInfoBlockItemLabel}>
                {t('orderPage.shippingRate')}:
              </div>
              <div className={classes.orderItemInfoBlockItemValue}>
                {orderHeader.shippingRateProviderData.servicelevel.name}
              </div>
            </div>
          )}

          {/*Divider */}
          <Divider />

          {/* Shipping Name */}
          {orderHeader.shippingProvider > 0 && (
            <div className={classes.orderItemInfoBlockItem}>
              <div className={classes.orderItemInfoBlockItemLabel}>
                {t('orderPage.shippingName')}:
              </div>
              <div className={classes.orderItemInfoBlockItemValue}>
                {orderHeader.shipToName}
              </div>
            </div>
          )}

          {/*Divider*/}
          <Divider />

          {/*Order Detail Item*/}
          {orderHeader.shippingProvider > 0 && (
            <div className={classes.orderItemInfoBlockItem}>
              <div className={classes.orderItemInfoBlockItemLabel}>
                {t('orderPage.shippingAddress')}:
              </div>
              <div className={classes.orderItemInfoBlockItemValue}>
                {getFullAddress()}
              </div>
            </div>
          )}
        </div>
      </div>

      {/*Buyer Name*/}
      <div className={classes.orderItemInfoBlockWrapper}>
        <Typography variant="h6" className={classes.orderItemInfoBlockTitle}>
          {t('orderPage.buyerUser')}
        </Typography>
        <div className={classes.orderItemInfoBlock}>
          {/*Block Item*/}
          <div className={classes.orderItemInfoBlockItem}>
            <div className={classes.orderItemInfoBlockItemLabel}>
              {t('orderPage.name')}:
            </div>
            <div className={classes.orderItemInfoBlockItemValue}>
              {orderHeader.buyerUser.name}
            </div>
          </div>

          {/*Divider*/}
          <Divider />

          {/*Block Item*/}
          <div className={classes.orderItemInfoBlockItem}>
            <div className={classes.orderItemInfoBlockItemLabel}>
              {t('orderPage.email')}:
            </div>
            <div className={classes.orderItemInfoBlockItemValue}>
              {orderHeader.buyerUser.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
