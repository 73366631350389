/** Convert date time object to ISO 8601 string with user local timezone offset information (yyyy-MM-ddThh:mm:ss+00:00) */
export const toDateTimeOffset = (original: Date): string =>
  toDateTimeOffsetInternal(
    original.getFullYear(),
    original.getMonth() + 1,
    original.getDate(),
    original.getHours(),
    original.getMinutes(),
    original.getSeconds(),
    original.getTimezoneOffset()
  )

export const toDateTimeOffsetInternal = (
  year: number,
  month: number,
  day: number,
  hours: number,
  minutes: number,
  seconds: number,
  offsetMinutes: number
): string => {
  const date = `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`
  const time = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  const offset =
    (offsetMinutes > 0 ? '-' : '+') +
    Math.floor(Math.abs(offsetMinutes / 60))
      .toString()
      .padStart(2, '0') +
    Math.floor(Math.abs(offsetMinutes % 60))
      .toString()
      .padStart(2, '0')

  return `${date}T${time}${offset}`
}

export const transformDate = (date?: Date) => {
  return (
    date?.toLocaleDateString('en-US', {
      day: 'numeric',
      year: 'numeric',
      month: 'long',
    }) ?? ''
  )
}
