import { call, put, takeEvery } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { listedItemsApi } from '../../services/api/listed-items'
import {
  fetchNonprofitDataAction,
  fetchNonprofitDataFailerAction,
  fetchNoprofitDataSuccessAction,
} from './reducer'
import {trackException} from '../app-insights/reducer'

export function* fetchNonprofitDataSaga({
  payload,
}: ReturnType<typeof fetchNonprofitDataAction>): SagaIterator {
  try {
    yield call(listedItemsApi.create, payload)
    yield put(fetchNoprofitDataSuccessAction())
  } catch (e) {
    yield put(fetchNonprofitDataFailerAction())
    yield put(trackException({e, source: 'fetchNonprofitDataSaga'}))
  }
}

export function* nonprofitSaga(): SagaIterator {
  yield takeEvery(fetchNonprofitDataAction, fetchNonprofitDataSaga)
}
