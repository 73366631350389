import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import { blue, green, orange, red } from '@material-ui/core/colors'
import { Done, Error, Info, Warning } from '@material-ui/icons'
import { useAppDialogStyles } from './use-app-dialog.styles'
import CloseIcon from '@material-ui/icons/Close'
import { AppDialogVariants } from '../../store/app-dialog/types'
import { SimpleFunc } from '../../models'
import Button from '@material-ui/core/Button'

type IAppDialogProps = {
  onExit?: () => void
  title: string
  forceClose?: boolean
  isShow?: boolean
  showCloseButton?: boolean
  actions?: React.ReactNode | null
  variant?: string
  message?: string
  closeHandler?: SimpleFunc
  resetHandler?: () => void
  setIsShow?: (value: boolean) => void
  children?: React.ReactNode
}

export const AppDialog: React.FC<IAppDialogProps> = (
  props
): React.ReactElement => {
  const [open, setOpen] = useState(false)
  const {
    onExit,
    title,
    actions,
    isShow,
    variant,
    message,
    showCloseButton,
    closeHandler,
    setIsShow,
  } = props
  const classes = useAppDialogStyles()

  useEffect(() => {
    isShow ? setOpen(true) : setOpen(false)
  }, [isShow])

  const onCloseHandler = (): void => {
    setIsShow && setIsShow(false)
    closeHandler && closeHandler()
  }

  return (
    <Dialog
      open={open}
      onClose={onCloseHandler}
      onExited={onExit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        {title}
        {showCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onCloseHandler}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {variant && (
          <div className={classes.dialogIcon}>
            <AppDialogIcon variant={variant} />
          </div>
        )}
        <div
          className="MuiTypography-body1 MuiTypography-colorTextSecondary"
          id="alert-dialog-description"
        >
          {/*{children || message?.split('\n').map((x, i) => (*/}
          {/*  <span key={i}>{x}<br/></span>*/}
          {/*))}*/}
          {message}
        </div>
      </DialogContent>
      {
        <DialogActions>
          {actions ? (
            actions
          ) : (
            <Button
              onClick={() => setIsShow && setIsShow(false)}
              color="primary"
            >
              Ok
            </Button>
          )}
        </DialogActions>
      }
    </Dialog>
  )
}

interface IVariantIconProps {
  variant?: string
}

const AppDialogIcon: React.FC<IVariantIconProps> = (
  props
): React.ReactElement => {
  const { variant } = props

  switch (variant) {
    case AppDialogVariants.success:
      return <Done style={{ color: green[500] }} />

    case AppDialogVariants.error:
      return <Error style={{ color: red[500] }} />

    case AppDialogVariants.warning:
      return <Warning style={{ color: orange[500] }} />

    case AppDialogVariants.info:
      return <Info style={{ color: blue[500] }} />

    default:
      console.warn(`Unknown app dialog variant icon: ${variant}`)
      return <></>
  }
}
