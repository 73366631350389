import React, { useEffect } from 'react'
import { useShipmentRateBlockStyle } from './shippment-rate-block.styles'
import { Backdrop, Box, Button, Fade, Grid, Modal } from '@material-ui/core'
import { useShipmentRateBlock } from './shippment-rate-block.hook'
import { ListRateForm } from './list-rate-form'
import { Loader } from '../../../components/loader'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import { RateItem } from './rate-item'
import { useAppDialog } from '../../../hooks/use-app-dialog'
import { ApiError } from '../../../services/api/api-error-data'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RateMessage } from '../../../store/checkout-page/types'
import { AppDialogVariants } from '../../../store/app-dialog/types'

export const ShipmentRateBlock: React.FC = (): React.ReactElement => {
  const classes = useShipmentRateBlockStyle()
  const {
    rates,
    ratesLoading,
    fullscreen,
    isOpen,
    saveAddressError,
    setIsOpen,
    addressLoading,
    getShipmentRateHandler,
    currentRate,
    setSelectedRateByArrayIndex,
    setLoadingRatesHandler,
    ratesError,
    rateMessages,
    resetStore,
  } = useShipmentRateBlock()

  const { setAppDialog, setIsShow } = useAppDialog()
  const { t } = useTranslation()
  const navigate = useNavigate()

  //Address Loading
  useEffect(() => {
    addressLoading && setLoadingRatesHandler()
    !addressLoading && !saveAddressError && getShipmentRateHandler()
  }, [addressLoading])

  //Rates Error
  useEffect(() => {
    if (ratesError) {
      const error = new ApiError(ratesError)

      setAppDialog({
        variant: 'error',
        isShow: true,
        message: `${error.getErrorMessage(t)}.${t('checkout.reloadPage')}`,
        title: `${t('checkout.error')}`,
        showCloseButton: false,
        actions: (
          <Button onClick={() => window.location.reload()} color="primary">
            {t('checkout.reload')}
          </Button>
        ),
      })
    } else if (saveAddressError) {
      setAppDialog({
        isShow: true,
        variant: AppDialogVariants.error,
        message: new ApiError(saveAddressError).getErrorMessage(t),
        title: t('accountShipping.saveErrorMessage'),
      })
    }
  }, [ratesError, saveAddressError])

  //Is Empty Rate
  const isEmptyRate = () => {
    return !rates.length && rateMessages.length
  }

  //Empty Rates Array (Not Rates Error)
  useEffect(() => {
    if (isEmptyRate()) {
      setAppDialog({
        variant: 'warning',
        isShow: true,
        message: <MessageBlockComponent rateMessages={rateMessages} />,
        title: 'Rates are not available',
        showCloseButton: true,
        actions: (
          <Button
            onClick={() => {
              setIsShow(false)
              navigate('/')
              resetStore()
            }}
            color="primary"
          >
            {t('checkout.goToHome')}
          </Button>
        ),
      })
    }
  }, [rates, rateMessages])

  //Render
  return ratesLoading ? (
    <div className={classes.loader}>
      <Loader size={20} />
    </div>
  ) : (
    <>
      {/*Box*/}
      <Box
        justifyContent="center"
        display="flex"
        className={classes.ratesWrapper}
      >
        {/*Rates Empty*/}
        {isEmptyRate() && (
          <span className={classes.ratesEmptyLabel}>
            {t('checkout.ratesNoAvailable')}
          </span>
        )}

        {/*Rates Not Empty*/}
        {!isEmptyRate() && (
          <div className={classes.ratesHolder}>
            <Grid container item xs={12} spacing={2} justifyContent="center">
              {/*Name*/}
              <Grid item xs={12} className={classes.nameLine}>
                {currentRate && <RateItem currentRate={currentRate} />}
                <div title="Edit" onClick={() => setIsOpen(true)}>
                  <BorderColorIcon className={classes.icon} />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </Box>

      {/*Modal*/}
      <Modal
        data-testid="modal"
        data-modal-open={isOpen}
        closeAfterTransition
        open={isOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <>
            <ListRateForm
              setSelectedRateByArrayIndex={setSelectedRateByArrayIndex}
              rates={rates}
              currentRate={currentRate}
              setIsOpen={setIsOpen}
              fullscreen={fullscreen}
            />
          </>
        </Fade>
      </Modal>
    </>
  )
}

//Type
type MessageBlockComponentProps = {
  rateMessages: RateMessage[]
}

//Message Block Component
const MessageBlockComponent: React.FC<MessageBlockComponentProps> = (
  props
): React.ReactElement => {
  const classes = useShipmentRateBlockStyle()
  const { rateMessages } = props

  if (!rateMessages.length) return <> </>

  const messagesBlock = () => {
    return (
      <>
        {rateMessages.map((item, key) => (
          <div key={key} className={classes.ratesMessagesItem}>
            <b>{item.source}: </b>
            <span>{item.text}</span>
          </div>
        ))}
      </>
    )
  }

  return <div>{messagesBlock()}</div>
}
