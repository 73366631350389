import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useItemImagesStyles = makeStyles((theme: Theme) => {
  const [width, height] = ['250px', '250px']

  return createStyles({
    root: {
      marginBottom: '40px',
    },
    uploadArea: {
      display: 'inline-flex',
      flexDirection: 'column',
      width: width,
      height: height,
      position: 'relative',
    },
    progressOverlay: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(1px)',

      '& > span': {
        backgroundColor: '#ffffffcc',
        padding: '10px 20px',
        borderRadius: '15px',
        fontSize: '18px',
      },
    },
    dragArea: {
      flexGrow: 1,
      border: 'dashed 1px #adadad',
      borderBottom: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      '& *': {
        pointerEvents: 'none',
      },

      '&.highlight': {
        borderColor: '#3f51b5',
        backgroundColor: '#fff',
      },
    },
    dragTitle: {
      color: '#adadad',
      marginTop: '15px',
    },
    uploadButton: {
      height: '50px',
      backgroundColor: '#3f51b5',
      color: '#fff',
      fontSize: '.9rem',

      '&:hover': {
        backgroundColor: '#303f9f',
      },
    },
    itemImage: {
      width: width,
      height: height,
      position: 'relative',
      backgroundColor: '#e8e8e8',
      margin: 'auto',
    },
    itemImageDeleteButton: {
      position: 'absolute',
      right: '0',
      top: '0',
      border: 'none',
      background: '#0000001A',
      color: '#fff',
      padding: '5px 10px',
      cursor: 'pointer',
      opacity: 0.75,
      transition: 'background 300ms, opacity 300ms',

      '&:hover': {
        background: '#0000004A',
        opacity: 1,
      },
    },
    imgBody: {
      position: 'relative',
    },
    move: {
      cursor: 'move',
      position: 'relative',
    },
    imageHolder: {
      '& > div': {
        paddingTop: '98.323% !important',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
      },
    },
    draggingElement: {
      cursor: 'move',
      display: 'inline-block',
      transform: 'scale(1.1)',
      boxSizing: 'border-box',
      position: 'relative',
      overflow: 'visible',

      '&:before': {
        display: 'block',
        top: -4,
        left: -4,
        right: -4,
        bottom: -4,
        content: '""',
        position: 'absolute',
        zIndex: 0,
        border: `4px ${theme.palette.primary.light} dashed`,
      },
    },
    defaultLabel: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      color: theme.palette.primary.main,
      height: '50px',
      backgroundColor: 'white',
      lineHeight: '50px',
      fontSize: '.9rem',
      fontWeight: 500,
    },
  })
})
