import React from 'react'
import { validate } from '../address-block.constants'
import { Button, Grid, IconButton, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  FormInitialValues,
  hasErrors,
  UseForm,
} from '../../../../hooks/use-form'
import { useAddressStyles } from '../address-block.styles'
import { AddressDataType } from '../address-block.hook'
import { Close } from '@material-ui/icons'

/* Form Holder Type */
type FormHolderType = {
  regions: string[]
  tempValues: AddressDataType
  address: FormInitialValues
  formValueChangesHandler: (values: any) => void
  formValueSaveHandler: () => void
  setRegion: (value: string) => void
  setIsOpen: (status: boolean) => void
  fullScreen: boolean
}

export const FormHolder: React.FC<FormHolderType> = (props) => {
  const {
    //props
    regions,
    tempValues,
    address,
    fullScreen,

    //methods
    formValueSaveHandler,
    formValueChangesHandler,
    setRegion,
    setIsOpen,
  } = props

  const classes = useAddressStyles()

  return (
    <>
      <div
        className={`${classes.paper} ${classes.modal} ${
          fullScreen && classes.fullScreen
        }`}
      >
        <IconButton
          onClick={() => setIsOpen(false)}
          className={classes.closeIcon}
        >
          <Close />
        </IconButton>
        <UseForm
          initialValues={{ ...address }}
          validate={validate}
          onSubmit={() => console.log()}
          formValueChanges={formValueChangesHandler}
        >
          {({ values, errors, inputHandlers, handleSubmit, form }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Name */}
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ 'data-testid': 'name' }}
                    error={!!errors?.name && form.name.dirty}
                    helperText={
                      form.name.dirty && !form.name.focused && errors.name
                    }
                    value={values.name}
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    label="Name"
                    autoFocus
                    autoComplete="given-name"
                    disabled={false}
                    {...inputHandlers}
                  />
                </Grid>

                {/*Address Line 1*/}
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ 'data-testid': 'addressLine1' }}
                    error={!!errors?.addressLine1 && form.addressLine1.dirty}
                    helperText={
                      form.addressLine1.dirty &&
                      !form.addressLine1.focused &&
                      errors.addressLine1
                    }
                    value={values.addressLine1}
                    name="addressLine1"
                    variant="outlined"
                    required
                    fullWidth
                    label="Address Line 1"
                    autoFocus
                    autoComplete="given-name"
                    disabled={false}
                    {...inputHandlers}
                  />
                </Grid>

                {/*Address Line 2*/}
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ 'data-testid': 'addressLine2' }}
                    error={!!errors?.addressLine2 && form.addressLine2.dirty}
                    helperText={
                      form.addressLine2.dirty &&
                      !form.addressLine2.focused &&
                      errors.addressLine2
                    }
                    value={values.addressLine2}
                    name="addressLine2"
                    variant="outlined"
                    fullWidth
                    label="Address Line 2"
                    autoFocus
                    autoComplete="given-name"
                    disabled={false}
                    {...inputHandlers}
                  />
                </Grid>

                {/*City*/}
                <Grid item xs={12}>
                  <TextField
                    inputProps={{ 'data-testid': 'city' }}
                    error={!!errors?.city && form.city.dirty}
                    helperText={
                      form.city.dirty && !form.city.focused && errors.city
                    }
                    value={values.city}
                    name="city"
                    variant="outlined"
                    required
                    fullWidth
                    label="City"
                    autoFocus
                    autoComplete="given-name"
                    disabled={false}
                    {...inputHandlers}
                  />
                </Grid>
                {/**/}
              </Grid>

              {/*Choose State*/}
              <Grid item spacing={2} container style={{ marginTop: 8 }}>
                <Grid item xs={12}>
                  <div>
                    <Autocomplete
                      id="region"
                      fullWidth
                      options={regions}
                      value={tempValues.region}
                      defaultValue={values.region as string}
                      onChange={(e, value) => setRegion(value as string)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Region"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                </Grid>

                {/*Postal Code*/}
                <Grid item xs={12} md={6}>
                  <TextField
                    inputProps={{ 'data-testid': 'postalCode' }}
                    error={!!errors?.postalCode && form.postalCode.dirty}
                    helperText={
                      form.postalCode.dirty &&
                      !form.postalCode.focused &&
                      errors.postalCode
                    }
                    value={values.postalCode}
                    name="postalCode"
                    variant="outlined"
                    required
                    fullWidth
                    label="Postal Code"
                    autoFocus
                    autoComplete="given-name"
                    disabled={false}
                    {...inputHandlers}
                  />
                </Grid>

                {/* Country Code*/}
                <Grid item xs={12} md={6}>
                  <TextField
                    inputProps={{ 'data-testid': 'countryCode' }}
                    error={!!errors?.countryCode && form.countryCode.dirty}
                    helperText={
                      form.countryCode.dirty &&
                      !form.countryCode.focused &&
                      errors.countryCode
                    }
                    value={values.countryCode}
                    name="countryCode"
                    variant="outlined"
                    required
                    fullWidth
                    label="Country"
                    autoFocus
                    autoComplete="given-name"
                    disabled={true}
                    {...inputHandlers}
                  />
                </Grid>

                {/*Submit Button*/}
                <Grid item xs={12} md={12}>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      style={{ marginTop: 8 }}
                      onClick={() => formValueSaveHandler()}
                      disabled={hasErrors(errors) || !tempValues.region.length}
                    >
                      SAVE
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          )}
        </UseForm>
      </div>
    </>
  )
}
