import React, { useState } from 'react'
import { useOrderPageStyles } from '../order-page.styles'
import { Backdrop, Modal, Fade, IconButton, Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { baseDomain } from '../../../api'
import { Loader } from '../../../components/loader'
import { useAppDialog } from '../../../hooks/use-app-dialog'

type OrderModalPropsType = {
  isOpen: boolean
  setIsOpen: (status: boolean) => void
  fullscreen: boolean
  createShippingLabel?: () => void
  labelImgUrl: string
}

export const OrderModal: React.FC<OrderModalPropsType> = (props) => {
  const { isOpen, setIsOpen, fullscreen, labelImgUrl } = props

  //UseApp Error
  const { setAppDialog } = useAppDialog()

  //Load Image Error
  const setError = () => {
    setLoadingImage(false)
    setAppDialog({
      title: 'Error',
      message: 'Loading image error',
      isShow: true,
      variant: 'error',
    })
  }

  //Loader State
  const [loadingImage, setLoadingImage] = useState<boolean>(true)

  //Css styles
  const classes = useOrderPageStyles()

  //Image Url
  const url = () => {
    return labelImgUrl ? `${baseDomain}${labelImgUrl}` : ''
  }

  //Download Label
  const downloadLabel = () => {
    window.open(url(), '_blank')
  }

  //Print
  const printHandler = () => {
    window.print()
  }
  return (
    <div className={classes.orderModal}>
      <Modal
        data-testid="modal"
        data-modal-open={isOpen}
        closeAfterTransition
        open={isOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <>
            <div
              className={`${classes.paper} ${classes.modal} ${
                fullscreen && classes.fullScreen
              }`}
            >
              {/* Close Button*/}
              <IconButton
                className={classes.closeIcon}
                onClick={() => setIsOpen(false)}
              >
                <Close />
              </IconButton>

              {/* Label Body*/}
              <div className={classes.modalImgWrapper}>
                <div className={classes.modalImgHolder}>
                  {url() && (
                    <img
                      src={url()}
                      alt={`${url()}`}
                      onError={() => setError()}
                      onLoad={() => setLoadingImage(false)}
                    />
                  )}
                  {loadingImage && <Loader />}
                </div>
              </div>

              {/*Btn Block*/}
              {!loadingImage && (
                <div className={classes.modalBtnBlock}>
                  <Button
                    color="primary"
                    className={classes.modalDownloadBtn}
                    onClick={() => downloadLabel()}
                  >
                    Download
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => printHandler()}
                  >
                    Print
                  </Button>
                </div>
              )}
            </div>
          </>
        </Fade>
      </Modal>
    </div>
  )
}
