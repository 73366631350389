import React, { useState } from 'react'

export const useAppHeaderDrawer = () => {
  const [isOpened, setIsOpened] = useState(false)

  const onHamburgerClickHandler = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    event.preventDefault()
    setIsOpened(true)
  }

  const onOpenHandler = () => {
    // do nothing, handler is required by drawer
  }

  const onCloseHandler = () => {
    // do nothing, handler is required by drawer
    setIsOpened(false)
  }

  return {
    isOpened,

    onOpenHandler,
    onCloseHandler,
    onHamburgerClickHandler,
  }
}
