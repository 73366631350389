import { useState } from 'react'
import {
  CheckOptionalType,
  Form,
  FormValueChangesProps,
  FormValues,
  ValidationErrors,
} from '../../../hooks/use-form'
import { initialValues } from './donate-details.constants'

export const useDonateDetails = () => {
  const [donateDetails, setDonateDetails] = useState(
    initialValues as FormValues
  )
  const [donateDetailsFormValid, setDonateDetailsFormValid] = useState(false)

  const formValueChanges = (data: FormValueChangesProps) => {
    setDonateDetails(data.formValue)
    setDonateDetailsFormValid(data.valid)
  }

  const checkOptionalHandler = (
    checkOptionalArray: CheckOptionalType,
    errors: ValidationErrors,
    form: Form
  ) => {
    const totalFields = checkOptionalArray.reduce(
      (accumulator: any[], currentOptionalItem) => {
        const { fieldName, optionalValues, triggerFieldName } =
          currentOptionalItem

        //Get Field Name
        const optionalField = optionalValues.reduce(
          (acc: string, currentValue) => {
            if (form[triggerFieldName].value === currentValue) {
              acc = fieldName
            }

            return acc
          },
          ''
        )

        //Add filed in array
        accumulator.push(optionalField)
        return accumulator
      },
      []
    )

    return totalFields.reduce(
      (errorsAcc, currentField) => {
        errorsAcc[currentField] = undefined
        return errorsAcc
      },
      { ...errors }
    )
  }

  return {
    donateDetails,
    donateDetailsFormValid,
    formValueChanges,
    setDonateDetails,
    checkOptionalHandler,
  }
}
