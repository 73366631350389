import React, { useEffect } from 'react'
import { useCheckoutPage } from './checkout-page.hook'
import {
  Box,
  Button,
  Container,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
import { CheckoutDescription } from './checkout-description'
import { CheckoutSummary } from './checkout-summary'
import { Alert } from '@material-ui/lab'
import { StripeForm } from './stripe-form'
import { LoadingState } from '../../store/types'
import { useDispatch } from 'react-redux'
import { resetOrder, resetStore } from '../../store/checkout-page/reducer'
import { useTranslation } from 'react-i18next'
import { useCheckoutPageStyles } from './checkout-page.styles'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { useNavigate } from 'react-router-dom'
import { useOverlayPreloader } from '../../hooks/use-overlay-preloader'

export const CheckoutPage: React.FC = (): React.ReactElement => {
  const props = useCheckoutPage()
  return <CheckoutPageContent {...props} />
}

export type CheckoutPageContentProps = ReturnType<typeof useCheckoutPage>

export const CheckoutPageContent: React.FC<CheckoutPageContentProps> = (
  props
): React.ReactElement => {
  const {
    //props
    itemLoadStatus,
    itemLoadError,
    billingDetails,
    orderDetails,
    orderStatus,
    orderError,
    isCurrentUserOwner,
    userInfo,
    isValidAddress,
    isShowAlert,

    //methods
    onPaymentMethodReceivedHandler,
    isShowStripeForm,
  } = props

  const { t } = useTranslation()
  const classes = useCheckoutPageStyles()
  const dispatch = useDispatch()
  const { setAppDialog } = useAppDialog()
  const navigate = useNavigate()
  const { setIsShow } = useAppDialog()
  const { setIsShowOverlayPreloader } = useOverlayPreloader()

  useEffect(() => {
    setIsShowOverlayPreloader &&
      setIsShowOverlayPreloader(orderStatus === LoadingState.LOADING)
  }, [orderStatus])

  //Reset Store
  useEffect(() => {
    return () => {
      dispatch(resetStore())
      setIsShow(false)
    }
  }, [])

  //Continue Handler
  const onContinueHandler = (): void => {
    dispatch(resetOrder())
    setAppDialog({
      isShow: false,
      message: t('checkout.successDialog.content'),
      title: t('checkout.successDialog.title'),
      actions: null,
    })
    navigate('/')
  }

  //Order Status Handler
  useEffect(() => {
    //Success Handler
    orderStatus === LoadingState.LOADED &&
      orderDetails &&
      setAppDialog({
        message: t('checkout.successDialog.content'),
        title: t('checkout.successDialog.title'),
        isShow: true,
        variant: 'success',
        closeHandler: onContinueHandler,
        actions: (
          <>
            <Button href={orderDetails.receiptUrl} {...{ target: '_blank' }}>
              {t('checkout.successDialog.buttons.receipt')}
            </Button>
            <Button onClick={onContinueHandler}>
              {t('checkout.successDialog.buttons.continue')}
            </Button>
          </>
        ),
      })

    //Error Handler
    orderStatus === LoadingState.ERROR &&
      setAppDialog({
        isShow: true,
        variant: 'error',
        title: t('checkout.errorDialog.title'),
        message: (
          <>
            <div>{t('checkout.errorDialog.content')}</div>
            <Typography color="error">
              {orderError?.getErrorMessage(t)}
            </Typography>
          </>
        ),
      })
  }, [orderStatus])

  if (!billingDetails) return <Error>{t('checkout.errorUnauthorized')}</Error>
  if (itemLoadStatus === LoadingState.ERROR)
    return <Error>{itemLoadError?.getErrorMessages(t)}</Error>
  if (isCurrentUserOwner)
    return <Error>{t('checkout.currentUserIsOwner')}</Error>
  if (itemLoadStatus !== LoadingState.LOADED) return <LinearProgress />

  const linkToPoliciesUrl =
    process.env.REACT_APP_HELP_SITE_BASE + 'link-to-policies'

  //Alert show
  const alertShow = () => {
    if (userInfo.shippingAddressExists === false) {
      return true
    } else {
      return isShowAlert
    }
  }

  //Alert Text
  const alertText = () => {
    if (userInfo.shippingAddressExists === false) {
      return (
        <>
          {t('checkout.addAddresssLabel')}
          <b className={classes.boldLink}>
            <ReactRouterLink to={'/account/shipping'}>
              {t('checkout.addAddressLink')}
            </ReactRouterLink>
          </b>
        </>
      )
    } else {
      return isValidAddress ? 'Address is valid' : 'Address is not valid'
    }
  }

  //Alert Status
  const alertStatus = () => {
    if (userInfo.shippingAddressExists === false) {
      return 'warning'
    } else {
      return isValidAddress ? 'success' : 'error'
    }
  }

  return (
    <Box marginTop={3} textAlign="start">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {alertShow() && (
              <Alert severity={alertStatus()}>{alertText()}</Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={7} className={classes.descriptionArea}>
            <CheckoutDescription />
          </Grid>

          <Grid item xs={12} sm={6} md={5}>
            <Box marginBottom={2}>
              <CheckoutSummary />
            </Box>

            {/*Stripe Form*/}
            {isShowStripeForm() && (
              <StripeForm
                onPaymentMethodReceived={onPaymentMethodReceivedHandler}
                billingDetails={billingDetails}
                disabled={
                  [LoadingState.LOADING, LoadingState.LOADED].indexOf(
                    orderStatus
                  ) !== -1
                }
              />
            )}

            <div className={classes.linksArea}>
              <Link href={linkToPoliciesUrl} className={classes.linkInNew}>
                {t('checkout.linkToPolices')}
                <OpenInNew />
              </Link>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const Error: React.FC<{ children?: React.ReactNode }> = (
  props
): React.ReactElement => {
  return (
    <Container>
      <Box marginTop={3}>
        <Alert color="error">{props.children}</Alert>
      </Box>
    </Container>
  )
}
