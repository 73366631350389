import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useNotFoundPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    notFoundPage: {
      marginTop: 'calc(100vh / 12)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    code: {
      fontSize: 150,
      margin: '100px 0 20px 0',
      letterSpacing: '2px',
      color: theme.palette.primary.light,
      textAlign: 'center',
      lineHeight: '100px',

      [theme.breakpoints.down('xs')]: {
        fontSize: 50,
        margin: '0',
      },
    },

    title: {
      fontSize: 30,
      margin: '20px 0 20px 0',
      letterSpacing: '2px',
      color: theme.palette.primary.main,
      textAlign: 'center',

      [theme.breakpoints.down('xs')]: {
        fontSize: 20,
        margin: '0',
      },
    },

    description: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 18,
      margin: '4px 0',
      color: theme.palette.primary.dark,

      [theme.breakpoints.down('xs')]: {
        margin: '5px 40px',
        fontSize: 14,
        flexDirection: 'column',
      },
    },

    link: {
      textDecoration: 'none',
      fontWeight: 'bold',
      letterSpacing: '.75px',
      cursor: 'pointer',
      color: theme.palette.primary.dark,

      '&:visited': {
        color: theme.palette.primary.dark,
      },

      [theme.breakpoints.down('xs')]: {
        margin: '5px 40px',
        fontSize: 14,
        flexDirection: 'column',
      },
    },

    back: {
      color: theme.palette.primary.dark,
      fontWeight: 'bold',
      letterSpacing: '.75px',
      textTransform: 'capitalize',
      fontSize: 18,
      padding: 0,

      '&:hover': {
        backgroundColor: 'transparent',
      },

      [theme.breakpoints.down('xs')]: {
        margin: '5px 40px',
        fontSize: 14,
      },
    },
  })
)
