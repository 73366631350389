import { createReducer } from '@reduxjs/toolkit'
import {
  hideSubscriptionSuccessMessage,
  subscribeEmail,
  subscribeEmailFailure,
  subscribeEmailSuccess,
} from './actions'
import { LoadingState } from '../types'

export enum SubscriptionShowedElement {
  FORM = 'SUBSCRIPTION_FORM',
  MESSAGE = 'SUCCESS_MESSAGE',
  VOID = 'VOID',
}

export type EmailSubscriptionState = {
  readonly loadingState: LoadingState
  readonly showedElement: SubscriptionShowedElement
}

const initialState: EmailSubscriptionState = {
  loadingState: LoadingState.NEVER,
  showedElement: SubscriptionShowedElement.FORM,
}

export const emailSubscriptionReducer = createReducer(initialState, {
  [subscribeEmail.type]: (state) => {
    state.loadingState = LoadingState.LOADING
  },
  [subscribeEmailSuccess.type]: (state) => {
    state.loadingState = LoadingState.LOADED
    state.showedElement = SubscriptionShowedElement.MESSAGE
  },
  [subscribeEmailFailure.type]: (state) => {
    state.loadingState = LoadingState.ERROR
    state.showedElement = SubscriptionShowedElement.FORM
  },
  [hideSubscriptionSuccessMessage.type]: (state) => {
    state.showedElement = SubscriptionShowedElement.VOID
  },
})
