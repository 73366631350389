import React from 'react'
import { useForm } from './use-form'
import { UseFormProps } from './use-form.types'

export const UseForm: React.FC<UseFormProps> = ({
  children,
  ...otherProps
}: UseFormProps): React.ReactElement => {
  const {
    values,
    errors,
    handleSubmit,
    inputHandlers,
    customHandlers,
    form,
    onCurrencyChange,
  } = useForm({ ...otherProps })
  return (
    <>
      {children({
        values,
        errors,
        handleSubmit,
        inputHandlers,
        customHandlers,
        form,
        onCurrencyChange,
      })}
    </>
  )
}
