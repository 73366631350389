import { call, takeEvery, put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { getDraftItems, getDraftItemsSuccess, getDonorItemsFailed } from './reducer'
import { listedItemsApi } from '../../services/api/listed-items'
import {trackException} from '../app-insights/reducer'

/*Get Donor Items*/
export function* getDraftItemsSaga({
  payload,
}: ReturnType<typeof getDraftItems>): SagaIterator {
  try {
    const response = yield call(listedItemsApi.getDonorItems, payload)
    yield put(getDraftItemsSuccess(response))
  } catch (e) {
    yield put(getDonorItemsFailed(e))
    yield put(trackException({e, source: 'getDraftItemsSaga'}))
  }
}

export function* draftItemsSaga(): SagaIterator {
  yield takeEvery(getDraftItems, getDraftItemsSaga)
}
