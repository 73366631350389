import { createSlice } from '@reduxjs/toolkit'
import {
  CheckoutPageState,
  GetShipmentRateSuccessAction,
  RatesType,
} from './types'
import { ActionType, LoadingState } from '../types'
import { ItemDetails } from '../../models/item-details'
import {
  ApiError,
  ApiErrorDataContent,
} from '../../services/api/api-error-data'
import { ListedItemPriceData } from '../../pages/checkout-page/checkout-page.models'
import { CreateOrderRequest } from '../../services/api/orders'
import { OrderDetails } from '../../models/order-details'
import { AddressDataType } from '../../pages/checkout-page/address-block/address-block.hook'
import { RequestShipmentInfoType } from '../../services/api/orders'
import { AxiosError } from 'axios'

//Initial Order State
const initialOrderState: Partial<CheckoutPageState> = {
  orderDetails: undefined,
  orderStatus: LoadingState.NEVER,
  orderRequest: undefined,
  orderError: undefined,
}

//Initial Item State
const initialItemState: Partial<CheckoutPageState> = {
  itemDetails: undefined,
  itemLoadStatus: LoadingState.NEVER,
  itemLoadId: undefined,
  itemLoadError: undefined,
  rateMessages: [],
}

const initialState = {
  ...initialItemState,
  ...initialOrderState,
  deliveryType: '',
  prices: [],
  choosingDeliveryType: undefined,
  addressFormDirty: false,
  address: {} as AddressDataType,
  regions: [],
  addressLoading: true,
  ratesLoading: true,
  isAddressValidated: false,
  isShowAlert: false,
  rates: [],
  rateMessages: [], //If rate array empty
  rateError: undefined,
  currentRate: undefined,
  saveAddressError: undefined,
} as CheckoutPageState

type FullAddressData = {
  regions: []
  address: AddressDataType
}

export const checkoutPageSlice = createSlice({
  name: 'checkoutPage',
  initialState: initialState,
  reducers: {
    resetStore: (state): void => {
      Object.assign(state, {
        ...initialState,
      })
    },
    loadItemDetails: (state, action: ActionType<string>): void => {
      Object.assign(state, {
        ...initialItemState,
        ...initialOrderState,
        ...{
          itemLoadId: action.payload,
          itemLoadStatus: LoadingState.LOADING,
        },
      })
    },

    loadItemSuccess: (state, action: ActionType<ItemDetails>): void => {
      state.itemDetails = action.payload
      state.itemLoadStatus = LoadingState.LOADED
    },

    loadItemError: (state, action: ActionType<ApiError>): void => {
      state.itemLoadError = action.payload
      state.itemLoadStatus = LoadingState.ERROR
    },

    setPrices: (state, action: ActionType<ListedItemPriceData[]>): void => {
      state.prices = action.payload
    },

    createOrder: (state, action: ActionType<CreateOrderRequest>): void => {
      Object.assign(state, {
        ...initialOrderState,
        ...{
          orderRequest: action.payload,
          orderStatus: LoadingState.LOADING,
        },
      })
    },

    createOrderSuccess: (state, action: ActionType<OrderDetails>): void => {
      state.orderStatus = LoadingState.LOADED
      state.orderDetails = action.payload
    },

    createOrderError: (state, action: ActionType<ApiError>): void => {
      state.orderStatus = LoadingState.ERROR
      state.orderError = action.payload
    },

    resetOrder: (state) => {
      Object.assign(state, initialOrderState)
    },

    setDeliveryType: (state, action: ActionType<string>): void => {
      state.deliveryType = action.payload
    },

    setDirtyForm: (state, action: ActionType<boolean>): void => {
      state.addressFormDirty = action.payload
    },
    loadAddressData: (state, action: ActionType<string>) => {
      state.addressLoading = true
      state.isShowAlert = false
    },
    loadAddressDataSuccess: (state, action: ActionType<FullAddressData>) => {
      const {
        addressLine2,
        addressLine1,
        city,
        countryCode,
        name,
        postalCode,
        region,
        isValidated,
      } = action.payload.address
      state.address = {
        addressLine1,
        addressLine2,
        region,
        city,
        countryCode,
        name,
        postalCode,
      }
      state.isAddressValidated = isValidated!
      state.regions = action.payload.regions
      state.addressLoading = false
      state.isShowAlert = true
    },
    setAddress: (state, action: ActionType<AddressDataType>): void => {
      state.addressLoading = true
      state.isShowAlert = false
      state.saveAddressError = undefined
    },
    setAddressSuccess: (state, action: ActionType<AddressDataType>): void => {
      state.addressLoading = false
      state.isAddressValidated = action.payload.isValidated!
      const { addressLine1, addressLine2, postalCode, city, region, name } =
        action.payload
      state.address = {
        ...state.address,
        addressLine1,
        addressLine2,
        postalCode,
        city,
        region,
        name,
      }
      state.isShowAlert = true
    },
    setAddressError: (state, action: ActionType<ApiErrorDataContent>): void => {
      state.saveAddressError = action.payload
      state.addressLoading = false
      state.ratesLoading = false
      state.isShowAlert = true
    },
    setRegions: (state, action: ActionType<string[]>): void => {
      state.regions = action.payload
    },
    getShippmentRate: (
      state,
      action: ActionType<RequestShipmentInfoType>
    ): void => {
      state.ratesLoading = true
    },
    getShippmentRateSuccess: (
      state,
      action: GetShipmentRateSuccessAction
    ): void => {
      const rates = action.payload.rates
      const messages = action.payload.messages
      const ratesLength = rates.length
      const messagesLength = messages.length
      state.rates = rates
      state.ratesLoading = false

      // If the list of arrays is non-empty, then select the first rate and fill in the fields in the store from it to display it to the user.
      if (ratesLength) {
        state.currentRate = rates[0]
        state.prices[2].amount = rates[0].amount
        //If the array list is empty, then we display messages from the delivery service.
      } else if (!ratesLength && messagesLength) {
        state.rateMessages = messages
      }
    },
    getShippmentRateFailed: (state, action: ActionType<AxiosError>): void => {
      state.ratesError = action.payload
    },
    setShippmentRateByArrayIndex: (state, action: ActionType<number>): void => {
      state.currentRate = state.rates[action.payload]
      state.prices[2].amount = state.rates[action.payload].amount
    },
    setLoadingRates: (state): void => {
      state.ratesLoading = true
    },
  },
})

export const checkoutPageReducer = checkoutPageSlice.reducer
export const {
  resetStore,
  loadItemDetails,
  loadItemSuccess,
  loadItemError,
  setPrices,
  createOrder,
  createOrderSuccess,
  createOrderError,
  resetOrder,
  setDeliveryType,
  setDirtyForm,
  setAddress,
  setAddressSuccess,
  setRegions,
  loadAddressData,
  loadAddressDataSuccess,
  getShippmentRate,
  getShippmentRateSuccess,
  getShippmentRateFailed,
  setShippmentRateByArrayIndex,
  setLoadingRates,
  setAddressError,
} = checkoutPageSlice.actions
