import React from 'react'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'
import { OwnItemDetailsComponentProps } from './own-item-details-component.types'
import { useOwnItemDetailsComponentStyles } from './own-item-details-component.styles'
import { SliderComponent } from './slider-component'
import { EmptyImageComponent } from './empty-image-component'
import { InfoComponent } from './info-component'
import { ItemState } from '../../../models'
import { useTranslation } from 'react-i18next'
import { GoBackButton } from '../../../components/go-back-button'

export const OwnItemDetailsComponent: React.FC<OwnItemDetailsComponentProps> = (
  props
) => {
  const {
    isCurrentUserOwner,
    images,
    itemCondition,
    currency,
    description,
    locale,
    currentPrice,
    name,
    nonprofitName,
    donorName,
    listedUtc,
    createdUtc,
    soldUtc,
    state,
    listedItemId,
    loading,
    city,
    editItemState,
    region,
    deliveryType,
    isLocalPickupAvailable,
    isShippingAvailable,
    setInStoreDeliveryType,
    onChangeMode,
    updateState,
  } = props
  // const classes = useEditItemDetailComponentStyles()
  const classes = useOwnItemDetailsComponentStyles()
  const { t } = useTranslation()

  //Placeholder
  const placeholderBlock = () => (
    <div>
      <Box pt={0.5}>
        <Skeleton className={classes.imgSkeleton} animation="wave" />
      </Box>
    </div>
  )

  //Img Block
  const imgBlock = () =>
    images && images.length ? (
      <SliderComponent images={images} />
    ) : (
      <EmptyImageComponent />
    )

  //Get Title
  const getTitle = (): string => {
    const TITLE_LIST = {
      [ItemState.Draft]: t('itemDetails.draftItem'),
      [ItemState.Active]: t('itemDetails.listedItem'),
      [ItemState.SellPending]: t('itemDetails.sellPending'),
      [ItemState.Sold]: t('itemDetails.sold'),
      [ItemState.Unlisted]: t('itemDetails.unlisted'),
    }

    return TITLE_LIST[state as keyof typeof TITLE_LIST]
  }

  return (
    <div className={classes.wrapper}>
      <GoBackButton className={classes.back} />
      <h2 className={classes.title}>{getTitle()}</h2>
      <div className={classes.sliderBlock}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={7} className={classes.sliderSectionBig}>
            {loading ? placeholderBlock() : imgBlock()}
          </Grid>
          <Grid item xs={12} md={5}>
            <InfoComponent
              updateState={updateState}
              editItemState={editItemState}
              listedItemId={listedItemId}
              deliveryType={deliveryType}
              isCurrentUserOwner={isCurrentUserOwner}
              itemCondition={itemCondition}
              currency={currency}
              city={city}
              region={region}
              description={description}
              locale={locale}
              loading={loading}
              name={name}
              state={state}
              listedUtc={listedUtc}
              createdUtc={createdUtc}
              soldUtc={soldUtc}
              nonprofitName={nonprofitName}
              donorName={donorName}
              isLocalPickupAvailable={isLocalPickupAvailable}
              isShippingAvailable={isShippingAvailable}
              setInStoreDeliveryType={setInStoreDeliveryType}
              onChangeMode={onChangeMode}
              currentPrice={currentPrice}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
