import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useImageCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageWrapper: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
      position: 'relative'
    },

    imageArea: {
      borderRadius: 4,
      background:
        'linear-gradient(to right, #E8E8E8 10%, #dddddd 40%, #E8E8E8 70%)',
      animationDuration: '5s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$imageLoadingAnimation',
      animationTimingFunction: 'linear',
      display: 'inline-box',
      overflow: 'hidden',
      position: 'relative',

      '& > div': {
        paddingTop: '98.323% !important',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
      },
    },

    loaderHolder: {
      position: 'absolute',
      left: 0,
      right: 0,
      height: 40,
      top: 0
    },

    loader: {
      transformOrigin: 'center center',
      opacity: '0.4',
      marginTop: '40%',

      '& div': {
        width: '40px !important',
        height: '40px !important',
        margin: 'auto',
      }
    },

    imgError: {
      width: '40px !important',
      height: '40px !important',
      margin: '0 auto 0 auto',
      opacity: '0.4',
      fontSize: 80,
      color: theme.palette.error.light
    },
  })
)
