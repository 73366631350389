import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAppHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: 100,
      paddingLeft: theme.spacing(0.5),
    },

    toolbar: {
      display: 'flex',
      alignItems: 'center',
    },
  })
)
