import React, { useEffect } from 'react'
import { Box, Modal, Backdrop, Fade } from '@material-ui/core'
import { AddressDataType, useAddressForm } from './address-block.hook'
import { useAddressStyles } from './address-block.styles'
import { Loader } from '../../../components/loader'
import { FormHolder } from './form-holder'
import { AddressInlineBlock } from './address-inline-block'

export const AddressBlock: React.FC = () => {
  /* Styles */
  const classes = useAddressStyles()
  const {
    //props
    addressLoading,
    address,
    fullScreen,
    isOpen,
    regions,
    tempValues,

    //methods
    setIsOpen,
    formValueChangesHandler,
    formValueSaveHandler,
    setRegion,
    loadInitialAddressData,
  } = useAddressForm()

  useEffect(() => {
    loadInitialAddressData()
  }, [])

  /*Default Props*/
  return addressLoading ? (
    <div className={classes.loader}>
      <Loader size={20} />
    </div>
  ) : (
    <>
      <Box justifyContent="center" display="flex">
        <AddressInlineBlock
          address={address as AddressDataType}
          setIsOpen={setIsOpen}
        />
      </Box>
      <Modal
        data-testid="modal"
        data-modal-open={isOpen}
        closeAfterTransition
        open={isOpen}
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={isOpen}>
          <>
            <FormHolder
              address={address}
              fullScreen={fullScreen}
              regions={regions}
              tempValues={tempValues}
              formValueChangesHandler={formValueChangesHandler}
              formValueSaveHandler={formValueSaveHandler}
              setIsOpen={setIsOpen}
              setRegion={setRegion}
            />
          </>
        </Fade>
      </Modal>
    </>
  )
}
