import { useDispatch } from 'react-redux'
import {
  setIsShowAction,
  setMessageAction,
  setShowCloseButtonAction,
  setTitleAction,
  setVariantAction,
  setCloseHandlerAction,
  setAppDialogChildrenAction,
} from '../../store/app-dialog/reducers'
import { AppDialogVariants } from '../../store/app-dialog/types'
import { SimpleFunc } from '../../models'
import React from 'react'

//Use App Dialog Return
type UseAppDialogReturnType = {
  setAppDialog: (settings: AppDialogSettingsType) => void
  setIsShow: (status: boolean) => void
}

export const useAppDialog = (): UseAppDialogReturnType => {
  const dispatch = useDispatch()

  const setIsShow = (status: boolean) => {
    status
      ? dispatch(setIsShowAction(status))
      : dispatch(setIsShowAction(false))
  }

  const setMessage = (message: React.ReactNode = '') => {
    dispatch(setMessageAction(message))
  }

  const setTitle = (title = '') => {
    dispatch(setTitleAction(title))
  }

  const setShowCloseButton = (value = true) => {
    dispatch(setShowCloseButtonAction(value))
  }

  const setCloseHandler = (fn: SimpleFunc) => {
    dispatch(setCloseHandlerAction(fn))
  }

  const setVariant = (variant = AppDialogVariants.success) => {
    dispatch(setVariantAction(variant))
  }

  const setActions = (actions: React.ReactNode) => {
    dispatch(setAppDialogChildrenAction(actions))
  }

  const setAppDialog = (settings: AppDialogSettingsType) => {
    const {
      isShow,
      message,
      closeHandler,
      showCloseButton,
      variant,
      title,
      actions,
    } = settings

    setIsShow(isShow)
    setShowCloseButton(showCloseButton)
    setVariant(variant)
    setMessage(message)
    setTitle(title)
    setCloseHandler(closeHandler as SimpleFunc)
    setActions(actions)
  }

  return {
    setAppDialog,
    setIsShow,
  }
}

type AppDialogSettingsType = Partial<{
  message: React.ReactNode
  variant: string
  title: string
  closeHandler: () => void
  showCloseButton: boolean
  actions: React.ReactNode
}> & { isShow: boolean }
