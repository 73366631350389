import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const draftItems = (state: RootState) => state.draftItems
export const selectLoading = createSelector(
  draftItems,
  (state) => state.loading
)
export const selectItemsList = createSelector(
  draftItems,
  (state) => state.itemsList
)
export const selectQueryParams = createSelector(
  draftItems,
  (state) => state.query
)
export const selectTotalPages = createSelector(
  draftItems,
  (state) => state.totalPages
)
export const selectFirstRender = createSelector(
  draftItems,
  (state) => state.firstRender
)
export const selectNeedApply = createSelector(
  draftItems,
  (state) => state.needApply
)
export const selectIsFilterCollapsed = createSelector(
  draftItems,
  (state) => state.isFilterCollapsed
)
export const selectError = createSelector(draftItems, (state) => state.error)
