import React from 'react'
import { Button } from '@material-ui/core'

export const StripeFormSubmitButton: React.FC<{
  processing: boolean
  disabled: boolean
  children?: React.ReactNode
}> = ({ processing, disabled, children }): React.ReactElement => (
  <Button
    disabled={disabled || processing}
    type="submit"
    variant="contained"
    color="primary"
  >
    {processing ? 'Processing...' : children}
  </Button>
)
