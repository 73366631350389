import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useInfoComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoBlock: {
      padding: '10px 0 0 10px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
      },
    },
    name: {
      fontWeight: 600,
      marginBottom: 18,
      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },
    listedTimeBlock: {
      marginBottom: 10,
    },
    timeLabel: {
      color: 'rgba(0,0,0,0.4)',
      fontSize: 13,
      fontWeight: 600,
    },
    time: {
      color: 'rgba(0,0,0,1)',
      fontSize: 13,
      fontWeight: 600,
    },
    descriptionTitle: {
      fontWeight: 600,
      fontSize: 16,
      marginBottom: 10,
    },
    descriptionText: {
      fontSize: 14,
      lineHeight: 1.7,
      color: 'rgba(0,0,0, 0.5)',
    },
    descriptionHolder: {
      overflowY: 'hidden',
    },
    propsHolder: {
      marginTop: 20,
    },
    propsBlock: {
      marginBottom: 5,
    },
    propsLabel: {
      color: 'rgba(0,0,0,0.4)',
      fontSize: 13,
      fontWeight: 600,
    },
    propsValue: {
      color: 'rgba(0,0,0,1)',
      fontSize: 13,
      fontWeight: 600,
    },
    priceBlock: {
      display: 'flex',
    },
    price: {
      fontWeight: 800,
      fontSize: 23,
      color: theme.palette.primary.main,
    },
    priceNumber: {
      position: 'relative',
      left: 25,
      top: -1,
    },

    verticalAlign: {
      marginTop: '30px',
    },
    topPart: {
      display: 'inline-block',
      verticalAlign: 'top',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    btnBlock: {
      minWidth: 105,
      display: 'inline-block',
      marginLeft: 62,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    statusMessageBlock: {
      textTransform: 'uppercase',
      fontSize: 11,
      color: theme.palette.primary.main,
      fontWeight: 800,
    },
    deliveryBlock: {
      '& .MuiFormGroup-root': {
        display: 'inline-block',
      },
    },
  })
)
