import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useEmptySearchComponentComponentStyles = makeStyles(() =>
  createStyles({
    emptySearchResultComponentHolder: {
      display: 'block',
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      background: 'white',
      borderRadius: 4,
      paddingTop: 40,
      paddingBottom: 40,
    },
    icon: {
      opacity: 0.1,
    },
    textBlock: {
      display: 'block',
      fontSize: 21,
      opacity: 0.4,
    },
  })
)
