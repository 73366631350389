import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { useDraftEditorStyles } from './draft-editor.styles'
import { useTranslation } from 'react-i18next'
import { ItemImages } from '../../donate-page/item-images'
import {
  Form,
  getErrorsFromSchema,
  UseForm,
  ValidationErrors,
} from '../../../hooks/use-form/'
import { DraftDataType } from './draft-editor.constants'
import { AsyncAutocomplete } from '../../../components/async-autocomplete'
import { useAppDialog } from '../../../hooks/use-app-dialog'
import { SelectNonprofitProps } from '../../donate-page/donate.models'
import {
  greaterThan,
  lessThan,
  max,
  min,
  numberRange,
  required,
} from '../../../hooks/use-form/use-form.validators'
import { useDraftEditor } from './draft-editor.hook'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { ImageObject } from '../../donate-page/item-images/item-images.hook'
import { Alert } from '@material-ui/lab'
import { Link as ReactRouterLink } from 'react-router-dom'
import CloseIcon from '@material-ui/icons/Close'
import { ItemState } from '../../../models'

type DraftHandlersType = {
  setImages: (value: ImageObject[]) => void
  getSelectedData: () => void
  submitItem: (value: Form, saveDraft?: boolean) => void
}

type InitialImages = {
  initialImages: string[]
}

type DraftState = {
  isDirtyDraft: boolean
  setIsDirtyDraft: (value: boolean) => void
  closeDraftMode: () => void
  editItemState?: ItemState
  loading: boolean
}

type Props = DraftDataType &
  DraftHandlersType &
  InitialImages &
  SelectNonprofitProps &
  DraftState

export const DraftEditor = (props: Props) => {
  const {
    weight,
    height,
    length,
    description,
    width,
    name,
    price,
    delivery,
    condition,
    isDirtyDraft,
    setIsDirtyDraft,
    editItemState,
    images,

    //Images
    setImages,
    initialImages = [],

    //Select
    categories,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    nonprofits,
    setNonprofits,
    selectedNonprofit,
    setSelectedNonprofit,
    categoryRequest,
    nonprofitRequest,
    submitItem,
    shippingAddressExists,
    closeDraftMode,
  } = props

  const classes = useDraftEditorStyles()
  const { t } = useTranslation()
  const { setAppDialog, setIsShow } = useAppDialog()
  const { formValueChanges, draftEditorData } = useDraftEditor({
    weight,
    height,
    length,
    description,
    width,
    name,
    price,
    delivery,
    condition,
  })

  const validateDraftEditor = getErrorsFromSchema({
    name: [required, min(2), max(100)],
    description: [required, min(2), max(4000)],
    price: [required, min(1), numberRange([1, 200000])],
    condition: [required],
    delivery: [required],
    weight: [greaterThan(0), lessThan(70)],
    height: [greaterThan(0), lessThan(125)],
    length: [greaterThan(0), lessThan(125)],
    width: [greaterThan(0), lessThan(125)],
  })

  const isDisabledPublish = (errors: ValidationErrors) => {
    const {
      name,
      description,
      condition,
      height,
      length,
      price,
      weight,
      width,
    } = errors
    // eslint-disable-next-line functional/no-let
    let status = !!(
      name ||
      description ||
      condition ||
      height ||
      length ||
      price ||
      weight ||
      width
    )

    if (!selectedCategory || !selectedNonprofit || !images.length) {
      status = true
    }
    return status
  }

  const isDisabledSaveDraft = () => !selectedCategory || !selectedNonprofit

  /** CancelHandler**/
  const cancelHandler = () => {
    setAppDialog({
      variant: 'warning',
      title: 'Warning',
      message: 'Are you sure you want to undo the edit?',
      isShow: true,
      actions: (
        <>
          <Button
            onClick={() => {
              closeDraftMode()
              setIsShow(false)
            }}
          >
            Ok
          </Button>
        </>
      ),
    })
  }

  return (
    <div className={classes.container}>
      {!shippingAddressExists && (
        <div className={classes.shippingAddressAlert}>
          <Alert severity="warning">
            {t('editItemDetailsPage.editItemDetailsPage')} &nbsp;
            <b>
              <ReactRouterLink to={'/account/shipping'}>
                {t('editItemDetailsPage.addAddressLink')}
              </ReactRouterLink>
            </b>
          </Alert>
        </div>
      )}

      {/*Forms*/}
      <Paper>
        <UseForm
          //@ts-ignore
          initialValues={props}
          formValueChanges={formValueChanges}
          onSubmit={() => console.log('Submit')}
          validate={validateDraftEditor}
          changeMiddleware={() => setIsDirtyDraft(true)}
        >
          {({
            errors,
            inputHandlers,
            handleSubmit,
            form,
            onCurrencyChange,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <div className={classes.formContainer}>
                <Grid container spacing={2}>
                  {/*Edit Images*/}
                  <Grid item xs={12}>
                    <div className={classes.header}>
                      <Typography variant="h6" className={classes.headerTitle}>
                        Edit Images
                      </Typography>
                      <span
                        onClick={() =>
                          isDirtyDraft ? cancelHandler() : closeDraftMode()
                        }
                      >
                        <CloseIcon />{' '}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {/*@ts-ignore*/}
                    <ItemImages
                      initialImages={initialImages}
                      setItemImages={setImages}
                      onErrorHandler={() => console.log('Error')}
                    />
                  </Grid>

                  {/*Edit Information*/}
                  <Grid item xs={12}>
                    <div className={classes.header}>
                      <Typography variant="h6" className={classes.headerTitle}>
                        {t('editItemDetailsPage.editInformation')}
                      </Typography>
                    </div>
                  </Grid>

                  {/*Name*/}
                  <Grid item xs={12} md={8}>
                    <div className={classes.inputHolder}>
                      <TextField
                        error={!!errors?.name && form.name.dirty}
                        helperText={
                          form.name.dirty && !form.name.focused && errors.name
                        }
                        value={draftEditorData.name}
                        required={true}
                        name="name"
                        variant="outlined"
                        fullWidth
                        label="Name"
                        {...inputHandlers}
                      />
                    </div>

                    {/*Description*/}
                    <div className={classes.inputHolder}>
                      <TextField
                        multiline={true}
                        error={!!errors?.description && form.description.dirty}
                        helperText={
                          form.description.dirty &&
                          !form.description.focused &&
                          errors.description
                        }
                        value={draftEditorData.description}
                        variant="outlined"
                        fullWidth
                        label="Description"
                        name="description"
                        {...inputHandlers}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {/*SELECT*/}
                    <div className={classes.inputHolder}>
                      <AsyncAutocomplete
                        required={true}
                        label={t(
                          'donation.selectGroup.selectNonprofitCategory'
                        )}
                        noOptionsText={t('donation.selectGroup.noCategories')}
                        options={categories}
                        setOptions={setCategories}
                        request={categoryRequest}
                        selectedOption={selectedCategory}
                        setSelectedOption={setSelectedCategory}
                      />
                    </div>

                    <div className={classes.inputHolder}>
                      <AsyncAutocomplete
                        required={true}
                        disabled={!selectedCategory}
                        label={t('donation.selectGroup.selectNonprofit')}
                        noOptionsText={t('donation.selectGroup.noNonprofits')}
                        options={nonprofits}
                        setOptions={setNonprofits}
                        request={nonprofitRequest}
                        selectedOption={selectedNonprofit}
                        setSelectedOption={setSelectedNonprofit}
                      />
                    </div>
                  </Grid>

                  {/*Width*/}
                  <Grid item xs={12} md={3}>
                    <div className={classes.inputHolder}>
                      <TextField
                        error={!!errors?.width && form.width.dirty}
                        helperText={
                          form.width.dirty &&
                          !form.width.focused &&
                          errors.width
                        }
                        value={draftEditorData.width}
                        type="number"
                        variant="outlined"
                        fullWidth
                        name="width"
                        label="Width, inches"
                        {...inputHandlers}
                        onBlur={inputHandlers.onBlur}
                        onFocus={inputHandlers.onFocus}
                      />
                    </div>
                  </Grid>

                  {/*Height*/}
                  <Grid item xs={12} md={3}>
                    <div className={classes.inputHolder}>
                      <TextField
                        error={!!errors?.height && form.height.dirty}
                        helperText={
                          form.height.dirty &&
                          !form.height.focused &&
                          errors.height
                        }
                        value={draftEditorData.height}
                        type="number"
                        variant="outlined"
                        fullWidth
                        name="height"
                        label="Height, inches"
                        {...inputHandlers}
                        onBlur={inputHandlers.onBlur}
                        onFocus={inputHandlers.onFocus}
                      />
                    </div>
                  </Grid>

                  {/*Length*/}
                  <Grid item xs={12} md={3}>
                    <div className={classes.inputHolder}>
                      <TextField
                        error={!!errors?.length && form.length.dirty}
                        helperText={
                          form.length.dirty &&
                          !form.length.focused &&
                          errors.length
                        }
                        value={draftEditorData.length}
                        type="number"
                        variant="outlined"
                        fullWidth
                        name="length"
                        label="Length, inches"
                        {...inputHandlers}
                        onBlur={inputHandlers.onBlur}
                        onFocus={inputHandlers.onFocus}
                      />
                    </div>
                  </Grid>

                  {/*Weight*/}
                  <Grid item xs={12} md={3}>
                    <div className={classes.inputHolder}>
                      <TextField
                        error={!!errors?.weight && form.weight.dirty}
                        helperText={
                          form.weight.dirty &&
                          !form.weight.focused &&
                          errors.weight
                        }
                        value={draftEditorData.weight}
                        type="number"
                        variant="outlined"
                        fullWidth
                        name="weight"
                        label="Weight, pounds"
                        {...inputHandlers}
                        onBlur={inputHandlers.onBlur}
                        onFocus={inputHandlers.onFocus}
                      />
                    </div>
                  </Grid>

                  {/*Price*/}
                  <Grid item xs={6} sm={4}>
                    <div className={classes.inputHolder}>
                      <CurrencyTextField
                        error={!!errors?.price && form.price.dirty}
                        helperText={
                          form.price.dirty &&
                          !form.price.focused &&
                          errors.price
                        }
                        value={draftEditorData.price}
                        variant="outlined"
                        fullWidth
                        label="Price"
                        name="price"
                        maximumValue={'200000'}
                        minimumValue={'0'}
                        onBlur={inputHandlers.onBlur}
                        onFocus={inputHandlers.onFocus}
                        onChange={onCurrencyChange}
                      />
                    </div>
                  </Grid>

                  {/*Condition*/}
                  <Grid item xs={6} sm={4}>
                    <div className={classes.inputHolder}>
                      <FormControl fullWidth onChange={inputHandlers.onChange}>
                        <FormLabel style={{ textAlign: 'start' }} required>
                          {t('donation.condition')}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="condition"
                          name="condition"
                          value={draftEditorData.condition}
                        >
                          <FormControlLabel
                            value="new"
                            control={<Radio color="primary" />}
                            label={t('donation.new')}
                          />
                          <FormControlLabel
                            value="used"
                            control={<Radio color="primary" />}
                            label={t('donation.used')}
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </Grid>

                  {/*Button Block*/}
                  <Grid item xs={12}>
                    <div className={classes.btnHolder}>
                      <Button
                        variant="contained"
                        disabled={
                          editItemState !== ItemState.Active &&
                          (!isDirtyDraft ||
                            !!errors?.name ||
                            isDisabledSaveDraft())
                        }
                        onClick={() => submitItem(form, false)}
                      >
                        {editItemState === ItemState.Active
                          ? t('editItemDetailsPage.moveDraft')
                          : t('editItemDetailsPage.saveDraft')}
                      </Button>
                      <Button
                        variant="contained"
                        className={classes.cancelBtn}
                        onClick={cancelHandler}
                        disabled={!isDirtyDraft}
                      >
                        {t('buttons.cancel')}
                      </Button>
                      {shippingAddressExists &&
                        editItemState !== ItemState.Active && (
                          <Button
                            variant="contained"
                            disabled={isDisabledPublish(errors)}
                            color="primary"
                            type="submit"
                            onClick={() => submitItem(form, true)}
                          >
                            {t('buttons.publish')}
                          </Button>
                        )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          )}
        </UseForm>
      </Paper>
    </div>
  )
}
