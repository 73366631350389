import { call, put, takeEvery, delay } from 'redux-saga/effects'
import {
  hideSubscriptionSuccessMessage,
  subscribeEmail,
  subscribeEmailFailure,
  subscribeEmailSuccess,
} from './actions'
import { emailSubscriptionApi } from '../../services/api/email-subscription-api'
import { SagaIterator } from 'redux-saga'
import {trackException} from '../app-insights/reducer'

const successMessageTimeout = 3000

export function* emailSubscriptionRequest({
  payload,
}: ReturnType<typeof subscribeEmail>): SagaIterator {
  try {
    yield call(emailSubscriptionApi.subscribe, payload)
    yield put(subscribeEmailSuccess())
    yield delay(successMessageTimeout)
    yield put(hideSubscriptionSuccessMessage())
  } catch (e) {
    yield put(subscribeEmailFailure())
    yield put(trackException({e, source: 'emailSubscriptionRequest'}))
  }
}

export function* emailSubscriptionSaga(): Generator {
  yield takeEvery(subscribeEmail, emailSubscriptionRequest)
}
