import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectAppDialog = (state: RootState) => state.appDialog
export const selectIsShow = createSelector(
  selectAppDialog,
  (state) => state.isShow
)
export const selectVariant = createSelector(
  selectAppDialog,
  (state) => state.variant
)
export const selectMessage = createSelector(
  selectAppDialog,
  (state) => state.message
)
export const selectShowCloseButton = createSelector(
  selectAppDialog,
  (state) => state.showCloseButton
)
export const selectTitle = createSelector(
  selectAppDialog,
  (state) => state.title
)
export const selectOnExit = createSelector(
  selectAppDialog,
  (state) => state.onExit
)
export const selectCloseHandler = createSelector(
  selectAppDialog,
  (state) => state.closeHandler
)
export const selectClosable = createSelector(
  selectAppDialog,
  (state) => state.closable
)
export const selectActions = createSelector(
  selectAppDialog,
  (state) => state.actions
)
