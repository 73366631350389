import axios from 'axios'
import { FeaturedProduct } from '../../components/product-card'
import { FetchProductsPayload } from '../../store/products/types'

export const productsApi = {
  getAll({
    page = 1,
    limit = 8,
  }: FetchProductsPayload): Promise<readonly FeaturedProduct[]> {
    return axios
      .get(`http://localhost:3001/products?_page=${page}&_limit=${limit}`)
      .then(({ data }) => data)
  },
}
