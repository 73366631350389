import './app-insight-examples.scss'
import React from 'react'

class AppInsightExamples {
  checkAppInsight() {
    console.log(window.appInsights)
  }

  trackDebug() {
    const message: string = prompt('Enter some message here') ?? 'Buzinga'
    window.appInsights.trackTrace({ message })
    window.appInsights.flush()
  }

  trackEvent() {
    const name: string = prompt('Enter event name') ?? 'Example event'
    window.appInsights.trackEvent({ name })
    window.appInsights.flush()
  }
}

export const Playground = (): React.ReactElement => {
  const examples = new AppInsightExamples()

  return (
    <div className="app-insight-examples">
      <h1>AppInsight examples</h1>
      <div className="buttons-list">
        <div>
          <button onClick={examples.checkAppInsight}>Check AppInsight</button>
          &nbsp; - log AppInsights instance to console
        </div>
        <div>
          <button onClick={examples.trackDebug}>Log message</button>
          &nbsp; - send trace log to AppInsights
        </div>
        <div>
          <button onClick={examples.trackEvent}>Send event with name</button>
          &nbsp; - send named event
        </div>
      </div>
    </div>
  )
}
