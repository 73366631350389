import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useStripeFormContentStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },

    stripeCard: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      paddingRight: '5px',

      '& .StripeElement': {
        flexGrow: 1,
      },
    },

    payButton: {},
  })
)
