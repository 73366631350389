import React, { useEffect } from 'react'
import { AppRouter } from './components/router'
import { Footer } from './components/footer'
import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { AppHeader } from './components/app-header'
import { AppDialog } from './hooks/use-app-dialog/use-app-dialog.component'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectMessage,
  selectTitle,
  selectIsShow,
  selectVariant,
  selectShowCloseButton,
  selectCloseHandler,
  selectActions,
} from './store/app-dialog/selectors'
import { setInitial, setIsShowAction } from './store/app-dialog/reducers'
import { CookieBanner } from './components/cookie-banner'
import { AppProgressIndicator } from './components/app-progress-indicator'
import { selectOverlayPreloaderIsShow } from './store/overlay-preloader/selectors'
import {useMsalAccessTokenProvider} from './hooks/use-fetch-with-msal/use-msal-access-token'
import {useTitle} from './hooks/use-title/use-title'
import { getUser } from './store/auth/reducer'
import { axiosManager } from './api'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    mainContainer: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      background: 'rgba(245, 245, 245, 1)',
      ['@media print']: {
        display: 'none',
      },
    },
    content: {
      flexGrow: 1,
    },
    miniOverlay: {
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
)

export const AppWrapper: React.FC = () => {
  const { authMsalResult} = useMsalAccessTokenProvider()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const appDialogMessage = useSelector(selectMessage)
  const appDialogTitle = useSelector(selectTitle)
  const appDialogIsShow = useSelector(selectIsShow)
  const appDialogVariant = useSelector(selectVariant)
  const appDialogShowCloseButton = useSelector(selectShowCloseButton)
  const appDialogCloseHandler = useSelector(selectCloseHandler)
  const appDialogActions = useSelector(selectActions)
  const overlayPreloaderIsShow = useSelector(selectOverlayPreloaderIsShow)
  const { setTitle } = useTitle()

  const resetHandler = () => {
    dispatch(setInitial())
  }

  const appDialogSetIsShow = (value: boolean): void => {
    dispatch(setIsShowAction(value))
  }

  const getUserCallback = () =>{
    dispatch(getUser())
  }

  useEffect(() => {
    if(authMsalResult.result?.state){
      navigate(authMsalResult.result?.state)
    }
  }, [authMsalResult.result?.state])

  useEffect(() => {
    setTitle('Prosphora')
  }, [])

  /* Set Access To Local Storage */
  useEffect(()=> {
    const token = authMsalResult.result?.accessToken || null
    axiosManager.setToken(token)

    const {error} = authMsalResult
    if(error || token){
      getUserCallback()
    }
  }, [authMsalResult.error, authMsalResult.result])

  /* Show Load Component Before Get Token */
  return (
      <>
      <div className={`App ${classes.mainContainer}`}>
        <AppHeader />
        <div className={classes.content}>
          <AppRouter />
        </div>
        <Footer />
        <CookieBanner />
      </div>
      <AppDialog
        title={appDialogTitle}
        showCloseButton={appDialogShowCloseButton}
        message={appDialogMessage}
        variant={appDialogVariant}
        isShow={appDialogIsShow}
        closeHandler={appDialogCloseHandler}
        setIsShow={appDialogSetIsShow}
        actions={appDialogActions}
        resetHandler={resetHandler}
      />
      {overlayPreloaderIsShow && <AppProgressIndicator />}
    </>
  )
}
