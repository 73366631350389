import { CheckOptionalType, getErrorsFromSchema } from '../../../hooks/use-form'
import {
  max,
  min,
  numberRange,
  required,
  greaterThan,
  lessThan,
} from '../../../hooks/use-form/use-form.validators'

/*
 * Delivery type when we
 * choose delivery during donation process
 */
export const DeliveryType = {
  Shipping: 'shipping',
  LocalPickup: 'local-pickup',
  Both: 'both',
}

export type DonateDetailsType = {
  title: string
  description: string
  price: number
  condition: string
  delivery: string
  weight?: number
  height?: number
  length?: number
  width?: number
}

export const initialValues: DonateDetailsType = {
  title: '',
  description: '',
  price: 1.00,
  condition: '',
  delivery: DeliveryType.Shipping,
  weight: undefined,
  height: undefined,
  length: undefined,
  width: undefined,
}

export const optionalValidatorCondition: CheckOptionalType = [
  {
    fieldName: 'width',
    triggerFieldName: 'delivery',
    optionalValues: ['', DeliveryType.LocalPickup],
  },
  {
    fieldName: 'weight',
    triggerFieldName: 'delivery',
    optionalValues: ['', DeliveryType.LocalPickup],
  },
  {
    fieldName: 'height',
    triggerFieldName: 'delivery',
    optionalValues: ['', DeliveryType.LocalPickup],
  },
  {
    fieldName: 'length',
    triggerFieldName: 'delivery',
    optionalValues: ['', DeliveryType.LocalPickup],
  },
]

export const validateDonateDetails = getErrorsFromSchema({
  title: [required, min(2), max(100)],
  description: [required, min(2), max(4000)],
  price: [required, min(1), numberRange([1, 200000])],
  condition: [required],
  delivery: [required],
  weight: [greaterThan(0), lessThan(70)],
  height: [greaterThan(0), lessThan(125)],
  length: [greaterThan(0), lessThan(125)],
  width: [greaterThan(0), lessThan(125)],
})
