import { getErrorsFromSchema } from '../../../hooks/use-form'
import { max, required } from '../../../hooks/use-form/use-form.validators'

export const validationRules = getErrorsFromSchema({
  name: [required, max(501)],
  addressLine1: [required, max(1024)],
  addressLine2: [max(1024)],
  city: [required, max(170)],
  region: [required],
  postalCode: [required, max(9)],
  countryCode: [required, max(2)],
})
