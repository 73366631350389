import { useDonateStyles } from './donate.styles'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { DonateProps } from './donate.models'
import { DeliveryType, DonateDetailsType } from './donate-details'
import { useTranslation } from 'react-i18next'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { AppDialogVariants } from '../../store/app-dialog/types'
import { selectUser } from '../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { MsalEmptyDataAuthPayload } from '../../services/api/auth/models'
import { ApiError } from '../../services/api/api-error-data'
import useValidateParcel from '../../hooks/use-validate-parcel/use-validate-parcel.hooks'
import { useOverlayPreloader } from '../../hooks/use-overlay-preloader'
import {trackException} from '../../store/app-insights/reducer'

type UseDonateProps = {
  nonprofitIdProp: DonateProps['nonprofitIdProp']
  createListedItemApi: (
    listedItemProps: {
      images: string[]
      price: number
      name: string
      width?: number
      length?: number
      isDraft: boolean
      description: string
      weight?: number
      nonprofitId: number
      itemCondition: string
      height?: number
      isLocalPickupAvailable: boolean
      isShippingAvailable: boolean
    } & MsalEmptyDataAuthPayload
  ) => Promise<any>
}
const initialLoadStatus = {
  loaded: false,
  loading: false,
  loadedSuccess: false,
  isDraft: false,
}

export const useDonate = ({
  nonprofitIdProp,
  createListedItemApi,
}: UseDonateProps) => {
  const dispatch = useDispatch()
  const { nonprofitId: id } = useParams()
  const [loadStatus, setLoadStatus] = useState({ ...initialLoadStatus })
  const { t } = useTranslation()
  const { setAppDialog, setIsShow } = useAppDialog()
  const [publishedItemId, setPublishedItemId] = useState(0)
  const shippingAddressExists = useSelector(selectUser).shippingAddressExists
  const [parcelData, setParcelData] = useState(false)
  const { setIsShowOverlayPreloader } = useOverlayPreloader()

  /** Parcel */
  const { validateParcel: validateParcelHook } = useValidateParcel(
    t('donation.error')
  )

  const classes = useDonateStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [steps, setSteps] = React.useState<string[]>([
    t('donation.stepLabels.selectNonprofit'),
    t('donation.stepLabels.provideItemInfo'),
    t('donation.stepLabels.uploadingImages'),
  ])

  React.useEffect(() => {
    (id || nonprofitIdProp) &&
      setSteps((prev: string[]) =>
        prev.filter((value: string, index: number) => index !== 0)
      )

    //eslint-disable-next-line
  }, [])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
    setLoadStatus({
      ...initialLoadStatus,
    })
  }

  const validateParcel = (params: Partial<DonateDetailsType>) => {
    const { weight, width, length, height } = params
    setLoadStatus({ ...loadStatus, loading: true })
    setIsShowOverlayPreloader(true)

    validateParcelHook({ weight, width, length, height })
      .then((result) => {
        if (result && Array.isArray(result.rates) && result.rates.length > 0) {
          setParcelData(true)
        } else {
          setAppDialog({
            isShow: true,
            title: t('donation.error'),
            variant: AppDialogVariants.error,
            message: result?.messages.reduce(
              (acc, curr) => acc + ' ' + curr.text,
              ''
            ),
          })
        }
      })
      .catch((e)=> {
        dispatch(trackException({e, source: 'validateParcelHook'}))
      })
      .finally(() => {
        setLoadStatus({
          ...loadStatus,
          loading: false,
        })
        setIsShowOverlayPreloader(false)
      })
    setParcelData(false)
  }

  const createListedItem = (
    donateDetails: Partial<DonateDetailsType>,
    nonprofitId: number,
    images: string[],
    isDraft: boolean
  ) => {
    const {
      description,
      price,
      condition,
      title,
      width,
      weight,
      length,
      delivery,
      height,
    } = donateDetails
    setLoadStatus({ ...loadStatus, loading: true })
    setIsShowOverlayPreloader(true)

    const packageAddStatus =
      delivery === DeliveryType.Shipping || delivery === DeliveryType.Both

    createListedItemApi({
      description: description ?? '',
      itemCondition: condition ?? '',
      name: title ?? '',
      nonprofitId,
      //Need To change on backend condition
      width: packageAddStatus ? width : undefined,
      weight: packageAddStatus ? weight : undefined,
      height: packageAddStatus ? height : undefined,
      length: packageAddStatus ? length : undefined,
      //End TODO
      price: price ?? 0,
      images,
      isDraft,
      isLocalPickupAvailable:
        delivery === DeliveryType.Both || delivery === DeliveryType.LocalPickup,
      isShippingAvailable:
        delivery === DeliveryType.Both || delivery === DeliveryType.Shipping,
    })
      .then((data) => {
        setPublishedItemId(data.entityId)
        setIsShowOverlayPreloader(false)
        setLoadStatus({
          ...loadStatus,
          loading: false,
          loadedSuccess: true,
          loaded: true,
          isDraft: isDraft,
        })
        setAppDialog({
          isShow: true,
          title: 'Success',
          message: isDraft ? t('donation.successDraft') : t('donation.success'),
        })
      })
      .catch((error) => {
        setIsShowOverlayPreloader(false)
        setLoadStatus({
          ...loadStatus,
          loading: false,
          loadedSuccess: false,
          loaded: false,
        })

        setAppDialog({
          isShow: true,
          variant: AppDialogVariants.error,
          message: new ApiError(error).getErrorMessage(t),
          title: t('donation.error'),
        })
        dispatch(trackException({e: error, source: 'createListedItemApi'}))
      })
  }

  return {
    shippingAddressExists,
    classes,
    activeStep,
    steps,
    nonprofitId: nonprofitIdProp || (id as string),
    handleNext,
    handleBack,
    handleReset,
    createListedItem,
    loadStatus,
    setAppDialog,
    publishedItemId,
    validateParcel,
    parcelData,
    setIsShow
  }
}
