/*
 * Delivery type when we
 * choose delivery during donation process
 */
export const DeliveryType = {
  Shipping: 'shipping',
  LocalPickup: 'local-pickup',
  Both: 'both',
}

export enum KindImageEnum {
  existing = 1,
  new = 2,
}

export type DraftImageItemType = {
  newImageGuid?: string | number
  existingImageId?: string | number
  kind: KindImageEnum
}

export type DraftDataType = {
  name: string
  description: string
  price: number
  condition: string
  delivery: string
  weight: number
  height: number
  length: number
  width: number
  images: DraftImageItemType[] | []
  nonprofitId?: number
}

export const initialValues: DraftDataType = {
  name: '',
  description: '',
  price: 0,
  condition: '',
  delivery: DeliveryType.Shipping,
  weight: 0,
  height: 0,
  length: 0,
  width: 0,
  images: [],
  nonprofitId: 0,
}
