import {
  ValidateParcelRequest,
  ValidateParcelResponse,
  ordersApi,
} from '../../services/api/orders'
import { useAppDialog } from '../use-app-dialog'
import { useTranslation } from 'react-i18next'
import { AppDialogVariants } from '../../store/app-dialog/types'
import { ApiError } from '../../services/api/api-error-data'

const useValidateParcel = (errorTitle: string) => {
  const { setAppDialog } = useAppDialog()
  const { t } = useTranslation()

  const validateParcel = async ({
    weight,
    width,
    length,
    height,
  }: ValidateParcelRequest): Promise<ValidateParcelResponse | null> => {
    try {
      const response = await ordersApi.validateParcelRequest({
        weight,
        width,
        length,
        height,
      })
      return response
    } catch (error) {
      setAppDialog({
        isShow: true,
        title: errorTitle,
        variant: AppDialogVariants.error,
        message: new ApiError(error).getErrorMessage(t),
      })
      return null
    }
  }

  return {
    validateParcel,
  }
}

export default useValidateParcel
