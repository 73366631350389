import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useSliderComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    slider: {
      '& .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg':
        {
          height: 50,
        },
    },
    slideHolder: {
      position: 'relative',
    },
    img: {
      maxHeight: 'calc(100vh - 80px)',
      width: '100%',
      objectFit: 'contain',
      zIndex: 2,
      position: 'relative',
    },

    '@keyframes imageLoadingAnimation': {
      '0%': {
        backgroundPosition: '-250px 0',
      },
      '100%': {
        backgroundPosition: '250px 0',
      },
    },
    imageWrapper: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    imageArea: {
      background:
        'linear-gradient(to right, #E8E8E8 10%, #dddddd 40%, #E8E8E8 70%)',
      animationDuration: '5s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$imageLoadingAnimation',
      animationTimingFunction: 'linear',
      overflow: 'hidden',
      position: 'relative',
    },
    imgBgBlock: {
      width: '100%',
      position: 'relative',
      display: 'block',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      zIndex: 1,
    },
    imageHolder: {
      width: '100%',
      position: 'relative',
      paddingBottom: '75%',
      backgroundColor: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
    },
    imageThumb: {
      paddingBottom: '50%',
      backgroundColor: 'white',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  })
)
