import React from 'react'
import { useAppHeaderDonateButtonStyles } from './app-header-donate-button.styles'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsAuthenticated } from '../../../store/auth/selectors'
import { useTranslation } from 'react-i18next'
import {msalRequest} from '../../../env-config'
import {useOverlayPreloader} from '../../../hooks/use-overlay-preloader'
import {useMsal} from '@azure/msal-react'

export const AppHeaderDonateButton: React.FC = (): React.ReactElement => {
  const classes = useAppHeaderDonateButtonStyles()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const { t } = useTranslation()
  const { instance } = useMsal()
  const { setIsShowOverlayPreloader } = useOverlayPreloader()
  /* Login Handler */
  const handleLoginRedirect = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setIsShowOverlayPreloader(true)
    instance.loginRedirect({...msalRequest, redirectUri: '/donate'}).catch((error) => console.log(error))
  }

  if (isAuthenticated){
    return (
      <Link to="/donate" className={classes.donateLink} >
        {t('header.menu.donate')}
      </Link>
    )
  } else {
    return(
      <Link to="/donate" className={classes.donateLink} onClick={handleLoginRedirect}>
        {t('header.menu.donate')}
      </Link>)
  }
}
