import { combineReducers } from 'redux'
import { emailSubscriptionReducer } from './email-subscription/reducer'
import { productsReducer } from './products/reducer'
import { authReducer } from './auth/reducer'
import { RootReducer } from './types'
import { nonProfitReducer } from './nonprofits/reducer'
import { homeListedItems } from './home-page-items/reducer'
import { checkoutPageReducer } from './checkout-page/reducer'
import { appDialogReducer } from './app-dialog/reducers'
import { orderReducer } from './order/reducer'
import { myListedItemsReducer } from './my-listed-items/reducer'
import { draftItemsReducer } from './draft-items/reducer'
import { overlayPreloaderReducer } from './overlay-preloader/reducer'
import {appInsightsReducer} from './app-insights/reducer'

export const rootReducer = combineReducers<RootReducer>({
  emailSubscription: emailSubscriptionReducer,
  products: productsReducer,
  auth: authReducer,
  nonprofit: nonProfitReducer,
  homeListedItems: homeListedItems,
  checkoutPage: checkoutPageReducer,
  appDialog: appDialogReducer,
  order: orderReducer,
  myListedItems: myListedItemsReducer,
  draftItems: draftItemsReducer,
  overlayPreloader: overlayPreloaderReducer,
  appInsights: appInsightsReducer
})
