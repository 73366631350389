import { EmailSubscriptionState, SubscriptionShowedElement } from './reducer'
import { createSelector } from '@reduxjs/toolkit'
import { LoadingState, RootState } from '../types'

const selectEmailSubscription = (state: RootState) => state.emailSubscription

export const selectEmailSubscriptionLoadingState = createSelector(
  selectEmailSubscription,
  (emailSubscription: EmailSubscriptionState) => emailSubscription.loadingState
)

export const selectSubscriptionLoading = createSelector(
  selectEmailSubscriptionLoadingState,
  (loadingState: LoadingState) => loadingState === LoadingState.LOADING
)

export const selectSubscriptionShowedElement = createSelector(
  selectEmailSubscription,
  (emailSubscription: EmailSubscriptionState) => emailSubscription.showedElement
)

export const selectShowSubscriptionForm = createSelector(
  selectSubscriptionShowedElement,
  (showedElement: SubscriptionShowedElement) =>
    showedElement === SubscriptionShowedElement.FORM
)

export const selectShowSubscriptionSuccessMessage = createSelector(
  selectSubscriptionShowedElement,
  (showedElement: SubscriptionShowedElement) =>
    showedElement === SubscriptionShowedElement.MESSAGE
)
