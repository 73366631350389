import React from 'react'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import {useSelector} from 'react-redux'
import {Container, Link, Typography} from '@material-ui/core'
import {useFooterStyles} from './footer.styles'
import {SubscriptionForm} from './subscription-form'
import {selectShowSubscriptionSuccessMessage} from '../../store/email-subscription/selectors'
import {ProsphoraLogoSvg} from '../../assets/svg/prosphora-logo-svg'
import {useTranslation} from 'react-i18next'
import PhoneIcon from '@material-ui/icons/Phone'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import {useScreenSize} from '../../hooks/use-screen-size'
import {ScreenSize} from '../../settings/device'
import {nonProfitRegister, terms} from '../../env-config'

export const Footer: React.FC = (): React.ReactElement => {
  const classes = useFooterStyles()
  const { t } = useTranslation()

  const linkToHelpBase = process.env.REACT_APP_HELP_SITE_BASE

  const links = [
    {
      link: 'about',
      title: t('footer.about'),
    },
    {
      link: linkToHelpBase + 'link-to-help-hardcoded',
      title: t('footer.help'),
    },
    {
      link: terms,
      title: t('footer.terms'),
    },
    {
      link: nonProfitRegister,
      title: t('footer.nonProfitRegister'),
    },
  ]

  const getYear =()=> new Date().getFullYear()

  //TODO show when subscription will be implemented
  const showSubscriptionForm = false
  const showMessage = useSelector(selectShowSubscriptionSuccessMessage)
  const mode = useScreenSize()

  return (
    <footer className={classes.footer}>

      {/*Footer*/}
      {mode === ScreenSize.Mobile ? <div className={classes.footerMobile}>
        <div className={classes.logoMobile}>
          <ProsphoraLogoSvg />
        </div>

        <address>
          <ul className={classes.contactMobile}>
            <li className={classes.contactItemMobile}>
              <MailOutlineIcon />
              <a className={classes.contact} href="mailto:help@prosphora.net">
                help@prosphora.net
              </a>
            </li>
            <li className={classes.contactItemMobile}>
              <PhoneIcon />
              <a className={classes.contact} href="tel:1-206-330-0326">
                1-206-330-0326
              </a>
            </li>
            <li className={classes.contactItemMobile}>
              <a
                className={classes.contact}
                href="https://goo.gl/maps/Hiw4puowQd8ro6qr8"
                target="_blank"
                rel="noreferrer"
              >
                1220 Main Str, Suite 400 Vancouver, WA, 98660
              </a>
            </li>
          </ul>

          <hr className={classes.divider} />

          <ul className={classes.linksMobile}>
            {links.map(({ link, title }, i) => (
              <li className={classes.linkItemMobile} key={i}>
                <Link
                  href={link}
                  color="inherit"
                  underline="none"
                  target="_blank"
                >
                  {title}
                </Link>
              </li>
            ))}
          </ul>
        </address>
        <span className={classes.copyrightMobile}>{t('footer.copyright', {target: getYear()})}</span>
      </div> :  <div >
        <Container>
          <div className={classes.wrapper}>
            <ProsphoraLogoSvg />
            <address>
              <ul className={classes.contacts}>
                <li className={classes.contactItem}>
                  <MailOutlineIcon />
                  <a className={classes.contact} href="mailto:help@prosphora.net">
                    help@prosphora.net
                  </a>
                </li>
                <li className={classes.contactItem}>
                  <PhoneIcon />
                  <a className={classes.contact} href="tel:1-206-330-0326">
                    1-206-330-0326
                  </a>
                </li>
                <li className={classes.contactItem}>
                  <LocationOnIcon />
                  <a
                    className={classes.contact}
                    href="https://goo.gl/maps/Hiw4puowQd8ro6qr8"
                    target="_blank"
                    rel="noreferrer"
                  >
                    1220 Main Str, Suite 400 Vancouver, WA, 98660
                  </a>
                </li>
              </ul>

              <hr className={classes.divider} />

              <ul className={classes.links}>
                {links.map(({ link, title }, i) => (
                  <li className={classes.linkItem} key={i}>
                    <Link
                      href={link}
                      color="inherit"
                      underline="none"
                      target="_blank"
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>
            </address>

            <div className={classes.subscribe}>
              {(showSubscriptionForm && <SubscriptionForm />) ||
                (showMessage && (
                  <Typography>{t('footer.subscribedSuccess')}</Typography>
                ))}
            </div>
          </div>
        </Container>
        <span className={classes.copyright}>{t('footer.copyright', {target: getYear()})}</span>
      </div>}

    </footer>
  )
}
