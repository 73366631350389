import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useOwnItemDetailsComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainHolder: {
      padding: `${theme.spacing(6)}px ${theme.spacing(4)}px`,
      marginTop: theme.spacing(4),
    },
    alertHolder: {
      padding: '20px 0',
    },
  })
)
