import React from 'react'
import { SelectNonprofitProps } from '../donate.models'
import { AsyncAutocomplete } from '../../../components/async-autocomplete'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

export const SelectNonprofit: React.FC<SelectNonprofitProps> = ({
  categories,
  setCategories,
  selectedCategory,
  setSelectedCategory,
  nonprofits,
  setNonprofits,
  selectedNonprofit,
  setSelectedNonprofit,
  categoryRequest,
  nonprofitRequest,
  shippingAddressExists,
}: SelectNonprofitProps): React.ReactElement => {
  const { t } = useTranslation()

  React.useEffect(() => {
    setNonprofits([])
  }, [selectedCategory])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md>
          <AsyncAutocomplete
            disabled={!shippingAddressExists}
            label={t('donation.selectGroup.selectNonprofitCategory')}
            noOptionsText={t('donation.selectGroup.noCategories')}
            options={categories}
            setOptions={setCategories}
            request={categoryRequest}
            selectedOption={selectedCategory}
            setSelectedOption={setSelectedCategory}
          />
        </Grid>
        <Grid item xs={12} md>
          <AsyncAutocomplete
            disabled={!selectedCategory}
            label={t('donation.selectGroup.selectNonprofit')}
            noOptionsText={t('donation.selectGroup.noNonprofits')}
            options={nonprofits}
            setOptions={setNonprofits}
            request={nonprofitRequest}
            selectedOption={selectedNonprofit}
            setSelectedOption={setSelectedNonprofit}
          />
        </Grid>
      </Grid>
    </>
  )
}
