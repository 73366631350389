import React from 'react'
import { useAppHeaderRightMenuStyles } from './app-header-right-menu.styles'
import { MenuForAuthenticated } from './menu-for-authenticated'
import { MenuForNotAuthenticated } from './menu-for-not-authenticated'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../store/auth/selectors'

type AppHeaderRightMenuProps = Record<string, unknown>

export const AppHeaderRightMenu: React.FC<AppHeaderRightMenuProps> = (): React.ReactElement => {
    const classes = useAppHeaderRightMenuStyles()
    const user = useSelector(selectUser)

    return <div className={classes.root}>
        <>
            {user.userId ? <MenuForAuthenticated /> : <MenuForNotAuthenticated />}
        </>
    </div>
  }
