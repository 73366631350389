import React, { useEffect, useRef, useState } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { useCheckoutDescriptionStyles } from './checkout-description.styles'
import { useSelector } from 'react-redux'
import { selectItemDetails } from '../../../store/checkout-page/selectors'
import { DeliveryTypeSelect } from '../delivery-type-select'
import { useScreenSize } from '../../../hooks/use-screen-size'
import { ScreenSize } from '../../../settings/device'
import { ExpandableText } from '../../listed-item-details-page/listed-item-details-component/info-component/expandable-text'
import { selectUser } from '../../../store/auth/selectors'
import { ImageCard } from '../../../components/image-card'

const expandableTextMinimumHeight = 500

export const CheckoutDescription: React.FC = (): React.ReactElement => {
  const itemDetails = useSelector(selectItemDetails)
  if (!itemDetails) throw new Error('Item details expected for component')
  const [isLongText, setIsLongText] = useState<boolean>(false)
  const screenSize = useScreenSize()
  const textHolder = useRef<HTMLDivElement>(null)
  const userInfo = useSelector(selectUser)

  const deviceType = useScreenSize()
  const classes = useCheckoutDescriptionStyles()
  const descriptionLines = itemDetails.description
    ?.split('\n')
    .map((x: string, i: number) => (
      <span className={classes.descriptionText} key={i}>
        {x}
        <br />
      </span>
    ))

  //Check Long Text
  const checkLongText = () => {
    const textHolderHeight = textHolder.current!.offsetHeight
    textHolderHeight > expandableTextMinimumHeight
      ? setIsLongText(true)
      : setIsLongText(false)
  }

  /* Use Effect Check Long Text */
  useEffect(checkLongText, [screenSize, itemDetails.description])

  const image = itemDetails.defaultImageId ? (
    <ImageCard imageId={itemDetails.defaultImageId} />
  ) : undefined

  return (
    <Paper className={classes.paper}>
      <div className={classes.root}>
        <Typography variant="h6">{itemDetails.name}</Typography>
        <div className={classes.description} ref={textHolder}>
          {deviceType === ScreenSize.Mobile && (
            <div>{image !== undefined && image}</div>
          )}
          <div className={classes.textArea}>
            {/*<p>{descriptionLines}</p>*/}
            {!isLongText && (
              <Typography
                variant="body1"
                className={classes.textWrapper}
                component="p"
              >
                {descriptionLines}
              </Typography>
            )}
            {isLongText && (
              <div className={classes.textWrapper}>
                <ExpandableText description={itemDetails.description} />
              </div>
            )}
            {userInfo.shippingAddressExists && <DeliveryTypeSelect />}
          </div>
          {deviceType === ScreenSize.Desktop && (
            <div>{image !== undefined && image}</div>
          )}
        </div>
      </div>
    </Paper>
  )
}
