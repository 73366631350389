import { takeEvery, call, put } from 'redux-saga/effects'
import {
  fetchProducts,
  fetchProductsSuccess,
  fetchProductsFailure,
  noMoreProducts,
} from './reducer'
import { productsApi } from '../../services/api/products-api'
import { SagaIterator } from 'redux-saga'
import { FeaturedProduct } from '../../components/product-card'
import {trackException} from '../app-insights/reducer'

export function* fetchProductsRequest({
  payload,
}: ReturnType<typeof fetchProducts>): SagaIterator {
  try {
    const products: readonly FeaturedProduct[] = yield call(
      productsApi.getAll,
      payload
    )
    yield products.length
      ? put(fetchProductsSuccess(products))
      : put(noMoreProducts())
  } catch (e) {
    yield put(fetchProductsFailure())
    yield put(trackException({e, source: 'fetchProductsRequest'}))
  }
}

export function* productsSaga(): Generator {
  yield takeEvery(fetchProducts, fetchProductsRequest)
}
