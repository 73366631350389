import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useShipmentRateBlockStyle = makeStyles((theme: Theme) =>
  createStyles({
    nameLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      color: theme.palette.primary.light,
      fontSize: 13,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    loader: {
      display: 'block',
      alignItems: 'center',
      width: '100%',
    },
    paper: {
      position: 'absolute',
      width: '100%',
      maxWidth: 525,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
      '&:focus': {
        outline: 'none',
      },
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    marginButton: {
      margin: theme.spacing(1),
    },
    modal: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    link: {
      cursor: 'pointer',
    },
    fullScreen: {
      width: '100%',
      height: '100%',
    },
    closeIcon: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: 'black',
    },
    ratesHolder: {
      width: '100%',
    },
    ratesWrapper: {
      width: '100%',
    },
    ratesItemHolder: {
      display: 'block',
    },
    rateItem: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: '10px',
      borderRadius: '6px',
    },
    rateItemBorder: {
      border: `1px ${theme.palette.grey['200']} solid`,
      padding: '10px 15px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: theme.palette.primary.dark,
      },
    },
    rateSelectedItem: {
      borderColor: theme.palette.primary.dark,
    },
    rateItemIconSelected: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
    rateLogo: {
      display: 'block',
      width: '70px',
      marginRight: '10px',
      flexGrow: 0,
      flexShrink: 0,
      flexBasis: '15%',

      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
      },
    },
    rateAmount: {
      display: 'block',
      fontWeight: 700,
      marginBottom: '1px',
    },
    ratesListWrapper: {
      overflow: 'auto',
      padding: '20px 0',
      overflowX: 'hidden',
    },
    rateListHolder: {
      overflowX: 'hidden',
      maxHeight: '500px',
    },
    rateInfoHolder: {},
    rateInfo: {
      color: theme.palette.grey['A200'],
      fontSize: 13,
      display: 'block',
    },
    serviceLevelName: {
      color: theme.palette.grey['A400'],
      fontSize: 12,
      display: 'block',
      marginBottom: '3px',
    },
    btnBlock: {
      padding: '30px 0 0 0',
    },
    changeRateBtn: {
      marginBottom: '10px',
    },

    ratesMessagesItem: {
      display: 'flex',
      width: '100%',
      marginBottom: 15,
      '& > b': {
        display: 'block',
        minWidth: 70,
      },

      '& > span': {
        display: 'block',
        minWidth: 70,
      },
    },

    ratesEmptyLabel: {
      width: '100%',
      color: theme.palette.grey['400'],
    },
  })
)
