import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useListedItemPlaceholderStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'relative',
    },
    image: {
      paddingTop: '100%',
      transform: 'scale(1)',
      height: '0.001em',
    },
    textHolder: {
      marginTop: 10,
    },
  })
)
