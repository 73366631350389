import React from 'react'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useForm } from '../../../../hooks/use-form'
import { useTranslation } from 'react-i18next'

export const ShippingFormAutocomplete: React.FC<ShippingFormAutocompleteProps> =
  (props): React.ReactElement => {
    const { formProps, name, required, values } = props

    const { t } = useTranslation()

    return (
      <Autocomplete
        options={values}
        value={formProps.values[name] as string}
        onChange={(_event, value) =>
          formProps.customHandlers.setValueHandler(name, value as string)
        }
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            fullWidth
            variant="outlined"
            onChange={(e) => console.log(e.target)}
            label={t(`accountShipping.${name}`)}
            error={!!formProps.errors[name]}
            required={required}
            helperText={formProps.form[name].dirty && formProps.errors[name]}
          />
        )}
      />
    )
  }

//Shipping Form Autocomplete
type ShippingFormAutocompleteProps = {
  formProps: ReturnType<typeof useForm>
  name: string
  values: string[]
  required?: boolean
}
