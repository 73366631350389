import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useExpandableTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    expandHolderClose: {
      maxHeight: 230,
      overflow: 'hidden',
      position: 'relative',
      '& > div': {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background:
          'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 75%, rgba(255,255,255,0.7)85%, rgba(255,255,255,1) 100%)',
      },
    },
    expandHolderOpen: {
      position: 'relative',
      paddingBottom: 30,
      '& > div': {
        background: 'none !important',
      },
    },
    bgState: {
      marginBottom: -20,
      background: 'none !important',
    },
    expandBtnBlock: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      textAlign: 'center',
      fontWeight: 700,
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      '& *': {
        verticalAlign: 'middle',
      },
    },
  })
)
