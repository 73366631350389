import { createSlice } from '@reduxjs/toolkit'
import {
  SetQueryParams,
  ChangeVisibleHandlerType,
  ErrorActionType,
  GetDonorItems,
} from './contracts'
import { ListedItemProps as DraftItemProps } from '../../components/listed-item'

type InitialStateType = {
  itemsList: DraftItemProps[]
  loading: boolean
  totalPages: number
  needApply: boolean
  firstRender: boolean
  isFilterCollapsed: boolean
  query: Partial<{
    pageSize: number
    searchText: string
    sortByDate: number
    pageIndex: number
    draftItemState: null | number
  }>
  error: any
}

const initialQuery = {
  pageSize: 12,
  searchText: '',
  sortByDate: 1,
  pageIndex: 0,
  listedItemState: 0,
}

const InitialState: InitialStateType = {
  itemsList: [],
  loading: false,
  totalPages: 0,
  needApply: true,
  firstRender: true,
  isFilterCollapsed: true,
  query: {
    ...initialQuery,
  },
  error: null,
}

const initialState: InitialStateType = {
  ...InitialState,
  query: {
    ...initialQuery,
  },
}

const draftItemsSlice = createSlice({
  name: 'draftItems',
  initialState: { ...initialState },
  reducers: {
    setShowItems(state, action) {},
    resetRequestParams(state) {
      state.needApply = true
    },
    setSortingInitial(state) {
      state.query = { ...initialQuery }
    },

    //Get Draft Items
    getDraftItems(state, action: GetDonorItems) {
      state.loading = true
    },
    getDraftItemsSuccess(state, action) {
      state.itemsList = [...(action.payload ? action.payload.values : [])]
      state.loading = false
      state.totalPages = action.payload.totalPages || 0
      state.firstRender = false
      state.needApply = false
    },
    getDonorItemsFailed(state, action) {
      state.loading = false
      state.error = action.payload
    },
    setQueryParams(state, action: SetQueryParams) {
      const {
        pageIndex,
        pageSize,
        isApply,
        isStartPagingInBegin,
        searchText,
        sortByDate,
      } = action.payload
      if (pageIndex || pageIndex === 0) {
        state.query.pageIndex = pageIndex
      }
      //Page Size
      if (pageSize) {
        state.query.pageSize = pageSize
      }
      if (searchText) {
        state.query.searchText = searchText
      } else if (searchText === null || searchText === '') {
        state.query.searchText = ''
      }
      if (sortByDate || sortByDate === 0) {
        state.query.sortByDate = sortByDate
      }
      if (isStartPagingInBegin) state.query.pageIndex = 0
      if (isApply) state.needApply = true
    },
    setError(state, action: ErrorActionType) {
      state.error = action.payload
    },
    changeFilterCollapsedAction(state, action: ChangeVisibleHandlerType) {
      state.isFilterCollapsed = action.payload
    },
  },
})

export const draftItemsReducer = draftItemsSlice.reducer
export const {
  setSortingInitial,
  setQueryParams,
  changeFilterCollapsedAction,
  setError,
  getDraftItemsSuccess,
  getDraftItems,
  resetRequestParams,
  getDonorItemsFailed
} = draftItemsSlice.actions
