import React from 'react'
import {
  Button,
  Grid,
  TextField,
  Box,
  CircularProgress,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import {
  SecurityComponentProps,
  UserStatus,
} from './security-component.constants'
import { useSecurityComponentStyles } from './security-component.styles'
import { useTranslation } from 'react-i18next'
import {IdentityProviderEnum} from '../../../../store/auth/contracts'

export const SecurityComponent: React.FC<SecurityComponentProps> = (
  props: SecurityComponentProps
) => {
  const {
    userStatus,
    email,
    loading,
    deleteAccountHandler,
    activateAccountRequest,
    identityProvider
  } = props
  const classes = useSecurityComponentStyles()
  const { t } = useTranslation()

  function Alert(props: any) {
    return <MuiAlert elevation={6} variant="standard" {...props} />
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        className={classes.securityComponentHolder}
      >
        <Grid item xs={12} md={8} lg={6}>
          {userStatus == UserStatus.Registered && (
            <Alert severity="warning" alignCenter="true">
              {t('accountSettings.security.activate.lblWarning')}
            </Alert>
          )}
          <TextField
            className={`${classes.alignCenter}`}
            fullWidth
            disabled
            id="standard-disabled"
            value={email}
          />
          <div className={classes.labelHolder}>
            <span>
              {`${t('accountSettings.authenticatedVia')} ${IdentityProviderEnum[identityProvider]}`}
            </span>
          </div>


          <div className={classes.btnHolder}>
            {userStatus == UserStatus.Registered && (
              <Box className={classes.activateLinkHolder}>
                <Button
                  className={classes.offset}
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  onClick={() => activateAccountRequest()}
                >
                  {t('accountSettings.security.activate.btnLabel')}
                </Button>

                {loading && (
                  <CircularProgress
                    size={20}
                    className={classes.activateLinkLoader}
                  />
                )}
              </Box>
            )}
            <Button color="secondary" fullWidth onClick={deleteAccountHandler}>
              {t('accountSettings.security.delete.btnLabel')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
