import { MyAccountMenuProps } from './my-account-menu'
import { auth } from '../../../../../services/api/auth'
import { useNavigate, useLocation } from 'react-router-dom'
import { logoutSuccess } from '../../../../../store/auth/reducer'
import { selectUserStatus } from '../../../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError } from 'axios'

export const useMyAccountMenu = (props: MyAccountMenuProps) => {
  const { setAnchorEl } = props

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  //Activate User Status
  const userStatus = useSelector(selectUserStatus)

  const menuClickHandler = () => {
    setAnchorEl(null)
  }

  const logoutClickHandler = async (): Promise<void> => {
    menuClickHandler()

    try {
      await auth.logout()
    } catch (e) {
      if (!(e as AxiosError).isAxiosError) {
        console.error('Unexpected error')
        return
      }

      // if response code is 401 - it means session is already closed
      if ((e as AxiosError).response?.status !== 401) {
        console.error('Cannot logout user')
        console.error(e)
        return
      }
    } finally {
      setTimeout(() => {
        const { pathname } = location
        if (pathname !== '/') navigate('/')
        dispatch(logoutSuccess())
      }, 100)
    }
  }

  return {
    //props
    userStatus,

    //methods
    menuClickHandler,
    logoutClickHandler,
  }
}
