import { createSlice } from '@reduxjs/toolkit'
import { InitialStateType, AppDialogVariants } from './types'

const initialState: InitialStateType = {
  isShow: false,
  variant: AppDialogVariants.success,
  title: '',
  message: '',
  showCloseButton: true,
  closable: true,
}

const appDialogSlicer = createSlice({
  name: 'appDialog',
  initialState: initialState,
  reducers: {
    setInitial(state) {
      Object.assign(state, { ...initialState })
    },
    setIsShowAction(state, action) {
      state.isShow = action.payload
    },
    setVariantAction(state, action) {
      state.variant = action.payload
    },
    setTitleAction(state, action) {
      state.title = action.payload
    },
    setMessageAction(state, action) {
      state.message = action.payload
    },
    setShowCloseButtonAction(state, action) {
      state.showCloseButton = action.payload
    },
    setCloseHandlerAction(state, action) {
      state.closeHandler = action.payload
    },
    setExitHandlerAction(state, action) {
      state.onExit = action.payload
    },
    setAppDialogChildrenAction(state, action) {
      state.actions = action.payload
    },
  },
})

export const appDialogReducer = appDialogSlicer.reducer
export const {
  setIsShowAction,
  setVariantAction,
  setTitleAction,
  setMessageAction,
  setShowCloseButtonAction,
  setCloseHandlerAction,
  setAppDialogChildrenAction,
  setInitial,
} = appDialogSlicer.actions
