import { createSlice } from '@reduxjs/toolkit'
import { InitialStateType } from './types'

const initialState: InitialStateType = {
  isShow: false,
}

const overlayPreloaderSlicer = createSlice({
  name: 'overlayPreloader',
  initialState: initialState,
  reducers: {
    setIsShowOverlayPreloaderAction(state, action) {
      state.isShow = action.payload
    },
  },
})

export const overlayPreloaderReducer = overlayPreloaderSlicer.reducer
export const { setIsShowOverlayPreloaderAction } =
  overlayPreloaderSlicer.actions
