import React, { useEffect } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { useMyListedItemsStyles } from './my-listing-items-page.styles'
import { Filter } from '../../components/filter'
import { useScreenSize } from '../../hooks/use-screen-size'
import {
  fetchCategories,
  fetchNonprofits,
  getDonorItems,
  setQueryParams,
  setSortingInitial,
  resetRequestParams,
} from '../../store/my-listed-items/reducer'
import { useDispatch } from 'react-redux'
import { useMyListedItemsPage } from './my-listed-items-page.hook'
import { ListedItem, ListedItemProps } from '../../components/listed-item'
import { QueryParams } from '../home-page/home-component/home.constants'
import { EmptySearchResultComponent } from '../home-page/home-component/empty-search-result-component'
import Pagination from '@material-ui/lab/Pagination'
import { SortDate } from '../../components/sort-date'
import { itemsCounter } from '../../settings/home-page-settings'
import { PlaceholderArrayComponent } from '../home-page/home-component/placeholder-array-component'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ScreenSize } from '../../settings/device'

export const MyListedItemsPage: React.FC = () => {
  const classes = useMyListedItemsStyles()
  const screenSize = useScreenSize()
  const dispatch = useDispatch()
  const { setIsShow, setAppDialog } = useAppDialog()
  const { t } = useTranslation()

  const {
    //props
    itemsList,
    loading,
    query,
    totalPages,
    needApply,
    firstRender,
    categories,
    nonprofits,
    isFilterCollapsed,
    userStatus,
    // error,
    // searchText,

    //methods
    changeFilterCollapsedAction,
  } = useMyListedItemsPage()

  const navigate = useNavigate()

  /*Filter Change Handler*/
  const onQueryParamsChanged = (
    data: QueryParams,
    isApply?: boolean,
    isStartPagingInBegin?: boolean
  ) => {
    dispatch(setQueryParams({ ...data, isApply, isStartPagingInBegin }))
  }

  //Get Listed Items
  useEffect(() => {
    const {
      pageSize,
      searchText,
      sortByDate,
      pageIndex,
      listedItemState,
      nonprofitCategoryId,
      nonprofitId,
    } = query

    needApply &&
      dispatch(
        getDonorItems({
          pageSize,
          searchText,
          sortByDate,
          pageIndex,
          listedItemState,
          ...(!nonprofitId && { nonprofitCategoryId }),
          ...(nonprofitId && { nonprofitId }),
        })
      )
  }, [query, needApply])

  /*Get categories when first loading*/
  useEffect(() => {
    dispatch(fetchCategories())
  }, [])

  /*Get nonprofits Before Loading PAge*/
  useEffect(() => {
    if (query.nonprofitCategoryId) {
      dispatch(
        fetchNonprofits({
          pageIndex: '',
          pageSize: '',
          categoryId: `${query.nonprofitCategoryId}`,
          searchName: '',
        })
      )
    }
  }, [query.nonprofitCategoryId])

  //User Activate
  useEffect(() => {
    !userStatus &&
      setAppDialog({
        isShow: true,
        title: t('myListingPage.appDialog.title'),
        message: t('myListingPage.appDialog.message'),
        variant: 'warning',
        actions: (
          <Button onClick={() => navigate('/account/security')} color="primary">
            {t('myListingPage.appDialog.btnText')}
          </Button>
        ),
        closeHandler: () => navigate('/'),
      })
  }, [userStatus])

  //After Unmount
  useEffect(() => {
    return () => {
      dispatch(resetRequestParams())
      setIsShow(false)
    }
  }, [])

  //Filter Collapse Handler
  const filterCollapseHandler = (value: boolean) => {
    dispatch(changeFilterCollapsedAction(value))
  }

  //Cancel Handler
  const cancelHandler = () => {
    dispatch(setSortingInitial())
  }

  //If user not activated
  if (!userStatus) {
    return <></>
  }

  return (
    <>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
      {/* Content Holder */}
      <div className={classes.container}>
        <Grid container spacing={2}>
          {/*Desktop Mode*/}
          {screenSize === ScreenSize.Desktop && (
            <Grid item xs={12}>
              {/*Header*/}
              <div className={classes.header}>
                <Typography variant="h6" className={classes.headerTitle}>
                  {t('myListingPage.title')}
                </Typography>

                <div className={classes.queryParamsHolder}>
                  {/*Sort Date*/}
                  <div>
                    <SortDate
                      changeHandler={(value) =>
                        onQueryParamsChanged({ sortByDate: value }, true, true)
                      }
                      value={query.sortByDate}
                    />
                  </div>
                  {/*Page Size*/}
                  <div className={classes.pageSizeHolder}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel
                        id="select-show-items-label"
                        disabled={loading}
                      >
                        {t('myListingPage.showItems')}
                      </InputLabel>
                      <Select
                        disabled={loading}
                        labelId="select-show-items-label"
                        id="select-show-items"
                        variant="outlined"
                        value={query.pageSize}
                        label={'Show Items'}
                        onChange={(event) =>
                          onQueryParamsChanged(
                            { pageSize: event.target.value as number },
                            true,
                            true
                          )
                        }
                      >
                        {itemsCounter.map((item, key) => (
                          <MenuItem value={item} key={key}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </Grid>
          )}

          {/*Mobile Mode*/}
          {screenSize === ScreenSize.Mobile && (
            <Grid item xs={12}>
              <div className={classes.headerMobile}>
                <Typography variant="h6" className={classes.headerTitleMobile}>
                  {t('myListingPage.title')}
                </Typography>
              </div>
              <div className={classes.dateBlockMobile}>
                <Filter
                  cancelHandler={cancelHandler}
                  categories={categories}
                  isFilterCollapsed={isFilterCollapsed}
                  loading={loading}
                  nonprofitCategoryId={query.nonprofitCategoryId}
                  nonprofitId={query.nonprofitId}
                  nonprofits={nonprofits}
                  onFilterChanged={onQueryParamsChanged}
                  screenSize={screenSize}
                  searchText={query.searchText}
                  itemsState={query.listedItemState}
                  setFilterCollapsed={filterCollapseHandler}
                />
                <SortDate
                  changeHandler={(value) =>
                    onQueryParamsChanged({ sortByDate: value }, true, true)
                  }
                  value={query.sortByDate}
                />
              </div>
            </Grid>
          )}

          {/*Filter*/}
          {screenSize === ScreenSize.Desktop && (
            <Grid item xs={6} lg={3} md={4}>
              <Filter
                cancelHandler={cancelHandler}
                categories={categories}
                isFilterCollapsed={isFilterCollapsed}
                loading={loading}
                nonprofitCategoryId={query.nonprofitCategoryId}
                nonprofitId={query.nonprofitId}
                nonprofits={nonprofits}
                onFilterChanged={onQueryParamsChanged}
                screenSize={screenSize}
                searchText={query.searchText}
                itemsState={query.listedItemState}
                setFilterCollapsed={filterCollapseHandler}
              />
            </Grid>
          )}

          {/*Content*/}
          <Grid item xs={12} lg={9} md={8}>
            <Grid container spacing={2}>
              {firstRender && <PlaceholderArrayComponent />}
              {!itemsList.length && !firstRender && (
                <Grid xs={12} item>
                  <EmptySearchResultComponent />
                </Grid>
              )}
              {itemsList.map((item: ListedItemProps, key: number) => (
                <Grid key={key} item xs={12} lg={3} md={4} sm={6}>
                  <ListedItem
                    {...item}
                    itemUrl={`/my-listed-items/${item.id}`}
                    settings={{
                      hideCity: true,
                      hideRegion: true,
                      hideCondition: true,
                    }}
                    showDate
                  />
                </Grid>
              ))}

              {!firstRender && totalPages > 1 && (
                <Grid item xs={12}>
                  <div className={classes.paginationHolder}>
                    <Pagination
                      count={totalPages}
                      disabled={loading}
                      shape="rounded"
                      color="primary"
                      page={query.pageIndex + 1}
                      onChange={(_, page) =>
                        onQueryParamsChanged({ pageIndex: page - 1 }, true)
                      }
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}
