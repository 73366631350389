import { useState } from 'react'
import { FormValueChangesProps, FormValues } from '../../../hooks/use-form'

export const useDraftEditor = (initialValues: FormValues) => {
  const [draftEditorData, setDraftEditorData] = useState(initialValues)
  const [draftEditorFormValid, setDraftEditorFormValid] = useState(false)

  const formValueChanges = (data: FormValueChangesProps) => {
    setDraftEditorData(data.formValue)
    setDraftEditorFormValid(data.valid)
  }

  return {
    draftEditorData,
    draftEditorFormValid,
    formValueChanges,
    setDraftEditorData,
  }
}
