import React from 'react'
import { Grid } from '@material-ui/core'
import { ListedItemPlaceholder } from '../../../../components/listed-item/listed-item-placeholder'

export const PlaceholderArrayComponent: React.FC = () => {
  const placeholderArray = []
  //eslint-disable-next-line
  for (let i = 0; i < 12; i++) {
    placeholderArray.push(
      <Grid key={i} item xs={12} lg={3} md={4} sm={6}>
        <ListedItemPlaceholder />
      </Grid>
    )
  }
  return <>{placeholderArray}</>
}
