import {useMsal} from '@azure/msal-react'
import {useOverlayPreloader} from '../../../../hooks/use-overlay-preloader'
import React from 'react'
import {msalRequest} from '../../../../env-config'

export const useMenuForNotAuthenticated = (closeDrawer: () => void) => {
  const { instance } = useMsal()
  const { setIsShowOverlayPreloader } = useOverlayPreloader()

  const onDonateClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    setIsShowOverlayPreloader(true)
    instance.loginRedirect({...msalRequest, redirectUri: '/donate'}).catch((error) => console.log(error))
    closeDrawer()
  }

  return {
    onDonateClickHandler,
    closeDrawer
  }
}
