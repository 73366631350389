import { all } from 'redux-saga/effects'
import { emailSubscriptionSaga } from './email-subscription/sagas'
import { productsSaga } from './products/sagas'
import { authSaga } from './auth/sagas'
import { nonprofitSaga } from './nonprofits/sagas'
import { homePageItemsSaga } from './home-page-items/sagas'
import { checkoutPageSaga } from './checkout-page/sagas'
import { orderSaga } from './order/sagas'
import { myListedItemsSaga } from './my-listed-items/sagas'
import { draftItemsSaga } from './draft-items/sagas'

export default function* rootSaga(): Generator {
  yield all([
    emailSubscriptionSaga(),
    productsSaga(),
    authSaga(),
    nonprofitSaga(),
    homePageItemsSaga(),
    checkoutPageSaga(),
    orderSaga(),
    myListedItemsSaga(),
    draftItemsSaga(),
  ])
}
