import React from 'react'
import { useOrderPageStyles } from '../order-page.styles'
import { Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { OrderPriceInfoType } from './order-price-info.constants'
import { useTranslation } from 'react-i18next'

//Currency Symbol
const currencySymbol = new Map([['USD', '$']])

export const OrderPriceInfo: React.FC<OrderPriceInfoType> = (props) => {
  //Translation
  const { t } = useTranslation()

  //Props
  const {
    orderHeader: { totalAmount, shippingAmount, subtotalAmount, paymentInfo },
  } = props

  //Styles
  const classes = useOrderPageStyles()

  //Get Currency Code
  const getCurrencySymbol = (): string => {
    const key: string = paymentInfo.currencyCode
    return currencySymbol.get(key) || ''
  }

  return (
    <div className={classes.orderPriceInfo}>
      {/*Price Info Title*/}
      <div className={classes.orderPriceInfoTitle}>
        <Typography variant="h5">
          {getCurrencySymbol() || ''}
          {totalAmount}
        </Typography>
        <span className={classes.orderPriceInfoSubtitle}>
          {t('orderPage.orderAmount')}
        </span>
      </div>

      {/*Price Info Body*/}
      <div className={classes.orderPriceInfoBody}>
        {/*Price Info Item*/}
        <div className={classes.orderPriceInfoBodyItem}>
          <div className={classes.orderPriceInfoBodyItemLabel}>
            {t('orderPage.price')}:
          </div>
          <div className={classes.orderPriceInfoBodyItemValue}>
            {subtotalAmount} {getCurrencySymbol()}
          </div>
        </div>

        {/*Price Info Item*/}
        <div className={classes.orderPriceInfoBodyItem}>
          <div className={classes.orderPriceInfoBodyItemLabel}>
            {t('orderPage.shipping')}:
          </div>
          <div className={classes.orderPriceInfoBodyItemValue}>
            {shippingAmount} {getCurrencySymbol()}
          </div>
        </div>

        {/*Price Info Item*/}
        {/*<div className={classes.orderPriceInfoBodyItem}>*/}
        {/*  <div className={classes.orderPriceInfoBodyItemLabel}>{t('orderPage.tax')}:</div>*/}
        {/*  <div className={classes.orderPriceInfoBodyItemValue}>{taxAmount} {getCurrencySymbol()}</div>*/}
        {/*</div>*/}
      </div>

      {/*Divider*/}
      <Divider />

      {/*Date*/}
      <div
        className={[
          classes.orderPriceInfoBodyItem,
          classes.orderPriceTotal,
        ].join(' ')}
      >
        <div className={classes.orderPriceInfoBodyItemLabel}>
          {t('orderPage.total')}:
        </div>
        <div className={classes.orderPriceInfoBodyItemValue}>
          {totalAmount} {getCurrencySymbol()}
        </div>
      </div>
    </div>
  )
}
