import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ProfilePage } from '../profile-page'
import { ShippingPage } from '../shipping-page'
import { SecurityPage } from '../security-page'

export const AccountRouter: React.FC = (): React.ReactElement => {
  return (
    <div>
      <Routes>
        <Route path={'/'} element={<ProfilePage />} />
        <Route path={'/shipping'} element={<ShippingPage />} />
        <Route path={'/security'} element={<SecurityPage />} />
      </Routes>
    </div>
  )
}
