import React from 'react'
import { Grid } from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import { AddressDataType } from '../address-block.hook'
import { useAddressStyles } from '../address-block.styles'

/* Address Inline Block */
type AddressInlineBlock = {
  address: AddressDataType
  setIsOpen: (value: boolean) => void
}

export const AddressInlineBlock: React.FC<AddressInlineBlock> = (props) => {
  const classes = useAddressStyles()
  const { address, setIsOpen } = props

  return (
    <div>
      <Grid container item xs={12} spacing={2} justifyContent="center">
        {/*Name*/}
        <Grid item xs={12} className={classes.nameLine}>
          <span>{address.name}</span>
          <div title="Edit" onClick={() => setIsOpen(true)}>
            <BorderColorIcon className={classes.icon} />
          </div>
        </Grid>

        {/*Address Line 1*/}
        <Grid item xs={12}>
          <span>{address.addressLine1}</span>
        </Grid>

        {/*Address Line 2*/}

        {!!address.addressLine2.length && (
          <Grid item xs={12}>
            <span>{address.addressLine2}</span>
          </Grid>
        )}

        {/*City*/}
        <Grid item xs={12}>
          <span>{address.city}</span>
        </Grid>

        {/*Postal Code*/}
        <Grid item xs={12}>
          <span>
            {address.region}, {address.postalCode}, {address.countryCode}
          </span>
        </Grid>
      </Grid>
    </div>
  )
}
