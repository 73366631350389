import React, { useState } from 'react'
import { hasErrors, UseForm } from '../../../../hooks/use-form'
import { validateProfileInfo } from './profile-component.constants'
import { Avatar, Button, FilledInput, Grid, TextField } from '@material-ui/core'
import { AddAPhoto } from '@material-ui/icons'
import { useProfileComponentStyles } from './profile-component.styles'
import { ProfileComponentProps } from './profile-component.models'
import { useProfileComponent } from './profile-component.hook'

export const ProfileComponent: React.FC<ProfileComponentProps> = (
  props: ProfileComponentProps
): React.ReactElement => {
  const {
    initialState,
    updateUserInfoAction,
    accountFormState,
    setNonUpdated,
  } = props

  const { updateUserInfoActionHandler, formValueChanges } = useProfileComponent(
    {
      updateUserInfoAction,
      accountFormState,
      setNonUpdated,
    }
  )

  const [imgUrl, setImage] = useState('')
  const { isSubmitting, nonUpdated } = accountFormState
  const creatAvatar = (event: React.ChangeEvent<any>) => {
    setNonUpdated()
    setImage(
      URL.createObjectURL(event.target.files ? event.target.files[0] : '')
    )
  }

  const classes = useProfileComponentStyles()
  return (
    <UseForm
      initialValues={initialState}
      validate={validateProfileInfo}
      onSubmit={updateUserInfoActionHandler}
      formValueChanges={formValueChanges}
    >
      {({ errors, inputHandlers, form, values, handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={8} lg={6}>
              <div className={classes.avatarHolder}>
                <FilledInput
                  className={classes.fileInput}
                  type="file"
                  name="avatar-file-input"
                  id="avatar-file-input"
                  onChange={(event) => creatAvatar(event)}
                />
                <label
                  htmlFor="avatar-file-input"
                  className={classes.labelFileInput}
                >
                  <Avatar src={imgUrl} className={classes.avatar} />
                  <div className={classes.choosingBlock}>
                    <AddAPhoto />
                  </div>
                </label>
              </div>
              <TextField
                inputProps={{ 'data-testid': 'name' }}
                error={!!errors?.name}
                name="name"
                value={values.name}
                fullWidth
                variant="outlined"
                required
                label="Name"
                helperText={form.name.dirty && errors.name}
                className={classes.textItem}
                {...inputHandlers}
                onFocus={() => {
                  return
                }}
                onBlur={() => {
                  return
                }}
              />

              {/*<TextField*/}
              {/*	error={false}*/}
              {/*	name='email'*/}
              {/*	fullWidth*/}
              {/*	value={email}*/}
              {/*	variant="outlined"*/}
              {/*	required*/}
              {/*	label="Email"*/}
              {/*	helperText={false}*/}
              {/*	disabled={true}*/}
              {/*	className={classes.textItem}*/}
              {/*/>*/}
              {/*<div style={{display: 'flex'}}>*/}
              {/*	<FormGroup style={{display: 'inline-block'}}>*/}
              {/*		<FormControlLabel control={<Checkbox checked={newsletter} onChange={()=>setNewsletter(!newsletter)} name="gilad" color="primary"/>} label="Newsletter" />*/}
              {/*	</FormGroup>*/}
              {/*</div>*/}
              <div>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  // disabled={hasErrors(errors) || isSubmitting || !dirty}
                  disabled={hasErrors(errors) || isSubmitting || !nonUpdated}
                >
                  {isSubmitting ? 'Wait...' : 'Save changes'}
                </Button>
                {/*TODO us translation hook*/}
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </UseForm>
  )
}
