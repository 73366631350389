import React, { useEffect, useRef } from 'react'
import { Home } from './home-component'
import { useDispatch } from 'react-redux'
import {
  fetchCategories,
  fetchNonprofits,
  getListedItems,
  setInitialState,
  setQueryParams,
  changeFilterCollapsedAction,
  getItemsMaxPrice,
} from '../../store/home-page-items/reducer'
import { QueryParams } from './home-component/home.constants'
import { useNavigate, useLocation } from 'react-router-dom'
import { useHomePage } from './home-page.hooks'
import { WelcomeMessage } from './welcome-message'
import { LinearProgress } from '@material-ui/core'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { AppDialogVariants } from '../../store/app-dialog/types'
import { ApiError } from '../../services/api/api-error-data'
import { useTranslation } from 'react-i18next'
import { useQuery } from '../../hooks/use-query'
import { RequestQueryParams } from '../../store/home-page-items/contracts'
import { nonprofitsApi, ExternalNonprofitData } from '../../services/api/nonprofits'
import { trackException } from '../../store/app-insights/reducer'

export const HomePage: React.FC = () => {
  const dispatch = useDispatch()
  const urlQuery = useQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const { setAppDialog } = useAppDialog()
  const { t } = useTranslation()
  const {
    itemsList,
    loading,
    query,
    totalPages,
    initialMinPrice,
    initialMaxPrice,
    searchText,
    needApply,
    firstRender,
    categories,
    nonprofits,
    isFilterCollapsed,
    error,
  } = useHomePage()

  /* Scrolled Component */
  const scrolledElement = useRef<HTMLDivElement>(null)

  /* isScrolled Method */
  const isScrollToTop = (): boolean => {
    return scrolledElement.current!.getBoundingClientRect().top < -304
  }

  /* Error handler */
  useEffect(() => {
    if (error) {
      setAppDialog({
        isShow: true,
        variant: AppDialogVariants.error,
        message: new ApiError(error).getErrorMessage(t),
        title: t('home.loadingErrorDialog.title'),
      })
    }
  }, [error])

  /* Scroll to up when items loaded */
  useEffect(() => {
    isScrollToTop() && window.scrollTo(0, 0)
  }, [itemsList])

  /* Get condition param for insert to url */
  const transformConditionToUrlParam = (
    condition: boolean[] | null | undefined
  ) => {
    if (
      condition === null ||
      condition === undefined ||
      condition[0] === condition[1]
    ) {
      return ''
    } else if (condition[0] && !condition[1]) {
      return '0'
    } else if (!condition[0] && condition[1]) {
      return '1'
    }
    return ''
  }

  /*Get condition param for add store*/
  const transformConditionFromUrlToStoreParam = () => {
    const _condition = urlQuery.get('condition')
    if (!_condition && _condition !== '0') {
      return undefined
    } else if (_condition === '0') {
      return [true, false]
    } else if (_condition === '1') {
      return [false, true]
    }
  }

  /* Change Query Handler*/
  const changeQueryParamsAction = (
    data: QueryParams,
    isApply?: boolean,
    isStartPagingInBegin?: boolean
  ) => {
    dispatch(setQueryParams({ ...data, isApply, isStartPagingInBegin }))
    if (!firstRender && isApply) {
      const {
        pageIndex,
        pageSize,
        priceValue,
        condition,
        nonprofitCategoryId,
        nonprofitId,
        searchText,
      } = data
      const _priceMin = priceValue ? priceValue[0] : query.priceValue[0]
      const _priceMax = priceValue ? priceValue[1] : query.priceValue[1]
      const _pageSize = pageSize ? pageSize : query.pageSize
      // eslint-disable-next-line functional/no-let
      let _pageIndex = pageIndex ? pageIndex : query.pageIndex
      _pageIndex = isStartPagingInBegin ? 1 : _pageIndex
      const _nonprofitCategoryId =
        nonprofitCategoryId || nonprofitCategoryId === null
          ? nonprofitCategoryId
          : query.nonprofitCategoryId
      const _nonprofitId =
        nonprofitId || nonprofitId === null ? nonprofitId : query.nonprofitId
      const _condition =
        condition !== undefined
          ? transformConditionToUrlParam(condition)
          : transformConditionToUrlParam(query.condition)
      const _searchText = searchText ? searchText : query.searchText

      const transformToUrlString = () => {
        if (_nonprofitCategoryId && _nonprofitId) {
          return `&nonprofitCategoryId=${_nonprofitCategoryId}&nonprofitId=${_nonprofitId}`
        } else if (_nonprofitCategoryId) {
          return `&nonprofitCategoryId=${_nonprofitCategoryId}`
        } else {
          return ''
        }
      }
      navigate(
        `/?pageIndex=${_pageIndex}&priceMin=${_priceMin}&priceMax=${_priceMax}&pageSize=${_pageSize}&condition=${_condition}&searchText=${_searchText}${transformToUrlString()}`
      )
    }
  }

  /*Set Query Data From Url*/
  const setQueryDataFromUrl = async () => {
    try {
      const fromUrlNonProfitExternalId = urlQuery.get('nonProfitExternalId')
      const nonProfitExternalData = fromUrlNonProfitExternalId ? await nonprofitsApi.getInfoByExternalId(fromUrlNonProfitExternalId).catch((e)=> {
        const { response } = e
        if(response && (response.status === 404)){
          setAppDialog({
            variant: AppDialogVariants.error,
            isShow: true,
            title: t('home.error.404.title'),
            message: t('home.error.404.message'),
            actions: null,
          })
        } else {
          setAppDialog({
            variant: AppDialogVariants.error,
            isShow: true,
            title: t('home.error.unknown.title'),
            message: t('home.error.unknown.message'),
            actions: null,
          })
        }
        dispatch(trackException({e, source: 'getInfoByExternalId'}))
      }) as ExternalNonprofitData : null
      const fromUrlPageIndex = urlQuery.get('pageIndex')
      const fromUrlPageSize = urlQuery.get('pageSize')
      const fromUrlPriceMin = urlQuery.get('priceMin')
      const fromUrlPriceMax = urlQuery.get('priceMax') ?? initialMaxPrice
      const fromUrlNonprofitCategoryId = (nonProfitExternalData && nonProfitExternalData.categoryId) ? nonProfitExternalData.categoryId : urlQuery.get('nonprofitCategoryId')
      const fromUrlNonprofitId =  (nonProfitExternalData && nonProfitExternalData.nonProfitId) ? nonProfitExternalData.nonProfitId : urlQuery.get('nonprofitId')
      const fromUrlCondition = transformConditionFromUrlToStoreParam()
      const fromUrlSearchText = urlQuery.get('searchText')

      const priceValue = [
        fromUrlPriceMin ? Number(fromUrlPriceMin) : query.priceValue[0],
        fromUrlPriceMax ? Number(fromUrlPriceMax) : query.priceValue[1],
      ] as number[]

      const queryObject = {
        pageIndex: fromUrlPageIndex ? Number(fromUrlPageIndex) : query.pageIndex,
        priceValue: priceValue,
        pageSize: fromUrlPageSize ? Number(fromUrlPageSize) : query.pageSize,
        condition: fromUrlCondition,
        nonprofitCategoryId: fromUrlNonprofitCategoryId
          ? Number(fromUrlNonprofitCategoryId)
          : query.nonprofitCategoryId,
        nonprofitId: fromUrlNonprofitId
          ? Number(fromUrlNonprofitId)
          : query.nonprofitId,
        searchText: fromUrlSearchText ? fromUrlSearchText : '',
      }

      if (firstRender) {
        changeQueryParamsAction({ ...queryObject })
      }

      const requestParams: any = {
        condition: transformConditionToUrlParam(queryObject.condition),
        priceMin: queryObject.priceValue[0],
        priceMax: queryObject.priceValue[1],
        pageSize: queryObject.pageSize,
        pageIndex: queryObject.pageIndex - 1,
        searchText: queryObject.searchText,
      }

      if (queryObject.nonprofitId) {
        requestParams.nonprofitId = queryObject.nonprofitId
      } else if (queryObject.nonprofitCategoryId) {
        requestParams.nonprofitCategoryId = queryObject.nonprofitCategoryId
      }

      dispatch(getListedItems(requestParams))
    } catch (e) {
      dispatch(trackException({ e, source: 'setSearchQueryDataFromUrl' }))
    }
  }

  const selectInitialMaxPrice = () => {
    dispatch(getItemsMaxPrice())
  }

  /*UseEffect when Component Did Mount*/
  useEffect(() => {
    initialMaxPrice ?  (async ()=> {
      await setQueryDataFromUrl()
    })()  : selectInitialMaxPrice()
  }, [location, initialMaxPrice])

  /*Get categories when first loading*/
  useEffect(() => {
    dispatch(fetchCategories())
  }, [])

  /*Get nonprofits*/
  useEffect(() => {
    if (query.nonprofitCategoryId) {
      dispatch(
        fetchNonprofits({
          pageIndex: '',
          pageSize: '',
          categoryId: `${query.nonprofitCategoryId}`,
          searchName: '',
        })
      )
    }
  }, [query.nonprofitCategoryId])

  /*Cancel Handler*/
  const cancelHandler = () => {
    dispatch(setInitialState())
    navigate('/')
  }

  useEffect(() => {
    return () => {
      dispatch(setInitialState())
    }
  }, [])

  /*Filter Collapsed Handler*/
  const changeFilterCollapsedHandler = (value: boolean) => {
    dispatch(changeFilterCollapsedAction(value))
  }

  return (
    <>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
      <WelcomeMessage />
      <div ref={scrolledElement}>
        <Home
          isFilterCollapsed={isFilterCollapsed}
          loading={loading}
          firstRender={firstRender}
          query={query as RequestQueryParams}
          initialMinPrice={initialMinPrice || 0}
          initialMaxPrice={initialMaxPrice || 0}
          totalPages={totalPages}
          cancelHandler={cancelHandler}
          changeQueryParamsAction={changeQueryParamsAction}
          itemsList={itemsList}
          categories={categories}
          changeFilterCollapsedHandler={changeFilterCollapsedHandler}
          nonprofits={nonprofits}
          needApply={needApply}
          searchText={searchText}
        />
      </div>
    </>
  )
}
