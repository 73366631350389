import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useMyAccountMenuStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 10,

      '& a': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        minWidth: 100,
      },

      '& hr': {
        margin: '5px 0',
      },
    },
  })
)
