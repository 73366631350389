import React from 'react'
import { ActivateComponent } from './activate-component'
import { auth } from '../../services/api/auth'
import { activate } from '../../store/auth/reducer'
import { selectActivateStatus, selectUser } from '../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'

export const ActivatePage: React.FC = () => {
  const dispatch = useDispatch()
  const activateStatus = useSelector(selectActivateStatus)
  const { email } = useSelector(selectUser)

  const activateRequestAction = (token: string) => {
    dispatch(activate({ token }))
  }
  return (
    <ActivateComponent
      email={email}
      activateRequest={auth.activate}
      activateStatus={activateStatus}
      activateRequestAction={activateRequestAction}
    />
  )
}
