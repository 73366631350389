import { call, takeEvery, put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  fetchCategories,
  fetchNonprofits,
  getDonorItems,
  getDonorItemsSuccess,
  fetchCategoriesSuccess,
  fetchNonprofitsSuccess,
  setError,
} from './reducer'
import { nonprofitsApi } from '../../services/api/nonprofits'
import { listedItemsApi } from '../../services/api/listed-items'
import { ApiError } from '../../services/api/api-error-data'
import {trackException} from '../app-insights/reducer'

/*Get Donor Items*/
export function* getDonorItemsSaga({
  payload,
}: ReturnType<typeof getDonorItems>): SagaIterator {
  try {
    const response = yield call(listedItemsApi.getDonorItems, payload)
    yield put(getDonorItemsSuccess(response))
  } catch (e) {
    trackException({e, source: 'getDonorItemsSaga'})
  }
}

/*GET CATEGORY LIST */
export function* fetchNonprofitCategories(): SagaIterator {
  try {
    const data = yield call(nonprofitsApi.categories)
    yield put(fetchCategoriesSuccess(data || []))
  } catch (e) {
    yield put(setError(e as ApiError))
  }
}

/*GET NONPROFITS LIST*/
export function* fetchNonprofitSaga({
  payload,
}: ReturnType<typeof fetchNonprofits>): SagaIterator {
  try {
    const data = yield call(nonprofitsApi.lookupData, payload)
    yield put(fetchNonprofitsSuccess(data || []))
  } catch (e) {
    yield put(yield put(setError(e as ApiError)))
  }
}

export function* myListedItemsSaga(): SagaIterator {
  yield takeEvery(getDonorItems, getDonorItemsSaga)
  yield takeEvery(fetchCategories, fetchNonprofitCategories)
  yield takeEvery(fetchNonprofits, fetchNonprofitSaga)
}
