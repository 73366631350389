import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useNotFoundPageStyles } from './not-found-page.styles'
import { GoBackButton } from '../../components/go-back-button'

export const NotFoundPage: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const classes = useNotFoundPageStyles()

  return (
    <div className={classes.notFoundPage}>
      <h1 className={classes.code}>404</h1>
      <h2 className={classes.title}>{t('notFound.title')}</h2>
      <p className={classes.description}>{t('notFound.mainDescription')}</p>
      <p className={classes.description}>
        <GoBackButton className={classes.back}/>
        &nbsp;{t('notFound.part1')}&nbsp;
        <Link to="/" className={classes.link}>
          {t('notFound.home')}
        </Link>
        &nbsp;{t('notFound.part2')}
      </p>
    </div>
  )
}
