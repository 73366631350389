import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useWelcomeMessageStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    welcomeSection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    welcomeIcon: {
      fontSize: '80px',
      margin: '10px 30px 5px',
      color: '#3f51b5',

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    welcomeText: {
      flexGrow: 1,
      textAlign: 'left',
      padding: '10px 30px 10px 25px',

      [theme.breakpoints.down('sm')]: {
        padding: '0',
        textAlign: 'center',
      },
    },
    welcomeButtons: {
      whiteSpace: 'nowrap',
      padding: '5px 40px 0',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      [theme.breakpoints.down('xs')]: {
        marginTop: '10px',
      },
    },
    welcomeHideButton: {
      color: '#6F6F6F',
      marginTop: '5px',
      textDecoration: 'underline',
      cursor: 'pointer',
      transition: 'color 200ms',

      '&:hover': {
        color: '#000',
      },
    },
    hidden: {
      display: 'none',
    },
  })
)
