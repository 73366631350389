import React, { useState } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import { SliderProps } from './slider-component.types'
import { useSliderComponentStyles } from './slider-component.styles'
import { baseURL } from '../../../../api'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Counter from 'yet-another-react-lightbox/plugins/counter'

type SliderProperties = {
  original: string
  index: number
}

export const SliderComponent: React.FC<SliderProps> = (props: SliderProps) => {
  const { images } = props
  const classes = useSliderComponentStyles()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [openLightBox, setOpenLightBox] = React.useState(false)

  /*Create Img Url*/
  const buildBigImageUrl = (imageId: number) =>
    `${baseURL}listed-items/images/${imageId}/large.jpg`
  const buildSmallImageUrl = (imageId: number) =>
    `${baseURL}listed-items/images/${imageId}/small.jpg`
  const _images = images.map((item) => {
    return {
      original: `${buildBigImageUrl(item)}`,
      thumbnail: `${buildSmallImageUrl(item)}`,
    }
  })

  const lightBoxImages = _images.map((item) => {
    return { src: item.original }
  })

  const renderItem = ({ original }: SliderProperties) => (
    <div className={classes.imageArea}>
      <div className={classes.imgBgBlock}>
        <div
          style={{ backgroundImage: `url(${original})` }}
          className={classes.imageHolder}
        >
          {' '}
        </div>
      </div>
    </div>
  )

  const renderThumb = (item: any) => (
    <div
      className={classes.imageThumb}
      style={{ backgroundImage: `url(${item.thumbnail})` }}
    ></div>
  )

  return (
    <>
      <div className={classes.slider}>
        <Lightbox
          index={currentIndex}
          open={openLightBox}
          close={() => setOpenLightBox(false)}
          slides={[...lightBoxImages]}
          plugins={[Fullscreen, Counter, Slideshow]}
          carousel={{ finite: images.length === 1 }}
        />
        <ImageGallery
          items={_images}
          renderThumbInner={renderThumb}
          showFullscreenButton={false}
          lazyLoad
          onClick={() => setOpenLightBox(true)}
          onSlide={setCurrentIndex}
          renderItem={renderItem}
          showPlayButton={images.length > 1}
        />
      </div>
    </>
  )
}
