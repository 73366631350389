import { Form } from './use-form.types'
import { curry } from '../../utils'
import { ServerErrorCodes } from '../../store/auth/contracts'
import i18n from '../../i18n'

export const required = curry((form: Form, fieldName: string) => {
  const _string = form[fieldName].value || ''
  if (!_string.toString().trim()) {
    return { [fieldName]: i18n.t('validationErrors.required') }
  }
  return (
    min(1, form, fieldName) && {
      [fieldName]: i18n.t('validationErrors.required'),
    }
  )
})

export const min = curry(
  (target: number, form: Form, fieldName: string) =>
    (form[fieldName]?.value as string)?.length < target && {
      [fieldName]: i18n.t('validationErrors.min', { target: target - 1 }),
    }
)

export const max = curry(
  (target: number, form: Form, fieldName: string) =>
    (form[fieldName].value as string).length > target && {
      [fieldName]: i18n.t('validationErrors.max', { target: target + 1 }),
    }
)

export const email = curry(
  (form: Form, fieldName: string) =>
    !(form[fieldName].value as string).match(/\S+@\S+\.\S+/) && {
      [fieldName]: i18n.t('validationErrors.invalidEmail'),
    }
)

export const equal = curry(
  (targetFieldName: string, form: Form, fieldName: string) =>
    form[fieldName].value !== form[targetFieldName].value && {
      [fieldName]: i18n.t('validationErrors.notEqual'),
    }
)

export const serverErrorCodes = curry(
  (errorCodes: readonly ServerErrorCodes[], form: Form, fieldName: string) => {
    const error = form[fieldName].reduxErrors.filter((error) =>
      errorCodes.find((code) => code === error.code)
    )[0]

    return error && { [fieldName]: i18n.t(`errors.${error.code}`) }
  }
)

export const numberRange = curry(
  ([min, max]: [number, number], form: Form, fieldName: string) => {
    return (
      (Number(form[fieldName].value) < min ||
        Number(form[fieldName].value) > max) && {
        [fieldName]: `${i18n.t('validationErrors.price', {
          min: '1',
          max: '200,00',
        })}`,
      }
    )
  }
)

export const minNumberValue = curry(
  (value: number, form: Form, fieldName: string) => {
    return (
      Number(form[fieldName].value) < value && {
        [fieldName]: i18n.t(`validationErrors.incorrect`),
      }
    )
  }
)
export const greaterThan = curry(
  (value: number, form: Form, fieldName: string) => {
    return (
      Number(form[fieldName].value) <= value && {
        [fieldName]: `${i18n.t('validationErrors.minNum', { target: value })}`,
      }
    )
  }
)

export const lessThan = curry(
  (value: number, form: Form, fieldName: string) => {
    return (
      Number(form[fieldName].value) > value && {
        [fieldName]: `${i18n.t('validationErrors.maxNum', { target: value })}`,
      }
    )
  }
)
/*
 *Validator for check optional form
 * Work when optional  form non visible or visible
 */
