import { RegionsCollection } from './models'
import { ApiError } from '../api-error-data'
import {axiosInstance} from '../../../api'

export const generalDataApi = {
  getRegionsAsync: async function (countryCode: string): Promise<RegionsCollection> {
    try {
      const endpointUrl = `data/regions/${countryCode}`
      const response = await axiosInstance.get(endpointUrl)
      return response.data as RegionsCollection
    } catch (error) {
      return Promise.reject(new ApiError(error))
    }
  },
}
