import React, { useState } from 'react'
import { Badge, Card, CardContent, Typography } from '@material-ui/core'
import { ListedItemProps } from './listed-item.interfaces'
import { useListedItemStyles } from './listed-item.styles'
import { Link } from 'react-router-dom'
import {
  currencyTransform,
  CurrencyList,
  Locale,
} from '../../utils/currency-transform'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'
import { ImageCard } from '../image-card'
import { StateListType } from '../filter'
import { useTranslation } from 'react-i18next'
import { ItemState } from '../../models'
import { getDateByState } from '../../utils/item-details'

export const ListedItem: React.FC<ListedItemProps> = (
  props: ListedItemProps
): React.ReactElement => {
  const {
    defaultImageId,
    name,
    condition,
    id,
    price,
    city,
    region,
    locale = Locale.US,
    state,
    currency = CurrencyList.USD,
    settings,
    showDate,
    soldUtc,
    createdUtc,
    listedUtc,
    itemUrl,
  } = props

  const classes = useListedItemStyles()
  const linkTo = itemUrl ? itemUrl : `/listed-items/${id}`
  const { t } = useTranslation()

  const [STATE_LIST] = useState<StateListType>([
    { state: t('filter.state.draft'), value: ItemState.Draft },
    { state: t('filter.state.active'), value: ItemState.Active },
    {
      state: t('filter.state.sellPending'),
      value: ItemState.SellPending,
    },
    { state: t('filter.state.sold'), value: ItemState.Sold },
    { state: t('filter.state.unlisted'), value: ItemState.Unlisted },
  ])

  //Get State Name
  const getState = () => {
    return STATE_LIST.filter((item) => item.value === state)[0].state
  }

  const getDateTitle = (): string => {
    const TITLE_LIST = {
      [ItemState.Draft]: t('filter.dateBlock.created'),
      [ItemState.Active]: t('filter.dateBlock.listed'),
      [ItemState.Sold]: t('filter.dateBlock.sold'),
    }

    return (
      TITLE_LIST[state as keyof typeof TITLE_LIST] ??
      t('filter.dateBlock.created')
    )
  }

  //Get State Color
  const getColor = () => {
    const COLOR_LIST = {
      [ItemState.Draft]: classes.badgeBlockDraft,
      [ItemState.Active]: classes.badgeBlockActive,
      [ItemState.SellPending]: classes.badgeBlockSellPending,
      [ItemState.Sold]: classes.badgeBlockSold,
      [ItemState.Unlisted]: classes.badgeBlockUnlisted,
    }
    return COLOR_LIST[state as keyof typeof COLOR_LIST]
  }

  return (
    <Card>
      <CardContent>
        <div className={classes.cardHeader}>
          <Link to={linkTo} className={classes.link}>
            {defaultImageId ? (
              <ImageCard imageId={defaultImageId} />
            ) : (
              <div className={classes.imgHolder}>
                <div className={classes.iconWrapper}>
                  <PhotoCameraOutlinedIcon
                    style={{ fontSize: 90, color: 'black' }}
                  />
                </div>
              </div>
            )}
          </Link>
          {settings && settings.hideCondition ? null : (
            <Badge
              overlap="rectangular"
              badgeContent={condition ? 'USED' : 'NEW'}
              color={condition ? 'primary' : 'secondary'}
              className={classes.badgeBlock}
            />
          )}
          {(state || state === ItemState.Draft) && (
            <Badge
              overlap="rectangular"
              badgeContent={getState()}
              className={[classes.badgeBlock, getColor()].join(' ')}
            />
          )}
        </div>
        <Link
          to={linkTo}
          className={`${classes.noneTextDecoration} ${classes.productTitleLink}`}
        >
          <Typography
            gutterBottom
            variant="h6"
            component="h5"
            className={classes.productTitle}
            title={name}
          >
            {name}
          </Typography>
        </Link>
        <div>
          <div
            className={`${classes.productPropertyLine} ${classes.productPropertyLineOffset}`}
          >
            <Typography className={classes.propertyValue}>
              {currencyTransform(price, locale, {
                style: 'currency',
                currency,
              })}
            </Typography>
          </div>
          {settings && settings.hideCity && settings.hideRegion ? null : (
            <div
              className={`${classes.productPropertyLine} ${classes.productPropertyLineOffset}`}
            >
              <Typography
                className={classes.cityValue}
              >{`${city}, ${region}`}</Typography>
            </div>
          )}
        </div>
        {showDate && (
          <div
            className={classes.dateBlock}
            style={{
              visibility: state === ItemState.Draft ? 'hidden' : 'visible',
            }}
          >
            <span className={classes.dateLabel}>{getDateTitle()}</span>
            <span>
              {getDateByState({ createdUtc, listedUtc, soldUtc }, state as ItemState)}
            </span>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
