import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAddressStyles = makeStyles((theme: Theme) =>
  createStyles({
    nameLine: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    icon: {
      color: theme.palette.primary.light,
      fontSize: 13,
      cursor: 'pointer',

      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    loader: {
      display: 'block',
      alignItems: 'center',
      width: '100%',
    },

    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
      '&:focus': {
        outline: 'none',
      },
    },
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    marginButton: {
      margin: theme.spacing(1),
    },
    modal: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    link: {
      cursor: 'pointer',
    },
    fullScreen: {
      width: '100%',
      height: '100%',
    },
    closeIcon: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: 'black',
    },
  })
)
