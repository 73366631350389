import { SimpleFunc } from '../../models'

export const AppDialogVariants = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
}

export type InitialStateType = {
  isShow: boolean
  variant?: string
  message?: React.ReactNode
  title: string
  showCloseButton: boolean
  onExit?: () => void
  closable?: boolean
  actions?: React.ReactNode
  forceClose?: boolean
  closeHandler?: SimpleFunc
}
