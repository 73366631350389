import React from 'react'
import { useAppHeaderStyles } from './app-header.styles'
import { AppBar, Toolbar } from '@material-ui/core'
import { AppHeaderRightMenu } from './app-header-right-menu'
import { AppHeaderDonateButton } from './app-header-donate-button'
import { AppHeaderLoadingIndicator } from './app-header-loading-indicator'
import { useSelector } from 'react-redux'
import { selectCheckedAuth } from '../../store/auth/selectors'
import { AppHeaderLogo } from './app-header-logo'
import { AppHeaderDrawer } from './app-header-drawer'

export const AppHeader: React.FC = (): React.ReactElement => {
  const classes = useAppHeaderStyles()
  const isLoading = !useSelector(selectCheckedAuth)

  return (
    <AppBar position="static" className={classes.appBar}>
      {isLoading && <AppHeaderLoadingIndicator />}
      <Toolbar className={classes.toolbar}>
        <AppHeaderLogo />
        {!isLoading && (
          <>
            <AppHeaderDonateButton />
            <AppHeaderRightMenu />
            <AppHeaderDrawer />
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}
