import React from 'react'
import { Account2Svg } from '../../../../assets/svg/account2svg'
import { useAppHeaderRightMenuStyles } from '../app-header-right-menu.styles'
import { useOverlayPreloader } from '../../../../hooks/use-overlay-preloader'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import { msalRequest } from '../../../../env-config'

export const MenuForNotAuthenticated: React.FC = (): React.ReactElement => {
  const { instance } = useMsal()
  const classes = useAppHeaderRightMenuStyles()
  const { setIsShowOverlayPreloader } = useOverlayPreloader()
  const { t } = useTranslation()

  /* Login Handler */
  const handleLoginRedirect = () => {
    setIsShowOverlayPreloader(true)
    // @ts-ignore
    instance.loginRedirect(msalRequest).catch((error) => console.log(error))
  }

  return (
    <>
      <span onClick={() => handleLoginRedirect()} className={classes.link}>
        <Account2Svg />{t('header.menu.login')}
      </span>
    </>
  )
}
