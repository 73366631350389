import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { FilterProps } from './filter.constants'
import { ScreenSize } from '../../settings/device'
import { Nonprofit, NonprofitCategory } from '../../models/item-details'

enum PriceInput {
  Left = 'left',
  Right = 'right',
}

export type ItemStateValueType = {
  state: string
  value: number
}

type useFilterHookProps = FilterProps
type useFilterHookType = FilterProps & {
  onFocus: boolean
  drawerStatus: boolean
  btnDisabled: boolean
  localPriceState: number[]
} & {
  isIncorrectPrice: () => boolean | undefined
  isApply: () => boolean
  setCondition: (value: boolean, conditionNumber: number) => void
  sliderChangeHandler: (event: any, newValue: number | number[]) => void
  onChangeCommitted: () => void
  isChangedLocalPriceState: () => boolean
  textFieldHandler: (
    event: React.ChangeEvent,
    value: number,
    priceInput: PriceInput
  ) => void
  textFieldBlurHandler: () => void
  searchTextFieldBlurHandler: () => void
  searchTextFieldEnterHandler: (value: React.KeyboardEvent) => void
  searchTextFieldFocusHandler: () => void
  handleChangeCategoryHandler: (value: any) => void
  handleChangeNonprofityHandler: (value: any) => void
  handleSearchTextHandler: (value: string) => void
  changeItemsState: (prop: ItemStateValueType | null) => void
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void | undefined
  applyBtnHandler: () => void
} & {
  setOnfocus: Dispatch<SetStateAction<boolean>>
  setDrawerStatus: Dispatch<SetStateAction<boolean>>
  setBtnDisabled: Dispatch<SetStateAction<boolean>>
  setLocalPriceState: Dispatch<SetStateAction<number[]>>
}

export const useFilterHook = (props: useFilterHookProps): useFilterHookType => {
  const {
    screenSize,
    initialMaxPrice,
    initialMinPrice,
    onFilterChanged,
    loading,
    condition,
    priceValue,
    cancelHandler,
    categories,
    nonprofits,
    nonprofitCategoryId,
    nonprofitId,
    setFilterCollapsed,
    isFilterCollapsed,
    searchText,
    itemsState,
    settings,
  } = props

  useEffect(() => {
    if (screenSize === 1) {
      setFilterCollapsed(false)
    }
  }, [])

  const [onFocus, setOnfocus] = useState<boolean>(false)
  const [drawerStatus, setDrawerStatus] = useState<boolean>(false)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(true)
  const [localPriceState, setLocalPriceState] = useState<number[]>(priceValue!)
  const [initialSearchState, setInitialSearchState] =
    useState<string>(searchText)
  const isIncorrectPrice = () => {
    if (!priceValue) return false
    if (priceValue[0] >= priceValue[1]) {
      return true
    }
  }

  /*Param that defined reload*/
  const isApply = () => {
    return screenSize !== ScreenSize.Mobile
  }

  /*SetConditionHandler*/
  const setCondition = (value: boolean, conditionNumber: number) => {
    if (isIncorrectPrice() || !condition) return

    setBtnDisabled(false)
    const _condition =
      conditionNumber === 0 ? [value, condition[1]] : [condition[0], value]
    onFilterChanged(
      {
        condition: _condition,
      },
      isApply(),
      true
    )
  }

  /*Slider Change Handler*/
  const sliderChangeHandler = (event: any, newValue: number | number[]) => {
    onFilterChanged(
      {
        priceValue: newValue as number[],
      },
      false
    )
  }

  /**Slider Change Commited Handler*/
  const onChangeCommitted = () => {
    setBtnDisabled(false)
    setLocalPriceState(priceValue!)
    onFilterChanged(
      {
        priceValue,
      },
      isApply(),
      true
    )
  }

  const isChangedLocalPriceState = () => {
    return !(
      priceValue![0] === localPriceState[0] &&
      priceValue![1] === localPriceState[1]
    )
  }

  /*Text Field Handler*/
  const textFieldHandler = (
    event: React.ChangeEvent,
    value: number,
    priceInput: PriceInput
  ) => {
    // eslint-disable-next-line functional/no-let
    let _priceValue: number[]
    setBtnDisabled(false)

    priceInput === PriceInput.Left
      ? (_priceValue = [value, priceValue![1]])
      : (_priceValue = [priceValue![0], value])

    onFilterChanged(
      {
        priceValue: _priceValue,
      },
      false
    )
  }

  /*Text Blur Handler*/
  const textFieldBlurHandler = () => {
    setOnfocus(false)

    if (isIncorrectPrice() || !isChangedLocalPriceState()) {
      return
    }
    priceValue && setLocalPriceState(priceValue)
    onFilterChanged({ priceValue }, isApply(), true)
  }

  /* Search Text Blur Handler */
  const searchTextFieldBlurHandler = () => {
    setOnfocus(false)

    if (searchText != initialSearchState) {
      onFilterChanged({ searchText }, isApply(), true)
    }
  }

  const searchTextFieldEnterHandler = (data: React.KeyboardEvent) => {
    if (data.code === 'Enter') {
      searchTextFieldBlurHandler()
    }
  }

  /*Search Text Focus Handler*/
  const searchTextFieldFocusHandler = () => {
    setInitialSearchState(searchText)
    setOnfocus(true)
  }

  /*Select Category Handler */
  const handleChangeCategoryHandler = (value: any) => {
    if (isIncorrectPrice()) return
    setBtnDisabled(false)
    onFilterChanged(
      {
        nonprofitCategoryId:
          (value as NonprofitCategory) && value.nonprofitCategoryId
            ? value.nonprofitCategoryId
            : null,
      },
      isApply(),
      true
    )
  }

  /* Search Text Handler */
  const handleSearchTextHandler = (value: string) => {
    const searchText = value

    setBtnDisabled(false)
    onFilterChanged(
      {
        searchText, //searchText: value as string
      },
      false
    )
  }

  /*Select Nonprofit Handler */
  const handleChangeNonprofityHandler = (value: any) => {
    if (isIncorrectPrice()) return
    setBtnDisabled(false)
    const nonprofitId =
      value && (value as Nonprofit).nonprofitId
        ? (value as Nonprofit).nonprofitId
        : null
    onFilterChanged(
      {
        nonprofitId,
      },
      isApply(),
      true
    )
  }

  /*DrawerManager*/
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setDrawerStatus(open)
    }

  /*Apply Btn Button*/
  const applyBtnHandler = () => {
    setDrawerStatus(false)
    setLocalPriceState(priceValue!)
    onFilterChanged({}, true, true)
    setBtnDisabled(true)
  }

  /* Set Items State*/
  const changeItemsState = (prop: ItemStateValueType | null) => {
    if (isIncorrectPrice()) return
    setBtnDisabled(false)
    onFilterChanged(
      {
        listedItemState: prop && prop.value,
      },
      isApply(),
      true
    )
  }

  return {
    //props
    screenSize,
    initialMaxPrice,
    initialMinPrice,
    onFilterChanged,
    loading,
    condition,
    priceValue,
    cancelHandler,
    categories,
    nonprofits,
    nonprofitCategoryId,
    nonprofitId,
    setFilterCollapsed,
    isFilterCollapsed,
    searchText,
    itemsState,
    settings,

    //methods
    isIncorrectPrice,
    isApply,
    setCondition,
    sliderChangeHandler,
    onChangeCommitted,
    isChangedLocalPriceState,
    textFieldHandler,
    textFieldBlurHandler,
    searchTextFieldFocusHandler,
    searchTextFieldBlurHandler,
    searchTextFieldEnterHandler,
    handleChangeCategoryHandler,
    handleChangeNonprofityHandler,
    handleSearchTextHandler,
    toggleDrawer,
    applyBtnHandler,
    changeItemsState,

    //state
    onFocus,
    drawerStatus,
    btnDisabled,
    localPriceState,

    //state methods
    setOnfocus,
    setLocalPriceState,
    setBtnDisabled,
    setDrawerStatus,
  }
}
