import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const myListedItems = (state: RootState) => state.myListedItems

export const selectLoading = createSelector(
  myListedItems,
  (state) => state.loading
)
export const selectItemsList = createSelector(
  myListedItems,
  (state) => state.itemsList
)
export const selectQueryParams = createSelector(
  myListedItems,
  (state) => state.query
)
export const selectTotalPages = createSelector(
  myListedItems,
  (state) => state.totalPages
)
export const selectFirstRender = createSelector(
  myListedItems,
  (state) => state.firstRender
)
export const selectNeedApply = createSelector(
  myListedItems,
  (state) => state.needApply
)
export const selectSearchText = createSelector(
  myListedItems,
  (state) => state.searchText
)
export const selectSearchQuery = createSelector(
  myListedItems,
  (state) => state.searchQuery
)
export const selectIsFilterCollapsed = createSelector(
  myListedItems,
  (state) => state.isFilterCollapsed
)
export const selectError = createSelector(myListedItems, (state) => state.error)
export const selectCategories = createSelector(
  myListedItems,
  (state) => state.categories
)
export const selectNonprofits = createSelector(
  myListedItems,
  (state) => state.nonprofits
)
