/* Validate */
import { getErrorsFromSchema } from '../../../hooks/use-form'
import { min, required } from '../../../hooks/use-form/use-form.validators'

export const validate = getErrorsFromSchema({
  name: [min(1), required],
  addressLine1: [min(1), required],
  city: [min(1), required],
  postalCode: [min(1), required],
})

//Initial Address Data State
export const initialAddressDataState = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  countryCode: '',
  name: '',
  postalCode: '',
  region: '',
}
