import React from 'react'
import { useAppProgressIndicatorStyles } from './app-progress-indicator.styles'
import { Backdrop, CircularProgress } from '@material-ui/core'

export const AppProgressIndicator: React.FC = (): React.ReactElement => {
  const { progressIndicator } = useAppProgressIndicatorStyles()
  return (
    <Backdrop open={true} className={progressIndicator} style={{zIndex: 100000}}>
      <CircularProgress color="inherit" />
    </Backdrop>
  )
}
