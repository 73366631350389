import { createStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useCheckoutPageStyles = makeStyles(() =>
  createStyles({
    linksArea: {
      height: 50,
      display: 'flex',
      justifyContent: 'right',
    },

    linkInNew: {
      display: 'inline-flex',
      alignItems: 'center',
      textDecoration: 'underline',

      '& .MuiSvgIcon-root': {
        marginLeft: 4,
        fontSize: 14,
      },
    },
    descriptionArea: {
      marginBottom: 50,
    },
    boldLink: {
      textDecoration: 'underline',
    },
  })
)
