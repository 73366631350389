import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useCheckoutDescriptionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    description: {
      display: 'flex',
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    paper: {
      height: '100%',
    },
    textArea: {
      [theme.breakpoints.up('md')]: {
        paddingRight: '15px',
        flexGrow: 1,
      },
    },
    '@keyframes imageLoadingAnimation': {
      '0%': {
        backgroundPosition: '-250px 0',
      },
      '100%': {
        backgroundPosition: '250px 0',
      },
    },



    imageAreaNonAnimation: {
      animationName: 'none',
    },
    imgBgWrapper: {},

    descriptionText: {
      fontSize: 14,
      lineHeight: 1.7,
      color: 'rgba(0,0,0, 0.5)',
    },
    textWrapper: {
      marginBottom: 20,
    },
  })
)
