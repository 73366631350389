import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Paper, Container, Typography } from '@material-ui/core'
import { useShopStyles } from './shop-page.styles'

export const ShopPage: React.FC = (): React.ReactElement => {
  const classes = useShopStyles()
  const { t } = useTranslation()
  return (
    <Container>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={`${classes.main} ${classes.paper}`}>
              <h1>{t('shop.title')}</h1>
              <Typography className={classes.mainText}>
                {t('shop.text')}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>{t('shop.contentTitle')}</Paper>
            <Grid container spacing={3}>
              {/*{animalSheltersCards.map((animalSheltersCard: FeaturedProduct, index: number) =>*/}
              {/*  <Grid item sm={12} md={3} key={index}>*/}
              {/*    <ProductCard{...animalSheltersCard} key={index} />*/}
              {/*  </Grid>*/}
              {/*)}*/}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/*{productCards.map((productCard: FeaturedProduct, index: number) =>*/}
              {/*  <Grid item sm={12} md={3} key={index}>*/}
              {/*    <ProductCard{...productCard} key={index}/>*/}
              {/*  </Grid>*/}
              {/*)}*/}
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}
