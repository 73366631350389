import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'
import { green, grey, orange, blue, indigo } from '@material-ui/core/colors'

export const useListedItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      position: 'relative',
    },
    displayNone: {
      display: 'none !important',
    },
    imgHolder: {
      width: '100%',
      paddingBottom: '100%',
      position: 'relative',
      backgroundColor: `${theme.palette.action.hover}`,
      borderRadius: 5,
      overflow: 'hidden',
    },
    imgResponsive: {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
    imgNoneVisible: {
      visibility: 'hidden',
    },
    badgeBlock: {
      position: 'absolute',
      top: '3%',
      right: '7%',
      color: 'white',
    },
    badgeBlockActive: {
      '& span': {
        background: green['500'],
      },
    },
    badgeBlockDraft: {
      '& span': {
        background: grey['500'],
      },
    },
    badgeBlockSellPending: {
      '& span': {
        background: orange['500'],
      },
    },
    badgeBlockSold: {
      '& span': {
        background: blue['600'],
      },
    },
    badgeBlockUnlisted: {
      '& span': {
        background: indigo['500'],
      },
    },
    link: {
      display: 'block',
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
      },
      '& div': {
        height: 'auto !important',
        width: '100% !important',
      },
    },
    noneTextDecoration: {
      textDecoration: 'none',
    },
    productTitleLink: {
      display: 'block',
      textAlign: 'left',
    },
    productTitle: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '4px 0',
      marginBottom: 0,
      lineHeight: 1.2,
      fontSize: 18,
    },
    productPropertyLine: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    productPropertyLineOffset: {
      //marginBottom: '0px'
    },
    propertyTitle: {
      color: `${theme.palette.grey['400']}`,
      fontWeight: 500,
      fontSize: '15px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
    },
    propertyValue: {
      color: `${theme.palette.grey['900']}`,
      fontWeight: 400,
      fontSize: '14px',
    },
    iconWrapper: {
      position: 'absolute',
      top: '34%',
      marginTop: -5,
      transformOrigin: 'center center',
      transform: 'scale(0.8)',
      left: 0,
      right: 0,
      opacity: 0.1,
    },
    cityValue: {
      color: theme.palette.grey['A200'],
      fontSize: 14,
      marginTop: 2,
    },
    dateBlock: {
      display: 'flex',
      color: theme.palette.grey['A200'],
      fontSize: 14,
    },
    dateLabel: {
      display: 'block',
      marginRight: 5,
    },
  })
)
