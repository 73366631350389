import React from 'react'
import { useMyAccountMenuStyles } from './my-account-menu.styles'
import { useMyAccountMenu } from './my-account-menu.hook'
import { Divider, Menu, MenuItem } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMsal } from '@azure/msal-react'
import {useOverlayPreloader} from '../../../../../hooks/use-overlay-preloader'

export type MyAccountMenuProps = {
  anchorEl: null | HTMLElement
  setAnchorEl: (anchorEl: null | HTMLElement) => void
}

export const MyAccountMenu: React.FC<MyAccountMenuProps> = (
  props
): React.ReactElement => {
  const classes = useMyAccountMenuStyles()

  const { anchorEl } = props

  const { menuClickHandler } = useMyAccountMenu(props)

  const {setIsShowOverlayPreloader} = useOverlayPreloader()
  const { t } = useTranslation()
  const { instance } = useMsal()

  const handleLogoutRedirect = () => {
    setIsShowOverlayPreloader(true)
    instance.logoutRedirect()
  }

  /* Added overlay layout */
  return (
    <Menu
      className={classes.root}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id="my-account-header-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={menuClickHandler}
    >
      <MenuItem onClick={menuClickHandler}>
        <NavLink to="/account">{t('header.menu.settings')}</NavLink>
      </MenuItem>

      <>
        {' '}
        <MenuItem onClick={menuClickHandler}>
          <NavLink to="/my-listed-items">
            {t('header.menu.myListedItems')}
          </NavLink>
        </MenuItem>
        <MenuItem onClick={menuClickHandler}>
          <NavLink to="/draft-items">{t('header.menu.draftItems')}</NavLink>
        </MenuItem>
      </>


      <Divider />
      <MenuItem onClick={handleLogoutRedirect}>
        {t('header.menu.logout')}
      </MenuItem>
    </Menu>
  )
}
