import React, { useEffect } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useDraftItemsStyles } from './draft-items-page.component.styles'
import { useTranslation } from 'react-i18next'
import { itemsCounter } from '../../settings/home-page-settings'
import { QueryParams } from '../home-page/home-component/home.constants'
import {
  getDraftItems,
  resetRequestParams,
  setQueryParams,
  setSortingInitial,
} from '../../store/draft-items/reducer'
import { useDispatch } from 'react-redux'
import { Filter } from '../../components/filter'
import { SortDate } from '../../components/sort-date'
import { PlaceholderArrayComponent } from '../home-page/home-component/placeholder-array-component'
import { EmptySearchResultComponent } from '../home-page/home-component/empty-search-result-component'
import { ListedItem, ListedItemProps } from '../../components/listed-item'
import Pagination from '@material-ui/lab/Pagination'
import { useNavigate } from 'react-router-dom'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { useDraftItemsPage } from './draft-items-page.component.hook'
import { useScreenSize } from '../../hooks/use-screen-size'
import { widthBreakpoint } from '../../settings/device'

export const DraftItemsPage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useDraftItemsStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const screenSize = useScreenSize()
  const { setAppDialog } = useAppDialog()
  const matches = useMediaQuery(`(min-width:${widthBreakpoint + 1}px)`)

  const {
    //props
    itemsList,
    loading,
    query,
    totalPages,
    needApply,
    firstRender,
    isFilterCollapsed,
    userStatus,
    error,

    //methods
    changeFilterCollapsedAction,
  } = useDraftItemsPage()

  //Get Listed Items
  useEffect(() => {
    const { pageSize, searchText, sortByDate, pageIndex, listedItemState } =
      query

    needApply &&
      dispatch(
        getDraftItems({
          pageSize,
          searchText,
          sortByDate,
          pageIndex,
          listedItemState,
        })
      )
  }, [query, needApply])

  //After Unmount
  useEffect(() => {
    return () => {
      dispatch(resetRequestParams())
    }
  }, [])

  const onQueryParamsChanged = (
    data: QueryParams,
    isApply?: boolean,
    isStartPagingInBegin?: boolean
  ) => {
    dispatch(
      setQueryParams({
        ...data,
        listedItemState: 0,
        isApply,
        isStartPagingInBegin,
      })
    )
  }

  //Filter Collapse Handler
  const filterCollapseHandler = (value: boolean) => {
    dispatch(changeFilterCollapsedAction(value))
  }

  //Cancel Handler
  const cancelHandler = () => {
    dispatch(setSortingInitial())
  }

  //User Activate
  useEffect(() => {
    !userStatus &&
      setAppDialog({
        isShow: true,
        title: t('myListingPage.appDialog.title'),
        message: t('myListingPage.appDialog.message'),
        variant: 'warning',
        actions: (
          <Button onClick={() => navigate('/account/security')} color="primary">
            {t('myListingPage.appDialog.btnText')}
          </Button>
        ),
        closeHandler: () => navigate('/'),
      })
  }, [userStatus])

  /* Error Loading Handler */
  useEffect(() => {
    error &&
    setAppDialog({
      isShow: true,
      title: t('errors.unexpectedError.title'),
      message: t('errors.unexpectedError.message'),
      variant: 'error',
    })
  }, [error])

  //If user not activated
  if (!userStatus) {
    return <></>
  }

  return (
    <>
      <LinearProgress style={{ visibility: loading ? 'visible' : 'hidden' }} />
      <div className={classes.container}>
        <Grid container spacing={2}>
          {/*Title*/}
          <Grid item xs={12}>
            <div className={classes.header}>
              <Typography variant="h6" className={classes.headerTitle}>
                {t('draftItemsPage.title')}
              </Typography>
            </div>
          </Grid>
        </Grid>

        {/*Search and Filter Block*/}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              className={
                matches ? classes.paramsBlockDesktop : classes.paramsBlockMobile
              }
            >
              <div
                className={
                  matches
                    ? classes.filterBlockDesktop
                    : classes.filterBlockMobile
                }
              >
                <Filter
                  cancelHandler={cancelHandler}
                  categories={[]}
                  isFilterCollapsed={isFilterCollapsed}
                  loading={loading}
                  nonprofitCategoryId={query.nonprofitCategoryId}
                  nonprofitId={query.nonprofitId}
                  nonprofits={[]}
                  onFilterChanged={onQueryParamsChanged}
                  screenSize={screenSize}
                  searchText={query.searchText}
                  itemsState={query.listedItemState}
                  setFilterCollapsed={filterCollapseHandler}
                  settings={{
                    hideCategory: true,
                    hideNonprofit: true,
                  }}
                />
              </div>
              <div className={classes.sortBlock}>
                <div
                  className={
                    matches
                      ? classes.sortDateBlockDesktop
                      : classes.sortDateBlockMobile
                  }
                >
                  <SortDate
                    changeHandler={(value) =>
                      onQueryParamsChanged({ sortByDate: value }, true, true)
                    }
                    value={query.sortByDate}
                  />
                </div>
                <div className={classes.pageSizeHolder}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="select-show-items-label" disabled={false}>
                      {t('myListingPage.showItems')}
                    </InputLabel>
                    <Select
                      disabled={false}
                      labelId="select-show-items-label"
                      id="select-show-items"
                      variant="outlined"
                      value={query.pageSize}
                      label={'Show Items'}
                      onChange={(event) =>
                        onQueryParamsChanged(
                          { pageSize: event.target.value as number },
                          true,
                          true
                        )
                      }
                    >
                      {itemsCounter.map((item, key) => (
                        <MenuItem value={item} key={key}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {/*Item Grid*/}
        <Grid container spacing={2}>
          {firstRender && <PlaceholderArrayComponent />}
          {!itemsList.length && !firstRender && (
            <Grid xs={12} item>
              <EmptySearchResultComponent text="No draft items" />
            </Grid>
          )}
          {itemsList.map((item: ListedItemProps, key: number) => (
            <Grid key={key} item xs={12} lg={3} md={4} sm={6}>
              <ListedItem
                {...item}
                itemUrl={`/my-listed-items/${item.id}`}
                settings={{
                  hideCity: true,
                  hideRegion: true,
                  hideCondition: true,
                }}
                showDate
              />
            </Grid>
          ))}

          {/*Pagination*/}
          {!firstRender && totalPages > 1 && (
            <Grid item xs={12}>
              <div className={classes.paginationHolder}>
                <Pagination
                  count={totalPages}
                  disabled={loading}
                  shape="rounded"
                  color="primary"
                  page={query.pageIndex + 1}
                  onChange={(_, page) =>
                    onQueryParamsChanged({ pageIndex: page - 1 }, true)
                  }
                />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}
