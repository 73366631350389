import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useOrderPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageHolder: {
      marginTop: theme.spacing(6),
      textAlign: 'left',
    },
    orderHeader: {
      padding: theme.spacing(5),
    },
    badgeHolder: {
      position: 'absolute',
      display: 'block',
      top: -10,
      right: -25,
    },
    dividerHolder: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    orderHeaderTitle: {
      marginRight: theme.spacing(6),
      position: 'relative',
      display: 'inline-block',
    },
    orderHeaderStatusText: {
      color: 'rgba(0,0,0, 0.5)',
    },
    orderBody: {
      padding: theme.spacing(5),
    },
    orderBodyHolder: {},

    //Price Info
    orderPriceInfo: {
      borderRadius: '5px',
      overflow: 'hidden',
      border: `1px ${theme.palette.grey['300']} solid`,
    },
    orderPriceInfoTitle: {
      textAlign: 'center',
      background: theme.palette.primary.main,
      color: 'white',
      padding: theme.spacing(2),
    },
    orderPriceInfoSubtitle: {
      fontWeight: 300,
    },
    orderPriceInfoBody: {
      borderRadius: '5px',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    orderPriceInfoBodyItem: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    orderPriceInfoBodyItemLabel: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.grey['500'],
    },
    orderPriceInfoBodyItemValue: {
      fontWeight: 700,
      fontSize: '16px',
    },
    orderPriceTotal: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      padding: theme.spacing(2),
      textAlign: 'center',
      fontWeight: 500,
    },
    orderItemInfoTitle: {
      fontWeight: 700,
      marginBottom: theme.spacing(1),
    },
    orderImgHolder: {
      width: '30%',
      borderRadius: 5,
      overflow: 'hidden',
      position: 'relative',
      paddingTop: '32%',
    },
    orderBgHolder: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    orderItemPropItem: {
      marginBottom: theme.spacing(1),
    },
    orderItemPropLabel: {
      color: theme.palette.grey['900'],
      fontWeight: 700,
      display: 'inline-block',
      marginRight: theme.spacing(2),
    },
    orderItemPropDescription: {
      color: theme.palette.grey['500'],
    },
    orderTypographyHolder: {
      width: '70%',
      paddingLeft: 20,
    },
    orderDescription: {
      //paddingTop: theme.spacing(2)
    },
    orderDescriptionTitle: {
      fontWeight: 700,
      fontSize: '15px',
      marginBottom: theme.spacing(1),
    },

    orderDescriptionText: {
      fontSize: 14,
      lineHeight: 1.7,
      color: 'rgba(0,0,0, 0.5)',
    },
    orderItemInfoTitleHolder: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: theme.spacing(4),

      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
      },
    },
    orderItemInfoDate: {
      color: theme.palette.grey['500'],
    },
    orderItemInfoBlockTitle: {
      fontWeight: 700,
      fontSize: '15px',
      marginBottom: theme.spacing(1),
    },
    orderItemInfoBlockWrapper: {
      marginBottom: theme.spacing(3),
    },
    orderItemInfoBlock: {
      borderRadius: '5px',
      overflow: 'hidden',
      border: `1px ${theme.palette.grey['300']} solid`,
    },
    orderItemInfoBlockItem: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: '12px',
      paddingBottom: '12px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    orderItemInfoBlockItemLabel: {
      color: theme.palette.grey['500'],
      fontSize: '16px',
      display: 'block',
      marginRight: '20px',
    },
    orderItemInfoBlockItemValue: {
      fontWeight: 700,
      fontSize: '16px',
      maxWidth: '500px',
      textAlign: 'right',
    },
    buttonBlock: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'end',
      width: '100%',
    },

    //Modal
    orderModalWrapper: {},
    orderModal: {},
    paper: {
      position: 'absolute',
      width: '100%',
      maxWidth: 425,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
      '&:focus': {
        outline: 'none',
      },
    },
    fullScreen: {
      width: '100%',
      height: '100%',
    },
    modal: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    closeIcon: {
      position: 'absolute',
      right: 0,
      top: 0,
      color: 'black',
    },
    modalImgWrapper: {
      padding: 20,
    },
    modalImgHolder: {
      maxWidth: 300,
      marginRight: 'auto',
      marginLeft: 'auto',
      '& img': {
        display: 'block',
        width: '100%',
      },
    },
    modalBtnBlock: {
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: 300,
      marginRight: 'auto',
      marginLeft: 'auto',
      ['@media print']: {
        display: 'none',
      },
    },
    modalDownloadBtn: {
      marginRight: 10,
    },
  })
)
