import React from 'react'
import { useAppHeaderLogoStyles } from './app-header-logo.styles'
import { Link } from 'react-router-dom'
import { ProsphoraLogoSvg } from '../../../assets/svg/prosphora-logo-svg'
import { useDispatch } from 'react-redux'
import { setInitialState } from '../../../store/home-page-items/reducer'
import { useTranslation } from 'react-i18next'

export const AppHeaderLogo: React.FC = (): React.ReactElement => {
  const classes = useAppHeaderLogoStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const onClickHandler = () => {
    // reset home page items settings
    dispatch(setInitialState())
  }

  return (
    <Link to="/" className={classes.root} onClick={onClickHandler}>
      <ProsphoraLogoSvg />
      <span>{t('title')}</span>
    </Link>
  )
}
