import React from 'react'
import { Box, Divider, Paper, Typography } from '@material-ui/core'
import { useCheckoutSummaryStyles } from './checkout-summary.styles'
import {
  CurrencyList,
  currencyTransform,
  Locale,
} from '../../../utils/currency-transform'
import { ListedItemPriceData } from '../checkout-page.models'
import { useSelector } from 'react-redux'
import {
  selectItemDetails,
  selectPrices,
} from '../../../store/checkout-page/selectors'
import { useTranslation } from 'react-i18next'

export const CheckoutSummary: React.FC = (): React.ReactElement => {
  const { t } = useTranslation()
  const prices = useSelector(selectPrices)
  const itemDetails = useSelector(selectItemDetails)
  if (!itemDetails) throw new Error('Item details expected for component')
  const { donorName, nonprofitName } = itemDetails

  const classes = useCheckoutSummaryStyles()
  const totalAmount = prices.reduce(
    (c: number, x: ListedItemPriceData) => c + x.amount,
    0
  )
  const totalAmountStr = currencyTransform(totalAmount, Locale.US, {
    style: 'currency',
    currency: CurrencyList.USD,
  })

  return (
    <Paper className={classes.root}>
      <Box padding={3} display="flex" flexDirection="column">
        <Box marginBottom={1}>
          <SummaryItem label={t('checkout.donorLabel')}>
            {donorName}
          </SummaryItem>
          <SummaryItem label={t('checkout.charityLabel')}>
            {nonprofitName}
          </SummaryItem>
        </Box>
        <Divider />
        <Box marginY={2} flexGrow={1}>
          {prices.map((x: ListedItemPriceData, i: number) => (
            <PriceItem key={i} title={x.title} amount={x.amount} />
          ))}
        </Box>
        <Divider />
        <div className={classes.totalPrice}>
          <div className={classes.totalPriceLabel}>{t('checkout.total')}</div>
          <div className={classes.totalPriceAmount}>{totalAmountStr}</div>
        </div>
      </Box>
    </Paper>
  )
}

const PriceItem: React.FC<ListedItemPriceData> = (
  props
): React.ReactElement => {
  const { title, amount } = props
  const amountString = currencyTransform(amount, Locale.US, {
    style: 'currency',
    currency: CurrencyList.USD,
  })
  return (
    <Box display="flex" justifyContent="space-between" marginY={1}>
      <Typography variant="subtitle2">{title}</Typography>
      <div>{amountString}</div>
    </Box>
  )
}

type SummaryItemProps = { label: string; children?: React.ReactNode }
const SummaryItem: React.FC<SummaryItemProps> = (props): React.ReactElement => {
  const { label, children } = props
  const classes = useCheckoutSummaryStyles()
  return (
    <div className={classes.summaryItem}>
      <span>{label}:</span>
      <span className={classes.summaryItemValue}>{children}</span>
    </div>
  )
}
