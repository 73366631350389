import React from 'react'
import { CircularProgress, CircularProgressProps } from '@material-ui/core'

export const Loader: React.FC<CircularProgressProps> = (
  props: CircularProgressProps
): React.ReactElement => (
  <div className="App">
    <CircularProgress {...props} />
  </div>
)
