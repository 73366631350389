import React from 'react'
import { TextField } from '@material-ui/core'
import { useForm } from '../../../../hooks/use-form'
import { useTranslation } from 'react-i18next'
import { StandardTextFieldProps } from '@material-ui/core/TextField/TextField'

type ShippingFormTextFieldProps = {
  formProps: ReturnType<typeof useForm>
  name: string
} & StandardTextFieldProps

export const ShippingFormTextField: React.FC<ShippingFormTextFieldProps> = (
  props
): React.ReactElement => {
  const { formProps, name, ...otherProps } = props

  const { t } = useTranslation()

  return (
    <TextField
      error={!!formProps.errors[name]}
      name={name}
      value={formProps.values[name]}
      fullWidth
      variant="outlined"
      label={t(`accountShipping.${name}`)}
      helperText={formProps.form[name].dirty && formProps.errors[name]}
      {...formProps.inputHandlers}
      {...otherProps}
    />
  )
}
