/* eslint-disable functional/no-this-expression */
import { AxiosError, AxiosResponse } from 'axios'
import { TFunction } from 'i18next'

export type ApiErrorData = {
  code: number
}

export class ApiError extends Error {
  public readonly data: ApiErrorData[]

  constructor(message: any) {
    const axiosError = message as AxiosError

    if (!axiosError || !axiosError.isAxiosError) {
      super(message)
      this.data = []
      return
    }

    const data = axiosError.response?.data as ApiErrorData[]
    if (!data || data.length === 0) {
      super(`API response with HTTP status: ${axiosError.response?.status}`)
      this.data = []
      return
    }

    super(`Api response with error code: ${data[0].code}`)
    this.data = data
  }

  getErrorMessages(t: TFunction): string[] {
    if (this.data.length === 0) return [this.message]
    return this.data.map((error) => t(`errors.${error.code}`))
  }

  getErrorMessage(t: TFunction): string {
    if (this.data.length === 0) return this.message
    return this.getErrorMessages(t).join('. ')
  }
}

export type ApiErrorDataContent = AxiosResponse<
  Array<{
    code: number
    data: {
      [key: string]: string
    }
    message: string
  }>
>
