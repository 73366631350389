import React from 'react'
import { createStore } from 'redux'
import { rootReducer } from '../store/root-reducer'
import { render } from '@testing-library/react'
import { Provider } from 'react-redux'

export const renderWithRedux = (
  component: React.ReactElement,
  { initialState, store = createStore(rootReducer, initialState) }: any = {
    initialState: {},
  }
) => ({
  ...render(<Provider store={store}>{component}</Provider>),
  store,
})

export const getInputs =
  <T extends ReadonlyArray<string>>(testIds: T) =>
  (fn: (container: string) => HTMLElement) =>
    Object.fromEntries(testIds.map((testId: string) => [testId, fn(testId)]))
