import React from 'react'
import { useAppHeaderDrawerStyles } from './app-header-drawer.styles'
import { useAppHeaderDrawer } from './app-header-drawer.hook'
import { MenuSvg } from '../../../assets/svg/menu-svg'
import { Link } from 'react-router-dom'
import { useAppHeaderRightMenuStyles } from '../app-header-right-menu/app-header-right-menu.styles'
import { SwipeableDrawer } from '@material-ui/core'
import { MenuForAuthenticated } from './menu-for-authenticated'
import { MenuForNotAuthenticated } from './menu-for-not-authenticated'
import {useSelector} from 'react-redux'
import {selectUser} from '../../../store/auth/selectors'

export const AppHeaderDrawer: React.FC = (): React.ReactElement => {
  const { isOpened, onOpenHandler, onCloseHandler, onHamburgerClickHandler } =
    useAppHeaderDrawer()

  const classesRightMenu = useAppHeaderRightMenuStyles()
  const classes = useAppHeaderDrawerStyles()
  const user = useSelector(selectUser)

  return (
    <>
      <Link
        onClick={onHamburgerClickHandler}
        to="/"
        className={[classesRightMenu.link, classes.hamburger].join(' ')}
      >
        <MenuSvg />
      </Link>
      <SwipeableDrawer
        anchor="right"
        open={isOpened}
        onClose={onCloseHandler}
        onOpen={onOpenHandler}
      >
        <div className={classes.list}>
          {user.userId ?  <MenuForAuthenticated closeDrawer={onCloseHandler} /> :   <MenuForNotAuthenticated closeDrawer={onCloseHandler} />}
        </div>
      </SwipeableDrawer>
    </>
  )
}
