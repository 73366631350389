import { createSlice } from '@reduxjs/toolkit'
import {
  CreateShippingLabelAction,
  FetchOrderDetailAction,
  FetchOrderDetailSuccessAction,
  InitialStateType,
  SetErrorAction,
} from './types'
import { ApiError } from '../../services/api/api-error-data'
import { ActionType } from '../types'

type LoadingErrorType = {
  loadingError?: ApiError
}

type LoadingOrderPageType = {
  loadingPage: boolean
}

type LoadingCreateLabelType = {
  loadingCreateLabel: boolean
}

type CreateShippingLabelErrorType = {
  createShippingLabelError?: ApiError
}

export const initialState: Partial<InitialStateType> &
  LoadingErrorType &
  LoadingOrderPageType &
  LoadingCreateLabelType &
  CreateShippingLabelErrorType = {
  listedItem: {
    listedItemId: 0,
    name: '',
    description: '',
    itemCondition: 0,
    currentPrice: 0,
    state: 0,
    listedUtc: '',
    soldUtc: '',
    defaultImageId: 0,
    donorName: '',
    nonprofitName: '',
    images: [],
    version: '',
    isCurrentUserOwner: false,
    region: '',
    city: '',
    isLocalPickupAvailable: false,
    isShippingAvailable: false,
  },

  orderHeader: {
    orderId: 0,
    orderNumber: '',
    taxAmount: 0,
    shippingAmount: 0,
    totalAmount: 0,
    status: '',
    subtotalAmount: 0,
    orderCreatedUtc: '',
    shippingProvider: 0,
    shipToAddressLine1: '',
    shipToAddressLine2: '',
    shipToCity: '',
    shipToCountryCode: '',
    shipToPostalCode: '',
    shipToRegion: '',
    shipToName: '',
    shipment: {
      shippingLabelUrl: '',
    },
    providerShippingRateId: '',
    paymentInfo: {
      currencyCode: 'USD',
      description: '',
    },
    shippingRateProviderData: {
      duration_terms: '',
      provider: '',
      servicelevel: {
        name: '',
      },
    },
    buyerUser: {
      email: '',
      name: '',
      userId: '',
    },
    donorUser: {
      email: '',
      name: '',
      userId: '',
    },
  },
  loadingError: undefined,
  loadingPage: true,
  loadingCreateLabel: true,
  createShippingLabelError: undefined,
}

const orderSlice = createSlice({
  name: 'order',
  initialState: { ...initialState },
  reducers: {
    //Order Page
    fetchOrderDataAction(state, action: FetchOrderDetailAction) {},
    fetchOrderDataSuccessAction(state, action: FetchOrderDetailSuccessAction) {
      state.orderHeader = action.payload.orderHeader
      state.listedItem = action.payload.listedItem
      state.loadingPage = false
    },
    fetchOrderDataErrorAction(state) {
      state.loadingPage = false
    },
    setErrorAction(state, action: SetErrorAction) {
      state.loadingError = action.payload
    },

    //Create Shipping Label
    createShippingLabelAction(state, action: CreateShippingLabelAction) {},

    createShippingLabelSuccessAction(state, action: ActionType<string>) {
      state.orderHeader!.shipment = {
        shippingLabelUrl: action.payload,
      }
    },
    createShippingLabelErrorAction(state, action: SetErrorAction) {
      state.loadingPage = false
      state.createShippingLabelError = action.payload
    },
  },
})

export const orderReducer = orderSlice.reducer
export const {
  fetchOrderDataAction,
  fetchOrderDataSuccessAction,
  fetchOrderDataErrorAction,
  setErrorAction,
  createShippingLabelAction,
  createShippingLabelSuccessAction,
  createShippingLabelErrorAction,
} = orderSlice.actions
