import React, { useState } from 'react'
import { listedItemsApi } from '../../../services/api/listed-items'
import { ApiError } from '../../../services/api/api-error-data'
import { useTranslation } from 'react-i18next'
import { IItemImagesProps } from './item-images.interfaces'
import { getTempUrl, getUrl } from '../../../utils/image-url'
import { useDispatch } from 'react-redux'
import {trackException} from '../../../store/app-insights/reducer'

export type ImageObject = {
  id: number | string
  isTemp: boolean
}

type ImageItemsList = ImageObject[]

export const useItemImages = ({
  setItemImages,
  onErrorHandler,
  initialImages = [],
}: IItemImagesProps) => {
  /** There are can be ids and image objects that uploaded in current session*/
  const dispatch = useDispatch()
  const [imageItemsList, _setImageItemsList] = useState<ImageItemsList>([])
  const [uploadingProgress, setUploadingProgress] =
    useState<number | undefined>(undefined)
  const [currentDraggingElementId, setCurrentDraggingElementId] =
    useState<string | number>('')
  const [currentDraggingElementIndex, setCurrentDraggingElementIndex] =
    useState<number>(-1)
  const [currentDroppedElementIndex, setCurrentDroppedElementIndex] =
    useState<number>(-1)
  const { t } = useTranslation()

  const setImageList = (images: ImageItemsList): void => {
    _setImageItemsList(images)
    setItemImages([...images])
  }

  const initial = () => {
    const initial = initialImages.map((id) => {
      return { isTemp: false, id }
    })
    _setImageItemsList(initial)
  }

  const resetUploadProgress = (): void => setUploadingProgress(undefined)

  const onFilesSelectedHandler = (files: File[]): void => {
    resetUploadProgress()

    listedItemsApi
      .uploadImages(files, uploadingProgressHandler)
      .then((tempImageIds) => {
        const newImages = tempImageIds.map((id) => {
          return {
            isTemp: true,
            id,
          }
        })
        setImageList([...imageItemsList, ...newImages])
        resetUploadProgress()
      })
      .catch((reason) => {
        const error = (reason as ApiError).getErrorMessage(t)
        onErrorHandler(error)
        resetUploadProgress()
        dispatch(trackException({e: reason, source: 'onFilesSelectedHandler'}))
      })
  }

  const uploadingProgressHandler = (event: ProgressEvent): void => {
    const progressValue = Math.round((100 * event.loaded) / event.total)
    setUploadingProgress(progressValue)
  }

  const onDeleteHandler = (imageId: string | number): void =>
    setImageList(imageItemsList.filter((x) => x.id !== imageId))

  const buildImageUrl = (imageId: string | number, isTemp: boolean): string => {
    return isTemp ? getTempUrl(imageId) : getUrl(imageId)
  }

  /* Method that fire when drag and drop ended and create new img order in array*/
  const setImagesOrder = (indexDraggable: number, indexDragOvering: number) => {
    const imgArray = new Array(...imageItemsList)
    const draggableItem = imgArray[indexDraggable]
    imgArray[indexDraggable] = imgArray[indexDragOvering]
    imgArray[indexDragOvering] = draggableItem
    setImageList(imgArray)
  }

  const onDragOverHandler = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault()
  }

  const onDragEndHandler = () => {
    setCurrentDraggingElementId('')
    currentDraggingElementIndex !== currentDroppedElementIndex &&
      setImagesOrder(currentDraggingElementIndex, currentDroppedElementIndex)
    setCurrentDraggingElementIndex(-1)
    setCurrentDroppedElementIndex(-1)
  }

  const onDragStartHandler = (imageId: string | number, index: number) => {
    setCurrentDraggingElementId(imageId)
    setCurrentDraggingElementIndex(index)
    setCurrentDroppedElementIndex(index)
  }

  const onDragEnterHandler = (index: number, imageId: string | number) => {
    setCurrentDraggingElementId(imageId)
    setCurrentDroppedElementIndex(index)
  }

  return {
    // methods
    onFilesSelectedHandler,
    onDeleteHandler,
    buildImageUrl,
    setImagesOrder,
    onDragOverHandler,
    onDragEndHandler,
    onDragStartHandler,
    onDragEnterHandler,
    setImageList,
    initial,

    // values
    uploadingProgress,
    currentDraggingElementId,
    imageItemsList,
  }
}
