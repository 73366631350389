import React, {useEffect} from 'react'
import {Donate} from './donate'
import {listedItemsApi} from '../../services/api/listed-items'
import {useSelector} from 'react-redux'
import {selectUserStatus, selectCheckedAuth, selectIsAuthenticated} from '../../store/auth/selectors'
import {useOverlayPreloader} from '../../hooks/use-overlay-preloader'
import {msalRequest} from '../../env-config'
import {useMsal} from '@azure/msal-react'
import {useLocation} from 'react-router-dom'

export const DonatePage: React.FC = () => {
  const userStatus = useSelector(selectUserStatus)
  const checkedAuth = useSelector(selectCheckedAuth)
  const auth = useSelector(selectIsAuthenticated)
  const { setIsShowOverlayPreloader } = useOverlayPreloader()
  const { instance } = useMsal()
  const { pathname } = useLocation()

  useEffect(()=> {
    if(checkedAuth && !auth){
      setIsShowOverlayPreloader(true)
      instance.loginRedirect({...msalRequest, redirectUri: '/donate', state: pathname}).catch((error) => console.log(error))
    }
  }, [auth, checkedAuth])

  if(!auth){
    return <div></div>
  }

  return (
    <>
      <Donate
        checkedAuth={checkedAuth}
        userStatus={userStatus}
        createListedItemApi={listedItemsApi.create}
      />
    </>
  )
}
