import React from 'react'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {useOverlayPreloader} from '../../../../hooks/use-overlay-preloader'
import {useMsal} from '@azure/msal-react'

type MenuForAuthenticatedProps = {
  closeDrawer: () => void
}

export const MenuForAuthenticated: React.FC<MenuForAuthenticatedProps> = (
  props
): React.ReactElement => {
  const { closeDrawer } = props
  const { t } = useTranslation()
  const { instance } = useMsal()
  const { setIsShowOverlayPreloader } = useOverlayPreloader()

  const handleLogoutRedirect = () => {
    setIsShowOverlayPreloader(true)
    closeDrawer()
    instance.logoutRedirect()
  }

  const menuLink = (to: string, label: string) => (
    <ListItem button to={to} component={Link} onClick={closeDrawer}>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  )

  const menuButton = (
    label: string,
    onClick: (e: React.MouseEvent) => void
  ) => (
    <ListItem button onClick={onClick}>
      <ListItemText>{label}</ListItemText>
    </ListItem>
  )

  return (
    <List>
      {menuLink('/donate', t('header.menu.donate'))}
      {menuLink('/my-listed-items', t('header.menu.myListedItems'))}
      {menuLink('/draft-items', t('header.menu.draftItems'))}
      {/*<Divider/>*/}
      {menuLink('/account', t('header.menu.settings'))}
      <Divider />
      {menuButton(t('header.menu.logout'), handleLogoutRedirect)}
    </List>
  )
}
