import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

export const useDonateStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    inputHolder: {
      marginBottom: '20px',
    },
    shippingAddressAlert: {
      paddingTop: 20,
    },
    btnHolder: {
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    loadedBtnHolder: {
      display: 'flex',
      justifyContent: 'center',
      '& button': {
        margin: '0 5px',
      },
    },
    btnBack: {
      marginLeft: 'auto',
    },
    btnNext: {},
    btnDraft: {
      marginRight: 'auto',
    },
    stepperOffset: {
      marginBottom: '30px',
      marginTop: '30px',
    },
    alert: {
      padding: '0 0 20px 0',
    },
    loading: {
      position: 'absolute',
      padding: '20px 0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '40%',
      left: '50%',
      zIndex: 1,
    },
    additionalBlock: {
      marginTop: '10px',
    },
  })
)
