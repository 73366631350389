import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { instrumentationKey } from './env-config'

// @ts-ignore
const browserHistory = createBrowserHistory({ basename: '' })

export function AppInsightsInit(reactPlugin: ReactPlugin) {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      enableDebugExceptions: true,
      enableAutoRouteTracking: true,
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      }
    },
  })

  appInsights.loadAppInsights()
  window.appInsights = appInsights

  return appInsights
}
