import React, { useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { Grid, Paper } from '@material-ui/core'
import { loadStripe, PaymentMethod } from '@stripe/stripe-js'
import { useStripeFormStyles } from './stripe-form.styles'
import { StripeFormContent } from './stripe-form-content'
import { IBillingDetails } from './stripe-form.hook'
import { Alert } from '@material-ui/lab'
import { stripePublicKey } from '../../../env-config'

// root component
type StripeFormProps = {
  onPaymentMethodReceived: (paymentMethod: PaymentMethod) => void
  billingDetails: IBillingDetails
  disabled?: boolean
}

export const StripeForm: React.FC<StripeFormProps> = (
  props
): React.ReactElement => {
  if (!stripePublicKey) throw Error('Stripe public key is not specified')

  const { onPaymentMethodReceived, billingDetails, disabled = false } = props

  const classes = useStripeFormStyles()
  const [paymentError, setPaymentError] = useState<string>()
  const [stripePromise] = useState(loadStripe(stripePublicKey))

  return (
    <>
      <Grid item xs={12}>
        <Paper className={`${classes.paper}`}>
          <Elements stripe={stripePromise}>
            <StripeFormContent
              onPaymentMethodReceived={onPaymentMethodReceived}
              onError={setPaymentError}
              billingDetails={billingDetails}
              disabled={disabled}
            />
          </Elements>
        </Paper>
      </Grid>
      {paymentError && (
        <Alert variant="filled" color="error">
          {paymentError}
        </Alert>
      )}
    </>
  )
}
