import React from 'react'
import { ListedItemDetailsComponentProps } from './listed-item-details-component.types'
import { useListedItemDetailsComponentStyles } from './listed-item-details-component.styles'
import { GoBackButton } from '../../../components/go-back-button'
import Grid from '@material-ui/core/Grid'
import { SliderComponent } from './slider-component'
import { InfoComponent } from './info-component'
import { EmptyImageComponent } from './empty-image-component'
import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'

export const ListedItemDetailsComponent: React.FC<ListedItemDetailsComponentProps> =
  (props: ListedItemDetailsComponentProps) => {
    const {
      isCurrentUserOwner,
      images,
      itemCondition,
      currency,
      description,
      locale,
      currentPrice,
      name,
      nonprofitName,
      donorName,
      listedUtc,
      createdUtc,
      soldUtc,
      state,
      listedItemId,
      loading,
      city,
      region,
      deliveryType,
      isLocalPickupAvailable,
      isShippingAvailable,
      setInStoreDeliveryType,
    } = props
    const classes = useListedItemDetailsComponentStyles()

    //Placeholder
    const placeholderBlock = () => (
      <div>
        <Box pt={0.5}>
          <Skeleton className={classes.imgSkeleton} animation="wave" />
        </Box>
      </div>
    )

    //Img Block
    const imgBlock = () =>
      images && images.length ? (
        <SliderComponent images={images} />
      ) : (
        <EmptyImageComponent />
      )

    return (
      <div className={classes.wrapper}>
        <GoBackButton />
        <div className={classes.sliderBlock}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7} className={classes.sliderSectionBig}>
              {loading ? placeholderBlock() : imgBlock()}
            </Grid>
            <Grid item xs={12} md={5}>
              <InfoComponent
                listedItemId={listedItemId}
                deliveryType={deliveryType}
                isCurrentUserOwner={isCurrentUserOwner}
                itemCondition={itemCondition}
                currency={currency}
                city={city}
                region={region}
                description={description}
                locale={locale}
                loading={loading}
                name={name}
                state={state}
                listedUtc={listedUtc}
                createdUtc={createdUtc}
                soldUtc={soldUtc}
                nonprofitName={nonprofitName}
                donorName={donorName}
                isLocalPickupAvailable={isLocalPickupAvailable}
                isShippingAvailable={isShippingAvailable}
                setInStoreDeliveryType={setInStoreDeliveryType}
                currentPrice={currentPrice}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    )
  }
