import { call, takeEvery, put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  getUser,
  getUserSuccess,
  getUserFailure,
  updateUserInfo,
  updateUserInfoSuccess,
  updateUserInfoFailed,
  GetUserError,
} from './reducer'
import { auth } from '../../services/api/auth'
import { ResponseServerError } from '../types'
import {trackException} from '../app-insights/reducer'

export function* getUserInfoRequest(): SagaIterator {
  try {
    const userInfo = yield call(auth.me)
    yield put(getUserSuccess(userInfo))
  } catch (e) {
    yield put(
      getUserFailure((e as ResponseServerError<GetUserError>).response.data)
    )
    yield put(trackException({e, source: 'getUserInfoRequest'}))
  }
}

export function* updateUserInfoRequest({
  payload,
}: ReturnType<typeof updateUserInfo>): SagaIterator {
  try {
    yield call(auth.update, payload)
    const userInfo = yield call(auth.me)
    yield put(getUserSuccess(userInfo))
    yield put(updateUserInfoSuccess())
  } catch (e) {
    yield put(updateUserInfoFailed())
    yield put(trackException({e, source: 'updateUserInfoRequest'}))
  }
}

export function* authSaga(): Generator {
  yield takeEvery(getUser, getUserInfoRequest)
 yield takeEvery(updateUserInfo, updateUserInfoRequest)
}
