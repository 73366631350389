import { createSlice } from '@reduxjs/toolkit'
import { LoadingState } from '../types'
import {
  GetUserFailure,
  Login,
  ForgotPassword,
  LoginFailure,
  ForgotPasswordFailure,
  Register,
  RegisterFailure,
  RegisterSuccess,
  SetLoginModalOpen,
  SetForgotPasswordModalOpen,
  SetRegisterModal,
  SetRegisterModalOpen,
  User,
} from './contracts'
import {
  InitialActivateStatusType,
  ResetPasswordModalStateAction,
} from './types'
import { ResetPasswordVisibleStateType } from '../../services/api/auth/models'

export type RequestError = {
  code: number
  message: string
}

export type GetUserError = {
  status: number
  title: string
  traceId: string
  type: string
}

export enum ACTIVATE_STATUS {
  NOT_ACTIVATED,
  SUCCESS,
  FAILED,
}

const userInitialState: User = {
  userId: 0,
  email: '',
  name: '',
  identityProvider: undefined
}

const setAppInsightsUserContext = (user: User | null) => {
  if (user != null) {
    window.appInsights.setAuthenticatedUserContext(user.userId.toString())
  } else {
    window.appInsights.clearAuthenticatedUserContext()
  }
}

const initialActivateStatus: InitialActivateStatusType = {
  isSubmitting: true,
  status: ACTIVATE_STATUS.NOT_ACTIVATED,
  error: null,
}

const initialResetPasswordActivateStatus: ResetPasswordVisibleStateType = {
  isOpen: true,
  isSubmitting: false,
  error: null,
}

const initialState = {
  registerLoadingState: LoadingState.NEVER,
  error: false,
  registerModal: {
    isOpen: false,
    isSubmitting: false,
  },
  loginLoadingState: LoadingState.NEVER,
  loginModal: {
    isOpen: false,
    isSubmitting: false,
  },
  forgotPasswordLoadingState: LoadingState.NEVER,
  forgotPasswordModal: {
    isOpen: false,
    isSubmitting: false,
    isSuccess: false,
  },
  checkedAuth: false,
  authenticated: false,
  status: 0,
  logoutProcess: false,
  showForgotPasswordLink: true,
  showRegisterLink: true,
  logoutStatus: {
    error: false,
    text: '',
  },
  user: {
    ...userInitialState,
  },
  updateLoadingState: LoadingState.NEVER,
  accountForm: {
    isSubmitting: false,
    nonUpdated: false,
  },
  signAsTitle: '',
  errors: [] as RequestError[],
  getUserError: {} as GetUserError,
  activateStatus: { ...initialActivateStatus },
  resetPasswordStatus: { ...initialResetPasswordActivateStatus },
  redirectTo: {
    status: false,
    to: '/',
  },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    setRegisterModalOpen(state, action: SetRegisterModalOpen) {
      state.registerModal.isOpen = action.payload
    },
    setRegisterModal(state, action: SetRegisterModal) {
      state.registerModal = action.payload
    },
    switchToForgotPasswordModal(state) {
      state.registerModal.isOpen = false
      state.loginModal.isOpen = false
      state.forgotPasswordModal.isOpen = true
    },
    switchToRegisterModal(state) {
      state.registerModal.isOpen = true
      state.loginModal.isOpen = false
      state.forgotPasswordModal.isOpen = false
    },
    setLoginModalOpen(state, action: SetLoginModalOpen) {
      state.loginModal.isOpen = action.payload
    },
    setForgotPasswordModalOpen(state, action: SetForgotPasswordModalOpen) {
      state.forgotPasswordModal.isOpen = action.payload
    },
    switchToLoginModal(state) {
      state.loginModal.isOpen = true
      state.registerModal.isOpen = false
      state.forgotPasswordModal.isOpen = false
    },
    register(state, action: Register) {
      state.registerLoadingState = LoadingState.LOADING
      state.registerModal.isSubmitting = true
    },
    registerSuccess(state, action: RegisterSuccess) {
      state.registerLoadingState = LoadingState.LOADED
      state.user = action.payload
      setAppInsightsUserContext(action.payload)
    },
    registerFailure(state, action: RegisterFailure) {
      state.registerLoadingState = LoadingState.ERROR
      state.authenticated = false
      state.registerModal = { isOpen: true, isSubmitting: false }
      state.errors = action.payload
    },
    login(state, action: Login) {
      state.loginLoadingState = LoadingState.LOADING
      state.loginModal.isSubmitting = true
    },
    forgotPassword(state, action: ForgotPassword) {
      state.forgotPasswordLoadingState = LoadingState.LOADING
      state.forgotPasswordModal.isSubmitting = true
    },
    forgotPasswordSuccess(state) {
      state.forgotPasswordLoadingState = LoadingState.LOADED
      state.forgotPasswordModal = {
        isOpen: true,
        isSubmitting: false,
        isSuccess: true,
      }
    },
    loginSuccess(state, action) {
      state.loginLoadingState = LoadingState.LOADED
      state.authenticated = true
      state.user = action.payload
      state.signAsTitle = action.payload.firstName
      setAppInsightsUserContext(action.payload)
      state.loginModal = {
        isOpen: false,
        isSubmitting: false,
      }
      state.registerModal = {
        isOpen: false,
        isSubmitting: false,
      }
      state.forgotPasswordModal = {
        isOpen: false,
        isSubmitting: false,
        isSuccess: false,
      }
      state.status = action.payload.status
    },

    loginFailure(state, action: LoginFailure) {
      state.loginLoadingState = LoadingState.ERROR
      state.authenticated = false
      state.loginModal = { isOpen: true, isSubmitting: false }
      state.errors = action.payload
    },
    forgotPasswordFailure(state, action: ForgotPasswordFailure) {
      state.forgotPasswordLoadingState = LoadingState.ERROR
      state.authenticated = false
      state.forgotPasswordModal = {
        isOpen: true,
        isSubmitting: false,
        isSuccess: false,
      }
      state.errors = action.payload
    },
    setErrorsArrayEmpty(state) {
      state.errors = []
    },
    logout(state) {
      state.logoutProcess = true
    },
    logoutSuccess(state) {
      state.authenticated = false
      state.user = userInitialState
      state.logoutProcess = false
      setAppInsightsUserContext(null)
    },
    logoutFailure(state) {
      state.logoutProcess = false
    },
    getUser(state) {},
    getUserSuccess(state, action) {
      state.authenticated = true
      state.user = action.payload
      state.checkedAuth = true
      state.status = action.payload.status
      state.signAsTitle = action.payload.firstName
      setAppInsightsUserContext(action.payload)
    },
    getUserFailure(state, action: GetUserFailure) {
      state.getUserError = action.payload
      state.checkedAuth = true
      setAppInsightsUserContext(null)
    },
    setUserInfo(state, action) {
      state.user = action.payload
    },
    setEmail(state, action) {
      state.user = {
        ...state.user,
        email: action.payload,
      }
    },
    setNonUpdatesStatus(state) {
      state.accountForm.nonUpdated = true
    },
    updateUserInfo(state, action) {
      state.updateLoadingState = LoadingState.LOADING
      state.accountForm.isSubmitting = true
    },
    updateUserInfoSuccess(state) {
      state.updateLoadingState = LoadingState.LOADED
      state.accountForm.isSubmitting = false
      state.accountForm.nonUpdated = false
    },
    updateUserInfoFailed(state) {
      state.updateLoadingState = LoadingState.ERROR
      state.accountForm.isSubmitting = false
    },
    activate(state, action) {
      state.activateStatus = {
        ...initialActivateStatus,
        isSubmitting: true,
      }
    },
    activateSuccess(state, action) {
      state.activateStatus = {
        ...initialActivateStatus,
        isSubmitting: false,
        status: ACTIVATE_STATUS.SUCCESS,
      }
    },
    activateFailed(state, action) {
      state.activateStatus = {
        ...initialActivateStatus,
        isSubmitting: false,
        status: ACTIVATE_STATUS.FAILED,
        error: action.payload,
      }
    },
    setResetPasswordModal(state, action: ResetPasswordModalStateAction) {
      state.resetPasswordStatus = {
        ...initialResetPasswordActivateStatus,
        isOpen: action.payload.isOpen,
        isSubmitting: action.payload.isSubmitting,
      }
    },
    resetPassword(state, action) {
      state.resetPasswordStatus = {
        ...initialResetPasswordActivateStatus,
        isSubmitting: true,
      }
    },
    resetPasswordSuccess(state, action) {
      state.resetPasswordStatus = {
        ...initialResetPasswordActivateStatus,
        isSubmitting: false,
        isOpen: false,
      }
      state.resetPasswordStatus.isOpen = false
      state.loginModal.isOpen = true
      state.redirectTo = {
        status: true,
        to: '/',
      }
    },
    resetPasswordFailure(state, action) {
      state.resetPasswordStatus = {
        ...initialResetPasswordActivateStatus,
        isSubmitting: false,
        error: action.payload,
      }
      state.errors = action.payload
    },
    setFooterAuthLinksVisible(state, action) {
      state.showRegisterLink = action.payload
      state.showForgotPasswordLink = action.payload
    },
  },
})

export const authReducer = authSlice.reducer
export const {
  login,
  loginSuccess,
  loginFailure,
  logout,
  logoutSuccess,
  logoutFailure,
  getUser,
  getUserSuccess,
  getUserFailure,
  setUserInfo,
  updateUserInfo,
  updateUserInfoFailed,
  updateUserInfoSuccess,
  setNonUpdatesStatus,
  activate,
  activateSuccess,
  activateFailed,
  setEmail,
  setErrorsArrayEmpty,
  setFooterAuthLinksVisible,
} = authSlice.actions
