import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProsphoraLogoSvg } from '../../assets/svg/prosphora-logo-svg'
import { useAboutPageStyles } from './about-page.styles'

export const AboutPage: React.FC = () => {
  const { t } = useTranslation()
  const classes = useAboutPageStyles()

  return (
    <div className={classes.aboutPageContainer}>
      <h1 className={classes.title}>{t('aboutPage.title')}</h1>
      <ProsphoraLogoSvg />
      <p className={classes.text}>{t('aboutPage.mainText')}</p>
    </div>
  )
}
