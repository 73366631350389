import React from 'react'
import { AsyncAutocompleteProps } from '../../pages/donate-page/donate.models'
import { TextField, CircularProgress } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import {useDispatch} from 'react-redux'
import {trackException} from '../../store/app-insights/reducer'

export const AsyncAutocomplete: React.FC<AsyncAutocompleteProps> = (props) => {
  const { t } = useTranslation()

  const {
    label,
    options,
    setOptions,
    request,
    selectedOption,
    setSelectedOption,
    disabled,
    noOptionsText = t('select.defaultNoOptionTitle'),
    required,
  } = props

  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const loading = open && options.length === 0

  React.useEffect(() => {
    loading &&
      request().then((options) =>
        options ? setOptions(options) : setOpen(false)
      ).catch(e => dispatch(trackException({e, source: 'loadingAutocomplete'})))
  }, [open])

  const openHandler = () => {
    setOpen(true)
  }

  return (
    <Autocomplete
      disabled={disabled}
      /*@ts-ignore*/
      style={{ marginBottom: 15 }}
      onChange={(event, value) => {
        setSelectedOption && setSelectedOption(value)
      }}
      open={open}
      onOpen={() => openHandler()}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      noOptionsText={noOptionsText}
      value={selectedOption || { name: '' }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
