import React, { useEffect, useRef, useState } from 'react'
import { Badge, Button, CircularProgress, Typography } from '@material-ui/core'
import {
  CurrencyList,
  currencyTransform,
  Locale,
} from '../../../../utils/currency-transform'
import { ItemCondition, ItemState } from '../../../../models'
import { useNavigate, useParams } from 'react-router-dom'
import { transformToMultiLine } from '../../../../utils/strings'
import { useTranslation } from 'react-i18next'
import { useScreenSize } from '../../../../hooks/use-screen-size'
import Skeleton from '@material-ui/lab/Skeleton'
import { DeliveryType } from '../../../../enums'
import { axiosInstance } from '../../../../api'
import { useAppDialog } from '../../../../hooks/use-app-dialog'
import { AppDialogVariants } from '../../../../store/app-dialog/types'
import { InfoComponentProps } from './info-component.types'
import { useInfoComponentStyles } from './info-component.styles'
import { ExpandableText } from './expandable-text'
import { getDateByState, getDateTitle } from '../../../../utils/item-details'
import {useDispatch} from 'react-redux'
import {trackException} from '../../../../store/app-insights/reducer'

const expandableTextMinimumHeight = 404

export const InfoComponent: React.FC<InfoComponentProps> = (props) => {
  const classes = useInfoComponentStyles()
  const navigate = useNavigate()
  const {
    name,
    description = '',
    currentPrice,
    itemCondition,
    locale = Locale.US,
    currency = CurrencyList.USD,
    nonprofitName,
    listedUtc,
    createdUtc,
    soldUtc,
    donorName,
    state,
    city,
    region,
    loading,
    isShippingAvailable,
    setInStoreDeliveryType,
    onChangeMode,
    updateState,
  } = props

  const { t } = useTranslation()
  const screenSize = useScreenSize()
  const dispatch = useDispatch()
  const [isLongText, setIsLongText] = useState<boolean>(false)
  const textHolder = useRef<HTMLDivElement>(null)
  const [orderId, setOrderId] = useState<number | string>('')
  const { itemId } = useParams()
  const { setAppDialog, setIsShow } = useAppDialog()

  //If Item sold
  useEffect(() => {
    axiosInstance.get(`/listed-items/donor/${itemId}`).then(({ data }) => {
      setOrderId(data.orderId)
    }).catch(e => dispatch(trackException({e, source: 'ListedItemsDonorOrderId'})))
  }, [])

  //Check Long Text
  const checkLongText = () => {
    const textHolderHeight = textHolder.current!.offsetHeight
    textHolderHeight > expandableTextMinimumHeight
      ? setIsLongText(true)
      : setIsLongText(false)
  }

  /* Use Effect Check Long Text */
  useEffect(checkLongText, [screenSize, description])

  /* Use Effect Check when only single type*/
  useEffect(() => {
    setInStoreDeliveryType(DeliveryType.Shipping)
  }, [isShippingAvailable])

  //UnList Handler
  const changeStateHandler = (state: ItemState) => {
    setAppDialog({
      isShow: true,
      variant: AppDialogVariants.warning,
      title: t('editItemDetailsPage.unActiveItem.title'),
      message:
        state === ItemState.Unlisted
          ? t('editItemDetailsPage.unActiveItem.unList')
          : t('editItemDetailsPage.unActiveItem.toDraft'),
      actions: (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              updateState(state)
              setIsShow(false)
            }}
          >
            Ok
          </Button>
        </>
      ),
    })
  }

  /*To order*/
  const toOrder = () => {
    if (!orderId) {
      return
    }
    navigate(`/selling/order/${orderId}`)
  }

  return (
    <div className={classes.infoBlock}>
      <Typography className={classes.name} variant="h5" component="h1">
        {name ? name : <Skeleton animation="wave" width="70%" />}
        <div className={classes.listedTimeBlock}>
          {listedUtc ? (
            <>
              <span className={classes.timeLabel}>
                {getDateTitle(t, state)}:{' '}
              </span>
              <span className={classes.time}>
                {getDateByState({ listedUtc, createdUtc, soldUtc }, state)}
              </span>
            </>
          ) : (
            <Skeleton animation="wave" width="50%" />
          )}
        </div>
      </Typography>

      {/* Description */}
      <div>
        {loading ? (
          <Skeleton animation="wave" width="40%" />
        ) : (
          <Typography
            className={classes.descriptionTitle}
            variant="h6"
            component="h3"
          >
            {t('itemDetails.description')}
          </Typography>
        )}
        <div>
          <>
            <Typography
              className={classes.descriptionText}
              ref={textHolder}
              variant="body1"
              style={{ overflow: 'hidden', position: 'absolute', zIndex: -1 }}
              component="p"
            >
              {transformToMultiLine(description)}
            </Typography>
            {!isLongText && description && (
              <Typography
                className={classes.descriptionText}
                variant="body1"
                component="p"
              >
                {transformToMultiLine(description)}
              </Typography>
            )}
            {isLongText && <ExpandableText description={description} />}
          </>
          {!description && !loading && (
            <span className={classes.noDescription}>No description</span>
          )}
          {!description && loading && (
            <>
              <Skeleton animation="wave" width="80%" />
              <Skeleton animation="wave" width="85%" />
              <Skeleton animation="wave" width="90%" />
              <Skeleton animation="wave" width="75%" />
            </>
          )}
        </div>

        {/* Props Holder */}
        <div className={classes.propsHolder}>
          <div className={classes.propsBlock}>
            {nonprofitName ? (
              <>
                <span className={classes.propsLabel}>
                  {t('itemDetails.nonprofit')}:{' '}
                </span>
                <span className={classes.propsValue}>{nonprofitName}</span>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="75%" />
              </>
            )}
          </div>
          <div className={classes.propsBlock}>
            {donorName ? (
              <>
                <span className={classes.propsLabel}>
                  {t('itemDetails.donorName')}:{' '}
                </span>
                <span className={classes.propsValue}>{donorName}</span>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="70%" />
              </>
            )}
          </div>
          <div className={classes.propsBlock}>
            {city ? (
              <>
                <span className={classes.propsLabel}>
                  {t('itemDetails.location')}:{' '}
                </span>
                <span
                  className={classes.propsValue}
                >{`${city}, ${region}`}</span>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="70%" />
              </>
            )}
          </div>
        </div>
        {!loading && (
          <div className={classes.verticalAlign}>
            <div className={classes.topPart}>
              <Typography className={classes.price}>
                <span>
                  {currencyTransform(currentPrice, locale, {
                    style: 'currency',
                    currency,
                  })}
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      itemCondition === ItemCondition.New
                        ? t('itemDetails.new')
                        : t('itemDetails.used')
                    }
                    color={
                      itemCondition === ItemCondition.New
                        ? 'secondary'
                        : 'primary'
                    }
                    className={classes.priceNumber}
                  />
                </span>
              </Typography>
            </div>
          </div>
        )}

        {!loading && (
          <div className={classes.btnBlock}>
            {state === ItemState.Active && (
              <>
                <Button
                  variant="contained"
                  onClick={() => changeStateHandler(ItemState.Draft)}
                  className={classes.btn}
                  color="primary"
                >
                  {t('editItemDetailsPage.setToDraft')}
                </Button>
                <div className={classes.additionalActionBtnsBlock}>
                  {state === ItemState.Active && (
                    <Button
                      variant="contained"
                      onClick={() => onChangeMode(true)}
                      className={classes.btn}
                      color="default"
                    >
                      {t('editItemDetailsPage.edit')}
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    onClick={() => changeStateHandler(ItemState.Unlisted)}
                    color="default"
                  >
                    {t('editItemDetailsPage.unlist')}
                  </Button>
                </div>
              </>
            )}

            {state === ItemState.Draft && (
              <>
                <Button
                  variant="contained"
                  onClick={() => onChangeMode(true)}
                  className={classes.btn}
                  color="primary"
                >
                  {t('editItemDetailsPage.editDraft')}
                </Button>
              </>
            )}

            {state === ItemState.Sold && (
              <>
                <Button
                  onClick={() => toOrder()}
                  variant="contained"
                  className={classes.btn}
                  color="primary"
                >
                  {t('editItemDetailsPage.goToOrder')}
                </Button>
                <span className={classes.wasSoldLabel}>
                  {t('editItemDetailsPage.thisItemWasSold')}
                </span>
              </>
            )}

            {state === ItemState.Unlisted && (
              <>
                <span className={classes.wasSoldLabel}>
                  {t('editItemDetailsPage.unlistedItem')}
                </span>
              </>
            )}
          </div>
        )}

        {loading && (
          <div className={classes.btnBlock}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}
