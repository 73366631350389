import React from 'react'
import { AccountSvg } from '../../../../assets/svg/account-svg'
import { useAppHeaderRightMenuStyles } from '../app-header-right-menu.styles'
import { useMenuForAuthenticated } from './menu-for-authenticated.hook'
import { MyAccountMenu } from './my-account-menu'
import { AuthenticatedTemplate } from '@azure/msal-react'
import {useSelector} from 'react-redux'
import {selectUser} from '../../../../store/auth/selectors'

export const MenuForAuthenticated: React.FC = (): React.ReactElement => {
  const classes = useAppHeaderRightMenuStyles()
  const { anchorEl, setAnchorEl, myAccountClickHandler } = useMenuForAuthenticated()
  const user = useSelector(selectUser)

  return (
    <>
      <AuthenticatedTemplate>
        <div className={classes.hideForMobile}>
          <span onClick={myAccountClickHandler} className={classes.link}>
          <AccountSvg />
          <span className={classes.accountName}>{ user.name }</span>
        </span>
        </div>
        <MyAccountMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
      </AuthenticatedTemplate>
    </>
  )
}
