import { getErrorsFromSchema } from '../../../../hooks/use-form'
import {
  max,
  min,
  required,
} from '../../../../hooks/use-form/use-form.validators'

export const validateProfileInfo = getErrorsFromSchema({
  name: [required, min(1), max(501)],
})
