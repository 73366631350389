import { axiosInstance} from '../../../api'
import { RequestListedItemsProps, ResponseParams } from './types'

export const homePageItems = {
  async availableListedItems(
    query: RequestListedItemsProps
  ): Promise<ResponseParams> {
    return axiosInstance
      .get('listed-items/public', { params: { ...query } })
      .then(({ data }) => data)
  },

  async getMaxPrice(): Promise<number> {
    return axiosInstance.post<number>('listed-items/max-price').then(({ data }) => data)
  },
}
