import {
  useState,
  useEffect
} from 'react'
import {AuthError, InteractionType} from '@azure/msal-browser'
import {MsalAuthenticationResult, useMsal, useMsalAuthentication} from '@azure/msal-react'
import {msalRequest} from '../../env-config'

type UseMsalAccessTokenType = {
  accessMsalToken?: string
  isLoading: boolean
  error: AuthError | null
  updateTrigger:  boolean
  authMsalResult: MsalAuthenticationResult
}
export const useMsalAccessTokenProvider = () : UseMsalAccessTokenType => {

  /* State */
  const { instance } = useMsal()
  const [isLoading] = useState<boolean>(false)
  const [error, setError] = useState<null | AuthError>(null)
  const [accessMsalToken, setAccessMsalToken] = useState<string | undefined>(undefined)

  /* Data */
  const data = useMsalAuthentication(InteractionType.Silent, {
    ...msalRequest,
    account: instance.getActiveAccount()!,
    redirectUri: '/'
  })

  const {
    result,
    error: msalError,
  } = data as MsalAuthenticationResult


  useEffect(()=> {
    if(msalError){
      setError(msalError)
    } else {
      setAccessMsalToken(result?.accessToken)
    }

  }, [result])

  return {
    authMsalResult: data,
    accessMsalToken,
    isLoading,
    error,
    updateTrigger: !!data
  }
}
