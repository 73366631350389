import { SagaIterator } from 'redux-saga'
import {
  createOrder,
  createOrderError,
  createOrderSuccess,
  loadItemDetails,
  loadItemError,
  loadItemSuccess,
  loadAddressData,
  loadAddressDataSuccess,
  setAddress,
  setAddressSuccess,
  getShippmentRateSuccess,
  getShippmentRateFailed,
  getShippmentRate,
  setAddressError,
} from './reducer'
import { put, takeEvery } from 'redux-saga/effects'
import * as Effects from 'redux-saga/effects'
import { listedItemsApi } from '../../services/api/listed-items'
import {
  ApiError,
  ApiErrorDataContent,
} from '../../services/api/api-error-data'
import { ordersApi } from '../../services/api/orders'
import { generalDataApi } from '../../services/api/general-data'
import { accountApi } from '../../services/api/account'
import { AxiosError } from 'axios'
import {trackException} from '../app-insights/reducer'

const call: any = Effects.call

export function* loadItemDetailsSaga({
  payload,
}: ReturnType<typeof loadItemDetails>): SagaIterator {
  try {
    const data = yield call(listedItemsApi.readDetails, payload)
    yield put(loadItemSuccess(data))
  } catch (reason) {
    yield call(dispatchError, loadItemError, reason)
    yield put(trackException({e: reason, source: 'loadItemDetailsSaga'}))
  }
}

export function* createOrderSaga({
  payload,
}: ReturnType<typeof createOrder>): SagaIterator {
  try {
    const data = yield call(ordersApi.create, payload)
    yield put(createOrderSuccess(data))
  } catch (reason) {
    yield call(dispatchError, createOrderError, reason)
    yield put(trackException({e: reason, source: 'createOrderSaga'}))
  }
}

export function* getAddressData({
  payload,
}: ReturnType<typeof loadAddressData>): SagaIterator {
  try {
    const regions = yield call(generalDataApi.getRegionsAsync, payload)
    const address = yield call(accountApi.getShippingAsync)
    yield put(loadAddressDataSuccess({ regions, address }))
  } catch (reason) {
    yield put(trackException({e: reason, source: 'getAddressData'}))
  }
}

export function* setAddressData({
  payload,
}: ReturnType<typeof setAddress>): SagaIterator {
  try {
    const data = yield call(accountApi.setShippingAsync, payload)
    yield put(setAddressSuccess(data))
  } catch (reason) {
    yield put(setAddressError(reason as ApiErrorDataContent))
    yield put(trackException({e: reason, source: 'setAddressData'}))
  }
}

//Get Order Rate
export function* getShippmentRateSaga({
  payload,
}: ReturnType<typeof getShippmentRate>): SagaIterator {
  try {
    const response = yield call(ordersApi.getShippmentRate, payload)
    yield put(
      getShippmentRateSuccess({
        rates: response.rates,
        messages: response.messages,
      })
    )
  } catch (reason) {
    yield put(getShippmentRateFailed(reason as AxiosError))
    yield put(trackException({e: reason, source: 'getShippmentRateSaga'}))
  }
}

// general method to dispatch any methods with ApiError argument
function* dispatchError(
  method: (error: ApiError) => any,
  reason: any
): SagaIterator {
  yield put(method(reason instanceof ApiError ? reason : new ApiError(reason)))
}

export function* checkoutPageSaga(): SagaIterator {
  yield takeEvery(loadItemDetails, loadItemDetailsSaga)
  yield takeEvery(createOrder, createOrderSaga)
  yield takeEvery(loadAddressData, getAddressData)
  yield takeEvery(setAddress, setAddressData)
  yield takeEvery(getShippmentRate, getShippmentRateSaga)
}
