import React, { useState } from 'react'
import { FilterProps } from './filter.constants'
import { useFilterStyles } from './filter.styles'
import { useTranslation } from 'react-i18next'
import FilterListIcon from '@material-ui/icons/FilterList'
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Slider,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { ScreenSize } from '../../settings/device'
import { useFilterHook } from './filter.hooks'

export type StateListItemType = {
  state: string 
  value: string | number
}

export type StateListType = StateListItemType[]

enum InputNames {
  New = 'new',
  Used = 'used',
  Slider = 'slider',
  Min = 'min',
  Max = 'max',
  Select = 'select',
  Text = 'search',
  State = 'state',
}

enum PriceInput {
  Left = 'left',
  Right = 'right',
}

export const Filter: React.FC<FilterProps> = (props: FilterProps) => {
  const classes = useFilterStyles()
  const { t } = useTranslation()
  const [STATE_LIST] = useState<StateListType>([
    // {state: t('filter.state.draft'), value: '0'},
    { state: t('filter.state.active'), value: '1' },
    { state: t('filter.state.sellPending'), value: '2' },
    { state: t('filter.state.sold'), value: '3' },
    { state: t('filter.state.unlisted'), value: '4' },
  ])

  const {
    //props
    screenSize,
    initialMaxPrice,
    initialMinPrice,
    loading,
    condition,
    priceValue,
    cancelHandler,
    categories,
    nonprofits,
    nonprofitCategoryId,
    nonprofitId,
    searchText,
    itemsState, //Draft=0, Active = 1, SellPending = 2, Sold = 3, Unlisted = 4
    settings = {},

    //methods
    isIncorrectPrice,
    setCondition,
    sliderChangeHandler,
    onChangeCommitted,
    textFieldHandler,
    textFieldBlurHandler,
    searchTextFieldBlurHandler,
    searchTextFieldEnterHandler,
    searchTextFieldFocusHandler,
    handleChangeCategoryHandler,
    handleChangeNonprofityHandler,
    toggleDrawer,
    applyBtnHandler,
    handleSearchTextHandler,
    changeItemsState,

    //state
    onFocus,
    drawerStatus,
    btnDisabled,

    //state methods
    setOnfocus,
  } = useFilterHook({
    ...props,
  })

  const isCorrectPriceParams =
    priceValue &&
    priceValue.filter(Number.isFinite).length === 2 &&
    initialMaxPrice

  const { hideCategory, hideNonprofit } = settings

  const filterWrapper = () => {
    return (
      <CardContent
        id="filter-card-content"
        className={`${
          screenSize === ScreenSize.Mobile ? classes.filterWrapper : ''
        }`}
      >
        {
          <div className={`${classes.filterBody}`}>
            {screenSize !== ScreenSize.Desktop && (
              <span className={classes.closeBtn} onClick={toggleDrawer(false)}>
                <CloseIcon />
              </span>
            )}
            <div
              className={
                screenSize === ScreenSize.Desktop
                  ? classes.searchTextHolder
                  : classes.searchTextHolderMobile
              }
            >
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label={t('filter.searchFormLabel')}
                  name={InputNames.Text}
                  value={searchText}
                  disabled={loading}
                  id={'filter-searchText'}
                  fullWidth
                  variant="outlined"
                  inputProps={{ 'data-testid': 'search' }}
                  onChange={(e) => handleSearchTextHandler(e.target.value)}
                  onFocus={() => searchTextFieldFocusHandler()}
                  onBlur={() => searchTextFieldBlurHandler()}
                  onKeyUpCapture={(e) => searchTextFieldEnterHandler(e)}
                />
              </FormControl>
            </div>
            {condition && (
              <div className={classes.checkboxHolder}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      disabled={loading}
                      checked={condition[0]}
                      name={InputNames.New}
                      onChange={(e, id) => setCondition(id, 0)}
                      style={{ paddingTop: 0, paddingBottom: 0 }}
                    />
                  }
                  label={t('filter.new')}
                  labelPlacement="end"
                />
                <br />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={condition[1]}
                      disabled={loading}
                      name={InputNames.Used}
                      onChange={(e, value) => setCondition(value, 1)}
                    />
                  }
                  label={t('filter.used')}
                  labelPlacement="end"
                />
              </div>
            )}

            {isCorrectPriceParams && (
              <div className={classes.rangeHolder}>
                <Typography className={classes.priceTitle}>
                  {t('filter.price')}, ($):
                </Typography>
                <Slider
                  value={priceValue}
                  disabled={loading}
                  name={InputNames.Slider}
                  min={initialMinPrice}
                  max={initialMaxPrice}
                  onChangeCommitted={onChangeCommitted}
                  className={classes.slider}
                  onChange={sliderChangeHandler}
                  valueLabelDisplay="off"
                  aria-labelledby="range-slider"
                />
                <div className={classes.inputPriceBlock}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <CurrencyTextField
                        label={t('filter.min')}
                        variant="outlined"
                        value={priceValue[0]}
                        currencySymbol="$"
                        size="small"
                        onFocus={() => setOnfocus(true)}
                        name={InputNames.Min}
                        outputFormat="number"
                        className={classes.priceInput}
                        onChange={(event: React.ChangeEvent, value: number) =>
                          textFieldHandler(event, value, PriceInput.Left)
                        }
                        onBlur={() => textFieldBlurHandler()}
                        maximumValue={`${initialMaxPrice}`}
                        minimumValue={`${initialMinPrice}`}
                        error={isIncorrectPrice()}
                        helperText={
                          isIncorrectPrice() &&
                          !onFocus &&
                          t('filter.priceError')
                        }
                        disabled={loading}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CurrencyTextField
                        label={t('filter.max')}
                        variant="outlined"
                        value={priceValue[1]}
                        currencySymbol="$"
                        size="small"
                        name={InputNames.Max}
                        onFocus={() => setOnfocus(true)}
                        outputFormat="number"
                        className={classes.priceInput}
                        onChange={(event: React.ChangeEvent, value: number) =>
                          textFieldHandler(event, value, PriceInput.Right)
                        }
                        onBlur={() => textFieldBlurHandler()}
                        maximumValue={`${initialMaxPrice}`}
                        minimumValue={`${initialMinPrice}`}
                        error={isIncorrectPrice()}
                        disabled={loading}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            )}
            {!hideCategory && (
              <div
                className={classes.lookupHolderCategory}
                id="filter-category-wrapper"
              >
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    value={
                      categories.filter(
                        (item) =>
                          item!.nonprofitCategoryId! === nonprofitCategoryId
                      )[0] || null
                    }
                    id={'filter-categories'}
                    options={categories}
                    disabled={!categories.length || loading}
                    autoHighlight
                    getOptionLabel={(option) => option.name as string}
                    onChange={(e, value) => handleChangeCategoryHandler(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('filter.selectCategory')}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}

            {!hideNonprofit && (
              <div
                className={classes.lookupHolderNonprofit}
                id="filter-nonprofits-wrapper"
              >
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    value={
                      nonprofits.filter(
                        (item) => item.nonprofitId! === nonprofitId
                      )[0] || null
                    }
                    id={'filter-nonpofits'}
                    options={nonprofits}
                    disabled={
                      !nonprofitCategoryId || !nonprofits.length || loading
                    }
                    autoHighlight
                    getOptionLabel={(option) => option.name as string}
                    onChange={(e, value) =>
                      handleChangeNonprofityHandler(value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select nonprofit"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}

            {/* ITEMS STATE*/}
            {(itemsState || itemsState === null) && (
              <div
                id="filter-states-wrapper"
                className={classes.lookupHolderNonprofit}
              >
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    value={
                      STATE_LIST.filter(
                        (item) => item.value! === itemsState
                      )[0] || null
                    }
                    id={'filter-states'}
                    options={STATE_LIST}
                    disabled={loading}
                    autoHighlight
                    getOptionLabel={(option) => option.state as string}
                    //@ts-ignore
                    onChange={(_, value) => changeItemsState(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t('myListingPage.selectItemState')}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </div>
            )}

            {/* Mobile Mode */}
            {screenSize === ScreenSize.Mobile && (
              <div>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={applyBtnHandler}
                  style={{ marginBottom: 10 }}
                  disabled={btnDisabled || isIncorrectPrice()}
                >
                  {t('filter.apply')}
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  disabled={btnDisabled || isIncorrectPrice()}
                  onClick={cancelHandler}
                >
                  {t('filter.cancel')}
                </Button>
              </div>
            )}
          </div>
        }
      </CardContent>
    )
  }

  return (
    <>
      <Card className={`${classes.filterHolder}`} id="filter-card">
        {screenSize !== ScreenSize.Desktop && (
          <div onClick={toggleDrawer(true)} className={classes.openBtn}>
            <FilterListIcon />
          </div>
        )}
        {screenSize !== ScreenSize.Unknown &&
        screenSize === ScreenSize.Desktop ? (
          filterWrapper()
        ) : (
          <SwipeableDrawer
            id="filter-drawer"
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            open={drawerStatus}
            anchor="left"
          >
            {filterWrapper()}
          </SwipeableDrawer>
        )}
      </Card>
    </>
  )
}
