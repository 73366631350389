import React from 'react'
import { useDeliveryTypeSelectStyles } from './delivery-type-select.styles'
import { useDeliveryTypeSelect } from './delivery-type-select.hook'
import { DeliveryType } from '../../../enums/delivery-type'
import { AddressBlock } from '../address-block'
import { ShipmentRateBlock } from '../shippment-rate-block'
import { useTranslation } from 'react-i18next'

export const DeliveryTypeSelect: React.FC = (): React.ReactElement => {
  const classes = useDeliveryTypeSelectStyles()
  const { t } = useTranslation()
  const { deliveryType, isValidAddress } = useDeliveryTypeSelect()

  // eslint-disable-next-line functional/no-let
  let shippingComponent: React.ReactElement
  switch (deliveryType) {
    case DeliveryType.Shipping:
      shippingComponent = <></>
      break

    default:
      throw new Error('Unknown shipping method selected')
  }

  return (
    <div className={classes.root}>
      {deliveryType === DeliveryType.Shipping && (
        <>
          <div
            className={`${[classes.selectArea, classes.addressArea].join(' ')}`}
          >
            <span
              className={`${[classes.label, classes.addressLabel].join(' ')}`}
            >
              {t('checkout.address')}:{' '}
            </span>
            <AddressBlock />
          </div>
        </>
      )}

      {deliveryType === DeliveryType.Shipping && isValidAddress && (
        <>
          <div
            className={`${[classes.selectArea, classes.addressArea].join(' ')}`}
          >
            <span
              className={`${[
                classes.label,
                classes.addressLabel,
                classes.ratesLabel,
              ].join(' ')}`}
            >
              {t('checkout.rate')}:{' '}
            </span>
            <ShipmentRateBlock />
          </div>
        </>
      )}

      <div>{shippingComponent}</div>
    </div>
  )
}
