import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useFilterStyles = makeStyles(() =>
  createStyles({
    filterHolder: {
      position: 'relative',
      '&  .MuiCardContent-root:last-child': {
        paddingBottom: '9px !important',
      },
    },
    closeBtn: {
      cursor: 'pointer',
      position: 'absolute',
      top: '0px',
      right: '0',
    },
    openBtn: {
      cursor: 'pointer',
      textAlign: 'center',
      height: 56,
      lineHeight: '66px',
      minWidth: 90,
    },
    filterBody: {
      position: 'relative',
      paddingTop: '10px',
    },
    filterWrapper: {
      width: '100vw',
    },
    checkboxHolder: {
      marginBottom: '10px',
      textAlign: 'left',
    },
    rangeHolder: {
      marginBottom: '10px',
    },
    slider: {
      marginBottom: '15px',
    },
    inputPriceBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '15px',
    },
    loaderBlock: {
      position: 'absolute',
      background: 'rgba(255,255,255, 0.8)',
      display: 'flex',
      left: 0,
      top: 0,
      bottom: 0,
      padding: 50,
      right: 0,
      justifyContent: 'center',
      zIndex: 999999999999,
    },
    loaderBlockCollapsed: {
      background: 'white',
      padding: 7,
    },
    priceTitle: {
      textAlign: 'left',
    },
    priceInput: {
      maxWidth: '100%',
    },
    fullscreen: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    lookupHolderCategory: {
      marginBottom: 15,
    },
    lookupHolderNonprofit: {
      marginBottom: 15,
    },
    searchTextHolder: {
      marginBottom: 15,
    },
    searchTextHolderMobile: {
      marginTop: 30,
      marginBottom: 15,
    },
    openInMobile: {
      zIndex: 9999,
    },
  })
)
