import React from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { UseForm } from '../../../hooks/use-form'
import {
  DeliveryType,
  validateDonateDetails,
  optionalValidatorCondition,
} from './donate-details.constants'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useDonateStyles } from '../donate.styles'
import { useDonateDetails } from './donate-details.hook'
import { useTranslation } from 'react-i18next'

type DonateDetailsProps = {
  donateDetails: any
  formValueChanges: any
  isAuth?: boolean
  isAuthRequiredHandler?: () => void
}

export const DonateDetails: React.FC<DonateDetailsProps> = (
  props
): React.ReactElement => {
  const { donateDetails, formValueChanges, isAuth, isAuthRequiredHandler } =
    props
  const classes = useDonateStyles()
  const { checkOptionalHandler } = useDonateDetails()
  const { t } = useTranslation()

  return (
    <UseForm
      initialValues={donateDetails}
      validate={validateDonateDetails}
      formValueChanges={formValueChanges}
      onSubmit={() => {
        return
      }}
      isAuth={isAuth}
      checkOptionalHandler={checkOptionalHandler}
      checkOptionalObject={optionalValidatorCondition}
      isAuthrequiredHandler={isAuthRequiredHandler}
    >
      {({ errors, inputHandlers, handleSubmit, form, onCurrencyChange }) => (
        <form noValidate onSubmit={handleSubmit}>
          {/*title*/}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                error={!!errors?.title && form.title.dirty}
                helperText={
                  form.title.dirty && !form.title.focused && errors.title
                }
                value={donateDetails.title}
                name="title"
                variant="outlined"
                fullWidth
                label="Title"
                autoFocus
                {...inputHandlers}
              />
            </Grid>

            {/*description*/}
            <Grid item xs={12} sm={12}>
              <TextField
                multiline={true}
                error={!!errors?.description && form.description.dirty}
                helperText={
                  form.description.dirty &&
                  !form.description.focused &&
                  errors.description
                }
                value={donateDetails.description}
                variant="outlined"
                fullWidth
                label="Description"
                name="description"
                {...inputHandlers}
              />
            </Grid>

            {/*price*/}
            <Grid item xs={12} sm={4}>
              <CurrencyTextField
                error={!!errors?.price && form.price.dirty}
                helperText={t('donation.formErrors.price')}
                value={donateDetails.price}
                variant="outlined"
                fullWidth
                label="Price"
                name="price"
                onBlur={inputHandlers.onBlur}
                onFocus={inputHandlers.onFocus}
                onChange={onCurrencyChange}
              />
            </Grid>

            {/*condition*/}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth onChange={inputHandlers.onChange}>
                <FormLabel style={{ textAlign: 'start' }} required>
                  {t('donation.condition')}
                </FormLabel>
                <RadioGroup
                  row
                  aria-label="condition"
                  name="condition"
                  value={donateDetails.condition}
                >
                  <FormControlLabel
                    value="new"
                    control={<Radio color="primary" />}
                    label={t('donation.new')}
                  />
                  <FormControlLabel
                    value="used"
                    control={<Radio color="primary" />}
                    label={t('donation.used')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/*shipping details*/}
            {(donateDetails.delivery === DeliveryType.Shipping ||
              donateDetails.delivery === DeliveryType.Both) && (
              <Grid item xs={12} className={classes.additionalBlock}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormLabel component="legend" style={{ textAlign: 'left' }}>
                      {t('donation.additionalInformation')}{' '}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      error={!!errors?.width && form.width.dirty}
                      helperText={
                        form.width.dirty && !form.width.focused && errors.width
                      }
                      value={donateDetails.width}
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="width"
                      label="Width, inches"
                      {...inputHandlers}
                      onBlur={inputHandlers.onBlur}
                      onFocus={inputHandlers.onFocus}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      error={!!errors?.height && form.height.dirty}
                      helperText={
                        form.height.dirty &&
                        !form.height.focused &&
                        errors.height
                      }
                      value={donateDetails.height}
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="height"
                      label="Height, inches"
                      {...inputHandlers}
                      onBlur={inputHandlers.onBlur}
                      onFocus={inputHandlers.onFocus}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      error={!!errors?.length && form.length.dirty}
                      helperText={
                        form.length.dirty &&
                        !form.length.focused &&
                        errors.length
                      }
                      value={donateDetails.length}
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="length"
                      label="Length, inches"
                      {...inputHandlers}
                      onBlur={inputHandlers.onBlur}
                      onFocus={inputHandlers.onFocus}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      error={!!errors?.weight && form.weight.dirty}
                      helperText={
                        form.weight.dirty &&
                        !form.weight.focused &&
                        errors.weight
                      }
                      value={donateDetails.weight}
                      type="number"
                      variant="outlined"
                      fullWidth
                      name="weight"
                      label="Weight, pounds"
                      {...inputHandlers}
                      onBlur={inputHandlers.onBlur}
                      onFocus={inputHandlers.onFocus}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      )}
    </UseForm>
  )
}
