export * from './testing'
export * from './window-api'
export * from './dates'

export const getDictionary =
  <T extends Record<string, any>, M extends keyof T>(fieldName: M) =>
  (array: T[]): T =>
    array.reduce(
      (previousValue: T, currentValue: T): T => ({
        ...previousValue,
        [currentValue[fieldName]]: currentValue,
      }),
      {} as T
    )

export const getDictionaryById = getDictionary('id')

export const isString = (value: unknown) =>
  typeof value === 'string' ||
  value instanceof String ||
  Object.prototype.toString.call(value) === '[object String]'
export const isNumber = (value: unknown): boolean => Number.isFinite(value)
export const ifElse = (condition: boolean, onTrue: unknown, onFalse: unknown) =>
  condition ? onTrue : onFalse

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// @ts-ignore
export const curry = (fn, ...par) => {
  // @ts-ignore
  const curried = (...args) =>
    fn.length > args.length ? curry(fn.bind(null, ...args)) : fn(...args)
  return par.length ? curried(...par) : curried
}

export const omit = <T, K extends string>(
  object: T,
  omit: K
): Omit<T, keyof K> =>
  Object.fromEntries(
    Object.entries(object).filter(([key]) => key !== omit)
  ) as Omit<T, keyof K>

export const switchCase =
  (cases: { readonly [key: string]: any }) =>
  (defaultCase: any) =>
  (key: string) =>
    cases.hasOwnProperty(key) ? cases[key] : defaultCase
