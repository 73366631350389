import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useDateSortStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortDateHolder: {
      position: 'relative',
    },
    sortDateItem: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      cursor: 'pointer',
      display: 'inline-block',
      borderRadius: `${theme.spacing(0.4)}px`,
      '& svg': {
        fontSize: '1rem',
        verticalAlign: 'middle',
      },
    },
    active: {
      background: theme.palette.primary['main'],
      color: 'white',
    },
    nameLabel: {
      display: 'inline-block',
      marginRight: 5,
    },
  })
)
