import React, { useEffect, useState, useRef } from 'react'
import { Badge, Button, Typography } from '@material-ui/core'
import {
  CurrencyList,
  currencyTransform,
  Locale,
} from '../../../../utils/currency-transform'
import { InfoComponentProps } from './info-component.types'
import { ItemCondition, ItemState } from '../../../../models/item-details'
import { useInfoComponentStyles } from './info-component.styles'
import { useNavigate } from 'react-router-dom'
import { ExpandableText } from './expandable-text'
import { transformToMultiLine } from '../../../../utils/strings'
import { useTranslation } from 'react-i18next'
import { useScreenSize } from '../../../../hooks/use-screen-size'
import Skeleton from '@material-ui/lab/Skeleton'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { DeliveryType } from '../../../../enums/delivery-type'
import { getDateByState, getDateTitle } from '../../../../utils/item-details'

const expandableTextMinimumHeight = 404

export const InfoComponent: React.FC<InfoComponentProps> = (
  props: InfoComponentProps
) => {
  const classes = useInfoComponentStyles()
  const navigate = useNavigate()
  const {
    name,
    description = '',
    currentPrice,
    itemCondition,
    locale = Locale.US,
    currency = CurrencyList.USD,
    isCurrentUserOwner,
    nonprofitName,
    listedUtc,
    soldUtc,
    createdUtc,
    donorName,
    state,
    listedItemId,
    city,
    region,
    loading,
    isShippingAvailable,
    deliveryType,
    setInStoreDeliveryType,
  } = props

  const { t } = useTranslation()
  const screenSize = useScreenSize()
  const [isLongText, setIsLongText] = useState<boolean>(false)
  const textHolder = useRef<HTMLDivElement>(null)

  //Message Text
  const messageText = () => {
    // eslint-disable-next-line functional/no-let
    let message = null
    if (isCurrentUserOwner) {
      message = 'Your item'
      state !== ItemState.Active &&
        (message = t('itemDetails.yourUnlistedItem'))
      state === ItemState.Draft && (message = t('itemDetails.yourDraft'))
    }
    return message
  }

  //Go to Checkout Handler
  const goToCheckout = () => {
    navigate(`/checkout/${listedItemId}/${deliveryType}`)
  }

  //Check Long Text
  const checkLongText = () => {
    const textHolderHeight = textHolder.current!.offsetHeight
    textHolderHeight > expandableTextMinimumHeight
      ? setIsLongText(true)
      : setIsLongText(false)
  }

  /* Use Effect Check Long Text */
  useEffect(checkLongText, [screenSize, description])

  /* Use Effect Check when only single type*/
  useEffect(() => {
    setInStoreDeliveryType(DeliveryType.Shipping)
  }, [isShippingAvailable])

  /* Change Handle */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInStoreDeliveryType((event.target as HTMLInputElement)!.value as string)
  }

  return (
    <div className={classes.infoBlock}>
      <Typography className={classes.name} variant="h5" component="h1">
        {name ? name : <Skeleton animation="wave" width="70%" />}
        <div className={classes.listedTimeBlock}>
          {listedUtc ? (
            <>
              <span className={classes.timeLabel}>
                {getDateTitle(t, state)}:{' '}
              </span>
              <span className={classes.time}>
                {getDateByState({ listedUtc, createdUtc, soldUtc }, state)}
              </span>
            </>
          ) : (
            <Skeleton animation="wave" width="50%" />
          )}
        </div>
      </Typography>
      <div>
        {description ? (
          <Typography
            className={classes.descriptionTitle}
            variant="h6"
            component="h3"
          >
            {t('itemDetails.description')}
          </Typography>
        ) : (
          <Skeleton animation="wave" width="40%" />
        )}
        <div>
          <>
            <Typography
              className={classes.descriptionText}
              ref={textHolder}
              variant="body1"
              style={{ overflow: 'hidden', position: 'absolute', zIndex: -1 }}
              component="p"
            >
              {transformToMultiLine(description)}
            </Typography>
            {!isLongText && (
              <Typography
                className={classes.descriptionText}
                variant="body1"
                component="p"
              >
                {transformToMultiLine(description)}
              </Typography>
            )}
            {isLongText && <ExpandableText description={description} />}
          </>
          {!description && (
            <>
              <Skeleton animation="wave" width="80%" />
              <Skeleton animation="wave" width="85%" />
              <Skeleton animation="wave" width="90%" />
              <Skeleton animation="wave" width="75%" />
            </>
          )}
        </div>
        <div className={classes.propsHolder}>
          <div className={classes.propsBlock}>
            {nonprofitName ? (
              <>
                <span className={classes.propsLabel}>
                  {t('itemDetails.nonprofit')}:{' '}
                </span>
                <span className={classes.propsValue}>{nonprofitName}</span>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="75%" />
              </>
            )}
          </div>
          <div className={classes.propsBlock}>
            {donorName ? (
              <>
                <span className={classes.propsLabel}>
                  {t('itemDetails.donorName')}:{' '}
                </span>
                <span className={classes.propsValue}>{donorName}</span>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="70%" />
              </>
            )}
          </div>
          <div className={classes.propsBlock}>
            {city ? (
              <>
                <span className={classes.propsLabel}>
                  {t('itemDetails.location')}:{' '}
                </span>
                <span
                  className={classes.propsValue}
                >{`${city}, ${region}`}</span>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="70%" />
              </>
            )}
          </div>
        </div>
        {!loading && (
          <div className={classes.verticalAlign}>
            <div className={classes.deliveryBlock}>
              {!messageText() && (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="deliveryType"
                    name="deliveryType"
                    value={deliveryType}
                    onChange={handleChange}
                  >
                    {/*{ isLocalPickupAvailable && <span><FormControlLabel value={DeliveryType.LocalPickup} control={<Radio color="primary"/>} label="Local Pickup" /></span>}*/}
                    {isShippingAvailable && (
                      <span>
                        <FormControlLabel
                          value={DeliveryType.Shipping}
                          control={<Radio color="primary" />}
                          label="Shipping"
                        />
                      </span>
                    )}
                  </RadioGroup>
                </FormControl>
              )}
            </div>
            <div className={classes.topPart}>
              <Typography className={classes.price}>
                <span>
                  {currencyTransform(currentPrice, locale, {
                    style: 'currency',
                    currency,
                  })}
                  <Badge
                    overlap="rectangular"
                    badgeContent={
                      itemCondition === ItemCondition.New
                        ? t('itemDetails.new')
                        : t('itemDetails.used')
                    }
                    color={
                      itemCondition === ItemCondition.New
                        ? 'secondary'
                        : 'primary'
                    }
                    className={classes.priceNumber}
                  />
                </span>
              </Typography>
            </div>
            <div className={classes.btnBlock}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                disabled={!!messageText() || !deliveryType}
                onClick={goToCheckout}
              >
                {t('itemDetails.buy')}
              </Button>
              {!!messageText() && (
                <span className={classes.statusMessageBlock}>
                  {messageText()}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
