import React from 'react'
import { grey } from '@material-ui/core/colors'
import { PaymentMethod } from '@stripe/stripe-js'
import { IBillingDetails, useStripeForm } from '../stripe-form.hook'
import { CardElement } from '@stripe/react-stripe-js'
import { StripeFormSubmitButton } from '../stripe-form-submit-button'
import { useStripeFormContentStyles } from './stripe-form-content.styles'
import { useTranslation } from 'react-i18next'

interface IStripeFormContentProps {
  onPaymentMethodReceived: (paymentMethod: PaymentMethod) => void
  onError: (message?: string) => void
  billingDetails: IBillingDetails
  disabled: boolean
}

export const StripeFormContent: React.FC<IStripeFormContentProps> = (
  props
): React.ReactElement => {
  const { onPaymentMethodReceived, billingDetails, onError, disabled } = props
  const { processing, canPay, onSubmitHandler, onCardDataChanged } =
    useStripeForm({ onPaymentMethodReceived, billingDetails, onError })

  const { t } = useTranslation()

  const classes = useStripeFormContentStyles()

  return (
    <form onSubmit={onSubmitHandler} className={classes.root}>
      <div className={classes.stripeCard}>
        {disabled ? (
          <CardDisabled />
        ) : (
          <CardElement onChange={onCardDataChanged} />
        )}
      </div>
      <div className={classes.payButton}>
        <StripeFormSubmitButton
          processing={processing}
          disabled={!canPay || disabled}
        >
          {t('checkout.pay')}
        </StripeFormSubmitButton>
      </div>
    </form>
  )
}

const CardDisabled: React.FC = (): React.ReactElement => (
  <span style={{ color: grey[500] }}>...</span>
)
