import React, { Fragment } from 'react'
import { Box, Button, Container, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use-storage'
import { useCookieBannerStyles } from './cookie-banner.styles'

export const CookieBanner = (): React.ReactElement => {
  const { t } = useTranslation()
  const [cookieState, setCookieState] = useLocalStorage(
    'userPropsCookieBannerDisplay',
    'show'
  )
  const classes = useCookieBannerStyles()

  const onButtonClickHandler = () => {
    setCookieState('hide')
  }

  return (
    (cookieState !== 'hide' && (
      <Container className={`${classes.container}`} maxWidth="xl">
        <Box marginTop={2}>
          <Paper className={`${classes.paper} ${classes.cookieBannerSection}`}>
            <img
              src="/images/cookies.svg"
              alt="cookies"
              className={classes.cookieBannerIcon}
              height={50}
            />
            <div className={classes.cookieBannerText}>
              {t('cookieBanner.text')}
            </div>
            <Button
              color="primary"
              className={classes.cookieBannerButton}
              onClick={onButtonClickHandler}
              variant="contained"
            >
              {t('cookieBanner.btnText')}
            </Button>
          </Paper>
        </Box>
      </Container>
    )) || <Fragment />
  )
}
