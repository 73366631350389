import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useDragAndDropperStyles = makeStyles(() => {
  return createStyles({
    dragAndDroper: {
      cursor: 'move',
      position: 'relative',
      display: 'inline-block',
    },
    select: {
      position: 'absolute',
      background: 'white',
      bottom: 0,
      right: 0,
      borderRadius: 4,
    },
  })
})
