import { AccountShippingAddress } from './models'
import { ApiError } from '../api-error-data'
import { axiosInstance } from '../../../api'

export type SaveAddressType = {
  isValidated: boolean
  isAddressChanged: boolean
} & AccountShippingAddress

export const accountApi = {
  async getShippingAsync(): Promise<AccountShippingAddress> {
    try {
      const response =  await axiosInstance.get('account/me/address/shipping')
      return response.data as AccountShippingAddress
    } catch (error) {
      throw new ApiError(error)
    }
  },

  async setShippingAsync(
    request: Omit<AccountShippingAddress, 'isValidated'>
  ): Promise<SaveAddressType> {
    try {
      const response = await axiosInstance.put('account/me/address/shipping', {
        ...request,
      })
      return response.data
    } catch (error) {
      throw new ApiError(error)
    }
  },
}
