import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useProfileComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(19),
      height: theme.spacing(19),
    },
    fileInput: {
      display: 'none',
    },
    labelFileInput: {
      position: 'relative',
      display: 'inline-block',
      overflow: 'hidden',
      borderRadius: '50%',
      cursor: 'pointer',
      marginBottom: '30px',
    },
    avatarHolder: {
      textAlign: 'center',
    },
    choosingBlock: {
      position: 'absolute',
      top: '60%',
      paddingTop: '8px',
      left: 0,
      width: '100%',
      background: 'rgba(255, 255, 255, 0.7)',
      height: '100%',
    },
    textItem: {
      marginBottom: '15px',
    },
    submit: {
      margin: '0 0 15px 0',
      display: 'inline-block',
    },
  })
)
