import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectRates,
  selectCurrentRate,
  selectRatesLoading,
} from '../../../store/checkout-page/selectors'
import { RateMessage, RatesType } from '../../../store/checkout-page/types'
import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  selectRateMessages,
  selectAddress,
  selectSaveAddressError,
  selectAddressLoading,
  selectIsValidAddress,
  selectItemDetails,
  selectRatesError,
} from '../../../store/checkout-page/selectors'
import {
  getShippmentRate,
  setShippmentRateByArrayIndex,
  setLoadingRates,
  resetStore as _resetStore,
} from '../../../store/checkout-page/reducer'
import { AddressDataType } from '../address-block/address-block.hook'
import { AxiosError } from 'axios'
import { ApiErrorDataContent } from '../../../services/api/api-error-data'

type UseShipmentRateBlock = {
  rates: RatesType[]
  currentRate?: RatesType
  ratesLoading: boolean
  fullscreen: boolean
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  address: AddressDataType
  addressLoading: boolean
  isAddressValid: boolean
  getShipmentRateHandler: () => void
  setSelectedRateByArrayIndex: (index: number) => void
  setLoadingRatesHandler: () => void
  resetStore: () => void
  ratesError: AxiosError
  rateMessages: RateMessage[]
  saveAddressError?: ApiErrorDataContent
}

export const useShipmentRateBlock = (): UseShipmentRateBlock => {
  const dispatch = useDispatch()
  const rates = useSelector(selectRates)
  const rateMessages = useSelector(selectRateMessages)
  const currentRate = useSelector(selectCurrentRate)
  const ratesLoading = useSelector(selectRatesLoading)
  const [isOpen, _setIsOpen] = useState<boolean>(false)
  const address = useSelector(selectAddress)
  const addressLoading = useSelector(selectAddressLoading)
  const isAddressValid = useSelector(selectIsValidAddress)
  const itemDetails = useSelector(selectItemDetails)
  const ratesError = useSelector(selectRatesError)
  const saveAddressError = useSelector(selectSaveAddressError)
  const theme = useTheme()

  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))

  const setIsOpen = (value: boolean) => {
    _setIsOpen(value)
  }

  //Get Shipment Rate Handler
  const getShipmentRateHandler = () => {
    dispatch(
      getShippmentRate({
        listedItemId: itemDetails.listedItemId,
        addressLine1: `${address.addressLine1} ${address.addressLine2}`,
        city: address.city,
        region: address.region,
        postalCode: address.postalCode,
        countryCode: address.countryCode,
        name: address.name,
      })
    )
  }

  //Set selected rate by Id
  const setSelectedRateByArrayIndex = (index: number) => {
    dispatch(setShippmentRateByArrayIndex(index))
  }

  //Set Loading Rate
  const setLoadingRatesHandler = () => {
    dispatch(setLoadingRates())
  }

  //Reset Store
  const resetStore = () => {
    dispatch(_resetStore())
  }

  return {
    //props
    rates,
    currentRate,
    ratesLoading,
    fullscreen,
    isOpen,
    address,
    addressLoading,
    isAddressValid,
    ratesError,
    rateMessages,
    saveAddressError,

    //methods
    setIsOpen,
    getShipmentRateHandler,
    setSelectedRateByArrayIndex,
    setLoadingRatesHandler,
    resetStore,
  }
}
