import createSagaMiddleware from 'redux-saga'
import { configureStore, Store } from '@reduxjs/toolkit'

import { rootReducer } from './root-reducer'
import rootSaga from './saga'

const sagaMiddleware = createSagaMiddleware()

export const store: Store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
  ],
})

sagaMiddleware.run(rootSaga)
