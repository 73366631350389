import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useDeliveryTypeSelectStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingRight: theme.spacing(2),
    },
    selectArea: {
      display: 'flex',
      alignItems: 'center',
    },
    addressArea: {
      alignItems: 'start',
      marginTop: theme.spacing(3),
    },

    label: {
      fontWeight: 'bold',
      marginRight: theme.spacing(1),
    },
    addressLabel: {
      marginTop: -1,
    },
    ratesLabel: {
      marginRight: '30px',
    },
    select: {
      flexGrow: 1,
      '& > .MuiSelect-selectMenu': {
        paddingLeft: '16px',
        paddingRight: '30px',

        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  })
)
