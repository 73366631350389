import { EmailSubscriptionState } from './email-subscription/reducer'
import { productsReducer } from './products/reducer'
import { authReducer } from './auth/reducer'
import { nonProfitReducer } from './nonprofits/reducer'
import { store } from './store'
import { homeListedItems } from './home-page-items/reducer'
import { checkoutPageReducer } from './checkout-page/reducer'
import { appDialogReducer } from './app-dialog/reducers'
import { orderReducer } from './order/reducer'
import { myListedItemsReducer } from './my-listed-items/reducer'
import { draftItemsReducer } from './draft-items/reducer'
import { overlayPreloaderReducer } from './overlay-preloader/reducer'
import {appInsightsReducer} from './app-insights/reducer'

export type ActionType<PayloadType> = {
  readonly type: string
  readonly payload: PayloadType
}

export type ActionMsalType<PayloadType> = {
  readonly type: string
  readonly payload: {accessMsalToken?: string} & PayloadType
}

export type ActionMsalEmptyDataType = {
  readonly type: string
  readonly payload: {accessMsalToken?: string}
}

export enum LoadingState {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NEVER = 'NEVER',
}
export type RootReducer = {
  readonly emailSubscription: EmailSubscriptionState
  readonly products: ReturnType<typeof productsReducer>
  readonly auth: ReturnType<typeof authReducer>
  readonly nonprofit: ReturnType<typeof nonProfitReducer>
  readonly homeListedItems: ReturnType<typeof homeListedItems>
  readonly checkoutPage: ReturnType<typeof checkoutPageReducer>
  readonly appDialog: ReturnType<typeof appDialogReducer>
  readonly order: ReturnType<typeof orderReducer>
  readonly myListedItems: ReturnType<typeof myListedItemsReducer>
  readonly draftItems: ReturnType<typeof draftItemsReducer>
  readonly overlayPreloader: ReturnType<typeof overlayPreloaderReducer>
  readonly appInsights: ReturnType<typeof appInsightsReducer>
}

export type RootState = ReturnType<typeof store.getState>
export type ResponseServerError<T> = {
  response: {
    data: T
  }
}
