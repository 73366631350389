import { FormValues } from '../../../../hooks/use-form'
import { AccountFormState, UpdateUserInfo } from './profile-component.models'

type UseProfileComponentProps = {
  updateUserInfoAction: (data: UpdateUserInfo) => void
  accountFormState: AccountFormState
  setNonUpdated: () => void
}

export const useProfileComponent = (props: UseProfileComponentProps) => {
  const { updateUserInfoAction, accountFormState, setNonUpdated } = props

  const formValueChanges = () => {
    setNonUpdated()
  }

  const updateUserInfoActionHandler = (data: FormValues) => {
    updateUserInfoAction(data as UpdateUserInfo)
  }

  return {
    formValueChanges,
    updateUserInfoActionHandler,
    accountFormState,
  }
}
