import { call, takeEvery, put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  fetchOrderDataAction,
  fetchOrderDataErrorAction,
  fetchOrderDataSuccessAction,
  setErrorAction,
  createShippingLabelAction,
  createShippingLabelSuccessAction,
  createShippingLabelErrorAction,
} from './reducer'
import { ordersApi } from '../../services/api/orders/'
import { ApiError } from '../../services/api/api-error-data'
import {trackException} from '../app-insights/reducer'

//Get Order Page detail
export function* fetchOrderDetailSaga({
  payload,
}: ReturnType<typeof fetchOrderDataAction>): SagaIterator {
  try {
    const data = yield call(ordersApi.getOrderDetail, payload)
    yield put(fetchOrderDataSuccessAction({ ...data }))
  } catch (e) {
    yield put(fetchOrderDataErrorAction())
    yield put(setErrorAction(e as ApiError))
    yield put(trackException({e, source: 'fetchOrderDetailSaga'}))
  }
}

//Create Shipping Label
export function* createShippingLabelSaga({
  payload,
}: ReturnType<typeof createShippingLabelAction>): SagaIterator {
  try {
    const data = yield call(ordersApi.createShippingLabel, payload)
    yield put(createShippingLabelSuccessAction(data.shippingLabelUrl as string))
  } catch (e) {
    yield put(createShippingLabelErrorAction(e as ApiError))
    yield put(trackException({e, source: 'createShippingLabelSaga'}))
  }
}

export function* orderSaga(): SagaIterator {
  yield takeEvery(fetchOrderDataAction, fetchOrderDetailSaga)
  yield takeEvery(createShippingLabelAction, createShippingLabelSaga)
}
