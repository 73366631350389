import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useAccountSideMenuStyle = makeStyles((theme: Theme) =>
  createStyles({
    sideMenu: {
      margin: 0,
      padding: 0,
      textAlign: 'left',
    },
    sideMenuHolder: {
      margin: '10px 0 0 0',
      padding: 0,

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    sideMenuItem: {
      listStyle: 'none',
      marginBottom: '15px',

      '&>a': {
        textDecoration: 'none',
        color: 'rgba(0,0,0, 0.8)',
        fontFamily: 'inherit',
      },
    },
    active: {
      color: 'black',
      fontWeight: 800,
    },
    selectHolder: {
      display: 'block',
      margin: '20px 0',

      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    select: {
      display: 'block',
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',

      '& select:focus': {
        background: 'none !important',
      },
    },
  })
)
