import React from 'react'
import { Typography, Badge, Divider } from '@material-ui/core'
import { useOrderPageStyles } from '../order-page.styles'
import { OrderHeaderComponentType } from './order-header.constants'
import { useTranslation } from 'react-i18next'

export const OrderHeader: React.FC<OrderHeaderComponentType> = (props) => {
  //Translation
  const { t } = useTranslation()

  //Status Label List
  const getStatusText = (status: string): string => {
    const textList = new Map([
      ['Pending', 'Item is not yet paid for'],
      ['Cancelled', 'Cancelled'],
      ['Paid', 'Buyer paid for the item and waiting for the shipment'],
      ['Complete', 'Buyer received item'],
      ['Shipped', 'Item was returned'],
    ])

    return textList.get(status) || ''
  }

  //Props
  const { orderNumber, status } = props

  //Styles
  const classes = useOrderPageStyles()

  return (
    <div>
      {/*Order Header*/}
      <div className={classes.orderHeader}>
        <Typography variant="h6" className={classes.orderHeaderTitle}>
          {t('orderPage.order')} #{orderNumber}
          <span className={classes.badgeHolder}>
            <Badge badgeContent={status} color={'primary'} />
          </span>
        </Typography>
        <div className={classes.orderHeaderStatusText}>
          <span>{getStatusText(status)}</span>
        </div>
      </div>

      {/*Divider Holder*/}
      <div className={classes.dividerHolder}>
        <Divider variant="fullWidth" />
      </div>
    </div>
  )
}
