import { useState } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  fetchOrderDataAction,
  createShippingLabelAction,
} from '../../store/order/reducer'
import {
  selectListedItem,
  selectOrderHeader,
  selectOrderLoadingPage,
  selectLoadingError,
  selectCreateShippingLabelError,
} from '../../store/order/selectors'

export const useOrder = () => {
  const [isOpen, _setIsOpen] = useState<boolean>(false)
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const { orderId } = useParams<{ orderId: string }>()
  const listedItem = useSelector(selectListedItem)
  const orderHeader = useSelector(selectOrderHeader)
  const loadingPage = useSelector(selectOrderLoadingPage)
  const loadingError = useSelector(selectLoadingError)
  const createShippingLabelError = useSelector(selectCreateShippingLabelError)

  //SetIsOpen
  const setIsOpen = (status: boolean) => {
    _setIsOpen(status)
  }

  //Get Order Data
  const getOrderData = () => {
    dispatch(fetchOrderDataAction(orderId as string))
  }

  //Create Shipping Label
  const createShippingLabel = () => {
    setIsOpen(true)
    console.log(orderHeader.orderHeaderId)
    !orderHeader.shipment &&
      dispatch(
        createShippingLabelAction({ orderid: orderHeader.orderHeaderId })
      )
  }

  return {
    //props
    isOpen,
    setIsOpen,
    fullscreen,
    orderHeader,
    listedItem,
    loadingPage,
    loadingError,
    createShippingLabelError,

    //methods
    getOrderData,
    createShippingLabel,
  }
}
