import { OrderHeaderType } from '../../../store/order/types'

//Order Header Component Type
export type OrderHeaderComponentType = Pick<
  OrderHeaderType,
  'orderNumber' | 'status'
>

//Order Status Map
export const OrderStatus = new Map([
  [0, 'Pending'],
  [1, 'Canceled'],
  [2, 'Paid'],
  [3, 'Shipped'],
  [4, 'Complete'],
  [5, 'Returned'],
])
