import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import { useListedItemPlaceholderStyles } from './listed-item-placeholder.styles'
import { Card, CardContent } from '@material-ui/core'
import Box from '@material-ui/core/Box'

export const ListedItemPlaceholder: React.FC = () => {
  const classes = useListedItemPlaceholderStyles()
  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Box pt={0.5}>
            <Skeleton animation="wave" className={classes.image} />
            <div className={classes.textHolder}>
              <Skeleton animation="wave" width="60%" />
              <Skeleton animation="wave" width="50%" />
              <Skeleton animation="wave" width="70%" />
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}
