import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAppHeaderDrawerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // padding: theme.spacing(2)
    },

    hamburger: {
      display: 'none',

      [theme.breakpoints.down('xs')]: {
        display: 'inline-flex',
      },
    },

    list: {
      width: 250,

      '& a': {
        textDecoration: 'none',
        color: theme.palette.text.primary,
      },

      '& hr': {
        margin: '5px 0',
      },
    },

    icon: {
      minWidth: 30,

      '& svg': {
        filter: 'invert(0.7)',
      },
    },

    sub: {
      marginLeft: 30,
      // borderLeft: 'solid 1px #ddd',
      //
      // '& li:before': {
      //   content: '\'\'',
      //   display: 'block',
      //   background: '#ddd',
      //   height: 1,
      //   width: 10,
      //   position: 'absolute',
      //   left: 0,
      // },
    },
  })
)
