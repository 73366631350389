import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAppHeaderLogoStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'uppercase',
      fontSize: 18,
      color: 'white',
      textDecoration: 'none',
      display: 'inline-flex',
      alignItems: 'center',
      marginRight: 30,

      '& > span': {
        marginLeft: theme.spacing(2),
        transition: 'text-shadow 300ms',
      },

      '&:hover > span': {
        textShadow: '0 0 20px rgb(0 0 0 / 90%)',
      },

      '& > svg': {
        height: 40,
        width: 40,
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: 15,

        '& > span': {
          marginLeft: theme.spacing(1.5),
        },

        '& > svg': {
          height: 35,
          width: 35,
        },
      },
    },
  })
)
