import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core'
import { SelectNonprofit, useSelectNonprofit } from './select-nonprofit'
import {
  initialValues,
  DonateDetails,
  useDonateDetails,
} from './donate-details'
import { switchCase } from '../../utils'
import { useDonate } from './donate.hook'
import { removeFirstCase } from './donate.utils'
import { DonateProps } from './donate.models'
import { ItemImages } from './item-images'
import { useTranslation } from 'react-i18next'
import { Link as ReactRouterLink, useNavigate, useParams } from 'react-router-dom'
import { Alert } from '@material-ui/lab'
import { ImageObject } from './item-images/item-images.hook'
import { nonprofitsApi } from '../../services/api/nonprofits'
import { trackException } from '../../store/app-insights/reducer'
import { AxiosError } from 'axios'

export const Donate: React.FC<DonateProps> = ({
  categoryRequest,
  nonprofitRequest,
  nonprofitIdProp,
  createListedItemApi,
  checkedAuth,
  userStatus,
}): React.ReactElement => {
  const {
    classes,
    activeStep,
    steps,
    nonprofitId,
    handleNext,
    handleBack,
    handleReset,
    loadStatus,
    setAppDialog,
    createListedItem,
    publishedItemId,
    shippingAddressExists,
    validateParcel,
    parcelData,
    setIsShow
    /*@ts-ignore*/
  } = useDonate({ nonprofitIdProp, createListedItemApi })
  const params = useParams<{nonprofitId: string}>()
  const selectNonprofit = useSelectNonprofit({
    categoryRequest,
    nonprofitRequest,
  })
  const navigate = useNavigate()
  const { donateDetailsFormValid, ...donateDetailsProps } = useDonateDetails()
  const [imageIds, _setImageIds] = useState<string[]>([])
  const [visibleDraftButton, setVisibleDraftButton] = useState(false)
  const { t } = useTranslation()

  /* Set Nonprofit id if there are NonprofitExternalId */
  useEffect(()=> {
    if(params && params.nonprofitId){
      const externalNonprofitId = params.nonprofitId
      const getInfoByExternalId = async ()=> {
        try {
          const data = await nonprofitsApi.getInfoByExternalId(externalNonprofitId).then((data)=> data)
          selectNonprofit.setSelectedNonprofit({
            nonprofitId: data.nonProfitId,
            name: data.name,
            nonprofitCategoryId: data.categoryId,
            selected: data.nonProfitId
          })
        } catch (e) {
          const {response} = e as AxiosError
          const code = response?.status || 500
          if(code === 404){
            setAppDialog({
              message: t('donation.externalNotFound.message'),
              title: t('donation.externalNotFound.title'),
              isShow: true,
              variant: 'warning',
              closeHandler: ()=> {
                /* Hard reload */
                window.location.href = '/donate'
              },
              actions: (
                <>
                  <Button onClick={()=> window.location.href = '/donate'}>
                    {t('donation.externalNotFound.ok')}
                  </Button>
                </>
              ),
            })
          } else {
            setAppDialog({
              message: t('donation.externalLoadError.message'),
              title: t('donation.externalLoadError.title'),
              isShow: true,
              variant: 'error',
              closeHandler: ()=> {
                /* Hard reload */
                setIsShow(false)
              },
              actions: (
                <>
                  <Button onClick={()=> window.location.reload()}>
                    {t('donation.externalLoadError.reload')}
                  </Button>
                </>
              ),
            })
            trackException({
              e
            })
          }
        }
      }

      getInfoByExternalId()
    }
  }, [])


  const setImageIds = (imageList: ImageObject[]) => {
    const ids = imageList.map(({ id }) => id) as string[]
    _setImageIds(ids)
  }

  const stepperCases = (nonprofitId: number | string) => {
    const cases = {
      '0': <SelectNonprofit {...selectNonprofit} />,
      '1': <DonateDetails {...donateDetailsProps} />,
      '2': (
        <ItemImages
          setItemImages={setImageIds}
          onErrorHandler={(message) =>
            setAppDialog({ isShow: true, variant: 'error', message })
          }
        />
      ),
    }
    return nonprofitId ? removeFirstCase(cases) : cases
  }

  const navigateToCreatedItem = () => {
    navigate(`/listed-items/${publishedItemId}`)
  }

  const toInitialDonateState = () => {
    handleReset()
    const { setSelectedCategory, setSelectedNonprofit } = selectNonprofit
    const { setDonateDetails } = donateDetailsProps
    setSelectedNonprofit()
    setSelectedCategory()
    setImageIds([])
    setDonateDetails({ ...initialValues })
  }

  useEffect(() => {
    isAvailableSaveDraft()
  }, [donateDetailsProps.donateDetails])

  useEffect(() => {
    if (parcelData) {
      handleNext()
    }
  }, [parcelData])

  const isAvailableSaveDraft = () => {
    const { title } = donateDetailsProps.donateDetails
    setVisibleDraftButton(!!title)
  }

  const isNextButtonDisabled = (nonprofitId: string | number): boolean => {
    const cases = {
      '0': !selectNonprofit.selectedNonprofit?.name,
      '1': donateDetailsFormValid,
      '2': false,
    }

    return switchCase(nonprofitId ? removeFirstCase(cases) : cases)(true)(
      activeStep.toString()
    )
  }

  /*Stepper content*/
  const stepperContent = (nonprofitId: number | string) =>
    switchCase(stepperCases(nonprofitId))('Unknown stepIndex')(
      activeStep.toString()
    )
  const nextButtonIsDisabled = () => {
    const { width, height, length, weight } = donateDetailsProps.donateDetails

    const shippingInvalid =
      !(width && height && length && weight) && activeStep === steps.length - 2
    return (
      isNextButtonDisabled(nonprofitId) ||
      loadStatus.loading ||
      (activeStep === steps.length - 1 && (!userStatus || !imageIds.length)) ||
      shippingInvalid
    )
  }

  /*Is Disabled Prev Btn*/
  const isDisabledPrevBtn = () => activeStep === 0

  const handleClick = (activeStep: number) => {
    if (activeStep === 1) {
      validateParcel(donateDetailsProps.donateDetails)
    } else {
      handleNext()
    }
  }

  return (
    <>
      <Container>
        <div className={classes.root}>
          {!shippingAddressExists && (
            <div className={classes.shippingAddressAlert}>
              <Alert severity="warning">
                {t('checkout.addAddresssLabel')}{' '}
                <b>
                  <ReactRouterLink to={'/account/shipping'}>
                    {t('checkout.addAddressLink')}
                  </ReactRouterLink>
                </b>
              </Alert>
            </div>
          )}
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            className={classes.stepperOffset}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {checkedAuth ? (
            <div>
              <div>
                <div className={classes.inputHolder}>
                  {activeStep !== steps.length &&
                    !loadStatus.loaded &&
                    stepperContent(nonprofitId)}
                </div>
                {loadStatus.loaded && (
                  <div className={classes.loadedBtnHolder}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={toInitialDonateState}
                    >
                      {t('donation.addNewButton')}
                    </Button>
                    {!loadStatus.isDraft && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={navigateToCreatedItem}
                      >
                        {t('donation.showListedItemButton')}
                      </Button>
                    )}
                  </div>
                )}
                {!loadStatus.loaded && (
                  <div className={classes.btnHolder}>
                    {visibleDraftButton && (
                      <Button
                        className={`${classes.btnDraft}`}
                        variant="contained"
                        /*@ts-ignore*/
                        onClick={() =>
                          createListedItem(
                            { ...donateDetailsProps.donateDetails },
                            selectNonprofit.selectedNonprofit?.nonprofitId ?? 0,
                            imageIds,
                            true
                          )
                        }
                      >
                        {t('donation.saveDraft')}
                      </Button>
                    )}
                    <Button
                      disabled={isDisabledPrevBtn()}
                      onClick={handleBack}
                      className={`${classes.backButton}`}
                    >
                      {t('donation.back')}
                    </Button>
                    <FormControl error={true}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          activeStep === steps.length - 1
                            ? createListedItem(
                                { ...donateDetailsProps.donateDetails },
                                selectNonprofit.selectedNonprofit
                                  ?.nonprofitId ?? 0,
                                imageIds,
                                false
                              )
                            : handleClick(activeStep)
                        }}
                        disabled={nextButtonIsDisabled()}
                        className={`${classes.btnNext}`}
                      >
                        {activeStep === steps.length - 1
                          ? t('donation.publish')
                          : t('donation.next')}
                      </Button>
                      {activeStep === steps.length - 1 && !userStatus && (
                        <FormHelperText>
                          {t('donation.pleaseActivateYourAccount')}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Container>
    </>
  )
}
