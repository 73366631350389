import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      borderTop: `1px solid ${theme.palette.primary.main}`,
      marginTop: 40,
      backgroundColor: '#3f51b5',
      color: 'white',
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '20px 0',
      alignItems: 'center',

      '& > svg': {
        marginRight: '80px',
        height: 60,
        width: 60,
      },
    },

    contacts: {
      display: 'flex',
      listStyleType: 'none',
      fontStyle: 'initial',
      padding: '0 0 10px 0',
    },

    contactItem: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '20px',
      color: 'white',
    },

    contact: {
      color: 'white',
      textDecoration: 'none',
      cursor: 'pointer',
      paddingLeft: '5px',
      '&:hover': {
        textDecoration: 'none',
      },
    },

    contactMobile: {
      padding: 0,
      margin: 0
    },

    links: {
      display: 'flex',
      listStyleType: 'none',
      fontStyle: 'initial',
      padding: '10px 0 0 0',
    },

    linkItem: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '20px',
    },

    linkItemMobile: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: '5px',
      paddingLeft: '5px'
    },

    divider: {
      borderTop: '1px solid white',
    },

    subscribe: {
      display: 'flex',
      alignItems: 'center',
    },

    copyright: {
      display: 'block',
      position: 'relative',
      lineHeight: '0',
      textAlign: 'right',
      right: '100px',
      bottom: '20px',
      fontSize: 12,
    },
    footerMobile: {
      padding: 30,

      '& li':{
        listStyle: 'none',
        fontStyle: 'normal'
      },
    },
    logo: {
      '& svg':{
        width: 60,
        height: 60
      }
    },

    logoMobile: {
      marginBottom: 30,
      '& svg':{
        width: 60,
        height: 60
      }
    },

    contactItemMobile: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      marginBottom: 15
    },

    copyrightMobile: {
      display: 'block',
      position: 'relative',
      lineHeight: '1.5',
      textAlign: 'center',
      fontSize: 12,

    },

    linksMobile: {
      display: 'flex',
      justifyContent: 'center',
      listStyleType: 'none',
      fontStyle: 'initial',
      padding: '10px 0 0 0',
      marginBottom: 30
    }
  })
)
