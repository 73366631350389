import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useSecurityComponentStyles = makeStyles((theme) =>
  createStyles({
    alignCenter: {
      '& *': {
        textAlign: 'center',
      },
    },
    offset: {
      marginBottom: 15,
    },
    labelHolder: {
      overflow: 'hidden',
      marginBottom: 10,
      color: theme.palette.grey['400'],
      fontStyle: 'italic'
    },

    securityComponentHolder: {
      margin: '0 auto',
    },
    btnHolder: {
      maxWidth: '260px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    activateLinkHolder: {
      position: 'relative',
    },
    activateLinkLoader: {
      color: '#3f51b5',
      position: 'absolute',
      top: 8,
      left: 120,
      zIndex: 1,
    },
  })
)
