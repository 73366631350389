import { useDispatch, useSelector } from 'react-redux'
import {
  selectDeliveryType,
  selectIsValidAddress,
} from '../../../store/checkout-page/selectors'
import { setDeliveryType } from '../../../store/checkout-page/reducer'

export const useDeliveryTypeSelect = () => {
  const deliveryType = useSelector(selectDeliveryType)
  const isValidAddress = useSelector(selectIsValidAddress)
  const dispatch = useDispatch()

  const deliveryTypeSelectHandler = (value: unknown): void => {
    const shippingSelected = value as string
    dispatch(setDeliveryType(shippingSelected))
  }

  return {
    deliveryType,
    deliveryTypeSelectHandler,
    isValidAddress,
  }
}
