import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useHomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 30,
    },
    homeContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      margin: '0 auto',
      marginTop: '10px',
      maxWidth: '1400px',
    },
    filterContainer: {
      marginTop: '71px',
      position: 'relative',
      zIndex: 999,
      [theme.breakpoints.down('sm')]: {
        maxWidth: 90,
      },
    },
    itemsCounter: {
      display: 'flex',
      justifyContent: 'right',
    },
    item: {},
    paginationBlock: {
      alignItems: 'center',
    },
    paginationHolder: {
      display: 'inline-block',
      padding: '40px 0',
      '&:after': {},
    },
    selectHolder: {
      width: '90px',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'auto',
      },
    },

    showItemsWrapper: {
      [theme.breakpoints.down('sm')]: {},
    },

    loaderBlock: {
      position: 'absolute',
      background: 'rgba(255,255,255, 0.4)',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 100,
    },
    itemsContainer: {
      position: 'relative',
      zIndex: 99,
    },
    noTopOffset: {
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
  })
)
