import {
  selectItemsList,
  selectLoading,
  selectQueryParams,
  selectTotalPages,
  selectNeedApply,
  selectFirstRender,
  selectCategories,
  selectNonprofits,
  selectIsFilterCollapsed,
  selectError,
  selectSearchText,
  //DonorPage
  // selectSearchQuery,
} from '../../store/my-listed-items/selectors'
import { changeFilterCollapsedAction } from '../../store/my-listed-items/reducer'
import { selectUserStatus } from '../../store/auth/selectors'
import { useSelector } from 'react-redux'

//Todo need typing hook
export const useMyListedItemsPage = () => {
  /*Items List*/
  const itemsList = useSelector(selectItemsList)

  /*Loading state*/
  const loading = useSelector(selectLoading)

  /*Query Params*/
  const query = useSelector(selectQueryParams)

  /*Total Pages*/
  const totalPages = useSelector(selectTotalPages)

  /*Text Search*/
  const searchText = useSelector(selectSearchText)

  /*Need Apply*/
  const needApply = useSelector(selectNeedApply)

  /*First Render Status*/
  const firstRender = useSelector(selectFirstRender)

  /*Category List*/
  const categories = useSelector(selectCategories)

  /*Nonprofits List*/
  const nonprofits = useSelector(selectNonprofits)

  /*Filter collapsed status*/
  const isFilterCollapsed = useSelector(selectIsFilterCollapsed)

  /*Error*/
  const error = useSelector(selectError)

  /*Activate user status*/
  const userStatus = useSelector(selectUserStatus)

  return {
    //props
    itemsList,
    loading,
    query,
    totalPages,
    needApply,
    firstRender,
    categories,
    nonprofits,
    isFilterCollapsed,
    error,
    searchText,
    userStatus,

    //methode
    changeFilterCollapsedAction,
  }
}
