import React from 'react'
import { Button } from '@material-ui/core'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

interface GoBackButtonProps {
  /*
  * Prop specifies path where to navigate if navigation history is empty
  * If not specified button on click navigates to the main page ('/')
  */
  defaultNavigationPath?: string
  /*
  * CSS style classes for Material Button customization 
  */
  className?: string
}

export const GoBackButton = ({
  defaultNavigationPath,
  className,
}: GoBackButtonProps): React.ReactElement => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Button
      className={className}
      onClick={() =>
        location.key !== 'default' ? navigate(-1) : navigate(defaultNavigationPath ?? '/')
      }
    >
      <ArrowBackIcon />
      {t('itemDetails.back')}
    </Button>
  )
}
