import React from 'react'
import { AccountRouter } from './account-router'
import { Container, Grid } from '@material-ui/core'
import { useAccountLayout } from './account-layout.hook'
import { AccountSideMenu } from './account-side-menu'

export const AccountLayout: React.FC = (): React.ReactElement => {
  const { classes } = useAccountLayout()

  return (
    <Container fixed className={classes.contentHolder}>
      <Grid container>
        <AccountRenderer />
      </Grid>
    </Container>
  )
}

const AccountRenderer: React.FC = () => {
  return (
    <>
      <Grid item xs={12} md={2}>
        <AccountSideMenu />
      </Grid>
      <Grid item xs={12} md={9}>
        <AccountRouter />
      </Grid>
    </>
  )
}
