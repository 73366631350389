import React, {useEffect, useRef} from 'react'
import { useItemImagesStyles } from '../item-images.styles'
import { Delete } from '@material-ui/icons'
import { axiosInstance } from '../../../../api'
import {useDispatch} from 'react-redux'
import {trackException} from '../../../../store/app-insights/reducer'

interface IProps {
  imageUrl: string
  onDeleteHandler: () => void
}

export const ItemImage: React.FC<IProps> = ({
  imageUrl,
  onDeleteHandler,
}): React.ReactElement => {
  const classes = useItemImagesStyles()
  const ref = useRef(null)
  const dispatch = useDispatch()

  useEffect(()=> {
    axiosInstance.get(imageUrl, { responseType: 'blob' }).then((response) => response.data)
      .then((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        (ref.current! as HTMLDivElement).style.backgroundImage = `url(${url})`
      })
      .catch(e => dispatch(trackException({e, source: 'GetImgUrl'})))
  }, [])

  return (
    <div className={classes.itemImage} draggable={true}>
      <div className={classes.imageHolder}>
        <div ref={ref}></div>
      </div>
      <button
        className={classes.itemImageDeleteButton}
        onClick={onDeleteHandler}
      >
        <Delete />
      </button>
    </div>
  )
}

export default ItemImage
