import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useAppProgressIndicatorStyles = makeStyles((theme: Theme) =>
  createStyles({
    progressIndicator: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })
)
