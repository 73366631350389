import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const selectAuth = (state: RootState) => state.auth

export const selectIsRegisterModalOpen = createSelector(
  selectAuth,
  (state) => state.registerModal.isOpen
)
export const selectIsRegisterModalSubmitting = createSelector(
  selectAuth,
  (state) => state.registerModal.isSubmitting
)
export const selectIsLoginModalOpen = createSelector(
  selectAuth,
  (state) => state.loginModal.isOpen
)
export const selectIsForgotPasswordModalOpen = createSelector(
  selectAuth,
  (state) => state.forgotPasswordModal.isOpen
)
export const selectCheckedAuth = createSelector(
  selectAuth,
  (state) => state.checkedAuth
)
export const selectIsLoginModalSubmitting = createSelector(
  selectAuth,
  (state) => state.loginModal.isSubmitting
)
export const selectIsForgotPasswordModalSubmitting = createSelector(
  selectAuth,
  (state) => state.forgotPasswordModal.isSubmitting
)
export const selectIsForgotPasswordSuccess = createSelector(
  selectAuth,
  (state) => state.forgotPasswordModal.isSuccess
)
export const selectIsAuthenticated = createSelector(
  selectAuth,
  (state) => state.authenticated
)
export const selectUser = createSelector(selectAuth, (state) => state.user)
export const selectAuthErrors = createSelector(
  selectAuth,
  (state) => state.errors
)
export const selectUserStatus = createSelector(
  selectAuth,
  (state) => state.status
)
export const selectAccountForm = createSelector(
  selectAuth,
  (state) => state.accountForm
)
export const selectActivateStatus = createSelector(
  selectAuth,
  (state) => state.activateStatus
)
export const selectResetPasswordStatus = createSelector(
  selectAuth,
  (state) => state.resetPasswordStatus
)
export const selectRedirectTo = createSelector(
  selectAuth,
  (state) => state.redirectTo
)
export const selectShowForgotPasswordLink = createSelector(
  selectAuth,
  (state) => state.showForgotPasswordLink
)
// export const selectShowRegisterLink = createSelector(
//   selectAuth,
//   (state) => state.showRegisterLink
// )
