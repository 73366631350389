import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Loader } from '../../../components/loader'
import { TFunc } from '../../../models/profile'
import { ACTIVATE_STATUS } from '../../../store/auth/reducer'
import { useAppDialog } from '../../../hooks/use-app-dialog'
import { InitialActivateStatusType } from '../../../store/auth/types'
import { useTranslation } from 'react-i18next'
import { AppDialogVariants } from '../../../store/app-dialog/types'
import Button from '@material-ui/core/Button'

type Token = {
  token: string
}

export type ActivateComponentProps = {
  activateRequest: (data: Token) => Promise<any>
  activateRequestAction: TFunc<string>
  activateStatus: InitialActivateStatusType
  email: string
}

export const ActivateComponent: React.FC<ActivateComponentProps> = (
  props: ActivateComponentProps
) => {
  const {
    activateRequestAction,
    email,
    activateStatus: { isSubmitting, status, error },
  } = props

  const navigate = useNavigate()
  const { token } = useParams()
  const { t } = useTranslation()

  const { setAppDialog, setIsShow } = useAppDialog()
  const pushToMainPage = () => {
    navigate('/')
  }

  const btnClickHandler = () => {
    setIsShow(false)
    pushToMainPage()
  }

  const Btn = (
    <Button onClick={btnClickHandler} color="primary">
      Ok
    </Button>
  )

  useEffect(() => {
    token && activateRequestAction(token)
  }, [token])

  //Hook setting AppDialog behavior
  useEffect(() => {
    if (error && !isSubmitting) {
      setAppDialog({
        isShow: true,
        title: 'Activation error',
        variant: AppDialogVariants.error,
        closeHandler: pushToMainPage,
        message: t(`activation.errors.${error}`),
        actions: Btn,
      })
    } else if (!error && !isSubmitting) {
      setAppDialog({
        isShow: true,
        title: 'Successful activation',
        variant: AppDialogVariants.success,
        closeHandler: pushToMainPage,
        message: t('activation.success', { email }),
        actions: Btn,
      })
    }
  }, [error, isSubmitting])

  return (
    <>
      {isSubmitting && status === ACTIVATE_STATUS.NOT_ACTIVATED && <Loader />}
    </>
  )
}
