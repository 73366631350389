import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useAccountLayoutStyle = makeStyles(() =>
  createStyles({
    contentHolder: {
      paddingTop: '40px',
    },
  })
)
