import { createSlice } from '@reduxjs/toolkit'
import { InitialLoadStatus } from './types'

const initialLoadStatus: InitialLoadStatus = {
  loaded: false,
  loading: false,
  loadedSuccess: false,
}

const initialState = {
  categories: [],
  selectedCategory: undefined,
  nonprofitList: [],
  nonprofitItem: {
    name: '',
    nonprofitCategoryId: '',
    nonprofitId: '',
  },
  nonprofitInfo: {
    condition: '',
    description: '',
    price: '',
    title: '',
  },
  dirtyForm: false,
  nonprofitInfoFormValid: false,
  image: '',
  loadStatus: {
    ...initialLoadStatus,
  },
  isAvaliableDraft: false,
  currentStep: 0,
}

const nonprofitSlice = createSlice({
  name: 'nonprofit',
  initialState: { ...initialState },
  reducers: {
    setInitialStateAction(state) {
      const keys: string[] = Object.keys(initialState)
      keys.map((item: string): void => {
        /*@ts-ignore*/
        return (state[item] = initialState[item])
      })
    },

    setCategoriesAction(state, action) {
      state.categories = action.payload
    },

    setSelectedCategoryAction(state, action) {
      state.selectedCategory = action.payload
    },

    setNonprofitListAction(state, action) {
      state.nonprofitList = action.payload
    },

    setNonprofitItemAction(state, action) {
      state.nonprofitItem = action.payload
    },

    setNonprofitInfoAction(state, action) {
      state.nonprofitInfo = action.payload
    },

    setNonprofitInfoFormValidAction(state, action) {
      state.nonprofitInfoFormValid = action.payload
    },

    setImageAction(state, action) {
      state.image = action.payload
    },

    fetchNonprofitDataAction(state, action) {
      state.loadStatus.loading = true
    },

    setAvaliabledraftStatus(state, action) {
      state.isAvaliableDraft = action.payload
    },

    fetchNoprofitDataSuccessAction(state) {
      state.loadStatus = {
        loaded: true,
        loading: false,
        loadedSuccess: true,
      }
    },

    fetchNonprofitDataFailerAction(state) {
      state.loadStatus = {
        ...initialLoadStatus,
      }
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    closeAlertAction(state) {},
    setNonprofitIdFromUrl(state, action) {
      state.nonprofitItem.nonprofitId = action.payload
    },
  },
})

export const nonProfitReducer = nonprofitSlice.reducer
export const {
  setCategoriesAction,
  setSelectedCategoryAction,
  setNonprofitListAction,
  setNonprofitItemAction,
  setNonprofitInfoAction,
  setNonprofitInfoFormValidAction,
  setImageAction,
  fetchNonprofitDataAction,
  fetchNoprofitDataSuccessAction,
  fetchNonprofitDataFailerAction,
  setCurrentStep,
  setAvaliabledraftStatus,
  setInitialStateAction,
  setNonprofitIdFromUrl,
} = nonprofitSlice.actions
