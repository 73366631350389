import { createSlice } from '@reduxjs/toolkit'
import {
  InitialStateType,
  SetQueryParams,
  GetListedItems,
  SetCategories,
  FetchNonprofits,
  FetchNonprofitsSuccess,
  ChangeVisibleHandlerType,
  ErrorActionType,
  GetItemsMaxPrice,
  GetItemsMaxPriceSuccess,
} from './contracts'
import { itemsCounter } from '../../settings/home-page-settings'

const initialPriceMin: number = 0

const initialState: InitialStateType = {
  itemsList: [],
  categories: [],
  nonprofits: [],
  initialMinPrice: initialPriceMin,
  initialMaxPrice: null,
  loading: true,
  totalPages: 0,
  needApply: true,
  firstRender: true,
  isFilterCollapsed: true,
  searchText: '',
  query: {
    condition: [false, false],
    nonprofitId: null,
    nonprofitCategoryId: null,
    pageIndex: 1,
    pageSize: itemsCounter[0],
    priceValue: [initialPriceMin, null],
    searchText: '',
  },
  error: null,
}

const homePageListedItemsSlice = createSlice({
  name: 'homePageListedItems',
  initialState: { ...initialState },
  reducers: {
    setShowItems(state, action) {},
    setInitialState(state) {
      state.categories = []
      state.nonprofits = []
      state.itemsList = []
      ;(state.firstRender = true),
        (state.initialMaxPrice = null),
        (state.query = {
          condition: [false, false],
          nonprofitId: null,
          nonprofitCategoryId: null,
          pageIndex: 1,
          pageSize: 12,
          priceValue: [initialPriceMin, null],
          searchText: '',
        })
    },
    fetchCategories(state) {},
    fetchCategoriesSuccess(state, action: SetCategories) {
      state.categories = action.payload
    },
    fetchNonprofits(state, action: FetchNonprofits) {},
    fetchNonprofitsSuccess(state, action: FetchNonprofitsSuccess) {
      state.nonprofits = action.payload
    },
    getItemsMaxPrice(state, action: GetItemsMaxPrice) {},
    getItemsMaxPriceSuccess(state, { payload }: GetItemsMaxPriceSuccess) {
      state.initialMaxPrice = payload
    },
    setQueryParams(state, action: SetQueryParams) {
      const {
        condition,
        priceValue,
        pageIndex,
        pageSize,
        isApply,
        nonprofitCategoryId,
        nonprofitId,
        isStartPagingInBegin,
        searchText,
      } = action.payload
      if (priceValue) state.query.priceValue = [...priceValue]
      if (condition) state.query.condition = condition
      if (pageIndex) state.query.pageIndex = pageIndex
      if (pageSize) state.query.pageSize = pageSize
      if (isApply) state.needApply = true

      /*Set Category*/
      if (nonprofitCategoryId) {
        state.query.nonprofitCategoryId = nonprofitCategoryId
        state.query.nonprofitId = null
      } else if (nonprofitCategoryId === null) {
        state.query.nonprofitCategoryId = null
        state.query.nonprofitId = null
        state.nonprofits = []
      }
      if (nonprofitId) {
        state.query.nonprofitId = nonprofitId
      } else if (nonprofitId === null) {
        state.query.nonprofitId = null
      }

      if (isStartPagingInBegin) state.query.pageIndex = 1

      if (searchText) {
        state.query.searchText = searchText
      } else if (searchText === null || searchText === '') {
        state.query.searchText = ''
      }
    },
    getListedItems(state, action: GetListedItems) {
      state.loading = true
      state.needApply = false
    },
    getListedItemsSuccess(state, action) {
      state.itemsList = [...(action.payload.data ? action.payload.data.values : [])]
      state.totalPages = action.payload.data.totalPages
      state.firstRender = false
      state.loading = false
    },
    getListedItemsFailed(state) {
      state.loading = false
      state.firstRender = false
      state.totalPages = 0
      state.itemsList = []
    },
    setError(state, action: ErrorActionType) {
      state.error = action.payload
    },
    changeFilterCollapsedAction(state, action: ChangeVisibleHandlerType) {
      state.isFilterCollapsed = action.payload
    },
  },
})

export const homeListedItems = homePageListedItemsSlice.reducer
export const {
  setInitialState,
  fetchCategories,
  setQueryParams,
  getListedItems,
  getListedItemsSuccess,
  getListedItemsFailed,
  getItemsMaxPrice,
  getItemsMaxPriceSuccess,
  fetchCategoriesSuccess,
  fetchNonprofits,
  fetchNonprofitsSuccess,
  changeFilterCollapsedAction,
  setError,
} = homePageListedItemsSlice.actions
