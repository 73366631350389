import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  generalDataApi,
  RegionsCollection,
} from '../../../services/api/general-data'
import { accountApi } from '../../../services/api/account'
import { FormInitialValues, FormValues } from '../../../hooks/use-form'
import {
  ApiError,
} from '../../../services/api/api-error-data'
import { useAppDialog } from '../../../hooks/use-app-dialog'
import { AppDialogVariants } from '../../../store/app-dialog/types'
import { getUser } from '../../../store/auth/reducer'
import {trackException} from '../../../store/app-insights/reducer'

export const useShippingPage = () => {
  const [dataLoadedStatus, setDataLoadedStatus] = useState(false)
  const [address, setAddress] = useState<FormInitialValues>({})
  const [regions, setRegions] = useState<RegionsCollection>([])
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [dirtyForm, setDirtyForm] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false)
  const { setAppDialog } = useAppDialog()
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const dataLoadAsync = async (): Promise<{
    regions: RegionsCollection
    address: FormInitialValues
  }> => {
    const countryCode = 'us' //us hardcoded for now
    const regionsResponse = await generalDataApi.getRegionsAsync(countryCode)
    const addressResponse = await accountApi.getShippingAsync()
    setIsValid(addressResponse.isValidated)

    return {
      address: {
        name: addressResponse.name ?? '',
        addressLine1: addressResponse.addressLine1 ?? '',
        addressLine2: addressResponse.addressLine2 ?? '',
        city: addressResponse.city ?? '',
        region: addressResponse.region ?? '',
        postalCode: addressResponse.postalCode ?? '',
        countryCode: 'US', // hardcoded for now
      },
      regions: regionsResponse,
    }
  }

  const saveChangesAsync = async (addressToSave: FormValues): Promise<void> => {
    try {
      setDataLoadedStatus(false)
      const data = await accountApi.setShippingAsync(addressToSave)
      setIsValid(data.isValidated)
      setIsAddressChanged(data.isAddressChanged)
      setAddress({
        name: data.name ?? '',
        addressLine1: data.addressLine1 ?? '',
        addressLine2: data.addressLine2 ?? '',
        city: data.city ?? '',
        region: data.region ?? '',
        postalCode: data.postalCode ?? '',
        countryCode: 'US', // hardcoded for now
      })

      // If original address entered by user
      setShowSnackbar(true)
    } catch (reason) {
      setAddress({ ...(addressToSave as FormInitialValues) })

      setAppDialog({
        isShow: true,
        variant: AppDialogVariants.error,
        message: new ApiError(reason).getErrorMessage(t),
        title: t('accountShipping.saveErrorMessage'),
      })
    } finally {
      setDirtyForm(false)
      setDataLoadedStatus(true)
      dispatch(getUser())
    }
  }

  useEffect(() => {
    dataLoadAsync().then((result) => {
      setAddress(result.address)
      setRegions(result.regions)
      setDataLoadedStatus(true)
    }).catch((e)=> dispatch(trackException({e, source: 'shippingDataLoadAsync'})))
  }, [])

  return {
    //props
    dataLoadedStatus,
    address,
    regions,
    showSnackbar,
    dirtyForm,
    isValid,
    isAddressChanged,

    //methods
    saveChangesAsync,
    setShowSnackbar,
    setDirtyForm,
    setIsAddressChanged,
  }
}
