import { useState } from 'react'
import { DonateProps, SelectNonprofitProps } from '../donate.models'
import { nonprofitsApi } from '../../../services/api/nonprofits'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../store/auth/selectors'
import { Nonprofit, NonprofitCategory } from '../../../models'

export type UseSelectNonprofitProps = DonateProps
export const useSelectNonprofit = (
  hookProps: UseSelectNonprofitProps
): SelectNonprofitProps => {
  const {categoryRequest, nonprofitRequest} = hookProps
  const [categories, setCategories] = useState<NonprofitCategory[]>([])
  const [selectedCategory, setSelectedCategory] = useState<NonprofitCategory>()
  const [nonprofits, setNonprofits] = useState<Nonprofit[]>([])
  const [selectedNonprofit, setSelectedNonprofit] = useState<Nonprofit>()
  const shippingAddressExists = useSelector(selectUser).shippingAddressExists

  const getCategoryId = () => {
    if (!selectedCategory) {
      return ''
    } else {
      /*@ts-ignore*/
      return selectedCategory!.nonprofitCategoryId.toString()
    }
  }

  return {
    categories,
    shippingAddressExists,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    nonprofits,
    setNonprofits,
    selectedNonprofit,
    setSelectedNonprofit,
    categoryRequest: categoryRequest || nonprofitsApi.categories,
    nonprofitRequest:
      nonprofitRequest ||
      (() =>
        nonprofitsApi.lookupData({
          pageSize: '',
          pageIndex: '',
          categoryId: getCategoryId(),
          searchName: '',
        })),
  }
}
