import { useSelector, useDispatch } from 'react-redux'
import { selectOverlayPreloaderIsShow } from '../../store/overlay-preloader/selectors'
import { setIsShowOverlayPreloaderAction } from '../../store/overlay-preloader/reducer'

type UseOverlayPreloaderType = {
  isShow: boolean
  setIsShowOverlayPreloader: (value: boolean) => void
}

export const useOverlayPreloader = (): UseOverlayPreloaderType => {
  const isShow = useSelector(selectOverlayPreloaderIsShow)
  const dispatch = useDispatch()

  const setIsShowOverlayPreloader = (value: boolean) => {
    dispatch(setIsShowOverlayPreloaderAction(value))
  }

  return {
    isShow,
    setIsShowOverlayPreloader,
  }
}
