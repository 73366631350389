import React from 'react'
import { useEmptyImageComponentStyles } from './empty-image-component.styles'
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined'

export const EmptyImageComponent: React.FC = () => {
  const classes = useEmptyImageComponentStyles()

  return (
    <div className={classes.emptyImage}>
      <div className={classes.iconWrapper}>
        <PhotoCameraOutlinedIcon style={{ fontSize: 90, color: 'black' }} />
      </div>
    </div>
  )
}
