import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { FeaturedProduct } from '../../components/product-card'
import { LoadingState } from '../types'
import { FetchProducts, FetchProductsSuccess } from './types'

const productsAdapter = createEntityAdapter<FeaturedProduct>()

export const productsSlice = createSlice({
  name: 'products',
  initialState: productsAdapter.getInitialState({
    loadingState: LoadingState.NEVER,
    hasMore: true,
  }),
  reducers: {
    fetchProducts(state, action: FetchProducts) {
      state.loadingState = LoadingState.LOADING
    },
    fetchProductsSuccess(state, action: FetchProductsSuccess) {
      productsAdapter.addMany(state, action.payload as FeaturedProduct[])
      state.loadingState = LoadingState.LOADED
    },
    fetchProductsFailure(state) {
      state.loadingState = LoadingState.ERROR
    },
    noMoreProducts(state) {
      state.hasMore = false
    },
  },
})

export const productsReducer = productsSlice.reducer
export const {
  fetchProducts,
  fetchProductsSuccess,
  fetchProductsFailure,
  noMoreProducts,
} = productsSlice.actions

export const { selectAll } = productsAdapter.getSelectors()
