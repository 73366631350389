import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useAboutPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: 30,
    },

    aboutPageContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      margin: '0 auto',
      marginTop: '10px',
      maxWidth: '1400px',

      '& > svg': {
        margin: '40px',
        float: 'left',
        height: 200,
        width: 200,
      },
    },

    title: {
      color: theme.palette.primary.dark,
    },

    text: {
      color: theme.palette.primary.main,
      textAlign: 'left',
      fontSize: 16,
      lineHeight: '30px',
      letterSpacing: '.5px',
    },
  })
)
