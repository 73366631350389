import { useWindowSize } from '../use-window-size'
import { ScreenSize } from '../../settings/device'
import { widthBreakpoint } from '../../settings/device'

export const useScreenSize = (): ScreenSize => {
  const { width } = useWindowSize()

  // eslint-disable-next-line functional/no-let
  let _deviceType = ScreenSize.Unknown

  if (width > widthBreakpoint) {
    _deviceType = ScreenSize.Desktop
  } else {
    _deviceType = ScreenSize.Mobile
  }

  return _deviceType
}
