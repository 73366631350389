import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useAppDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      paddingBottom: 12,
      paddingRight: 75,

      '& > h2': {
        display: 'flex',
        alignItems: 'center',
      },

      '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(0.75),
      },
    },

    dialogContent: {
      display: 'flex',
      alignItems: 'center',
    },

    dialogIcon: {
      '& .MuiSvgIcon-root': {
        fontSize: '3rem',
        marginRight: theme.spacing(2),
      },
    },

    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],

      '& .MuiSvgIcon-root': {
        marginRight: '0!important',
      },
    },
  })
)
