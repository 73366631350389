import {
  selectItemsList,
  selectLoading,
  selectQueryParams,
  selectTotalPages,
  selectNeedApply,
  selectFirstRender,
  selectIsFilterCollapsed,
  selectError,
} from '../../store/draft-items/selectors'
import { changeFilterCollapsedAction } from '../../store/draft-items/reducer'
import { selectUserStatus } from '../../store/auth/selectors'
import { useSelector } from 'react-redux'

//Todo need typing hook
export const useDraftItemsPage = () => {
  /*Items List*/
  const itemsList = useSelector(selectItemsList)

  /*Loading state*/
  const loading = useSelector(selectLoading)

  /*Query Params*/
  const query = useSelector(selectQueryParams)

  /*Total Pages*/
  const totalPages = useSelector(selectTotalPages)

  /*Need Apply*/
  const needApply = useSelector(selectNeedApply)

  /*First Render Status*/
  const firstRender = useSelector(selectFirstRender)

  /*Filter collapsed status*/
  const isFilterCollapsed = useSelector(selectIsFilterCollapsed)

  /*Error*/
  const error = useSelector(selectError)

  /*Activate user status*/
  const userStatus = useSelector(selectUserStatus)

  return {
    //props
    itemsList,
    loading,
    query,
    totalPages,
    needApply,
    firstRender,
    isFilterCollapsed,
    error,
    userStatus,

    //methode
    changeFilterCollapsedAction,
  }
}
