import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'

const homeListedItems = (state: RootState) => state.homeListedItems
export const selectLoading = createSelector(
  homeListedItems,
  (state) => state.loading
)
export const selectItemsList = createSelector(
  homeListedItems,
  (state) => state.itemsList
)
export const selectQueryParams = createSelector(
  homeListedItems,
  (state) => state.query
)
export const selectTotalPages = createSelector(
  homeListedItems,
  (state) => state.totalPages
)
export const selectSearchText = createSelector(
  homeListedItems,
  (state) => state.searchText
)
export const selectInitialMinPrice = createSelector(
  homeListedItems,
  (state) => state.initialMinPrice
)
export const selectInitialMaxPrice = createSelector(
  homeListedItems,
  (state) => state.initialMaxPrice
)
export const selectNeedApply = createSelector(
  homeListedItems,
  (state) => state.needApply
)
export const selectFirstRender = createSelector(
  homeListedItems,
  (state) => state.firstRender
)
export const selectCategories = createSelector(
  homeListedItems,
  (state) => state.categories
)
export const selectNonprofits = createSelector(
  homeListedItems,
  (state) => state.nonprofits
)
export const selectIsFilterCollapsed = createSelector(
  homeListedItems,
  (state) => state.isFilterCollapsed
)
export const selectError = createSelector(
  homeListedItems,
  (state) => state.error
)
