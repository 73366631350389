import { ActionType } from '../types'
import {
  ActivatePayload,
  LoginPayload,
  RegisterModalVisibleStateType,
  RegisterPayload,
  ForgotPasswordPayload,
} from '../../services/api/auth/models'
import { GetUserError, RequestError } from './reducer'

export type Register = ActionType<RegisterPayload>
export type Login = ActionType<LoginPayload>
export type ForgotPassword = ActionType<ForgotPasswordPayload>
export type Activate = ActionType<ActivatePayload>
export type SetRegisterModalOpen = ActionType<boolean>
export type SetRegisterModal = ActionType<RegisterModalVisibleStateType>
export type SetLoginModalOpen = SetRegisterModalOpen
export type SetForgotPasswordModalOpen = SetRegisterModalOpen

export enum IdentityProviderEnum{
  Google = 'Google',
  Facebook = 'Facebook',
  Local = 'email'
}

export type User = {
  readonly userId: number
  readonly email: string
  readonly name: string
  readonly shippingAddressExists?: boolean
  readonly identityProvider?: IdentityProviderEnum
}

export type RegisterSuccess = ActionType<User>
export type RegisterFailure = ActionType<RequestError[]>
export type LoginFailure = RegisterFailure
export type ForgotPasswordFailure = RegisterFailure
export type GetUserFailure = ActionType<GetUserError>
export enum ServerErrorCodes {
  incorrectPassword = 1,
  incorrectArguments = 2,
  incorrectEmail = 3,
  emailAlreadyExist = 4,
  userAlreadyLogged = 5,
  cantFindUserWithEmail = 6,
  cantFindUserWithEmailOnForgot = 8,
  invalidToken = 11,
}
