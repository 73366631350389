import React, { useState, useEffect } from 'react'
import { IconButton, Button } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useShipmentRateBlockStyle } from '../shippment-rate-block.styles'
import { RatesType } from '../../../../store/checkout-page/types'
import { RateItem } from '../rate-item'
import { useTranslation } from 'react-i18next'

//ListRateFrom Type
type ListRateFormType = {
  fullscreen: boolean
  setIsOpen: (value: boolean) => void
  rates: RatesType[]
  currentRate?: RatesType
  setSelectedRateByArrayIndex: (index: number) => void
}

//Component
export const ListRateForm: React.FC<ListRateFormType> = (
  props
): React.ReactElement => {
  //Props
  const {
    fullscreen,
    setIsOpen,
    rates,
    currentRate,
    setSelectedRateByArrayIndex,
  } = props

  //Temp Rate (Need For Temp Rate View)
  const [tempId, setTempId] = useState<string>(currentRate!.rateId)
  const [tempIdKey, setTempIdKey] = useState<number>(0)
  const { t } = useTranslation()

  //Set Current Temp Id
  useEffect(() => {
    setTempId(currentRate!.rateId)
  }, [])

  //Set Temp Rate
  const setTempRate = (key: number, tempRateId: string) => {
    setTempId(tempRateId)
    setTempIdKey(key)
  }

  //Change Rate In Store
  const changeRateStore = (key: number) => {
    setSelectedRateByArrayIndex(key)
    setIsOpen(false)
  }

  //Style
  const classes = useShipmentRateBlockStyle()

  //Return
  return (
    <div
      className={`${classes.paper} ${classes.modal} ${
        fullscreen && classes.fullScreen
      }`}
    >
      <IconButton
        className={classes.closeIcon}
        onClick={() => setIsOpen(false)}
      >
        <Close />
      </IconButton>

      {/* Rate list*/}
      <div className={`${classes.rateListHolder}`}>
        <div className={`${classes.ratesListWrapper}`}>
          {rates.map((item, key) => (
            <div key={key} onClick={() => setTempRate(key, item.rateId)}>
              <RateItem
                bordered
                selected={tempId === item.rateId}
                currentRate={item}
              />
            </div>
          ))}
        </div>
      </div>

      {/*Btn Block*/}
      <div className={`${classes.btnBlock}`}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          className={`${classes.changeRateBtn}`}
          onClick={() => changeRateStore(tempIdKey)}
        >
          {t('checkout.ok')}
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => setIsOpen(false)}
        >
          {t('checkout.cancel')}
        </Button>
      </div>
    </div>
  )
}
