import { makeStyles } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const usePageWrapperStyles = makeStyles(() =>
  createStyles({
    profilePage: {
      position: 'relative',
      background: 'rgba(255, 255, 255, 1)',
      padding: '40px 30px 80px 30px',
    },
    pageHolder: {
      position: 'relative',
    },
  })
)
