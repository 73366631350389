import React, { useState } from 'react'
import { PageWrapper } from '../page-wrapper'
import { SecurityComponent } from './security-component'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../../store/auth/selectors'
import { auth } from '../../../services/api/auth'
import { logout, setEmail } from '../../../store/auth/reducer'
import { useTranslation } from 'react-i18next'
import { ApiError } from '../../../services/api/api-error-data'
import { useNavigate } from 'react-router-dom'
import {
  ChangeResponseStatusType,
  RequestDataType,
} from './security-component/security-component.constants'
import { useAppDialog } from '../../../hooks/use-app-dialog'
import { Button } from '@material-ui/core'
import { AppDialogVariants } from '../../../store/app-dialog/types'
import {trackException} from '../../../store/app-insights/reducer'

export enum ChangeRequestTypes {
  Email,
  Password,
}

export const SecurityPage: React.FC = () => {
  const {email, identityProvider} = useSelector(selectUser)
  const userStatus = useSelector(selectUser).status
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setAppDialog, setIsShow } = useAppDialog()
  const [loading, setLoading] = useState<boolean>(false)

  //Change Email Success
  const changeEmailSuccess = (email: string) => {
    dispatch(setEmail(email))
    setAppDialog({
      isShow: true,
      title: t('accountSettings.security.email.success.title'),
      message: t('accountSettings.security.email.success.message'),
    })
  }

  //Change Email Failed
  const changeEmailFailed = (e: Error) => {
    setAppDialog({
      variant: AppDialogVariants.error,
      isShow: true,
      title: t('accountSettings.security.errorTitle'),
      message: new ApiError(e).getErrorMessage(t),
      actions: null,
    })
  }

  //Change Password Success
  const changePasswordSuccess = () => {
    setAppDialog({
      isShow: true,
      title: t('accountSettings.security.password.success.title'),
      message: t('accountSettings.security.password.success.message'),
    })
  }

  //Change Password Failed
  const changePasswordFailed = (error: Error) => {
    setAppDialog({
      isShow: true,
      message: new ApiError(error).getErrorMessage(t),
      title: t('accountSettings.security.errorTitle'),
      actions: null,
      variant: AppDialogVariants.error,
    })
  }

  const changeResponseHandler = (
    responseStatus: ChangeResponseStatusType,
    data: RequestDataType,
    error?: Error
  ) => {
    //Change Email Response Handlers
    responseStatus === ChangeResponseStatusType.Success &&
      data &&
      changeEmailSuccess(data as string)
    responseStatus === ChangeResponseStatusType.Error &&
      data &&
      changeEmailFailed(error!)

    //Change Password Response Handler
    responseStatus === ChangeResponseStatusType.Success &&
      data === null &&
      changePasswordSuccess()
    responseStatus === ChangeResponseStatusType.Error &&
      data === null &&
      changePasswordFailed(error!)
  }

  //Continue Handler
  const continueHandler = () => {
    auth
      .userDelete()
      .then(() => {
        setIsShow(false)
        dispatch(logout())
        navigate('/')
      })
      .catch((e) => {
        setAppDialog({
          variant: AppDialogVariants.error,
          actions: null,
          isShow: true,
          title: t('accountSettings.security.errorTitle'),
          message: new ApiError(e).getErrorMessage(t),
        })
        dispatch(trackException({e, source: 'logoutContinueHandler'}))
      })
  }

  //Delete Account Handler
  const deleteAccountHandler = () => {
    setAppDialog({
      actions: (
        <>
          <Button onClick={continueHandler}>{t('buttons.continue')}</Button>
          <Button onClick={() => setIsShow(false)}>
            {t('buttons.cancel')}
          </Button>
        </>
      ),
      isShow: true,
      title: t('accountSettings.security.delete.modalTitle'),
      message: t('accountSettings.security.delete.message'),
      variant: AppDialogVariants.warning,
    })
  }

  const activateAccountHandler = () => {
    setLoading(true)
    auth
      .resendActivateAccountEmail()
      .then(() => {
        setAppDialog({
          isShow: true,
          title: t('accountSettings.security.activate.success.title'),
          message: t('accountSettings.security.activate.success.message', {
            email,
          }),
        })
      })
      .catch((e) => {
        setAppDialog({
          variant: AppDialogVariants.error,
          actions: null,
          isShow: true,
          title: t('accountSettings.security.errorTitle'),
          message: new ApiError(e).getErrorMessage(t),
        })
        dispatch(trackException({e, source: 'activateAccountHandler'}))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <PageWrapper>
        <SecurityComponent
          identityProvider={identityProvider}
          changeEmailRequest={auth.changeEmail}
          changePasswordRequest={auth.changePassword}
          changeResponseHandler={changeResponseHandler}
          deleteAccountHandler={deleteAccountHandler}
          activateAccountRequest={activateAccountHandler}
          email={email}
          userStatus={userStatus}
          loading={loading}
        />
      </PageWrapper>
    </>
  )
}
