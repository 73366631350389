import React, { useEffect, useState } from 'react'
import { OwnItemDetailsComponent } from './own-item-details-component'
import { useOwnItemDetailsPage } from './own-item-details-page.hooks'
import { useOwnItemDetailsComponentStyles } from './own-item-details-page.styles'
import { Container, LinearProgress, Paper } from '@material-ui/core'
import { DraftEditor } from './draft-editor'
import { Alert } from '@material-ui/lab'

export const OwnItemDetailsPage: React.FC = () => {
  const classes = useOwnItemDetailsComponentStyles()
  const [editMode, setEditMode] = useState<boolean>(false)
  const {
    detailProps,
    getOwnItemDetails,
    loading,
    deliveryType,
    setInStoreDeliveryType,
    draftData,
    setImages,
    submitItem,
    initialImages,
    ownItemState,

    //Select Props
    categories,
    shippingAddressExists,
    setCategories,
    selectedCategory,
    setSelectedCategory,
    nonprofits,
    setNonprofits,
    selectedNonprofit,
    setSelectedNonprofit,
    categoryRequest,
    nonprofitRequest,
    isDirtyDraft,
    updateState,
    setIsDirtyDraft,
  } = useOwnItemDetailsPage()

  //Get Item Detail
  useEffect(() => {
    getOwnItemDetails()
  }, [])

  const closeDraftMode = () => {
    setEditMode(false)
    setIsDirtyDraft(false)
  }

  if (editMode) {
    return (
      <>
        <Container>
          <DraftEditor
            {...draftData}
            editItemState={ownItemState}
            submitItem={submitItem}
            isDirtyDraft={isDirtyDraft}
            getSelectedData={() => console.log('test')}
            categories={categories}
            shippingAddressExists={shippingAddressExists}
            setCategories={setCategories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            nonprofits={nonprofits}
            setNonprofits={setNonprofits}
            selectedNonprofit={selectedNonprofit}
            setSelectedNonprofit={setSelectedNonprofit}
            categoryRequest={categoryRequest}
            nonprofitRequest={nonprofitRequest}
            setImages={setImages}
            loading={loading}
            setIsDirtyDraft={setIsDirtyDraft}
            closeDraftMode={closeDraftMode}
            initialImages={initialImages}
          />
        </Container>
        {/*{loading && <AppProgressIndicator/>}*/}
      </>
    )
  }

  return (
    <>
      {loading ? (
        <LinearProgress />
      ) : (
        <Container>
          {detailProps && detailProps.isCurrentUserOwner ? (
            <Paper>
              <div className={classes.mainHolder}>
                <OwnItemDetailsComponent
                  {...detailProps}
                  editItemState={ownItemState}
                  loading={loading}
                  deliveryType={deliveryType}
                  updateState={updateState}
                  setInStoreDeliveryType={setInStoreDeliveryType}
                  onChangeMode={(mode: boolean) => setEditMode(mode)}
                />
              </div>
            </Paper>
          ) : (
            <div className={classes.alertHolder}>
              <Alert severity={'warning'}>This is not your item</Alert>
            </div>
          )}
        </Container>
      )}
    </>
  )
}
