import './app.css'
import React from 'react'
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'
import { AppInsightsInit } from './app.functions'
import { AppWrapper } from './app-wrapper'
import { MsalProvider } from '@azure/msal-react'

const reactPlugin = new ReactPlugin()

type AppPropsType = {
  msalInstance: any
}

function App (props: AppPropsType): React.ReactElement {
  const { msalInstance } = props
  AppInsightsInit(reactPlugin)
  return <MsalProvider instance={msalInstance}>
    <AppWrapper />
  </MsalProvider>
}

export default withAITracking(reactPlugin, App)
