import React, { useEffect } from 'react'
import { ListedItemDetailsComponent } from './listed-item-details-component'
import { useListedItemDetailsPage } from './listed-item-details-page.hooks'
import { useListedItemDetailsComponentStyles } from './listed-item-details-page.styles'
import { Container, LinearProgress, Paper } from '@material-ui/core'

export const ListedItemDetailsPage: React.FC = () => {
  const classes = useListedItemDetailsComponentStyles()
  const {
    detailProps,
    getListedItemDetails,
    loading,
    deliveryType,
    setInStoreDeliveryType,
  } = useListedItemDetailsPage()

  //Get Item Detail
  useEffect(() => {
    getListedItemDetails()
  }, [])

  return (
    <>
      {loading && <LinearProgress />}
      <Container>
        <Paper>
          <div className={classes.mainHolder}>
            {/*@ts-ignore*/}
            <ListedItemDetailsComponent
              {...detailProps}
              loading={loading}
              deliveryType={deliveryType}
              setInStoreDeliveryType={setInStoreDeliveryType}
            />
          </div>
        </Paper>
      </Container>
    </>
  )
}
