import React from 'react'
import { useDateSortStyles } from './sort-date.styles'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { useMediaQuery } from '@material-ui/core'
import { widthBreakpoint } from '../../settings/device'
import { useTranslation } from 'react-i18next'

type DateSortPropsType = {
  changeHandler: (value: number) => void
  value: number
}

export const SortDate: React.FC<DateSortPropsType> = ({
  changeHandler,
  value,
}): React.ReactElement => {
  //Styles
  const { sortDateHolder, sortDateItem, active, nameLabel } =
    useDateSortStyles()

  const { t } = useTranslation()

  const matches = useMediaQuery(`(min-width:${widthBreakpoint + 1}px)`)

  return (
    <div className={sortDateHolder}>
      {!matches && (
        <span className={`${nameLabel}`}>{t('sortByDate.mobileLabel')}:</span>
      )}
      <div
        className={[sortDateItem, `${value === 0 ? active : ''}`].join(' ')}
        onClick={() => changeHandler(0)}
      >
        {matches && <span>{t('sortByDate.olderFirst')} </span>}
        <ArrowUpwardIcon />
      </div>
      <div
        className={[sortDateItem, `${value === 1 ? active : ''}`].join(' ')}
        onClick={() => changeHandler(1)}
      >
        {matches && <span>{t('sortByDate.newestFirst')} </span>}{' '}
        <ArrowDownwardIcon />
      </div>
    </div>
  )
}
