import { makeStyles } from '@material-ui/core/styles'
import { createStyles, Theme } from '@material-ui/core'

export const useDraftEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 20,
      margin: '0 auto',
      marginTop: '10px',
      maxWidth: '1400px',
    },
    //Header
    header: {
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerMobile: {},
    formContainer: {
      padding: 30,
    },
    inputHolder: {
      marginBottom: 20,
    },
    shippingAddressAlert: {
      paddingTop: 20,
    },
    headerTitle: {
      position: 'relative',
      display: 'inline-block',
      marginRight: 'auto',
    },

    btnHolder: {
      display: 'flex',
    },

    cancelBtn: {
      marginLeft: 'auto',
      marginRight: theme.spacing(1),
    },

    loading: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: '40%',
      left: '50%',
      zIndex: 1,
    }
  })
)
