//Props type
type UseTitlePropsType = {
  setTitle: (title: string) => void
}

export const useTitle = (): UseTitlePropsType => {
  const setTitle = (title: string) => {
    document.title = title
  }
  return {
    setTitle,
  }
}
