import React from 'react'

export const useMenuForAuthenticated = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const myAccountClickHandler = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  return {
    anchorEl,
    setAnchorEl,
    myAccountClickHandler,
  }
}
