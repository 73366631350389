import {
  Form,
  FormControl,
  FormControlValue,
  UseFormProps,
  UseFormReturnType,
} from './use-form.types'
import { ChangeEvent, FocusEvent, FormEvent, useEffect, useState } from 'react'
import { createForm } from './use-form.utils'
import { useSelector } from 'react-redux'
import { RequestError } from '../../store/auth/reducer'

export type UseFormHookProps = Omit<UseFormProps, 'children'>

export const useForm = (hookProps: UseFormHookProps): UseFormReturnType => {
  const {
    initialValues,
    onSubmit,
    validate,
    reduxErrorsSelector,
    formValueChanges,
    isAuthrequiredHandler,
    isAuth,
    checkOptionalHandler,
    checkOptionalObject,
    changeMiddleware,
  } = hookProps
  const reduxErrors: readonly RequestError[] = useSelector(
    reduxErrorsSelector ? reduxErrorsSelector : () => []
  )
  const [form, setForm] = useState(createForm(initialValues))
  /*
   *Redux Error Handler
   */
  const updateReduxErrorsStatus = (prevValue: Form) => {
    const prevValueArray = Object.entries(prevValue)
    const entries = prevValueArray.map(([key, value]) => {
      return [key, { ...value, reduxErrors }]
    })
    return Object.fromEntries(entries)
  }

  /*
   *Use Effect for Redux Errors
   */
  useEffect(() => {
    setForm(updateReduxErrorsStatus)
  }, [reduxErrorsSelector && reduxErrors])

  /*
   *Create Input Handler
   */
  const createInputHandler = (
    event:
      | ChangeEvent<HTMLInputElement>
      | FocusEvent<HTMLInputElement>
      | string,
    formValues: Partial<FormControl>
  ) => {
    typeof event !== 'string' && event.persist()
    const inputName: string =
      typeof event !== 'string'
        ? (event.target.getAttribute('name') as string)
        : event

    const updatedFormValue = {
      ...form,
      [inputName]: {
        ...form[inputName],
        ...formValues,
      },
    }

    const _errors = validate(updatedFormValue)
    const errors = checkOptionalHandler
      ? checkOptionalHandler(checkOptionalObject!, _errors, updatedFormValue)
      : _errors

    setForm(updatedFormValue)
    formValueChanges &&
      formValueChanges({
        formValue: getFormValues(updatedFormValue),
        errors,
        valid: !Object.values(errors).every((err) => err === undefined),
      })
  }

  /*
   *Set Value Handler
   */
  const setValueHandler = (fieldName: string, value: string) => {
    //eslint-disable-next-line
    if (!isAuth && isAuthrequiredHandler) {
      return isAuthrequiredHandler()
    }
    createInputHandler(fieldName, {
      value: value,
      dirty: true,
      touched: true,
      focused: true,
      reduxErrors: [],
    })
  }

  /*
   *On Change Handler
   */
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line functional/no-conditional-statement
    if (!isAuth && isAuthrequiredHandler) {
      return isAuthrequiredHandler()
    }

    /* A method that we can call while making changes to forms */
    changeMiddleware && changeMiddleware()

    createInputHandler(event, {
      value: event.target.value,
      dirty: true,
      touched: true,
      focused: true,
      reduxErrors: [],
    })
  }

  /*
   *On Currency Change Handler
   */
  const onCurrencyChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: any
  ) => {
    // eslint-disable-next-line functional/no-conditional-statement
    if (!isAuth && isAuthrequiredHandler) {
      return isAuthrequiredHandler()
    }
    changeMiddleware && changeMiddleware(event)

    //eslint-disable-next-line
    createInputHandler(event, {
      value,
      dirty: true,
      touched: true,
      focused: true,
      reduxErrors: [],
    })
  }

  /*
   * On Blur Handler
   */
  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    createInputHandler(event, { touched: true, focused: false })
  }

  /*
   *On Focus Handler
   */
  const onFocus = (event: FocusEvent<HTMLInputElement>) => {
    if (!isAuth && isAuthrequiredHandler) {
      return isAuthrequiredHandler()
    }
    //eslint-disable-next-line
    createInputHandler(event, { touched: true, focused: false })
  }

  /*
   *Get Form Values Handler
   */
  const getFormValues = (
    form: Form
  ): { readonly [key: string]: FormControlValue } =>
    Object.fromEntries(
      Object.entries(form).map(([key, formControl]) => [key, formControl.value])
    )

  /*
   *Submit Handler
   */
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    onSubmit(getFormValues(form))
  }

  const values = getFormValues(form)

  return {
    form,
    values,
    onCurrencyChange,
    errors: validate(form),
    // valueSetHandler,
    inputHandlers: { onChange, onBlur, onFocus },
    customHandlers: { setValueHandler },
    handleSubmit,
  }
}
