import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { ItemDetails } from '../../models'
import { listedItemsApi } from '../../services/api/listed-items'
import { SimpleFunc } from '../../models'
import { ApiError } from '../../services/api/api-error-data'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectDeliveryType } from '../../store/checkout-page/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { setDeliveryType } from '../../store/checkout-page/reducer'
import { useAppDialog } from '../../hooks/use-app-dialog'
import { AppDialogVariants } from '../../store/app-dialog/types'
import {trackException} from '../../store/app-insights/reducer'

type UseListedItemDetailsPage = {
  detailProps?: ItemDetails
  loading: boolean
  getListedItemDetails: SimpleFunc
  deliveryType: string
  setInStoreDeliveryType: (value: string) => void
}

export const useListedItemDetailsPage = (): UseListedItemDetailsPage => {
  const navigate = useNavigate()
  const { itemId } = useParams()
  const dispatch = useDispatch()
  const [detailProps, setDetailProps] = useState<ItemDetails>()
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(true)
  const { setAppDialog } = useAppDialog()
  const deliveryType = useSelector(selectDeliveryType)

  //Get Listed Item Details
  const getListedItemDetails = () => {
    listedItemsApi
      .readDetails(itemId ? `${itemId}` : '')
      .then((data) => {
        setLoading(false)
        setDetailProps(data)
      })
      .catch((reason) => {
        setAppDialog({
          isShow: true,
          title: 'Error',
          message: (reason as ApiError).getErrorMessage(t),
          variant: AppDialogVariants.error,
          actions: null,
          closeHandler: () => navigate('/'),
        })
        setLoading(false)
        dispatch(trackException({e: reason, source: 'getListedItemDetails'}))
      })
  }

  const setInStoreDeliveryType = (value: string) => {
    dispatch(setDeliveryType(value))
  }

  return {
    detailProps,
    loading,
    getListedItemDetails,
    deliveryType,
    setInStoreDeliveryType,
  }
}
