import React from 'react'
import { usePageWrapperStyles } from './page-wrapper.styles'

type PageWrapperProps = {
  children: React.ReactNode
}
/*Wrapper component for setting common styles for all pages in the account section*/
export const PageWrapper: React.FC<PageWrapperProps> = (
  props: PageWrapperProps
): React.ReactElement => {
  const { children } = props
  const classes = usePageWrapperStyles()
  return (
    <div className={classes.pageHolder}>
      <div className={classes.profilePage}>{children}</div>
    </div>
  )
}
