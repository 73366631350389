import { axiosInstance } from '../../../api'

export type LookupDataProps = {
  pageSize: string
  pageIndex: string
  categoryId: string
  searchName: string
}

export type ExternalNonprofitData = {
  nonProfitId: string,
  externalId: string,
  name: string,
  categoryId: number
}

export const nonprofitsApi = {
  categories(): Promise<any> {
    return axiosInstance.get('nonprofits/categories').then(({ data }) => data)
  },
  lookupData(lookupDataProps: LookupDataProps): Promise<any> {
    return axiosInstance
      .get('nonprofits/lookupdata', { params: { ...lookupDataProps } })
      .then(({ data }) => data)
  },
  getInfoByExternalId(id: string): Promise<ExternalNonprofitData>{
    return axiosInstance.get(`nonprofits/external/${id}`).then(({data})=> data)
  }
}
