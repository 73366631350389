import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../types'
const selector = (state: RootState) => state.checkoutPage
export const selectItemDetails = createSelector(
  selector,
  (state) => state.itemDetails
)
export const selectItemLoading = createSelector(selector, (state) => ({
  itemLoadStatus: state.itemLoadStatus,
  itemLoadError: state.itemLoadError,
}))
export const selectPrices = createSelector(selector, (state) => state.prices)
export const selectOrderDetails = createSelector(
  selector,
  (state) => state.orderDetails
)
export const selectOrderLoading = createSelector(selector, (state) => ({
  orderStatus: state.orderStatus,
  orderError: state.orderError,
}))
export const selectDeliveryType = createSelector(
  selector,
  (state) => state.deliveryType
)
export const selectAddressFormDirty = createSelector(
  selector,
  (state) => state.addressFormDirty
)
export const selectAddress = createSelector(selector, (state) => state.address)
export const selectRegions = createSelector(selector, (state) => state.regions)
export const selectAddressLoading = createSelector(
  selector,
  (state) => state.addressLoading
)
export const selectIsValidAddress = createSelector(
  selector,
  (state) => state.isAddressValidated
)
export const selectIsShowAlert = createSelector(
  selector,
  (state) => state.isShowAlert
)
export const selectRates = createSelector(selector, (state) => state.rates)
export const selectCurrentRate = createSelector(
  selector,
  (state) => state.currentRate
)
export const selectRatesLoading = createSelector(
  selector,
  (state) => state.ratesLoading
)
export const selectRatesError = createSelector(
  selector,
  (state) => state.ratesError
)
export const selectRateMessages = createSelector(
  selector,
  (state) => state.rateMessages
)
export const selectSaveAddressError = createSelector(
  selector,
  (state) => state.saveAddressError
)
