import React from 'react'
import { useShipmentRateBlockStyle } from '../shippment-rate-block.styles'
import { RatesType } from '../../../../store/checkout-page/types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

//Current Rate Type
type CurrentRatesType = {
  currentRate: RatesType
  bordered?: boolean
  selected?: boolean
}

export const RateItem: React.FC<CurrentRatesType> = (
  props
): React.ReactElement => {
  const { currentRate, bordered, selected } = props

  const classes = useShipmentRateBlockStyle()
  return (
    <div
      className={`${classes.rateItem} ${bordered && classes.rateItemBorder} ${
        selected && classes.rateSelectedItem
      }`}
    >
      {selected && (
        <div className={classes.rateItemIconSelected}>
          <CheckCircleIcon />
        </div>
      )}

      <div className={classes.rateLogo}>
        <img src={currentRate.providerImage200Url} alt="" />
      </div>
      <div className={classes.rateInfoHolder}>
        <span className={classes.rateAmount}>${currentRate.amount}</span>
        <span className={classes.serviceLevelName}>
          {currentRate.serviceLevelName}
        </span>
        <span className={classes.rateInfo}>{currentRate.durationTerms}</span>
      </div>
    </div>
  )
}
