import { makeStyles, Theme } from '@material-ui/core/styles'
import { createStyles } from '@material-ui/core'

export const useEmptyImageComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyImage: {
      paddingTop: '75%',
      background: 'red',
      backgroundColor: `${theme.palette.action.hover}`,
      position: 'relative',
    },
    iconWrapper: {
      position: 'absolute',
      top: '50%',
      left: 0,
      right: 0,
      fontSize: '10em',
      opacity: 0.1,
      textAlign: 'center',
      marginTop: -100,
    },
  })
)
