import React, { useEffect } from 'react'
import { useOrderPageStyles } from './order-page.styles'
import { Grid, Card, Container } from '@material-ui/core'
import { OrderHeader } from './order-header'
import { OrderPriceInfo } from './order-price-info'
import { OrderItemInfo } from './order-item-info'
import Button from '@material-ui/core/Button'
import { OrderModal } from './order-modal'
import { useOrder } from './order-page.hook'
import { OrderStatus } from './order-header/order-header.constants'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../components/loader'
import { useAppDialog } from '../../hooks/use-app-dialog'

export const OrderPage: React.FC = () => {
  //Styles
  const classes = useOrderPageStyles()
  const { orderHeader, listedItem } = useOrder()
  const { t } = useTranslation()
  const { setAppDialog } = useAppDialog()

  const {
    //props
    isOpen,
    setIsOpen,
    fullscreen,
    loadingPage,
    loadingError,
    createShippingLabelError,

    //methods
    getOrderData,
    createShippingLabel,
  } = useOrder()

  //Error Handler
  useEffect(() => {
    if (!loadingPage && loadingError) {
      setAppDialog({
        isShow: true,
        variant: 'error',
        title: 'Error',
        message: loadingError.getErrorMessage(t),
        showCloseButton: false,
        actions: (
          <Button onClick={() => window.location.replace('/')} color="primary">
            Ok
          </Button>
        ),
      })
    } else if (!loadingPage && createShippingLabelError) {
      setIsOpen(false)
      setAppDialog({
        isShow: true,
        variant: 'error',
        title: 'Error',
        message: createShippingLabelError.getErrorMessage(t),
        actions: null,
      })
    }
  }, [loadingPage, loadingError, createShippingLabelError])

  //Get Order Data
  useEffect(() => {
    getOrderData()
  }, [])

  //Get Status
  const getStatus = (): string => {
    const key: number = orderHeader.status
    return t(`orderPage.${OrderStatus.get(key)}`) || ''
  }

  //Create Label Text
  const buttonLabel = () => {
    return orderHeader.shipment && orderHeader.shipment.shippingLabelUrl
      ? t('orderPage.printLabel')
      : t('orderPage.createLabel')
  }

  //Order Page
  return (
    <div className={classes.pageHolder}>
      <Container>
        <Card>
          {/*Loader */}
          {loadingPage && (
            <div style={{ padding: '20px 0' }}>
              <Loader />
            </div>
          )}

          {/*Order Page Body*/}
          {!loadingPage && !loadingError && (
            <>
              {/*Order Header*/}
              <Grid container>
                <Grid item xs={12}>
                  <OrderHeader
                    orderNumber={orderHeader.orderNumber}
                    status={getStatus()}
                  />
                </Grid>
              </Grid>

              {/*Order Body*/}
              <div className={classes.orderBody}>
                <div className={classes.orderBodyHolder}>
                  <Grid container spacing={3}>
                    {/*Grid Price Info*/}
                    <Grid item xs={12} md={4}>
                      <OrderPriceInfo orderHeader={orderHeader} />
                    </Grid>

                    {/*Grid Item Info*/}
                    <Grid item xs={12} md={8}>
                      <OrderItemInfo
                        orderHeader={orderHeader}
                        listedItem={listedItem}
                      />
                    </Grid>

                    {/*Grid Item Info*/}
                    <Grid item xs={12}>
                      <div className={classes.buttonBlock}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => createShippingLabel()}
                        >
                          {buttonLabel()}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>

              {/*Order Modal*/}
              <div className={classes.orderModalWrapper}>
                <OrderModal
                  labelImgUrl={
                    orderHeader.shipment
                      ? orderHeader.shipment.shippingLabelUrl
                      : ''
                  }
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  fullscreen={fullscreen}
                />
              </div>
            </>
          )}
        </Card>
      </Container>
    </div>
  )
}
