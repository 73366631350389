import { createSlice } from '@reduxjs/toolkit'
import { ActionType } from "../types";

const appInsightsSlice = createSlice({
  name: 'appInsights',
  initialState: {},
  reducers: {
    trackException(_, action: ActionType<{source?: string, e: any}>){
      const {e, source} = action.payload
      window.appInsights.trackException({
          exception: new Error(JSON.stringify(e))
        },
      source ? { source } : {}
      )
    }
  }
})
export const appInsightsReducer = appInsightsSlice.reducer
export const {
  trackException
} = appInsightsSlice.actions
