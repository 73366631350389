import React from 'react'

export const transformToMultiLine = (str: string) => {
  return str.split('\n').map((x, i) => (
    <span key={i}>
      {x}
      <br />
    </span>
  ))
}
