/*eslint-disable-next-line*/
import React from 'react'
import { useEmptySearchComponentComponentStyles } from './empty-search-result.component.styles'

type EmptySearchResultProps = {
  text?: string
}

export const EmptySearchResultComponent: React.FC<EmptySearchResultProps> = (
  props
) => {
  const { text } = props
  const classes = useEmptySearchComponentComponentStyles()
  return (
    <div className={classes.emptySearchResultComponentHolder}>
      <img
        src="/images/empty-search-result.svg"
        alt="empty-search-result"
        className={classes.icon}
        height={70}
      />
      <span className={classes.textBlock}>
        {text ? `${text}` : 'No search results'}
      </span>
    </div>
  )
}
