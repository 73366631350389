import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useState } from 'react'
import stripeJs, { PaymentMethod, StripeCardElement } from '@stripe/stripe-js'

interface IStripeError {
  code?: string
  message?: string
}

export interface IBillingDetails {
  email: string
  name: string
}

interface IUseStripeFormProps {
  onPaymentMethodReceived: (paymentMethod: PaymentMethod) => void
  onError: (message?: string) => void
  billingDetails: IBillingDetails
}

export const useStripeForm = (props: IUseStripeFormProps) => {
  const { onPaymentMethodReceived, onError, billingDetails } = props
  const stripe = useStripe()
  const elements = useElements()

  const [error, _setError] = useState<IStripeError>()
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)

  const setError = (error?: IStripeError) => {
    onError(error?.message)
    _setError(error)
  }

  const onSubmitHandler = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault()
    const cardElement = elements?.getElement('card') as StripeCardElement

    // when processing or stripe is not loaded yet
    if (!canPay || !stripe) {
      console.warn('Payment system is not ready yet')
      return
    }

    if (!cardElement) {
      console.warn('Card component not found')
      return
    }

    if (error) cardElement.focus()
    if (cardComplete) setProcessing(true)

    const stripeResponse = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        email: billingDetails.email,
        name: billingDetails.name,
      },
    })

    setProcessing(false)

    if (stripeResponse.error)
      setError({
        code: stripeResponse.error.code,
        message: stripeResponse.error.message,
      })
    else {
      setError(undefined)
      onPaymentMethodReceived(stripeResponse.paymentMethod)
    }
  }

  const onCardDataChanged = (
    event: stripeJs.StripeCardElementChangeEvent
  ): void => {
    setError(event.error)
    setCardComplete(event.complete)
  }

  const canPay = stripe && !error

  return {
    processing,
    canPay,
    error,

    onSubmitHandler,
    onCardDataChanged,
  }
}
