import React, { useEffect } from 'react'
import { UploadArea } from './upload-area'
import { ItemImage } from './item-image'
import { Grid } from '@material-ui/core'
import { useItemImagesStyles } from './item-images.styles'
import { IItemImagesProps } from './item-images.interfaces'
import { useItemImages } from './item-images.hook'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import { DragAndDropper } from '../../../components/drag-and-dropper'

export const ItemImages: React.FC<IItemImagesProps> = (
  props
): React.ReactElement => {
  const classes = useItemImagesStyles()
  const { t } = useTranslation()
  const {
    // methods
    onFilesSelectedHandler,
    onDeleteHandler,
    buildImageUrl,
    onDragOverHandler,
    onDragEndHandler,
    onDragStartHandler,
    onDragEnterHandler,
    initial,

    // values
    uploadingProgress,
    currentDraggingElementId,
    imageItemsList,
  } = useItemImages(props)

  /* Set Initial images */
  useEffect(() => {
    initial()
  }, [])

  const showAlert = () => {
    return imageItemsList.length >= 10 || !imageItemsList.length
  }

  const alertText = () => {
    return imageItemsList.length >= 10
      ? t('donation.imageUploadLimit')
      : t('donation.imgRequired')
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      {showAlert() && (
        <Grid item xs={12}>
          <Alert severity="info">{alertText()}</Alert>
        </Grid>
      )}

      {/*If imageIds is empty*/}
      {imageItemsList.length < 10 && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <UploadArea
            uploadingProgress={uploadingProgress}
            onFilesSelectedHandler={onFilesSelectedHandler}
          />
        </Grid>
      )}

      {/*If there are imageObjects*/}
      {imageItemsList.map(({ isTemp, id }, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={`img-${id}`}>
          <DragAndDropper
            draggingStyleClass={
              currentDraggingElementId === id ? classes.draggingElement : ''
            }
            onDragStart={() => onDragStartHandler(id, index)}
            onDragEnd={() => onDragEndHandler()}
            onDropEnter={() => {
              onDragEnterHandler(index, id)
            }}
            onDragOver={(e) => onDragOverHandler(e)}
          >
            <ItemImage
              imageUrl={buildImageUrl(id, isTemp)}
              onDeleteHandler={() => onDeleteHandler(id)}
            />
            {index === 0 && (
              <span className={classes.defaultLabel}>
                {t('donation.defaultImgLabel')}
              </span>
            )}
          </DragAndDropper>
        </Grid>
      ))}
    </Grid>
  )
}
