import {
  selectItemsList,
  selectLoading,
  selectQueryParams,
  selectTotalPages,
  selectInitialMinPrice,
  selectInitialMaxPrice,
  selectNeedApply,
  selectFirstRender,
  selectCategories,
  selectNonprofits,
  selectIsFilterCollapsed,
  selectError,
  selectSearchText,
} from '../../store/home-page-items/selectors'
import { useSelector } from 'react-redux'
import { InitialStateType } from '../../store/home-page-items/contracts'

type UseHomePageType = InitialStateType

export const useHomePage = (): UseHomePageType => {
  /*Items List*/
  const itemsList = useSelector(selectItemsList)

  /*Loading state*/
  const loading = useSelector(selectLoading)

  /*QUery Params*/
  const query = useSelector(selectQueryParams)

  /*Total Pages*/
  const totalPages = useSelector(selectTotalPages)

  /*Text Search*/
  const searchText = useSelector(selectSearchText)

  /*Initial Max Price*/
  const initialMaxPrice = useSelector(selectInitialMaxPrice)

  /*Initial Min Price*/
  const initialMinPrice = useSelector(selectInitialMinPrice)

  /*Need Apply*/
  const needApply = useSelector(selectNeedApply)

  /*First Render Status*/
  const firstRender = useSelector(selectFirstRender)

  /*Category List*/
  const categories = useSelector(selectCategories)

  /*Nonprofits List*/
  const nonprofits = useSelector(selectNonprofits)

  /*Filter collapsed status*/
  const isFilterCollapsed = useSelector(selectIsFilterCollapsed)

  /*Error*/
  const error = useSelector(selectError)

  return {
    itemsList,
    loading,
    query,
    totalPages,
    initialMinPrice,
    initialMaxPrice,
    needApply,
    firstRender,
    categories,
    nonprofits,
    isFilterCollapsed,
    error,
    searchText,
  }
}
