import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import { BrowserRouter, Outlet } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import './index.css'
import App from './app'
import theme from './theme'
import { Provider } from 'react-redux'
import { store } from './store/store'
import './i18n'
import { Loader } from './components/loader'
import {msalInstance} from './api'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLDivElement
)
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Loader />}>
          <App msalInstance={msalInstance} />
          <Outlet />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </ThemeProvider>
)

serviceWorker.unregister()
