export enum Locale {
  US = 'en-US',
  DE = 'de-DE',
}

export enum CurrencyList {
  USD = 'USD',
  EUR = 'EUR',
}

type OptionsType = {
  style: string
  currency: CurrencyList
}

export const currencyTransform = (
  number: number,
  code: Locale,
  options: OptionsType
) => {
  return new Intl.NumberFormat(code, options).format(number)
}
