import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { ListedItemPriceData } from './checkout-page.models'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../store/auth/selectors'
import { PaymentMethod } from '@stripe/stripe-js'
import { getUser } from '../../store/auth/reducer'
import {
  selectItemDetails,
  selectItemLoading,
  selectOrderDetails,
  selectOrderLoading,
  selectDeliveryType,
  selectIsValidAddress,
  selectIsShowAlert,
  selectAddress,
  selectRatesError,
  selectAddressLoading,
  selectRatesLoading,
  selectRateMessages,
  selectRates,
} from '../../store/checkout-page/selectors'
import { LoadingState } from '../../store/types'
import {
  createOrder,
  loadItemDetails,
  setPrices,
  setDeliveryType,
} from '../../store/checkout-page/reducer'
import { IBillingDetails } from './stripe-form/stripe-form.hook'
import { AddressDataType } from './address-block/address-block.hook'
import { CreateOrderRequest } from '../../services/api/orders'
import { selectCurrentRate } from '../../store/checkout-page/selectors'

export const useCheckoutPage = () => {
  const { urlItemId, urlDeliveryType } = useParams()
  const dispatch = useDispatch()

  const userInfo = useSelector(selectUser)
  const itemDetails = useSelector(selectItemDetails)
  const { itemLoadStatus, itemLoadError } = useSelector(selectItemLoading)
  const orderDetails = useSelector(selectOrderDetails)
  const { orderStatus, orderError } = useSelector(selectOrderLoading)
  const deliveryType = useSelector(selectDeliveryType)
  const isValidAddress = useSelector(selectIsValidAddress)
  const isCurrentUserOwner = itemDetails && itemDetails.isCurrentUserOwner
  const isShowAlert = useSelector(selectIsShowAlert)
  const address = useSelector(selectAddress) as AddressDataType
  const ratesError = useSelector(selectRatesError)
  const addressLoading = useSelector(selectAddressLoading)
  const ratesLoading = useSelector(selectRatesLoading)
  const currentRate = useSelector(selectCurrentRate)
  const rates = useSelector(selectRates)
  const rateMessages = useSelector(selectRateMessages)

  //Get User Info
  useEffect(() => {
   dispatch(getUser())
  }, [])

  // Read listed item information
  useEffect(() => {
    if (userInfo && itemLoadStatus !== LoadingState.LOADING)
      dispatch(loadItemDetails(urlItemId!))
  }, [userInfo, urlDeliveryType])

  //Set Delivery Type in Store
  useEffect(() => {
    dispatch(setDeliveryType(urlDeliveryType!))
  }, [])

  // calculate prices
  useEffect(() => {
    if (itemLoadStatus !== LoadingState.LOADED || !itemDetails) return

    //TODO us translation hook
    const prices: ListedItemPriceData[] = [
      { title: 'Item price', amount: itemDetails.currentPrice },
      { title: 'Tax', amount: 0 },
      { title: 'Shipping', amount: 0 },
    ]

    dispatch(setPrices(prices))
  }, [itemLoadStatus])

  //Return condition form hide or show stripe form
  const isShowStripeForm = (): boolean => {
    return !(
      addressLoading ||
      ratesError ||
      ratesLoading ||
      (!rates.length && rateMessages.length) ||
      !isValidAddress
    )
  }

  const billingDetails: IBillingDetails | undefined = userInfo?.email
    ? { name: userInfo.name, email: userInfo.email }
    : undefined

  //Payment Methods
  const onPaymentMethodReceivedHandler = (
    paymentMethod: PaymentMethod
  ): void => {
    // is not possible situation, but let's log it
    if (!itemDetails) {
      console.warn('Cannot finish payment until item details not loaded')
      return
    }

    //const shipping = deliveryType === "local-pickup" ? 0 : 1
    const orderObject = {
      paymentMethod: paymentMethod.id,
      version: itemDetails.version,
      listedItemId: itemDetails.listedItemId,
      clientDateTime: new Date(),
      /*@ts-ignore*/
      //shipping,
      shipToName: address.name,
      shipToAddressLine1: address.addressLine1,
      shipToAddressLine2: address.addressLine2,
      shipToCity: address.city,
      shipToRegion: address.region,
      shipToPostalCode: address.postalCode,
      shipToCountryCode: address.countryCode,
    } as CreateOrderRequest

    deliveryType === 'shipping' &&
      currentRate &&
      (orderObject.providerShippingRateId = currentRate.rateId)

    dispatch(
      createOrder({
        ...orderObject,
      })
    )
  }

  return {
    //props
    itemLoadStatus,
    itemLoadError,
    billingDetails,
    orderDetails,
    orderStatus,
    orderError,
    isCurrentUserOwner,
    deliveryType,
    userInfo,
    isValidAddress,
    isShowAlert,
    ratesError,

    //methods
    onPaymentMethodReceivedHandler,
    isShowStripeForm,
  }
}
