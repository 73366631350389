import { OrderDetails } from '../../../models'
import { axiosInstance } from '../../../api'
import { ApiError } from '../api-error-data'
import { CreateOrderRequest, CreateShippingLabelRequest, ValidateParcelRequest, ValidateParcelResponse } from './models'
import { toDateTimeOffset } from '../../../utils'
import { RatesType } from '../../../store/checkout-page/types'
import { ResponseOrderDetailType } from '../../../store/order/types'

// Shipment Rate Request Type
export type RequestShipmentInfoType = {
  listedItemId: number
  addressLine1: string
  city: string
  region: string
  postalCode: string
  countryCode: string
  name: string
}

//Shipment Rate Response Type
export type ResponseShipmentInfoType = {
  providerShipmentId: string
  rates: RatesType[]
}

export const ordersApi = {
  create(request: CreateOrderRequest): Promise<OrderDetails> {
    const finalRequest = {
      ...request,
      ...{ clientDateTime: toDateTimeOffset(request.clientDateTime) },
    }
    return axiosInstance
      .post('order/create', { ...finalRequest })
      .then((response) => response.data as OrderDetails)
      .catch((error) => {
        throw new ApiError(error)
      })
  },

  async getShippmentRate(
    request: RequestShipmentInfoType
  ): Promise<ResponseShipmentInfoType> {
    try {
      const response = await axiosInstance.post('/order/available-shipping-rates', {
        ...request,
      })
      return response.data
    } catch (error) {
      throw new ApiError(error)
    }
  },

  async getOrderDetail(orderId: string): Promise<ResponseOrderDetailType> {
    try {
      const response = await axiosInstance.get(`/order/${orderId}`)
      return {
        ...response.data,
      }
    } catch (error) {
      throw new ApiError(error)
    }
  },

  async createShippingLabel(
    params: CreateShippingLabelRequest
  ): Promise<string> {
    try {
      const response = await axiosInstance.post(`/order/shippinglabel/create`, {
        ...params,
      })
      return response.data
    } catch (error) {
      throw new ApiError(error)
    }
  },

  async validateParcelRequest(
    params: ValidateParcelRequest
  ): Promise<ValidateParcelResponse> {
    try {
      const response = await axiosInstance.post(`/order/validate-parcel`, {
        ...params,
      })
      return response.data
    } catch (error) {
      throw new ApiError(error)
    }
  },
}
