import { createStyles, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const useAppHeaderDonateButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    donateLink: {
      color: 'white',
      textDecoration: 'none',
      fontSize: 16,
      display: 'inline-flex',
      background: '#3fb5a3',
      height: 37,
      padding: '0 35px',
      alignItems: 'center',
      borderRadius: 3,
      transition: 'background 300ms',

      '&:hover': {
        background: '#52d5c1',
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 15,
        padding: '0 25px',
      },

      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
  })
)
