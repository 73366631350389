import { call, takeEvery, put } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  getListedItems,
  getListedItemsSuccess,
  getListedItemsFailed,
  getItemsMaxPrice,
  getItemsMaxPriceSuccess,
  fetchCategories,
  fetchCategoriesSuccess,
  fetchNonprofits,
  fetchNonprofitsSuccess,
  setError,
} from './reducer'
import { homePageItems } from '../../services/api/home-page-items'
import { nonprofitsApi } from '../../services/api/nonprofits'
import { ApiError } from '../../services/api/api-error-data'
import {trackException} from '../app-insights/reducer'

/* GET LISTED ITEMS WHEN APPLY FILTER Changes */
export function* getListedItemsSaga({
  payload,
}: ReturnType<typeof getListedItems>): SagaIterator {
  try {
    const data = yield call(homePageItems.availableListedItems, payload)
    yield put(getListedItemsSuccess({ data }))
  } catch (e) {
    yield put(getListedItemsFailed())
    yield put(setError(e as ApiError))
    yield put(trackException({e, source: 'getListedItemsSaga'}))
  }
}

/* GET CATEGORY LIST */
export function* fetchNonprofitCategories(): SagaIterator {
  try {
    const data = yield call(nonprofitsApi.categories)
    yield put(fetchCategoriesSuccess(data || []))
  } catch (e) {
    yield put(setError(e as ApiError))
    yield put(trackException({e, source: 'fetchNonprofitCategories'}))
  }
}

/* GET NONPROFITS LIST */
export function* fetchNonprofitSaga({
  payload,
}: ReturnType<typeof fetchNonprofits>): SagaIterator {
  try {
    const data = yield call(nonprofitsApi.lookupData, payload)
    yield put(fetchNonprofitsSuccess(data || []))
  } catch (e) {
    yield put(yield put(setError(e as ApiError)))
    yield put(trackException({e, source: 'fetchNonprofitSaga'}))
  }
}

export function* getItemsMaxPriceSaga(): SagaIterator {
  try {
    const data = yield call(homePageItems.getMaxPrice)
    yield put(getItemsMaxPriceSuccess(data))
  } catch (e) {
    yield put(setError(e as ApiError))
    yield put(trackException({e, source: 'getItemsMaxPriceSaga'}))
  }
}

export function* homePageItemsSaga(): SagaIterator {
  yield takeEvery(getListedItems, getListedItemsSaga)
  yield takeEvery(fetchCategories, fetchNonprofitCategories)
  yield takeEvery(fetchNonprofits, fetchNonprofitSaga)
  yield takeEvery(getItemsMaxPrice, getItemsMaxPriceSaga)
}
