import {IdentityProviderEnum} from '../../../../store/auth/contracts'

type EmailType = string

export enum ModalFormMode {
  NonChanging,
  EmailForm,
  PasswordForm,
}

export enum UserStatus {
  Registered = 0,
  Active = 1,
  Archived = 2,
}

export type EmailQueryType = {
  email: string
}

export type PasswordQueryType = {
  passwordOld: string
  passwordNew: string
}

export type RequestDataType = EmailType | PasswordQueryType | null

export enum ChangeResponseStatusType {
  Error,
  Success,
}

export type SecurityComponentProps = {
  identityProvider: keyof typeof IdentityProviderEnum
  userStatus?: UserStatus
  email: string
  changeEmailRequest: (query: EmailQueryType) => Promise<any>
  changePasswordRequest: (query: PasswordQueryType) => Promise<any>
  activateAccountRequest: () => void
  deleteAccountHandler: () => void
  changeResponseHandler: (
    responseStatus: ChangeResponseStatusType,
    data: RequestDataType,
    error?: Error
  ) => void
  loading: boolean
}
