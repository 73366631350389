import {
  ActivatePayload,
  LoginPayload,
  RegisterPayload,
  UserUpdatePayload,
  ChangeEmailPayload,
  ChangePasswordPayload,
  EmailTypeToResend,
  ForgotPasswordPayload,
  ResetPasswordPayload, MsalEmptyDataAuthPayload,
} from './models'
import { User } from '../../../store/auth/contracts'
import { axiosInstance } from '../../../api'

export const auth = {
  me(): Promise<any> {
    return axiosInstance.get('account/me/info').then(({ data }) => data)
  },
  register(userData: RegisterPayload): Promise<User> {
    return axiosInstance
      .post('account/register', { ...userData })
      .then(({ data }) => data)
  },
  login(userData: LoginPayload): Promise<User> {
    return axiosInstance.post('account/login', { ...userData }).then(({ data }) => data)
  },
  forgotPassword(userData: ForgotPasswordPayload): Promise<User> {
    return axiosInstance
      .post('account/forgot-password', { ...userData })
      .then(({ data }) => data)
  },
  resetPassword(data: ResetPasswordPayload): Promise<void> {
    return axiosInstance
      .post('account/reset-password', { ...data })
      .then(({ data }) => data)
  },
  logout(): Promise<any> {
    return axiosInstance.post('account/logout').then((response) => response)
  },
  update(userData: UserUpdatePayload & MsalEmptyDataAuthPayload): Promise<any> {
    const {name} = userData
    return axiosInstance.put('account/me/info', { name }).then(({ data }) => data)
  },
  activate(token: ActivatePayload): Promise<any> {
    return axiosInstance.post('account/activate', { ...token }).then(({ data }) => data)
  },

  changeEmail(emailData: ChangeEmailPayload): Promise<void> {
    return axiosInstance
      .put('account/email/change', { ...emailData })
      .then(({ data }) => data)
  },
  changePassword(passwordData: ChangePasswordPayload): Promise<void> {
    return axiosInstance
      .post('account/me/change-password', { ...passwordData })
      .then(({ data }) => data)
  },
  resendActivateAccountEmail(): Promise<void> {
    return axiosInstance
      .post('account/email/resend', { emailType: EmailTypeToResend.Welcome })
      .then(({ data }) => data)
  },

  async userDelete(): Promise<any> {
    return axiosInstance.delete('account/me').then(({ data }) => data)
  },
}
