import React, {useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import { DonatePage } from '../../pages/donate-page'
import { Playground } from '../../pages/playground-page'
import { LoginPage } from '../../pages/login-page'
import { CharityPage } from '../../pages/charity-page'
import { AccountLayout } from '../../pages/account-page'
import { ActivatePage } from '../../pages/activate-page'
import { HomePage } from '../../pages/home-page'
import { CheckoutPage } from '../../pages/checkout-page'
import { ListedItemDetailsPage } from '../../pages/listed-item-details-page'
import { useSelector } from 'react-redux'
import {
  selectIsAuthenticated,
  selectCheckedAuth,
} from '../../store/auth/selectors'
import { Page401 } from '../../pages/page401'
import { CircularProgress } from '@material-ui/core'
import { OrderPage } from '../../pages/order-page'
import { ShopPage } from '../../pages/shop-page'
import { MyListedItemsPage } from '../../pages/my-listed-items-page'
import { OwnItemDetailsPage } from '../../pages/own-item-details-page'
import { DraftItemsPage } from '../../pages/draft-items-page/draft-items-page.component'
import { NotFoundPage } from '../../pages/not-found-page'
import { AboutPage } from '../../pages/about-page'
import {useMsal} from '@azure/msal-react'
import {useOverlayPreloader} from '../../hooks/use-overlay-preloader'
import {msalRequest} from '../../env-config'

export const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/playground" element={<Playground />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/donate" element={<DonatePage />}/>
      <Route path="/donate/:nonprofitId" element={<DonatePage />} />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/charity" element={<CharityPage />} />
      <Route
        path="/account/*"
        element={<AuthRequired basePath='/account' isAuthComponent={<AccountLayout />} />}
      />
      <Route
        path="/checkout/:urlItemId/:urlDeliveryType"

        //element={<CheckoutPage />}
        element={<AuthRequired  basePath='/checkout' isAuthComponent={<CheckoutPage />} />}
      />
      <Route path="/listed-items/:itemId" element={<ListedItemDetailsPage />} />
      <Route
        path="/selling/order/:orderId"
        element={<AuthRequired  basePath='/selling' isAuthComponent={<OrderPage />} />}
      />
      <Route
        path="/my-listed-items/:itemId"
        element={<AuthRequired  basePath='/my-listed-items' isAuthComponent={<OwnItemDetailsPage />} />}
      />
      <Route
        path="/my-listed-items"
        element={<AuthRequired basePath='/my-listed-items' isAuthComponent={<MyListedItemsPage />} />}
      />
      <Route
        path="/draft-items"
        element={<AuthRequired basePath='/draft-items' isAuthComponent={<DraftItemsPage />} />}
      />
      <Route path="user">
        <Route path="activate/:token" element={<ActivatePage />} />
        <Route path="email-verification/:token" element={<HomePage />} />
      </Route>
      <Route path="/about" element={<AboutPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

type AuthCheckerType = {
  isAuthComponent: React.ReactElement
  basePath: string
}

//AuthChecker - Component for Auth Check
const AuthRequired: React.FC<AuthCheckerType> = (props) => {
  const auth = useSelector(selectIsAuthenticated)
  const checkedAuth = useSelector(selectCheckedAuth)
  const { setIsShowOverlayPreloader } = useOverlayPreloader()
  const { isAuthComponent, basePath } = props
  const { instance } = useMsal()
  const { pathname } = useLocation()

  useEffect(()=> {
    if(checkedAuth && !auth){
      setIsShowOverlayPreloader(true)
      instance.loginRedirect({...msalRequest, redirectUri: basePath, state: pathname}).catch((error) => console.log(error))
    }
  }, [auth, checkedAuth])

  if (!checkedAuth) {
    return <LoaderRenderer />
  }

  return <>{auth ? isAuthComponent : <div />}</>
}

const LoaderRenderer: React.FC = () => {
  return <CircularProgress />
}
